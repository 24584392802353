import { formatBudgetSpend, formatNumber } from 'utils/numbers';
import { v4 as uuidv4 } from 'uuid';

export const normalizePacingLineItems = (data = []) => data.map((row) => normalizePacingLineItem(row));

export const normalizePacingLineItem = (row) => {
  const {
    impression_budget,
    cpm_micro_usd,
    external_spend_micro_usd,
    spend_budget_micro_usd,
    external_impressions,
    external_pacing_percent,
    internal_pacing_percent,
    margin_percent,
  } = row;

  const cpm_micro_usd_formatted = formatNumber(cpm_micro_usd, { format: 'currency-abbreviated', append: '$0' });

  const external_pacing_percent_formatted = formatNumber(external_pacing_percent, {
    format: 'percentage-rounded',
    append: 'N/A',
  });
  const internal_pacing_percent_formatted = formatNumber(internal_pacing_percent, {
    format: 'percentage-rounded',
    append: 'N/A',
  });
  const margin_percent_formatted = formatNumber(margin_percent, { format: 'percentage-rounded', append: 'N/A' });

  const spend_budget_usd = formatBudgetSpend(spend_budget_micro_usd);
  const spend_budget_usd_formatted = formatNumber(spend_budget_usd, { format: 'currency-abbreviated', append: '$0' });
  const spend_budget_usd_comma = formatNumber(spend_budget_usd, { format: 'currency-comma', append: '$0' });

  const external_spend_usd = formatBudgetSpend(external_spend_micro_usd);
  const external_spend_usd_formatted = formatNumber(external_spend_usd, {
    format: 'currency-abbreviated',
    append: '$0',
  });
  const external_spend_usd_comma = formatNumber(external_spend_usd, {
    format: 'currency-comma',
    append: '$0',
  });

  const external_impressions_formatted = formatNumber(external_impressions, { format: 'number-abbreviated' });
  const external_impressions_comma = formatNumber(external_impressions, { format: 'number-comma' });

  const impression_budget_formatted = formatNumber(impression_budget, { format: 'number-abbreviated', append: 0 });
  const impression_budget_comma = formatNumber(impression_budget, { format: 'number-comma', append: 0 });

  const impressions_budget_difference = impression_budget - external_impressions;
  const impressions_budget_difference_formatted = formatNumber(impressions_budget_difference, {
    format: 'number-abbreviated',
  });

  const spend_budget_difference = spend_budget_usd - external_spend_usd;
  const spend_budget_difference_formatted = formatNumber(spend_budget_difference, {
    format: 'currency-abbreviated',
  });
  return {
    ...row,
    cpm_micro_usd_formatted,
    external_spend_usd,
    impressions_budget_difference,
    impressions_budget_difference_formatted,
    spend_budget_difference,
    spend_budget_difference_formatted,
    external_spend_usd_formatted,
    external_spend_usd_comma,
    external_impressions_formatted,
    external_impressions_comma,
    spend_budget_usd,
    spend_budget_usd_formatted,
    spend_budget_usd_comma,
    impression_budget_formatted,
    impression_budget_comma,
    external_pacing_percent_formatted,
    internal_pacing_percent_formatted,
    margin_percent_formatted,
    uuid: uuidv4(),
  };
};

import { CLEAR_ADVERTISERS, SET_ADVERTISERS } from '../types';

export const advertisersReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ADVERTISERS:
      return action.payload;
    case CLEAR_ADVERTISERS:
      return [];
    default:
      return state;
  }
};

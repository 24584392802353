import { CLEAR_FREQUENCY_CAP_INTERVALS_LIST, SET_FREQUENCY_CAP_INTERVALS_LIST } from '../types';

export const frequencyCapIntervalsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FREQUENCY_CAP_INTERVALS_LIST:
      return action.payload;
    case CLEAR_FREQUENCY_CAP_INTERVALS_LIST:
      return [];
    default:
      return state;
  }
};

/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Dropzone, Input, Modal } from '@cognitiv/cyprus-ui';
import { clearList } from 'ducks/actions/lists';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createAdvertiserList, updateList } from 'ducks/operators/lists';
import { selectFeatureLists } from 'ducks/selectors/feature-definitions';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  list_name: '',
  feature_definition_id: null,
  state_id: 1,
  list_content: [],
};

export const ManageList = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const { list, feature_definitions_list, modals } = useSelector(
    (state) => ({
      list: state.list,
      modals: state.modals,
      feature_definitions_list: selectFeatureLists(state.feature_definitions_list),
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...list });
  }, [list]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on list page
    if (!match.params.list_id) {
      dispatch(clearList());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_list: false }));
  };

  const setUpload = async (file) => {
    try {
      const text = await readCsv(file[0]);
      const contents = text.split('\n');
      const list_content = contents.map((l) => String(l).trim());
      onChange({ list_content });
    } catch (err) {
      handleError(err);
    }
  };

  const readCsv = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsText(file);
    });

  const onSubmit = async (e) => {
    e.preventDefault();
    const { advertiser_id } = match.params;
    const { list_id } = list;
    const { search, pathname } = location;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        list_name: form.list_name,
        feature_definition_id: form.feature_definition_id,
        state_id: form.state_id,
        list_content: form.list_content,
        add_content: form.list_content,
      };

      if (list_id) {
        await dispatch(updateList(list_id, data));
      }
      if (!list_id) {
        await dispatch(createAdvertiserList(advertiser_id, data));
      }

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('list has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { list_id } = list;
  const { list_name, feature_definition_name } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_list"
      show={modals.manage_list}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{list_id ? 'Update List' : 'Create List'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus={!list_id}
          label="List Name"
          value={list_name}
          disabled={list_id}
          onChange={(value) => onChange({ list_name: value })}
        />
        <Dropdown
          label="Select List Type"
          options={feature_definitions_list}
          option_key="feature_definition_name"
          value={feature_definition_name}
          disabled={list_id}
          placeholder="List Type"
          margin="15px 0px 0px 0px"
          onSelect={(item) =>
            onChange({
              feature_definition_name: item.feature_definition_name,
              feature_definition_id: item.feature_definition_id,
            })
          }
        />
        <Dropzone label="Upload Document" margin="15px 0px 0px 0px" height="150px" accept=".csv" onDrop={setUpload} />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit">
            {list_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageList.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import { CLEAR_LINE_ITEMS_PACING, SET_LINE_ITEMS_PACING } from '../types';

export const lineItemsPacingReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LINE_ITEMS_PACING:
      return action.payload;
    case CLEAR_LINE_ITEMS_PACING:
      return [];
    default:
      return state;
  }
};

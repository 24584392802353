import { updateSettings } from 'ducks/actions/settings';
import { setStrategies } from 'ducks/actions/strategies';
import { normalizeChangeOrderStrategies, normalizeStrategies } from 'ducks/normalizers/strategies';
import { Gemini } from 'utils/axios';

export const getFlightStrategies = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/flights/${entity_id}/strategies`);

  const { frequency_cap_intervals_list } = getState();
  const strategies = await normalizeStrategies(res.data, frequency_cap_intervals_list);
  await dispatch(setStrategies(strategies));
  return strategies;
};

export const getLineItemStrategies = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/line_items/${entity_id}/strategies`);

  const { frequency_cap_intervals_list } = getState();
  const strategies = await normalizeStrategies(res.data, frequency_cap_intervals_list);
  await dispatch(setStrategies(strategies));
  return strategies;
};

export const getChangeOrderStrategies = (change_order_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/change_orders/${change_order_id}/strategies/view/change_order`);

  const { frequency_cap_intervals_list } = getState();
  const strategies = normalizeChangeOrderStrategies(res.data, frequency_cap_intervals_list);
  await dispatch(setStrategies(strategies));
};

export const createChangeOrderStrategy = (change_order_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${change_order_id}/change_order_strategies/create`, data);
  return res.data;
};

export const autoCreateStrategies = (campaign_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${campaign_id}/strategies/create`);
  await dispatch(updateSettings({ loading: false }));
  return res.data;
};

export const copyStrategies = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/strategies/copy', data);
  return res.data;
};

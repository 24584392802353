import React, { useEffect } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { clearOverlay } from 'ducks/actions/overlay';
import { updateSettings } from 'ducks/actions/settings';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CreativePreview from '../preview/CreativePreview';

import cn from './Overlay.module.scss';

export const Overlay = () => {
  const dispatch = useDispatch();

  const {
    overlay: {
      tag_html,
      creative_width,
      creative_height,
      creative_ratio,
      creative_type_id,
      video_source,
      show_preview_actions = true,
      handleNextPreview,
      handlePreviousPreview,
    },
    settings: { loading },
  } = useSelector(
    (state) => ({
      overlay: state.overlay,
      settings: state.settings,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (creative_type_id === 1 && loading) {
      dispatch(updateSettings({ loading: false }));
    }
  }, [creative_type_id, dispatch, loading]);

  const handleCloseOverlay = (e) => {
    if (e.target.tagName === 'DIV') {
      dispatch(clearOverlay());
      dispatch(updateSettings({ loading: false }));
    }
  };

  return (
    <div onClick={handleCloseOverlay} className={classNames(cn.overlay, { [cn.overlayColor]: !loading })}>
      <div className={cn.preview}>
        {show_preview_actions && (
          <div onClick={handlePreviousPreview}>
            <FontAwesomeIcon size="3x" className={cn.pink} icon={faChevronLeft} />
          </div>
        )}
        {loading && creative_type_id === 1 ? null : (
          <CreativePreview
            is_transformed={false}
            tag_html={tag_html}
            creative_width={creative_width}
            creative_height={creative_height}
            creative_ratio={creative_ratio}
            creative_type_id={creative_type_id}
            video_url={video_source?.url}
            height={video_source?.height ?? '100%'}
            width={video_source?.width ?? '100%'}
          />
        )}
        {show_preview_actions && (
          <div onClick={handleNextPreview}>
            <FontAwesomeIcon size="3x" className={cn.pink} icon={faChevronRight} />
          </div>
        )}
      </div>
      <div className={cn.button}>
        <div className={cn.relative} onClick={() => dispatch(clearOverlay())}>
          <FontAwesomeIcon size="1x" className={cn.pink} icon={['fas', 'times']} />
        </div>
      </div>
    </div>
  );
};

import { Gemini } from '../../utils/axios';
import { replaceQuery } from '../../utils/queries';
import { setAdvertiserModels, setModels } from '../actions/models';
import { updateSettings } from '../actions/settings';
import { normalizeAdvertiserModels, normalizeModels } from '../normalizers/models';

import { getAdvertisers } from './advertisers';

export const getModelsPage =
  (queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const query = {
      include_archived: false,
      ...queries,
    };

    await Promise.all([dispatch(getAdvertisers()), dispatch(getModels(query))]);
    await dispatch(updateSettings({ loading: false }));
  };

export const getModels = (query) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/models/list${replaceQuery(query)}`);
  const models = await normalizeModels(res.data);
  await dispatch(setModels(models));
  return models;
};

export const createModels = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/models/create', data);
  return res.data;
};

export const updateModels = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/models/update`, data);
  return res.data;
};

export const archiveUnarchiveModel = (model_id, type) => async (dispatch) => {
  if (!model_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/models/${model_id}/${type}`);
};

export const getAdvertiserModels = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    include_archived: false,
    ...queries,
  };
  const res = await Gemini.get(`/advertisers/${entity_id}/models${replaceQuery(query)}`);
  const models = await normalizeAdvertiserModels(res.data);

  await dispatch(setAdvertiserModels(models));
  return models;
};

import { CLEAR_STRATEGY_TEMPLATE, SET_STRATEGY_TEMPLATE } from 'ducks/types';

export const strategyTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_STRATEGY_TEMPLATE:
      return {
        ...action.payload,
      };
    case CLEAR_STRATEGY_TEMPLATE:
      return {};
    default:
      return state;
  }
};

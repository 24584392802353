import { CLEAR_CAMPAIGN_DAILY_PACING, SET_CAMPAIGN_DAILY_PACING } from '../types';

// empty header needs to be available for the table
const initial_state = {
  headers_ideal: [{ flex_grow: 1, name: 'N/A', uuid: 1 }],
  headers_actual: [{ flex_grow: 1, name: 'N/A', uuid: 1 }],
  rows: [],
  legend_values: [],
  measure_type_id: 17,
  measure_type_name: 'Pacing %',
  low: '90%',
  low_mid: '95%',
  high_mid: '100%',
  high: '105%',
};
export const campaignDailyPacingReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_DAILY_PACING:
      return action.payload;
    case CLEAR_CAMPAIGN_DAILY_PACING:
      return { ...initial_state };
    default:
      return state;
  }
};

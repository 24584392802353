import { CLEAR_CREATIVE_TYPES_LIST, SET_CREATIVE_TYPES_LIST } from '../types';

export const creativeTypesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CREATIVE_TYPES_LIST:
      return action.payload;
    case CLEAR_CREATIVE_TYPES_LIST:
      return [];
    default:
      return state;
  }
};

import { CLEAR_PHYSICAL_TAG, SET_PHYSICAL_TAG } from '../types';

const initial_state = {
  tag_id: null,
  tag_raw_data: [],
  tag_rule: null,
  tag_type_id: null,
  tag_type_name: '',
  tag_variables: [],
  tag_variables_formatted: [],
};
export const physicalTagReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_PHYSICAL_TAG:
      return action.payload;
    case CLEAR_PHYSICAL_TAG:
      return { ...initial_state };
    default:
      return state;
  }
};

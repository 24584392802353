import { CLEAR_KPI_INTERVALS, SET_KPI_INTERVALS } from '../types';

export const kpiIntervalsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_KPI_INTERVALS:
      return action.payload;
    case CLEAR_KPI_INTERVALS:
      return [];
    default:
      return state;
  }
};

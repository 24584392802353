import { colors } from 'utils/colors';
import { getColorFromRange } from 'utils/helpers';
import { checkSort, getCharCount } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeFilters = (data) =>
  data.map((row, i) => normalizeFilter(row, i)).sort((a, b) => checkSort(a, b, 'filter_name', 'asc'));

export const normalizeFilter = (row, index) => {
  const { filter_json } = row;

  const criteria = filter_json.reduce(
    (acc, next) => {
      switch (next.feature_definition_id) {
        case 30: // countries
        case 31: // states
        case 33: // cities
          return {
            ...acc,
            criteria_geo: [...acc.criteria_geo, next],
          };
        case 7: // domains
          return {
            ...acc,
            criteria_domains: [...acc.criteria_domains, next],
          };
        case 107: // video
          return {
            ...acc,
            criteria_video: [...acc.criteria_video, next],
          };
        case 154: // segments
          return {
            ...acc,
            criteria_segments: [...acc.criteria_segments, next],
          };
        case 157: // regex
          // eslint-disable-next-line no-case-declarations
          const value = next?.value.replace(/\\\\+/g, `\\`) || '';
          return {
            ...acc,
            criteria_other: [...acc.criteria_other, { ...next, value, label: 'Normalized URL' }],
          };
        case 158: // custom input
          return {
            ...acc,
            criteria_other: [...acc.criteria_other, { ...next, value: next.value, label: 'Custom Input' }],
          };

        default:
          return {
            ...acc,
            criteria_other: [...acc.criteria_other, next],
          };
      }
    },
    {
      criteria_geo: [],
      criteria_video: [],
      criteria_domains: [],
      criteria_segments: [],
      criteria_other: [],
    },
  );

  const count = getCharCount(String(row.filter_id) || index);

  return {
    color: getColorFromRange(colors, count),
    criteria_geo: [],
    criteria_video: [],
    criteria_domains: [],
    criteria_segments: [],
    criteria_other: [],
    ...row,
    ...criteria,
    filter_name_with_id: `${row.filter_id} ${row.filter_name}`,
    uuid: uuidv4(),
  };
};

import { formatBudgetSpend, formatNumber } from 'utils/numbers';

export const normalizeCampaignMetrics = (data = {}, measure_types) => {
  const {
    dsp_pacing_percent,
    external_impression_spend_budget,
    external_p_kpi,
    external_spend_budget,
    impression_spend_budget,
    internal_p_kpi,
    p_kpi_measure_type_id,
    pacing_percent,
    spend_budget,
    p_kpi_target,
  } = data;

  const measure_type = measure_types.find((m) => m.measure_type_id === p_kpi_measure_type_id) || {
    measure_type_abbreviated: 'currency-abbreviated',
  };

  const external_p_kpi_formatted = formatNumber(external_p_kpi, { format: measure_type.measure_type_abbreviated });
  const internal_p_kpi_formatted = formatNumber(internal_p_kpi, { format: measure_type.measure_type_abbreviated });
  const p_kpi_target_formatted = formatNumber(p_kpi_target, { format: measure_type.measure_type_abbreviated });

  const dsp_pacing_percent_formatted = formatNumber(dsp_pacing_percent, { format: 'percentage-rounded' });
  const pacing_percent_formatted = formatNumber(pacing_percent, { format: 'percentage-rounded' });

  const external_spend = formatBudgetSpend(external_spend_budget, { append: 0 });
  const external_spend_formatted = formatNumber(external_spend, { format: 'currency-abbreviated' });

  const spend = formatBudgetSpend(spend_budget, { append: 0 });
  const spend_formatted = formatNumber(spend, { format: 'currency-abbreviated' });

  const impression_spend_budget_formatted = formatNumber(impression_spend_budget, { format: 'number-abbreviated' });

  const external_impression_spend = formatNumber(external_impression_spend_budget, { format: 'number-abbreviated' });
  const external_impression_spend_formatted = formatNumber(external_impression_spend, {
    format: 'currency-abbreviated',
  });
  return {
    ...data,
    external_p_kpi_formatted,
    internal_p_kpi_formatted,
    p_kpi_target_formatted,
    dsp_pacing_percent_formatted,
    pacing_percent_formatted,
    external_spend,
    external_spend_formatted,
    spend,
    spend_formatted,
    impression_spend_budget_formatted,
    external_impression_spend,
    external_impression_spend_formatted,
  };
};

import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeAdvertisers = (data = []) =>
  data.map((d) => normalizeAdvertiser(d)).sort((a, b) => checkSort(a, b, 'advertiser_name', 'asc'));

export const normalizeAdvertiser = (row = {}) => ({
  ...row,
  checked: false,
  modified: false,
  uuid: uuidv4(),
});

import { setCampaignDailyPacing } from 'ducks/actions/campaign-daily-pacing';
import { setCampaignPacingPerformance } from 'ducks/actions/campaign-pacing-performance';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCampaignDailyPacing } from 'ducks/normalizers/campaign-daily-pacing';
import { normalizeCampaignPacingPerformance } from 'ducks/normalizers/campaign-pacing-performance';
import { Gemini } from 'utils/axios';
import { getStartDateIncrement, subtractFromDate } from 'utils/dates';

export const getCampaignDailyPacing = (queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    measure_type_id: 17,
    campaign_id: null,
    start_date_utc: getStartDateIncrement(new Date(), { type: 'quarter' }),
    finish_date_utc: subtractFromDate(new Date(), { utc: false }),
    ...queries,
  };

  if (!query.campaign_id) {
    return null;
  }

  // normalizing the queries so they match between reports
  query.start_date = query.start_date_utc;
  query.finish_date = query.finish_date_utc;

  const res = await Gemini.post(`/campaigns/${query.campaign_id}/line_item_metric_report`, query);

  const campaign_daily_pacing = normalizeCampaignDailyPacing(res.data);
  await dispatch(setCampaignDailyPacing(campaign_daily_pacing));
  return campaign_daily_pacing;
};

export const getLineItemDailyPacing = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/line_items/${entity_id}/daily_pacing`);
  const pacing_performance = await normalizeCampaignPacingPerformance(res.data.rows);
  dispatch(setCampaignPacingPerformance(pacing_performance));
  dispatch(updateSettings({ loading: false }));
  return pacing_performance;
};

import { setCreativePrices } from 'ducks/actions/creative-prices';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCreativePrices } from 'ducks/normalizers/creative-prices';
import { Gemini } from 'utils/axios';

export const getCreativePrices = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/${entity_id}/prices`);
  const creative_prices = await normalizeCreativePrices(res.data);
  await dispatch(setCreativePrices(creative_prices));
  return creative_prices;
};

export const createCreativePrices = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/creatives/${entity_id}/prices/create`, data);
  return res.data;
};

export const removeCreativePrices = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/creatives/${entity_id}/prices/remove`, data);
  return res.data;
};

import { formatMicroUsd, formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeCreativePixels = (data = []) =>
  data.map((row) => ({
    pixel_url: row.url,
    pixel_type_id: row.pixel_type_id,
    pixel_type_name: row.pixel_type_name,
    saved: true,
    uuid: uuidv4(),
  }));

export const normalizeCreativeScripts = (data = []) =>
  data.map((row) => ({ creative_script_name: row, uuid: uuidv4() }));

export const normalizeCreatives = (data = []) =>
  data.map((row) => normalizeCreative(row)).sort((a, b) => checkSort(a, b, 'creative_name', 'desc'));

export const normalizeCreative = (row) => {
  const {
    creative_name,
    price_micro_usd,
    creative_price_micro_usd,
    creative_type_id,
    creative_size_name,
    pixels,
    scripts,
  } = row;
  const price_usd = formatMicroUsd(price_micro_usd);
  const price_usd_formatted = formatNumber(price_usd, { format: 'currency-abbreviated', append: '$0' });
  const price_usd_comma = formatNumber(price_usd, { format: 'currency-comma', append: '$0' });

  const creative_price_usd = formatMicroUsd(creative_price_micro_usd);
  const creative_price_usd_formatted = formatNumber(creative_price_usd, {
    format: 'currency-abbreviated',
    append: '$0',
  });
  const creative_price_usd_comma = formatNumber(creative_price_usd, { format: 'currency-comma', append: '$0' });

  const psa_validation = creative_name.toLowerCase().includes('psa');

  let creative_width = 0;
  let creative_height = 0;
  let creative_ratio = 1;

  if (creative_type_id === 1) {
    const size = String(creative_size_name).split('x');
    creative_width = parseInt(size[0] || 0);
    creative_height = parseInt(size[1] || 0);
    creative_ratio = parseInt(size[0]) / parseInt(size[1]);
  }

  return {
    ...row,
    creative_width,
    creative_height,
    creative_ratio,
    psa_validation: psa_validation === row.is_psa,
    price_usd,
    price_usd_formatted,
    price_usd_comma,
    creative_price_usd,
    creative_price_usd_formatted,
    creative_price_usd_comma,
    pixels: pixels || [],
    scripts: scripts || [],
    creative_name_with_id: `${row.creative_id} ${row.creative_name}`,
    line_item_name_with_id: `${row.line_item_creative_id} ${row.line_item_name}`,
    uuid: uuidv4(),
  };
};

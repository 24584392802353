import * as constants from 'ducks/types';

export const setCreativePrices = (payload = []) => ({
  type: constants.SET_CREATIVE_PRICES,
  payload,
});

export const clearCreativePrices = () => ({
  type: constants.CLEAR_CREATIVE_PRICES,
});

export const colors = [
  // 'rgba(255, 154, 162, 0.8)',
  // 'rgba(255, 183, 178, 0.8)',
  'rgba(255, 218, 193, 0.8)',
  'rgba(226, 240, 203, 0.8)',
  'rgba(181, 234, 215, 0.8)',
  'rgba(199, 206, 234, 0.8)',
  'rgba(255, 154, 162, 0.6)',
  'rgba(255, 183, 178, 0.6)',
  'rgba(255, 218, 193, 0.6)',
  'rgba(226, 240, 203, 0.6)',
  'rgba(181, 234, 215, 0.6)',
  'rgba(199, 206, 234, 0.6)',
  'rgba(255, 154, 162, 0.4)',
  // 'rgba(255, 183, 178, 0.4)',
  'rgba(255, 218, 193, 0.4)',
  'rgba(226, 240, 203, 0.4)',
  'rgba(181, 234, 215, 0.4)',
  'rgba(199, 206, 234, 0.4)',
];

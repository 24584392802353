import * as constants from 'ducks/types';

export const setCampaignPacingPerformance = (payload = {}) => ({
  type: constants.SET_CAMPAIGN_PACING_PERFORMANCE,
  payload,
});

export const clearCampaignPacingPerformance = () => ({
  type: constants.CLEAR_CAMPAIGN_PACING_PERFORMANCE,
});

import * as constants from 'ducks/types';

export const setCreativeExtras = (payload = {}) => ({
  type: constants.SET_CREATIVE_EXTRAS,
  payload,
});

export const clearCreativeExtras = () => ({
  type: constants.CLEAR_CREATIVE_EXTRAS,
});

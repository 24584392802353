export const transformStrategyTemplates = (templates = []) => templates.map((t) => transformStrategyTemplate(t));

export const transformStrategyTemplate = (data = {}) => ({
  strategy_template_id: data.strategy_template_id,
  strategy_template_name: data.strategy_template_name,
  bid_algorithm_id: data.bid_algorithm_id,
  line_item_type_id: data.line_item_type_id,
  creative_selection_id: data.creative_selection_id,
  strategy_budget_type_id: data.strategy_budget_type_id,
  budget_weight: null,
  filter_json: data.filter_json,
  bid_algorithm_json: data.bid_algorithm_json,
  creative_selection_json: data.creative_selection_json,
  pacing_weight_json: data.pacing_weight_json,
  state_id: 1,
  daily_overspend_factor: data.daily_overspend_factor,
  intervals_per_day: data.intervals_per_day,
  control_systems_json: data.control_systems_json,
  pricing_json: data.pricing_json,
  scoring_json: data.scoring_json,
  model_json: data.model_json,
});

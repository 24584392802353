import {
  CLEAR_STRATEGIES,
  INSERT_STRATEGY,
  MERGE_CHANGE_ORDER_STRATEGIES,
  MERGE_STRATEGY,
  SET_STRATEGIES,
  UPDATE_STRATEGIES,
} from '../types';

export const strategiesReducer = (state = [], action) => {
  switch (action.type) {
    case CLEAR_STRATEGIES:
      return [];
    case SET_STRATEGIES:
      return action.payload;
    case UPDATE_STRATEGIES: {
      const update = { ...action.payload };
      const strategies = [...state];
      return strategies.map((strategy) => ({
        ...strategy,
        ...update,
      }));
    }
    case MERGE_STRATEGY: {
      const strategies = [...state];
      const update = { ...action.payload };
      const index = strategies.findIndex((d) => d.strategy_id === update.strategy_id);
      if (index === -1) {
        strategies.push(update);
        return strategies;
      }
      strategies[index] = { ...strategies[index], ...update };
      return strategies;
    }
    case MERGE_CHANGE_ORDER_STRATEGIES: {
      const strategies = [...state];
      const update = [...action.payload];
      update.forEach((u) => {
        const index = strategies.findIndex((s) => s.change_order_strategy_id === u.change_order_strategy_id);
        if (index === -1) {
          strategies.push(u);
        }
        if (index !== -1) {
          strategies[index] = { ...strategies[index], ...u };
        }
      });
      return strategies;
    }
    case INSERT_STRATEGY: {
      const strategies = [...state];
      const strategy = action.payload;
      strategies.push(strategy);
      return strategies;
    }
    default:
      return state;
  }
};

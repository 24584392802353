import { CLEAR_PANELS, SET_PANELS } from '../types';

export const panelsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PANELS:
      return action.payload;
    case CLEAR_PANELS:
      return [];
    default:
      return state;
  }
};

import { setDeals, setGlobalDeals } from 'ducks/actions/deals';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeDeals, normalizeLineItemDeals, normalizeStrategyDeals } from 'ducks/normalizers/deals';
import { Gemini } from 'utils/axios';

export const getDealsPage =
  (show_archived = false) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const [partners] = await Promise.all([dispatch(getDeals(show_archived))]);
    await dispatch(updateSettings({ loading: false }));
    return partners;
  };

export const getDeals =
  (show_archived = false) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const res = await Gemini.get(`/deals?include_archived=${show_archived}`);
    const deals = normalizeDeals(res.data);
    dispatch(setGlobalDeals(deals));
    return deals;
  };

export const updateDeal = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/deals/${entity_id}/update`, data);
  return res.data;
};

export const createDeal = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/deals/create`, data);
  return res.data;
};

export const getLineItemDeals = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/line_items/${entity_id}/deals`);
  const deals = normalizeLineItemDeals(res.data);
  await dispatch(setDeals(deals));
  return deals;
};

export const createLineItemDeals = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/deals/line_items/create', data);
  return res.data;
};

export const getStrategyDeals = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/strategies/${entity_id}/deals`);
  const deals = normalizeStrategyDeals(res.data);
  await dispatch(setDeals(deals));
  return deals;
};

export const createStrategyDeals = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/deals/strategies/create', data);
  return res.data;
};

import moment from 'moment-timezone';

export const getTimeZone = () => {
  const timeZoneContry = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.tz(timeZoneContry).format('z');
};

export const getNewYorkDST = () => {
  const new_york = moment().tz('America/New_York').format();
  return moment(new_york).isDST();
};

import * as constants from 'ducks/types';

export const setCreativePixels = (payload = []) => ({
  type: constants.SET_CREATIVE_PIXELS,
  payload,
});

export const clearCreativePixels = () => ({
  type: constants.CLEAR_CREATIVE_PIXELS,
});

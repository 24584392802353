import React from 'react';
import * as PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import cn from './Navigation.module.scss';

export const StrategyTemplatesWithRouter = () => {
    const selectStrategyTemplates = () => `/strategy-templates`;

    return (
        <>
            <div className={cn.items}>
                <Link className={cn.item} to={selectStrategyTemplates}>
                    Strategy Templates
                </Link>
                <div className={cn.flex} />
            </div>
        </>
    );
};

StrategyTemplatesWithRouter.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export const StrategyTemplates = withRouter(StrategyTemplatesWithRouter);
import { setCreativeAddons } from 'ducks/actions/creative-addons';
import { updateSettings } from 'ducks/actions/settings';
import { Gemini } from 'utils/axios';
import { uniqueIdentifiers } from 'utils/helpers';

export const getCreativeAddons = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/${entity_id}/add_ons`);
  const creative_addons = await uniqueIdentifiers(res.data);
  await dispatch(setCreativeAddons(creative_addons));
  return creative_addons;
};

import { CLEAR_THIRD_PARTY_REPORT_DELIVERIES_LIST, SET_THIRD_PARTY_REPORT_DELIVERIES_LIST } from '../types';

export const thirdPartyReportDeliveriesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_THIRD_PARTY_REPORT_DELIVERIES_LIST:
      return action.payload;
    case CLEAR_THIRD_PARTY_REPORT_DELIVERIES_LIST:
      return [];
    default:
      return state;
  }
};

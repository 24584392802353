import { CLEAR_MODEL, SET_MODEL } from '../types';

const initial_state = {};
export const modelReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_MODEL:
      return { ...state, ...action.payload };
    case CLEAR_MODEL:
      return initial_state;
    default:
      return state;
  }
};

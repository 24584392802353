import { checkSort } from 'utils/string';

export const removeTagsByState = (tags = [], state_id) =>
  tags.filter((c) => c.state_id !== state_id).sort((a, b) => checkSort(a, b, 'tag_name', 'desc'));

export const selectAdvertiserTagsCsv = (rows) => {
  const csv_data = [['Tag Name', 'Script Tag', 'Image Tag']];

  for (let i = 0; i < rows.length; i++) {
    csv_data.push([rows[i].tag_name, rows[i].script_html, rows[i].image_html]);
  }

  return csv_data;
};

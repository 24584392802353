import { updateSettings } from 'ducks/actions/settings';
import { setThirdPartyReports } from 'ducks/actions/third-party-reports';
import { normalizeThirdPartyReports } from 'ducks/normalizers/third-party-reports';
import { Gemini } from 'utils/axios';

export const getCampaignThirdPartyReports = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/third_party_reports`);
  const third_party_reports = normalizeThirdPartyReports(res.data);
  dispatch(setThirdPartyReports(third_party_reports));
  return third_party_reports;
};

export const updateCampaignThirdPartyReport = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/third_party_reports/update`, data);
  return res.data;
};

export const createCampaignThirdPartyReport = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/third_party_reports/create`, data);
  return res.data;
};

export const deleteCampaignThirdPartyReport = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/third_party_reports/delete`, data);
  return res.data;
};

import { CLEAR_MODEL_OUTPUTS, SET_MODEL_OUTPUTS } from '../types';

export const modelOutputsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_MODEL_OUTPUTS:
      return action.payload;
    case CLEAR_MODEL_OUTPUTS:
      return [];
    default:
      return state;
  }
};

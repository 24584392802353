/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Dropdown } from '@cognitiv/cyprus-ui';
import { Select } from 'components/cells/Select';
import { State } from 'components/cells/State';

import { Action } from '../../components/cells/Action';
import { icons } from '../../FaIcons';

const cellColors = (activated, updated) => {
  if (activated) {
    return '#e4edf9';
  }
  if (updated) {
    return '#fff4d3';
  }
  return false;
};

export const SelectCell = ({ row, onClick, settings }) => (
  <Cell onClick={() => onClick({ ...row, checked: !row.checked })} background={cellColors(row.activated)}>
    <Select tooltip="Select Model" checked={row.checked} height={settings.row_height} />
  </Cell>
);

export const ModelNameCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <State state_id={row.state_id}>{row.model_name}</State>
  </Cell>
);

export const ModelIdCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <p style={{ textAlign: 'center' }}>{row.model_id}</p>
  </Cell>
);

export const ModelDisplayCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <p style={{ textAlign: 'center' }}>{row.model_display_name}</p>
  </Cell>
);

export const AdvertisersCountCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <p style={{ textAlign: 'center' }}>{row.advertiser_count || 0}</p>
  </Cell>
);

export const StrategiesCountCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <p style={{ textAlign: 'center' }}>{row.strategies_count || 0}</p>
  </Cell>
);

export const CreatedDateCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <p style={{ textAlign: 'center' }}>{row.created_date_formatted}</p>
  </Cell>
);

export const ImportedDateCell = ({ row, onClick, settings }) => (
  <Cell
    onClick={() => onClick({ ...row, activated: !row.activated })}
    height={settings.row_height}
    background={cellColors(row.activated)}
  >
    <p style={{ textAlign: 'center' }}>{row.imported_date_formatted}</p>
  </Cell>
);

export const AdvertiserSelectCell = ({ row, onClick, settings }) => (
  <Cell onClick={() => onClick({ ...row, checked: !row.checked })}>
    <Select tooltip="Associate with Modal" checked={row.checked} height={settings.row_height} />
  </Cell>
);

export const AdvertiserNameCell = ({ row }) => (
  <Cell align="left">
    <p>{row.advertiser_name}</p>
  </Cell>
);

export const AdvertiserIdCell = ({ row }) => (
  <Cell align="center">
    <p>{row.advertiser_id}</p>
  </Cell>
);

export const OutputIdCell = ({ row }) => (
  <Cell align="center">
    <p>{row.output_index}</p>
  </Cell>
);

export const OutputNameCell = ({ row }) => (
  <Cell align="center">
    <p>{row.output_name}</p>
  </Cell>
);

export const OutputDefaultScoreTypeCell = ({ row, options, onSelect }) => (
  <Cell>
    <Dropdown
      options={options}
      option_key="score_type_name"
      value={row.default_score_type_name}
      onSelect={(item) =>
        onSelect({
          uuid: row.output_index,
          default_score_type_name: item.score_type_name,
          default_score_type_id: item.score_type_id,
        })
      }
      transparent
    />
  </Cell>
);

export const StatusCell = ({ row, onClick }) => {
  const is_active = row.state_id === 1;
  return (
    <Cell background={cellColors(row.activated)} onClick={() => onClick(row, is_active ? 4 : 1)}>
      <Action
        tooltip={`${is_active ? 'Deactivate' : 'Activate'} Advertiser`}
        icon={icons[is_active ? 'faUnlock' : 'faLock']}
      />
    </Cell>
  );
};

export const EditModelCell = ({ row, onClick }) => (
  <Cell onClick={() => onClick(row, 'edit')}>
    <Action tooltip="Edit Model" icon={['fas', 'edit']} />
  </Cell>
);

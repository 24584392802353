import * as constants from 'ducks/types';

export const setFlightCreatives = (payload = []) => ({
  type: constants.SET_FLIGHT_CREATIVES,
  payload,
});

export const clearFlightCreatives = () => ({
  type: constants.CLEAR_FLIGHT_CREATIVES,
});

import { CLEAR_FREQUENCY_CAPS, SET_FREQUENCY_CAPS } from '../types';

export const frequencyCapsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FREQUENCY_CAPS:
      return action.payload;
    case CLEAR_FREQUENCY_CAPS:
      return [];
    default:
      return state;
  }
};

import { CLEAR_DEMOGRAPHICS_LIST, SET_DEMOGRAPHICS_LIST } from '../types';

export const demographicsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_DEMOGRAPHICS_LIST:
      return action.payload;
    case CLEAR_DEMOGRAPHICS_LIST:
      return [];
    default:
      return state;
  }
};

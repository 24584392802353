import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearTag } from 'ducks/actions/tags';
import { createTag, updateTag } from 'ducks/operators/tags';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  tag_name: '',
};

export const ManageTag = ({ history, match, location }) => {
  const dispatch = useDispatch();

  const { tag, modals } = useSelector(
    (state) => ({
      tag: state.tag,
      modals: state.modals,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...tag });
  }, [tag]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on tag page
    if (!match.params.tag_id) {
      dispatch(clearTag());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_tag: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { tag_id } = tag;
    const { advertiser_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });
    let attributes = form?.attributes ? [...form.attributes] : [];

    if (attributes.length === 0 && form.expose_insights) {
      attributes.push('centauri');
    } else if (attributes.length > 0) {
      if (form.expose_insights) {
        const check_if_centauri_exist = attributes.includes('centauri');
        if (!check_if_centauri_exist) {
          attributes.push('centauri');
        }
      } else {
        const check_if_centauri_exist = attributes.includes('centauri');
        if (check_if_centauri_exist) {
          attributes = form?.attributes.filter((el) => el !== 'centauri');
        }
      }
    }

    try {
      const data = {
        tag_name: form.tag_name,
        attributes,
      };
      if (tag_id) {
        await dispatch(updateTag(tag_id, data));
      }
      if (!tag_id) {
        await dispatch(createTag(advertiser_id, data));
      }

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('tag has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { tag_id } = tag;
  const { tag_name, expose_insights } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_tag"
      show={modals.manage_tag}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{tag_id ? 'Update Tag' : 'Create Tag'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus
          label="Tag Name"
          value={tag_name}
          onChange={(value) =>
            onChange({
              tag_name: value
                .replace(/[^a-z0-9_]/g, '')
                .trim()
                .toLowerCase(),
            })
          }
        />
        <Checkbox
          onClick={(checked) => onChange({ expose_insights: checked })}
          checked={expose_insights}
          margin="10px 0"
          width="max-content"
        >
          Expose Insights
        </Checkbox>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!tag_name}>
            {tag_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageTag.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

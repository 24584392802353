import * as constants from 'ducks/types';

export const setLists = (payload = []) => ({
  type: constants.SET_LISTS,
  payload,
});

export const clearLists = () => ({
  type: constants.CLEAR_LISTS,
});

export const setList = (payload = {}) => ({
  type: constants.SET_LIST,
  payload,
});

export const clearList = () => ({
  type: constants.CLEAR_LIST,
});

export const setAdServersList = (payload = []) => ({
  type: constants.SET_AD_SERVERS_LIST,
  payload,
});

export const clearAdServersList = () => ({
  type: constants.CLEAR_AD_SERVERS_LIST,
});

export const setAdServerCampaignIdentifiersList = (payload = []) => ({
  type: constants.SET_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST,
  payload,
});

export const clearAdServerCampaignIdentifiersList = () => ({
  type: constants.CLEAR_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST,
});

export const setAttributionTypesList = (payload = []) => ({
  type: constants.SET_ATTRIBUTION_TYPES_LIST,
  payload,
});

export const clearAttributionTypesList = () => ({
  type: constants.CLEAR_ATTRIBUTION_TYPES_LIST,
});

export const clearBidAlgorithmsList = () => ({
  type: constants.CLEAR_BID_ALGORITHMS_LIST,
});

export const setBudgetTypesList = (payload = []) => ({
  type: constants.SET_BUDGET_TYPES_LIST,
  payload,
});

export const clearBudgetTypesList = () => ({
  type: constants.CLEAR_BUDGET_TYPES_LIST,
});

export const setCategoriesList = (payload = []) => ({
  type: constants.SET_CATEGORIES_LIST,
  payload,
});

export const clearCategoriesList = () => ({
  type: constants.CLEAR_CATEGORIES_LIST,
});

export const setChangeOrderStatesList = (payload = []) => ({
  type: constants.SET_CHANGE_ORDER_STATES_LIST,
  payload,
});

export const clearChangeOrderStatesList = () => ({
  type: constants.CLEAR_CHANGE_ORDER_STATES_LIST,
});

export const setCitiesList = (payload = []) => ({
  type: constants.SET_CITIES_LIST,
  payload,
});

export const clearCitiesList = () => ({
  type: constants.CLEAR_CITIES_LIST,
});

export const setCreativeAddonsList = (payload = []) => ({
  type: constants.SET_CREATIVE_ADDONS_LIST,
  payload,
});

export const clearCreativeAddonsList = () => ({
  type: constants.CLEAR_CREATIVE_ADDONS_LIST,
});
export const setCreativeExtrasList = (payload = []) => ({
  type: constants.SET_CREATIVE_EXTRAS_LIST,
  payload,
});

export const clearCreativeExtrasList = () => ({
  type: constants.CLEAR_CREATIVE_EXTRAS_LIST,
});

export const setCreativeSelectionsList = (payload = []) => ({
  type: constants.SET_CREATIVE_SELECTIONS_LIST,
  payload,
});

export const clearCreativeSelectionsList = () => ({
  type: constants.CLEAR_CREATIVE_SELECTIONS_LIST,
});

export const setCreativeTypesList = (payload = []) => ({
  type: constants.SET_CREATIVE_TYPES_LIST,
  payload,
});

export const clearCreativeTypesList = () => ({
  type: constants.CLEAR_CREATIVE_TYPES_LIST,
});

export const setCreativeVideoEventsList = (payload = []) => ({
  type: constants.SET_CREATIVE_VIDEO_EVENTS_LIST,
  payload,
});

export const clearCreativeVideoEventsList = () => ({
  type: constants.CLEAR_CREATIVE_VIDEO_EVENTS_LIST,
});

export const clearCreativesList = () => ({
  type: constants.CLEAR_CREATIVES_LIST,
});

export const setCreativesList = (payload = []) => ({
  type: constants.SET_CREATIVES_LIST,
  payload,
});

export const setCountriesList = (payload = []) => ({
  type: constants.SET_COUNTRIES_LIST,
  payload,
});

export const clearCountriesList = () => ({
  type: constants.CLEAR_COUNTRIES_LIST,
});

export const setDomainsList = (payload = []) => ({
  type: constants.SET_DOMAINS_LIST,
  payload,
});

export const clearDomainsList = () => ({
  type: constants.CLEAR_DOMAINS_LIST,
});

export const setDemographicsList = (payload = []) => ({
  type: constants.SET_DEMOGRAPHICS_LIST,
  payload,
});

export const clearDemographicsList = () => ({
  type: constants.CLEAR_DEMOGRAPHICS_LIST,
});

export const setFeatureDefinitionsList = (payload = []) => ({
  type: constants.SET_FEATURE_DEFINITIONS_LIST,
  payload,
});

export const clearFeatureDefinitionsList = () => ({
  type: constants.CLEAR_FEATURE_DEFINITIONS_LIST,
});

export const setFilterSourcesList = (payload = []) => ({
  type: constants.SET_AD_SERVERS_LIST,
  payload,
});

export const clearFilterSourcesList = () => ({
  type: constants.CLEAR_AD_SERVERS_LIST,
});

export const setFrequencyIntervalsList = (payload = []) => ({
  type: constants.SET_FREQUENCY_INTERVALS_LIST,
  payload,
});

export const clearFrequencyIntervalsList = () => ({
  type: constants.CLEAR_FREQUENCY_INTERVALS_LIST,
});

export const setLineItemTypesList = (payload = []) => ({
  type: constants.SET_LINE_ITEM_TYPES_LIST,
  payload,
});

export const clearLineItemTypesList = () => ({
  type: constants.CLEAR_LINE_ITEM_TYPES_LIST,
});

export const setIntervalsList = (payload = []) => ({
  type: constants.SET_INTERVALS_LIST,
  payload,
});

export const clearIntervalsList = () => ({
  type: constants.CLEAR_INTERVALS_LIST,
});

export const setInventorySourcesList = (payload = []) => ({
  type: constants.SET_INVENTORY_SOURCES_LIST,
  payload,
});

export const clearInventorySourcesList = () => ({
  type: constants.CLEAR_INVENTORY_SOURCES_LIST,
});

export const setFrequencyCapIntervalsList = (payload = []) => ({
  type: constants.SET_FREQUENCY_CAP_INTERVALS_LIST,
  payload,
});
export const clearFrequencyCapIntervalsList = () => ({
  type: constants.CLEAR_FREQUENCY_CAP_INTERVALS_LIST,
});

export const setKpiGroupsList = (payload = []) => ({
  type: constants.SET_KPI_GROUPS_LIST,
  payload,
});

export const clearKpiGroupsList = () => ({
  type: constants.CLEAR_KPI_GROUPS_LIST,
});

export const setMeasureTypesList = (payload = []) => ({
  type: constants.SET_MEASURE_TYPES_LIST,
  payload,
});

export const clearMeasureTypesList = () => ({
  type: constants.CLEAR_MEASURE_TYPES_LIST,
});

export const setMeasureUnitsList = (payload = []) => ({
  type: constants.SET_MEASURE_UNITS_LIST,
  payload,
});

export const clearMeasureUnitsList = () => ({
  type: constants.CLEAR_MEASURE_UNITS_LIST,
});

export const setMetroAreasList = (payload = []) => ({
  type: constants.SET_METRO_AREAS_LIST,
  payload,
});

export const clearMetroAreasList = () => ({
  type: constants.CLEAR_METRO_AREAS_LIST,
});

export const setPlatformsList = (payload = []) => ({
  type: constants.SET_PLATFORMS_LIST,
  payload,
});

export const clearPlatformsList = () => ({
  type: constants.CLEAR_PLATFORMS_LIST,
});

export const setRegionsList = (payload = []) => ({
  type: constants.SET_REGIONS_LIST,
  payload,
});

export const clearRegionsList = () => ({
  type: constants.CLEAR_REGIONS_LIST,
});

export const setOperatingSystemsList = (payload = []) => ({
  type: constants.SET_OPERATING_SYSTEMS_LIST,
  payload,
});

export const clearOperatingSystemsList = () => ({
  type: constants.CLEAR_OPERATING_SYSTEMS_LIST,
});

export const setPostalCodesList = (payload = []) => ({
  type: constants.SET_POSTAL_CODES_LIST,
  payload,
});

export const clearPostalCodesList = () => ({
  type: constants.CLEAR_POSTAL_CODES_LIST,
});

export const setStatesList = (payload = []) => ({
  type: constants.SET_STATES_LIST,
  payload,
});

export const clearStatesList = () => ({
  type: constants.CLEAR_STATES_LIST,
});

export const setStrategyBudgetTypesList = (payload = []) => ({
  type: constants.SET_STRATEGY_BUDGET_TYPES_LIST,
  payload,
});

export const clearStrategyBudgetTypesList = () => ({
  type: constants.CLEAR_STRATEGY_BUDGET_TYPES_LIST,
});

export const setRevenueTypesList = (payload = []) => ({
  type: constants.SET_REVENUE_TYPES_LIST,
  payload,
});

export const clearRevenueTypesList = () => ({
  type: constants.CLEAR_REVENUE_TYPES_LIST,
});

export const setVendorFeesList = (payload = []) => ({
  type: constants.SET_VENDOR_FEES_LIST,
  payload,
});
export const clearVendorFeesList = () => ({
  type: constants.CLEAR_VENDOR_FEES_LIST,
});

export const setVendorsList = (payload = []) => ({
  type: constants.SET_VENDORS_LIST,
  payload,
});

export const clearVendorsList = () => ({
  type: constants.CLEAR_VENDORS_LIST,
});

export const setVideoBitratesList = (payload = []) => ({
  type: constants.SET_VIDEO_BITRATES_LIST,
  payload,
});

export const clearVideoBitratesList = () => ({
  type: constants.CLEAR_VIDEO_BITRATES_LIST,
});

export const setVideoMimeTypesList = (payload = []) => ({
  type: constants.SET_VIDEO_MIME_TYPES_LIST,
  payload,
});

export const clearVideoMimeTypesList = () => ({
  type: constants.CLEAR_VIDEO_MIME_TYPES_LIST,
});

export const setVideoPlacementsList = (payload = []) => ({
  type: constants.SET_VIDEO_PLACEMENTS_LIST,
  payload,
});

export const clearVideoPlacementsList = () => ({
  type: constants.CLEAR_VIDEO_PLACEMENTS_LIST,
});

export const setCampaignRolesList = (payload = []) => ({
  type: constants.SET_CAMPAIGN_ROLES_LIST,
  payload,
});

export const clearCampaignRolesList = () => ({
  type: constants.CLEAR_CAMPAIGN_ROLES_LIST,
});

export const setThirdPartyReportSourcesList = (payload = []) => ({
  type: constants.SET_THIRD_PARTY_REPORT_SOURCES_LIST,
  payload,
});

export const clearThirdPartyReportSourcesList = () => ({
  type: constants.CLEAR_THIRD_PARTY_REPORT_SOURCES_LIST,
});

export const setThirdPartyReportCadencesList = (payload = []) => ({
  type: constants.SET_THIRD_PARTY_REPORT_CADENCES_LIST,
  payload,
});

export const clearThirdPartyReportCadencesList = () => ({
  type: constants.CLEAR_THIRD_PARTY_REPORT_CADENCES_LIST,
});

export const setThirdPartyReportDeliveriesList = (payload = []) => ({
  type: constants.SET_THIRD_PARTY_REPORT_DELIVERIES_LIST,
  payload,
});

export const clearThirdPartyReportDeliveriesList = () => ({
  type: constants.CLEAR_THIRD_PARTY_REPORT_DELIVERIES_LIST,
});

export const setTagTypesList = (payload = []) => ({
  type: constants.SET_TAG_TYPES_LIST,
  payload,
});

export const clearTagTypesList = () => ({
  type: constants.CLEAR_TAG_TYPES_LIST,
});

export const setTagOperatorsList = (payload = []) => ({
  type: constants.SET_TAG_OPERATORS_LIST,
  payload,
});

export const clearTagOperatorsList = () => ({
  type: constants.CLEAR_TAG_OPERATORS_LIST,
});

export const setPixelTypesList = (payload = []) => ({
  type: constants.SET_PIXEL_TYPES_LIST,
  payload,
});

export const clearPixelTypesList = () => ({
  type: constants.CLEAR_PIXEL_TYPES_LIST,
});

export const setIdentityGraphsList = (payload = []) => ({
  type: constants.SET_IDENTITY_GRAPHS_LIST,
  payload,
});

export const clearIdentityGraphsList = () => ({
  type: constants.CLEAR_IDENTITY_GRAPHS_LIST,
});

export const setIdentityTypesList = (payload = []) => ({
  type: constants.SET_IDENTITY_TYPES_LIST,
  payload,
});

export const clearIdentityTypesList = () => ({
  type: constants.CLEAR_IDENTITY_TYPES_LIST,
});

export const setCreativeMessagesList = (payload = []) => ({
  type: constants.SET_CREATIVE_MESSAGES_LIST,
  payload,
});

export const clearCreativeMessagesList = () => ({
  type: constants.CLEAR_CREATIVE_MESSAGES_LIST,
});

import { setFilter, setFilters } from 'ducks/actions/filters';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeFilter, normalizeFilters } from 'ducks/normalizers/filters';
import { Gemini } from 'utils/axios';

import { getAdvertisers } from './advertisers';
import { getAuditLog } from './audit-logs';
import { getAdvertiserLists } from './lists';
import { getAdvertiserSegments } from './segments';

export const getFilterPage =
  (queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const query = {
      include_archived: false,
      ...queries,
    };

    const [filter] = await Promise.all([
      dispatch(getAdvertisers()),
      dispatch(getAdvertiserFilters(query.advertiser_id)),
      dispatch(getAdvertiserSegments(query.advertiser_id)),
      dispatch(getFilter(query.filter_id)),
      dispatch(getAuditLog({ filter_id: query.filter_id })),
      dispatch(getAdvertiserLists(query.advertiser_id)),
    ]);

    await dispatch(updateSettings({ loading: false }));
    return filter;
  };

export const getAdvertiserFilters = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const query = {
    include_archived: false,
    ...queries,
  };

  const res = await Gemini.post(`/advertisers/${entity_id}/filters/list`, query);
  const filters = await normalizeFilters(res.data);
  await dispatch(setFilters(filters));
  return filters;
};

export const createAdvertiserFilter = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  // normalizing payload - API needs to be update
  data.advertiser_id = entity_id;

  const res = await Gemini.post(`/filters/create`, data);
  return res.data;
};

export const getFilter = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.get(`/filters/${entity_id}`);
  const filter = normalizeFilter(res.data);
  dispatch(setFilter(filter));
  return filter;
};

export const updateFilter = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.post(`/filters/${entity_id}/update`, data);
  return res.data;
};

export const activateFilter = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/filters/${entity_id}/activate`);
  return res.data;
};

export const deactivateFilter = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/filters/${entity_id}/deactivate`);
  return res.data;
};

export const archiveFilter = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/filters/${entity_id}/archive`);
  return res.data;
};

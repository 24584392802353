import { CLEAR_CAMPAIGN_PACING_PERFORMANCE, SET_CAMPAIGN_PACING_PERFORMANCE } from '../types';

const initial_state = {
  suggested_max: 10,
  x: [],
  y1: [],
  y2: [],
  y3: [],
};
export const campaignPacingPerformanceReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_PACING_PERFORMANCE:
      return action.payload;
    case CLEAR_CAMPAIGN_PACING_PERFORMANCE:
      return { ...initial_state };
    default:
      return state;
  }
};

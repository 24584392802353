import { CLEAR_PHYSICAL_TAGS, SET_PHYSICAL_TAGS } from '../types';

export const physicalTagsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PHYSICAL_TAGS:
      return action.payload;
    case CLEAR_PHYSICAL_TAGS:
      return [];
    default:
      return state;
  }
};

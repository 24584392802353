import * as constants from 'ducks/types';

export const setLineItemCreatives = (payload = {}) => ({
  type: constants.SET_LINE_ITEM_CREATIVES,
  payload,
});

export const clearLineItemCreatives = () => ({
  type: constants.CLEAR_LINE_ITEM_CREATIVES,
});

import beautify from 'json-beautify';

export const checkSort = (a, b, key, direction = 'asc') => {
  const a_value = (a[key] || '').toString().toLowerCase();
  const b_value = (b[key] || '').toString().toLowerCase();

  if (direction === 'asc') {
    return a_value.localeCompare(b_value, 'en-US', {
      numeric: 'true',
      sensitivity: 'base',
    });
  }
  return b_value.localeCompare(a_value, 'en-US', {
    numeric: 'true',
    sensitivity: 'base',
  });
};

export const jsonBeautify = (value = '') => {
  if (typeof value === 'string') {
    return beautify(JSON.parse(value), null, 2, 100);
  } else {
    return beautify(value, null, 2, 100);
  }
};

export const tableFilter = (a, b) => {
  // undefined or null cannot be interpolated into a string
  const a_value = a || '';
  const b_value = b || '';
  return a_value.toString().toLowerCase().includes(b_value.toString().toLowerCase());
};

export const mergeStrategyOverride = (a, b) => {
  // 0 is an acceptable override value
  if (a === 0) {
    return a;
  }

  // else we can do a basic compare
  return a || b;
};

export const strategyModelArrayMerge = (a, b) => a || b;

export const strategyUpdateCount = (a, b) => {
  // override will be null if its a new change order
  // null or undefined will get merged with the current values
  if (a === null || a === undefined) {
    return 0;
  }

  // comparing the value would need to be after
  if (a === b) {
    return 0;
  }
  return 1;
};

export const strategyObjectCount = (a, b) => {
  // default json returns object and uses combined value
  if (JSON.stringify(a) === '{}') {
    return 0;
  }

  // override will be an object (a)
  // current will be an object (b)
  const combined_json = {
    ...b,
    ...a,
  };

  // see if the combined matches the current
  if (JSON.stringify(combined_json) === JSON.stringify(b)) return 0;
  return 1;
};

export const strategyObjectMerge = (a, b) => {
  // default json returns object and uses combined value
  if (JSON.stringify(a) === '{}') {
    return b || {};
  }
  return a || {};
};

export const strategyCreativeMerge = (a, b) => {
  // default json returns object and uses combined value
  if (a === null || a === undefined) {
    return b || {};
  }
  return a || {};
};

export const strategyArrayCount = (a, b) => {
  // default pacing weight returns object instead of array
  if (JSON.stringify(a) === '{}') {
    return 0;
  }
  if (JSON.stringify(b) === '{}') {
    return 0;
  }

  // override will be an array (a)
  // current will be an array (b)
  if (JSON.stringify(a) === JSON.stringify(b)) return 0;
  return 1;
};

export const strategyArrayMerge = (a, b) => {
  // default pacing weight returns object instead of array
  if (JSON.stringify(a) === '{}') {
    if (JSON.stringify(b) === '{}') {
      return [];
    }
    return b;
  }
  return a || [];
};

export const getCharCount = (str) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    const char_code = str.charCodeAt(i);
    count += char_code;
  }
  return count;
};

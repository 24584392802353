import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { formatBudgetSpend, formatMicroUsd, formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';
import { getFrequencyCapsName, normalizeFrequencyCaps } from './frequency-caps';

export const normalizeLineItems = (data = []) =>
  data.map((row) => normalizeLineItem(row)).sort((a, b) => checkSort(a, b, 'line_item_state_sort', 'asc'));

export const normalizeLineItem = (row, frequency_intervals = []) => {
  const {
    impression_budget,
    max_impression_budget,
    overserve_factor,
    price_micro_usd,
    spend_budget_micro_usd,
    start_date_time_utc,
    finish_date_time_utc,
  } = row;

  const price_usd = formatMicroUsd(price_micro_usd);
  const price_usd_formatted = formatNumber(price_usd, { format: 'currency-abbreviated', append: '$0' });
  const price_usd_comma = formatNumber(price_usd, { format: 'currency-comma', append: '$0' });

  const spend_budget_usd = formatBudgetSpend(spend_budget_micro_usd);
  const spend_budget_usd_formatted = formatNumber(spend_budget_usd, { format: 'currency-abbreviated', append: '$0' });
  const spend_budget_usd_comma = formatNumber(spend_budget_usd, { format: 'currency-comma', append: '$0' });

  const max_impression_budget_formatted = formatNumber(max_impression_budget, { format: 'number-comma', append: 0 });
  const impression_budget_formatted = formatNumber(impression_budget, { format: 'number-abbreviated', append: 0 });
  const impression_budget_comma = formatNumber(impression_budget, { format: 'number-comma', append: 0 });

  const overserve_factor_formatted = formatNumber(overserve_factor, { format: 'percentage-fixed', append: 0 });

  const flight_dates = `${formatDate(start_date_time_utc, { format: 'MM/DD/YY' })} - ${formatDate(
    finish_date_time_utc,
    { format: 'MM/DD/YY' },
  )}`;

  const line_item_frequency_caps = normalizeFrequencyCaps(row.frequency_caps, frequency_intervals);
  const has_frequency_caps = line_item_frequency_caps.length !== 0;
  const frequency_cap_name = getFrequencyCapsName(line_item_frequency_caps);


  return {
    ...row,
    price_usd,
    price_usd_formatted,
    price_usd_comma,
    spend_budget_usd,
    spend_budget_usd_formatted,
    spend_budget_usd_comma,
    impression_budget_formatted,
    impression_budget_comma,
    overserve_factor_formatted,
    flight_dates,
    max_impression_budget_formatted,
    line_item_state_sort: `${row.state_id} ${row.line_item_name}`,
    modified: false,
    saved: true,
    line_item_name_with_id: `${row.line_item_id} ${row.line_item_name}`,
    line_item_frequency_caps,
    has_frequency_caps,
    frequency_cap_name,
    uuid: uuidv4(),
  };
};

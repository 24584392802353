/* eslint-disable no-undef */
import { setList, setLists } from 'ducks/actions/lists';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeLists } from 'ducks/normalizers/lists';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

export const getAdvertiserLists = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const query = {
    include_archived: false,
    ...queries,
  };

  const res = await Gemini.get(`/advertisers/${entity_id}/lists${replaceQuery(query)}`);
  const lists = await normalizeLists(res.data);
  await dispatch(setLists(lists));
  return lists;
};

export const getList = (list_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lists/${list_id}`);
  await dispatch(setList(res.data));
  return res.data;
};

export const downloadList = (list_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lists/${list_id}/content`);
  const csv = res.data.list_content.join('\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'list.csv');
  document.body.appendChild(a);
  a.click();
  dispatch(updateSettings({ loading: false }));
};

export const createAdvertiserList = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/lists/create`, data);
  return res.data;
};

export const updateList = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/lists/${entity_id}/remove_all`);
  const res = await Gemini.post(`/lists/${entity_id}/add`, data);
  return res.data;
};

export const archiveList = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/lists/${entity_id}/archive`);
  return res.data;
};

export const unarchiveList = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/lists/${entity_id}/unarchive`);
  return res.data;
};

export const activateList = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/lists/${entity_id}/activate`);
  return res.data;
};

export const deactivateList = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/lists/${entity_id}/deactivate`);
  return res.data;
};

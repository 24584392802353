import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearStrategy } from 'ducks/actions/strategies';
import { createStrategyTemplate } from 'ducks/operators/strategy-templates';
import { transformStrategyTemplate } from 'ducks/transforms/strategy-templates';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const initial_state = {
  strategy_template_name: '',
  bid_algorithm_id: 9,
  bid_algorithm_name: 'Incrementality',
  creative_selection_id: 3,
  creative_selection_name: 'Ctr Random',
  filter_json: '{}',
  bid_algorithm_json: '{}',
  creative_selection_json: '{}',
  pacing_weight_json: '[]',
  intervals_per_day: 360,
  budget_weight: null,
  strategy_budget_type_id: null,
  daily_overspend_factor: null,
  state_id: 1,
  line_item_type_id: null,
};

export const CreateStrategyTemplate = ({ location, history }) => {
  const dispatch = useDispatch();

  const { modals, strategy } = useSelector((state) => ({
    strategy: state.strategy,
    modals: state.modals,
  }));

  const [form, setForm] = useState({ ...initial_state });

  useEffect(() => {
    setForm({ ...initial_state, ...strategy });
  }, [strategy]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    dispatch(clearStrategy());
    setForm({ ...initial_state });
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ create_strategy_template: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { search } = location;
      const data = transformStrategyTemplate(form);
      const entity_id = await dispatch(createStrategyTemplate(data));
      const queries = mergeQuery(search, { strategy_template_id: entity_id, feature_id: 1, update: uuidv4() });
      history.push(`/strategy-templates${queries}`);
      dispatch(updateSuccess('strategy template has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { strategy_id, strategy_template_name } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="create_strategy_template"
      show={modals.create_strategy_template}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{strategy_id ? 'Create Strategy Template From Strategy' : 'Create Strategy Template'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          label="Template Name"
          auto_focus
          value={strategy_template_name}
          onChange={(value) => onChange({ strategy_template_name: value })}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button
            button_size="small"
            width="100px"
            margin="10px 0px 0px 0px"
            type="submit"
            disabled={!strategy_template_name}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

CreateStrategyTemplate.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import * as constants from 'ducks/types';

export const setTagVariableRules = (payload = []) => ({
  type: constants.SET_TAG_VARIABLE_RULES,
  payload,
});

export const clearTagVariableRules = () => ({
  type: constants.CLEAR_TAG_VARIABLE_RULES,
});

import { CLEAR_CAMPAIGN_METRICS, SET_CAMPAIGN_METRICS } from '../types';

export const campaignMetricsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_METRICS:
      return action.payload;
    case CLEAR_CAMPAIGN_METRICS:
      return {};
    default:
      return state;
  }
};

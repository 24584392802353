import { setCampaignCumulativePerformance } from 'ducks/actions/campaign-cumulative-performance';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCampaignCumulativePerformance } from 'ducks/normalizers/campaign-cumulative-performance';
import { Gemini } from 'utils/axios';
import { subtractFromDate } from 'utils/dates';
import { replaceQuery } from 'utils/queries';

export const getCampaignCumulativePerformance = (queries) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const { campaign, kpi_groups } = getState();

  const query = {
    campaign_id: null,
    start_date_utc: subtractFromDate(new Date(), { decrement: 31 }),
    finish_date_utc: subtractFromDate(new Date(), { decrement: 1 }),
    ...queries,
  };

  if (!query.campaign_id) {
    return null;
  }

  const { campaign_kpi_group_id } = campaign;
  const kpi_group = kpi_groups.find((k) => k.kpi_group_id === campaign_kpi_group_id) || {
    p_kpi_measure_unit: {},
    s_kpi_measure_unit: {},
    p_kpi_measure_type: {},
    s_kpi_measure_type: {},
  };

  const res = await Gemini.get(`/campaigns/${query.campaign_id}/cumulative_performance${replaceQuery(query)}`);
  const cumulative_performance = await normalizeCampaignCumulativePerformance(res.data, kpi_group);
  await dispatch(setCampaignCumulativePerformance(cumulative_performance));
  return res.data;
};

import React from 'react';
import { Loading } from 'components/loading/Loading';
import { EntitiesNavigation } from 'components/navigation/EntitiesNavigation';
import { Navigation } from 'components/navigation/Navigation';
import { Overlay } from 'components/preview-overlay/Overlay';
import { Snackbar } from 'components/snackbar/Snackbar';
import { Modals } from 'modals';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { shallowEqual, useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import cn from './Layout.module.scss';

export const PrivateLayout = ({ children }) => {
  const { settings, overlay } = useSelector(
    (state) => ({
      settings: state.settings,
      overlay: state.overlay,
    }),
    shallowEqual,
  );

  const { loading, configuration } = settings;
  return (
    <div className={cn.private}>
      <EntitiesNavigation />
      <div className={cn.content}>
        <Navigation />
        <div className={cn.scrollable}>
          <AutoSizer>
            {({ height, width }) => (
              <Scrollbars className={cn.scroll} style={{ height, width }}>
                <div className={cn.page}>{configuration && children}</div>
              </Scrollbars>
            )}
          </AutoSizer>
        </div>
      </div>
      <Snackbar />
      <Modals />
      {loading && <Loading variant="dark" />}
      {overlay?.show_overlay && <Overlay />}
    </div>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.node,
};

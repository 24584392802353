import React, { useState } from 'react';
import { Action } from 'components/actions/Action';
import { CreateCircle } from 'components/icons/CreateCircle';
import { clearAdvertiser } from 'ducks/actions/advertisers';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { pauseCampaigns, resumeCampaigns } from 'ducks/operators/campaigns';
import { ConfirmAction } from 'modals/ConfirmAction';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { handleError } from 'utils/errors';

import cn from './Navigation.module.scss';

export const AdvertisersWithRouter = ({ location, history }) => {
  const dispatch = useDispatch();

  const [paused, setPaused] = useState(false);
  const [resume, setResume] = useState(false);

  const onSubmit = async () => {
    const { search, pathname } = location;

    try {
      if (paused) {
        await dispatch(pauseCampaigns());
        setPaused(false);
      }
      if (resume) {
        await dispatch(resumeCampaigns());
        setResume(false);
      }

      history.push(`${pathname}${search}`);
      dispatch(updateSuccess('campaigns have been successfully updated'));
    } catch (err) {
      handleError(err);
    }
  };

  const selectAdvertisers = () => `/advertisers`;

  const createAdvertiser = () => {
    dispatch(clearAdvertiser());
    dispatch(updateModal({ manage_advertiser: true }));
  };

  const resumeCampaignsAction = () => {
    setResume(true);
    dispatch(updateSettings({ message: 'Are you sure that you want to resume all campaigns?' }));
    dispatch(updateModal({ confirm_action: true }));
  };

  const pauseCampaignsAction = () => {
    setPaused(true);
    dispatch(updateSettings({ message: 'Are you sure that you want to pause all campaigns?' }));
    dispatch(updateModal({ confirm_action: true }));
  };

  return (
    <>
      <div className={cn.items}>
        <Link className={cn.item} to={selectAdvertisers}>
          Advertisers
        </Link>
        <div className={cn.flex} />
        <Action
          className={cn.green}
          tooltip="Resume All Campaigns"
          onClick={resumeCampaignsAction}
          icon={['fas', 'play']}
        />
        <Action
          className={cn.orange}
          tooltip="Pause All Campaigns"
          onClick={pauseCampaignsAction}
          icon={['fas', 'pause']}
        />
      </div>
      <CreateCircle tooltip="Create Advertiser" onClick={createAdvertiser} />
      <ConfirmAction onSubmit={onSubmit} />
    </>
  );
};

AdvertisersWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export const Advertisers = withRouter(AdvertisersWithRouter);

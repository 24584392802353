import React from 'react';
import { Tooltip } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import cn from './Actions.module.scss';

export const Action = ({ onClick, className, download, href, icon, tooltip, font_size, style }) => {
  if (download) {
    return (
      <a className={cn.action} href={href} style={{ ...style, fontSize: font_size }} download>
        {tooltip && (
          <Tooltip tooltip={tooltip} caret="right" width="max-content" position={{ left: null, right: 40 }} />
        )}
        <FontAwesomeIcon className={className} icon={icon} />
      </a>
    );
  }
  if (href) {
    return (
      <Link className={cn.link} to={href} style={{ ...style, fontSize: font_size }} download={download} target="_self">
        {tooltip && (
          <Tooltip tooltip={tooltip} caret="right" width="max-content" position={{ left: null, right: 40 }} />
        )}
        <FontAwesomeIcon className={className} icon={icon} />
      </Link>
    );
  }
  return (
    <div className={cn.action} onClick={onClick} style={{ ...style, fontSize: font_size }}>
      {tooltip && <Tooltip tooltip={tooltip} caret="right" width="max-content" position={{ left: null, right: 40 }} />}
      <FontAwesomeIcon className={className} icon={icon} />
    </div>
  );
};

Action.defaultProps = {
  onClick: () => {},
  style: {},
  font_size: 14,
  download: false,
  className: '',
  icon: ['fad', 'circle'],
  tooltip: null,
  href: '',
};
Action.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  tooltip: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.string,
  download: PropTypes.bool,
  font_size: PropTypes.number,
  href: PropTypes.string,
};

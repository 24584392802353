/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from '@cognitiv/cyprus-ui';
import { Action } from 'components/cells/Action';

export const CreativeIdCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.creative_id}</p>
  </Cell>
);
export const CreativeNameCell = ({ row }) => {
  const error = row.creative_name_error;
  return (
    <Cell>
      {!error && <p>{row.creative_name}</p>}
      {error && <Action icon={['fas', 'times']} color="red" font_size="14px" />}
    </Cell>
  );
};
export const ErrorCell = ({ row, row_key }) => {
  const error = !!row[row_key];
  return (
    <Cell>
      {error && <Action icon={['fas', 'check']} color="green" font_size="14px" />}
      {!error && <Action icon={['fas', 'times']} color="red" font_size="14px" />}
    </Cell>
  );
};

export const OptionalCell = ({ row, row_key }) => {
  const error = !!row[row_key];
  return (
    <Cell>
      {error && <Action icon={['fas', 'check']} color="green" font_size="14px" />}
      {!error && <Action icon={['fas', 'minus']} color="orange" font_size="14px" />}
    </Cell>
  );
};

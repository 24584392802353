import { subtractFromDate } from 'utils/dates';

import { CLEAR_DATE_PREVIOUS_RANGES, SET_DATE_PREVIOUS_RANGES } from '../types';

const initial_state = [
  {
    start_date_utc: null,
    finish_date_utc: null,
    range_name: 'To Date',
    range_id: null,
    uuid: 1,
  },
  {
    start_date_utc: subtractFromDate(new Date(), { decrement: 8, utc: false }),
    finish_date_utc: subtractFromDate(new Date(), { decrement: 1, utc: false }),
    range_name: 'Last 7 Days',
    range_id: 2,
    uuid: 2,
  },
  {
    start_date_utc: subtractFromDate(new Date(), { decrement: 31, utc: false }),
    finish_date_utc: subtractFromDate(new Date(), { decrement: 1, utc: false }),
    range_name: 'Last 30 Days',
    range_id: 3,
    uuid: 3,
  },
];
export const datePreviousRangesListReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_DATE_PREVIOUS_RANGES:
      return action.payload;
    case CLEAR_DATE_PREVIOUS_RANGES:
      return initial_state;
    default:
      return state;
  }
};

import rootReducer from 'ducks/reducers/index';
import { saveUser } from 'ducks/selectors/users';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { getQuery, getStorage, setQuery } from 'utils/storage';

const user = getStorage();
const query_parameters = getQuery();
const persistedState = { user, query_parameters };

const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  const redux_user = store.getState().user;
  const redux_query = store.getState().query_parameters;
  if (JSON.stringify(redux_user) !== JSON.stringify(user)) {
    saveUser(redux_user);
  }
  if (JSON.stringify(redux_query) !== JSON.stringify(query_parameters)) {
    setQuery(redux_query);
  }
});

export default store;

import { updateSettings } from 'ducks/actions/settings';
import { setStrategyTemplates } from 'ducks/actions/strategy-templates';
import { normalizeStrategyTemplates } from 'ducks/normalizers/strategy-templates';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

export const getStrategyTemplatesPage =
  (queries = {}) =>
  async (dispatch) => {
    const query = {
      include_archived: false,
      ...queries,
    };

    const [strategy_templates] = await Promise.all([dispatch(getStrategyTemplates(query))]);

    await dispatch(updateSettings({ loading: false }));
    return strategy_templates;
  };

export const getStrategyTemplates = (queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const query = {
    include_archived: false,
    ...queries,
  };

  if (query.include_archived) {
    query.include_state_ids = [1, 4];
  }

  const res = await Gemini.get(`/strategies/templates${replaceQuery(query)}`);
  const strategy_templates = normalizeStrategyTemplates(res.data);
  dispatch(setStrategyTemplates(strategy_templates));
  return strategy_templates;
};

export const archiveStrategyTemplate = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/strategies/templates/${entity_id}/archive`);
};

export const activateStrategyTemplate = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/strategies/templates/${entity_id}/activate`);
};

export const updateStrategyTemplate = (entity_id, data) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/strategies/templates/${entity_id}/update`, data);
  return res.data;
};

export const createStrategyTemplate = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/strategies/templates/create`, data);
  return res.data;
};

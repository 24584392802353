import * as constants from 'ducks/types';

export const setThirdPartyReports = (payload = []) => ({
  type: constants.SET_THIRD_PARTY_REPORTS,
  payload,
});

export const clearThirdPartyReports = () => ({
  type: constants.CLEAR_THIRD_PARTY_REPORTS,
});

export const setThirdPartyReport = (payload = {}) => ({
  type: constants.SET_THIRD_PARTY_REPORT,
  payload,
});

export const clearThirdPartyReport = () => ({
  type: constants.CLEAR_THIRD_PARTY_REPORT,
});

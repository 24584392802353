export const selectAdvertiserCampaigns = (advertisers = [], campaigns = []) =>
  advertisers.reduce((acc, next) => {
    const { advertiser_id } = next;
    const advertiser_campaigns = campaigns.filter((c) => c.advertiser_id === advertiser_id);

    const campaigns_normalized = advertiser_campaigns
      .map((c) => ({
        campaign_name: c.campaign_name,
        state_id: c.state_id,
        to: `/advertisers/${advertiser_id}/campaigns/${c.campaign_id}/overview`,
      }))
      .sort((a, b) => a.state_id - b.state_id);

    next.campaigns = campaigns_normalized;
    acc.push(next);
    return acc;
  }, []);

export const selectModelAdvertisers = (advertisers = [], model_advertisers = []) =>
  advertisers.map((a) => {
    const { advertiser_id } = a;
    const model_advertiser = model_advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id));
    if (model_advertiser) {
      const res = { ...a, ...model_advertiser, checked: true };
      return res;
    }
    return {
      checked: false,
      ...a,
    };
  });

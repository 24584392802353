import { CLEAR_STRATEGY, SET_STRATEGY, UPDATE_STRATEGY } from 'ducks/types';

export const strategyReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_STRATEGY:
      return {
        ...action.payload,
      };
    case UPDATE_STRATEGY:
      return { ...state, ...action.payload };
    case CLEAR_STRATEGY:
      return {};
    default:
      return state;
  }
};

import { setLineItem, setLineItems } from 'ducks/actions/line-items';
import { setLineItemsExternal } from 'ducks/actions/line-items-external';
import { setLineItemsPacing } from 'ducks/actions/line-items-pacing';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeLineItem, normalizeLineItems } from 'ducks/normalizers/line-items';
import { normalizeExternalLineItems } from 'ducks/normalizers/line-items-external';
import { normalizePacingLineItems } from 'ducks/normalizers/line-items-pacing';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

import { getAdvertiser, getAdvertisers } from './advertisers';
import { getAdvertiserCampaigns, getCampaign } from './campaigns';
import { getCampaignCreatives } from './creatives';
import { getDeals, getLineItemDeals } from './deals';
import { getAdvertiserFilters } from './filters';
import { getCampaignFlights, getFlight } from './flights';
import { getLineItemFrequencyCaps } from './frequency-caps';
import { getFlightLineItemCreatives } from './line-item-creatives';
import { getLineItemDcpm } from './line-item-dcpm';
import { getAdvertiserSegments } from './segments';
import { getFlightStrategies } from './strategies';
import { getLineItemVendorFees, getVendorFees } from './vendor-fees';

export const getLineItemPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const { advertiser_id, campaign_id, flight_id, line_item_id } = params;

  // race condition - get line item before dcpm gets retrieved
  await dispatch(getLineItem(line_item_id));

  const [advertiser] = await Promise.all([
    dispatch(getAdvertisers()),
    dispatch(getDeals()),
    dispatch(getVendorFees()),
    dispatch(getAdvertiser(advertiser_id)),
    dispatch(getAdvertiserFilters(advertiser_id)),
    dispatch(getAdvertiserSegments(advertiser_id)),
    dispatch(getAdvertiserCampaigns(advertiser_id)),
    dispatch(getCampaign(campaign_id)),
    dispatch(getCampaignFlights(campaign_id)),
    dispatch(getCampaignLineItems(campaign_id)),
    dispatch(getLineItemFrequencyCaps(line_item_id)),
    dispatch(getFlight(flight_id)),
    dispatch(getFlightLineItemCreatives(flight_id)),
    dispatch(getCampaignCreatives(campaign_id)),
    dispatch(getFlightStrategies(flight_id)),
    dispatch(getLineItemDcpm(line_item_id)),
    dispatch(getLineItemDeals(line_item_id)),
    dispatch(getLineItemVendorFees(line_item_id)),
  ]);

  await dispatch(updateSettings({ loading: false }));
  return advertiser;
};

export const getCampaignLineItems = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/line_items`);
  const line_items = await normalizeLineItems(res.data);
  await dispatch(setLineItems(line_items));
  return line_items;
};

export const getFlightLineItems =
  (entity_id, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const query = {
      include_archived: false,
      ...queries,
    };

    if (query.include_archived) {
      query.include_state_ids = [1, 2, 3, 4];
    }

    const res = await Gemini.get(`/flights/${entity_id}/line_items${replaceQuery(query)}`);
    const line_items = await normalizeLineItems(res.data);
    await dispatch(setLineItems(line_items));
    return line_items;
  };

export const getCampaignPacingLineItems = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/line_item_pacing/list`, data);
  const pacing_line_items = await normalizePacingLineItems(res.data);
  await dispatch(setLineItemsPacing(pacing_line_items));
  return pacing_line_items;
};

export const getCampaignExternalLineItems = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/external_spend`);
  const external_line_items = normalizeExternalLineItems(res.data);
  await dispatch(setLineItemsExternal(external_line_items));
  return external_line_items;
};

export const updateCampaignLineItemsBudget = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/update_budget`, data);
  return res.data;
};

export const getLineItem = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/line_items/${entity_id}`);
  const line_item = await normalizeLineItem(res.data);
  await dispatch(setLineItem(line_item));
  return line_item;
};

export const createFlightLineItem = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: false }));
  const res = await Gemini.post(`/flights/${entity_id}/line_items/create`, data);
  return res.data;
};

export const updateLineItems = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/update_line_items`, data);
  return res.data;
};

export const activateLineItem = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_items/${entity_id}/activate`, {});
  return res.data;
};

export const pauseLineItem = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_items/${entity_id}/pause`, {});
  return res.data;
};

export const deactivateLineItem = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_items/${entity_id}/deactivate`, {});
  return res.data;
};
export const archiveLineItem = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_items/${entity_id}/archive`, {});
  return res.data;
};

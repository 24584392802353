import moment from 'moment';
import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { checkSort, tableFilter } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeChangeOrderAuditLogs = (data = []) =>
  data
    .map((d) => normalizeChangeOrderAuditLog(d))
    .sort((a, b) => {
      if (b.change_order_id === a.change_order_id) {
        return moment(a.timestamp_utc) > moment(b.timestamp_utc) ? -1 : 1;
      }
      return b.change_order_id - a.change_order_id;
    });

export const normalizeChangeOrderAuditLog = (row = {}) => {
  const { timestamp_utc, is_message, workflow_state_name, workflow_message, user_name, message } = row;
  const user_message_formatted = `Comment added by ${user_name}`;

  return {
    ...row,
    audit_log_search: `${timestamp_utc} ${workflow_state_name} ${user_name} ${workflow_message}`,
    message_formatted: message || 'No user message provided.',
    user_message_formatted,
    combined_message_formatted: is_message ? user_message_formatted : workflow_message,
    timestamp_utc_formatted: formatDate(timestamp_utc, { format: 'MM/DD/YYYY h:mm A' }),
    timestamp_local_formatted: offsetTimezoneUTC(timestamp_utc, { format: 'MM/DD/YYYY h:mm A', local_timezone: true }),
    uuid: uuidv4(),
  };
};
export const normalizeAuditLogs = (logs = []) =>
  normalizeAuditLog(logs.sort((a, b) => checkSort(a, b, 'audit_log_id', 'desc')));

export const normalizeAuditLog = (logs) => {
  const users = new Set();
  const fields = new Set();
  const entities = new Set();

  const normalized_log = logs.map((log) => {
    const entity_name_formatted = log.entity_name.replace(/([a-z])([A-Z])/g, '$1 $2');
    const field_name_formatted = log.field_name.replace(/([a-z])([A-Z])/g, '$1 $2');

    users.add(log.user_name);
    fields.add(field_name_formatted);
    entities.add(entity_name_formatted);

    const description_formatted = insertHtml(log.description, log.user_name);
    const is_json = tableFilter(log.field_name, 'json');
    const is_html = tableFilter(log.field_name, 'html');
    let old_value_parsed = null;
    let new_value_parsed = null;

    if (is_json && !log.old_value.includes('none')) {
      old_value_parsed = JSON.parse(log.old_value);
    }
    if (is_json && !log.new_value.includes('none')) {
      new_value_parsed = JSON.parse(log.new_value);
    }
    return {
      ...log,
      description_formatted,
      entity_name_formatted,
      field_name_formatted,
      is_json,
      is_html,
      old_value_parsed,
      new_value_parsed,
      timestamp_formatted: offsetTimezoneUTC(log.created_date, { format: 'MM/DD/YYYY h:mm A' }),
      uuid: uuidv4(),
    };
  });
  return {
    data: normalized_log,
    users: Array.from(users)
      .map((u, i) => ({ user_name: u, uuid: uuidv4(), user_id: i + 1 }))
      .sort((a, b) => checkSort(a, b, 'user_name', 'asc')),
    fields: Array.from(fields)
      .filter((f) => f !== '')
      .map((f, i) => ({ field_name: f, uuid: uuidv4(), field_id: i + 1 }))
      .sort((a, b) => checkSort(a, b, 'field_name', 'asc')),
    entities: Array.from(entities)
      .map((f, i) => ({ entity_name: f, uuid: uuidv4(), entity_id: i + 1 }))
      .sort((a, b) => checkSort(a, b, 'entity_name', 'asc')),
  };
};

// replaces [] and user name with styled div tags to display in bold in the audit log table
const insertHtml = (description = '', user_name = '') =>
  `${description.replace(/\[+([^\][]+)]+/g, '<b>$1</b>').replace(user_name, `<b>${user_name}</b>`)}`;

import { CLEAR_BID_ALGORITHMS_LIST, SET_BID_ALGORITHMS_LIST } from '../types';

export const bidAlgorithmsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_BID_ALGORITHMS_LIST:
      return action.payload;
    case CLEAR_BID_ALGORITHMS_LIST:
      return [];
    default:
      return state;
  }
};

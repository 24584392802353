import React from 'react';
import { Checkbox, Tooltip } from '@cognitiv/cyprus-ui';
import PropTypes from 'prop-types';

import cn from './Cells.module.scss';

export const Select = ({ height, tooltip, checked, onClick, disabled, width }) => (
  <div className={cn.action} style={{ height, textAlign: 'center', width }}>
    <Checkbox onClick={onClick} checked={checked} disabled={disabled} width="max-content" />
    {tooltip && (
      <Tooltip
        tooltip={tooltip}
        caret="left"
        width="max-content"
        position={{ left: 'calc(100% + 5px)', right: null }}
      />
    )}
  </div>
);

Select.defaultProps = {
  height: 30,
  width: null,
  tooltip: null,
  checked: false,
  disabled: false,
  onClick: () => {},
};
Select.propTypes = {
  height: PropTypes.any,
  tooltip: PropTypes.any,
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.any,
};

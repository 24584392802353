import { Gemini } from '../../utils/axios';
import { setModel, setModelAdvertisers, setModelOutputs } from '../actions/model';
import { updateSettings } from '../actions/settings';
import { normalizeModel, normalizeModelAdvertisers } from '../normalizers/model';

export const getModelAdvertisers = (model_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/models/${model_id}/advertisers`);
  const model_advertisers = await normalizeModelAdvertisers(res.data);
  await dispatch(setModelAdvertisers(model_advertisers));
  return model_advertisers;
};

export const getModel = (model_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/models/${model_id}`);
  const model = await normalizeModel(res.data);
  await dispatch(setModelOutputs(model.model_outputs || []));
  await dispatch(setModel(model));
  return model;
};

export const getModelPage = (model_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const [models] = await Promise.all([dispatch(getModel(model_id)), dispatch(getModelAdvertisers(model_id))]);
  return models;
};

export const updateSingleModel = (model_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/models/${model_id}/update`, data);
  return res.data;
};

export const updateModelAdvertisers = (model_id, data, refetch) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/models/${model_id}/advertisers/update`, data);
  if (refetch) {
    await dispatch(getModelAdvertisers(model_id));
  }
  await dispatch(updateSettings({ loading: false }));
  return res.data;
};

export const updateModelOutputs = (model_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/models/${model_id}/outputs/update`, data);
  return res.data;
};

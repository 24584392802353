import { CLEAR_REPORT_CONFIGURATION, SET_REPORT_CONFIGURATION } from '../types';

const initial_state = {
  report_id: null,
  folder_id: null,
  folder_name: '',
  report_name: '',
  report_type_id: null,
  report_type_name: '',
  output_type_id: null,
  output_type_name: '',
  json_version_id: 1,
  report_json: {
    selected_fields: [],
    extended_date: {},
  },
  identity_id: null,
  owned_by: '',
  is_archived: false,
  created_date: '',
};

export const reportConfigurationReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_REPORT_CONFIGURATION:
      return action.payload;
    case CLEAR_REPORT_CONFIGURATION:
      return { ...initial_state };
    default:
      return state;
  }
};

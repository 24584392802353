import React from 'react';
import { Button } from '@cognitiv/cyprus-ui';
import PropTypes from 'prop-types';

import cn from './WizardButtons.module.scss';

export const WizardButtons = ({ current, end, save, disabled, onClose, onBack, read_only, onNext, onSubmit }) => (
  <div className={cn.buttons}>
    <div className={cn.flex} />
    <p className={cn.cancel} role="presentation" onClick={onClose}>
      Cancel
    </p>
    {current !== 0 && (
      <Button onClick={onBack} margin="0px 0px 0px 15px" width={125}>
        Previous
      </Button>
    )}
    {current !== end && (
      <>
        {!read_only && (
          <Button type="submit" onClick={onSubmit} margin="0px 0px 0px 15px" outline width={125}>
            {save ? 'Save' : 'Run'}
          </Button>
        )}
        {!disabled && (
          <Button onClick={onNext} margin="0px 0px 0px 15px" width={125}>
            Next
          </Button>
        )}
      </>
    )}
    {current === end && (
      <Button
        type="submit"
        margin="0px 0px 0px 15px"
        onClick={onSubmit}
        disabled={read_only}
        outline={!read_only}
        variant={read_only ? 'grey' : 'primary'}
        width={125}
      >
        {save ? 'Save' : 'Run'}
      </Button>
    )}
  </div>
);

WizardButtons.defaultProps = {
  save: false,
  disabled: false,
  onBack: () => {},
  onNext: () => {},
  onClose: () => {},
  onSubmit: () => {},
  current: 0,
  read_only: false,
  end: null,
};
WizardButtons.propTypes = {
  save: PropTypes.bool,
  read_only: PropTypes.bool,
  disabled: PropTypes.bool,
  current: PropTypes.number,
  end: PropTypes.number,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
};

import React from 'react';
import { Tooltip } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import cn from './CreateCircle.module.scss';

export const CreateCircle = ({ onClick, tooltip }) => (
  <div className={cn.circle} onClick={onClick}>
    <div className={cn.relative}>
      {tooltip && <Tooltip tooltip={tooltip} position={{ right: 60 }} caret="right" />}
      <FontAwesomeIcon icon={['fas', 'plus']} />
    </div>
  </div>
);

CreateCircle.propTypes = {
  onClick: PropTypes.func,
  tooltip: PropTypes.bool,
};

import * as constants from 'ducks/types';

export const setGlobalVendorFees = (payload = {}) => ({
  type: constants.SET_GLOBAL_VENDOR_FEES,
  payload,
});

export const clearGlobalVendorFees = () => ({
  type: constants.CLEAR_GLOBAL_VENDOR_FEES,
});

export const setVendorFees = (payload = {}) => ({
  type: constants.SET_VENDOR_FEES,
  payload,
});

export const clearVendorFees = () => ({
  type: constants.CLEAR_VENDOR_FEES,
});

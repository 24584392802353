import { CLEAR_ADVERTISER_REPORT, SET_ADVERTISER_REPORT } from '../types';

const initial_state = {};

export const advertiserReportReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_ADVERTISER_REPORT:
      return action.payload;
    case CLEAR_ADVERTISER_REPORT:
      return { ...initial_state };
    default:
      return state;
  }
};

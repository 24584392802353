import moment from 'moment';
import { compareDates, formatDate, offsetTimezoneUTC, validDate } from 'utils/dates';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeChangeOrders = (data = []) =>
  data.map((row) => normalizeChangeOrder(row)).sort((a, b) => checkSort(a, b, 'created_date_utc', 'desc'));

export const normalizeChangeOrder = (row = {}) => {
  const { publish_date_utc } = row;
  const publish_date_local_formatted = offsetTimezoneUTC(publish_date_utc, { format: 'M/D/YY h:mm:ss A' });
  const publish_date_utc_formatted = formatDate(publish_date_utc, { format: 'M/D/YY h:mm:ss A' });
  const current_date_utc_formatted = moment().format('M/D/YY h:mm:ss A');
  const published_date_utc_valid = validDate(publish_date_utc_formatted, { format: 'M/D/YY h:mm:ss A' });

  return {
    ...row,
    change_order_unsaved: false,
    validated_state_id: row.validated ? 1 : 2,
    published_date_utc_valid,
    created_date_local_formatted: offsetTimezoneUTC(row.created_date_utc, { format: 'M/D/YY h:mm:ss A' }),
    current_date_utc_formatted,
    publish_date_utc_formatted,
    publish_date_local_formatted,
    has_published: compareDates(publish_date_utc_formatted, current_date_utc_formatted, { precision: 'seconds' }),
    uuid: uuidv4(),
  };
};

import { CLEAR_PARTNERS, SET_PARTNERS } from '../types';

export const partnersReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PARTNERS:
      return action.payload;
    case CLEAR_PARTNERS:
      return [];
    default:
      return state;
  }
};

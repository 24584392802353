export const transformCreative = (creative = {}) => {
  const { video_events = [], add_ons = [], extras = [], pixels = [] } = creative;

  return {
    advertiser_id: creative.advertiser_id,
    creative_id: creative.creative_id,
    creative_type_id: creative.creative_type_id,
    creative_name: creative.creative_name,
    creative_message_id: creative.creative_message_id,
    price_micro_usd: creative.price_usd * 1000,
    price_start_date_utc: creative.price_start_date_utc || null,
    tag_html: creative.tag_html,
    ad_server_id: creative.ad_server_id,
    click_url: creative.click_url,
    state_id: creative.state_id,
    pixels,
    scripts: creative.scripts,
    add_ons: add_ons.filter((a) => a.checked).map((a) => a.add_on_id),
    video_events: video_events.map((v) => ({ event_id: v.event_id, url: v.url })),
    skippable: null,
    category_id: creative.category_id,
    domain_url: creative.domain_url,
    preview_url: creative.preview_url,
    is_psa: creative.is_psa,
    creative_content_id: creative.creative_content_id,
    playback_method_id: creative.playback_method_id,
    flight_id: creative.flight_id || null,
    ad_server_campaign_identifier_id: creative.ad_server_campaign_identifier_id,
    extra_ids: extras.filter((e) => e.checked).map((e) => e.creative_extra_id),
    remote_vast: creative.remote_vast,
    ad_server_identifier: creative.ad_server_identifier,
  };
};

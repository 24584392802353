import { CLEAR_STRATEGY_WARNINGS, SET_STRATEGY_WARNINGS, UPDATE_STRATEGY_WARNINGS } from 'ducks/types';

export const strategyWarningsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_STRATEGY_WARNINGS:
      return action.payload;
    case UPDATE_STRATEGY_WARNINGS:
      return { ...state, ...action.payload };
    case CLEAR_STRATEGY_WARNINGS:
      return {};
    default:
      return state;
  }
};

import React from 'react';
import { Button, Modal } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings } from 'ducks/actions/settings';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import cn from './Modal.module.scss';

export const ConfirmAction = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { modals, settings } = useSelector((state) => ({ modals: state.modals, settings: state.settings }));

  const closeAction = () => {
    dispatch(updateModal({ confirm_action: false }));
    dispatch(updateSettings({ message: '' }));
  };

  const submitAction = () => {
    dispatch(updateModal({ confirm_action: false }));
    onSubmit();
  };

  return (
    <Modal
      name="confirm_action"
      show={modals.confirm_action}
      width={350}
      padding={0}
      onClose={closeAction}
      standard={false}
      theme={{
        modal_close_color: '#f9a43e',
      }}
    >
      <div className={cn.modalMessage}>
        <div className={cn.iconContainer}>
          <FontAwesomeIcon className={classNames(cn.icon, cn.orange)} icon={['fas', 'exclamation-triangle']} />
        </div>
        <p className={cn.text} style={{ textAlign: 'center' }}>
          {settings.message}
        </p>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button width="100%" variant="orange" onClick={submitAction}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmAction.propTypes = {
  onSubmit: PropTypes.func,
};

import { setCampaignPacingPerformance } from 'ducks/actions/campaign-pacing-performance';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCampaignPacingPerformance } from 'ducks/normalizers/campaign-pacing-performance';
import { Gemini } from 'utils/axios';
import { subtractFromDate } from 'utils/dates';
import { replaceQuery } from 'utils/queries';

export const getCampaignPacingPerformance = (queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const query = {
    campaign_id: null,
    start_date_utc: subtractFromDate(new Date(), { decrement: 31 }),
    finish_date_utc: subtractFromDate(new Date(), { decrement: 1 }),
    ...queries,
  };

  if (!query.campaign_id) {
    return null;
  }

  const res = await Gemini.get(`/campaigns/${query.campaign_id}/daily_pacing${replaceQuery(query)}`);
  const pacing_performance = await normalizeCampaignPacingPerformance(res.data);
  dispatch(setCampaignPacingPerformance(pacing_performance));
  return pacing_performance;
};

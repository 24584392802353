import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal, Table } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearVendorFees } from 'ducks/actions/vendor-fees';
import { createStrategyVendorFees } from 'ducks/operators/vendor-fees';
import { mergeStrategyVendorFees } from 'ducks/selectors/vendor-fees';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery, parseQueries } from 'utils/queries';

import {
  FeeFinishDateCell,
  FeeStartDateCell,
  ImpressionMaxCell,
  ImpressionMinCell,
  ParentFeeNameCell,
  SelectCell,
  VendorFeeCell,
  VendorFeeNameCell,
} from './ManageStrategyVendorFeesCells';

import cn from './Modal.module.scss';

const headers = [
  {
    name: '',
    width: '35px',
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'Effective Date',
    width: '120px',
    uuid: 2, // required key
  },
  {
    name: 'Vendor Fee',
    width: '100px',
    flex_grow: 1,
    uuid: 3, // required key
  },
  {
    name: 'Fee',
    width: '100px',
    uuid: 4, // required key
  },
  {
    name: 'Min. Impressions',
    width: '120px',
    uuid: 5, // required key
  },
  {
    name: 'Max. Impressions',
    width: '120px',
    uuid: 6, // required key
  },
  {
    name: 'Parent Vendor',
    width: '120px',
    uuid: 7, // required key
  },
  {
    name: 'Fee Start Date',
    width: '120px',
    uuid: 7, // required key
  },
  {
    name: 'Fee Finish Date',
    width: '120px',
    uuid: 8, // required key
  },
];

export const ManageStrategyVendorFees = ({ location, history }) => {
  const dispatch = useDispatch();

  const { vendor_fees, global_vendor_fees, strategy_vendor_fees, modals } = useSelector(
    (state) => ({
      vendor_fees: state.vendor_fees,
      global_vendor_fees: state.global_vendor_fees,
      modals: state.modals,
      strategy_vendor_fees: mergeStrategyVendorFees(state.global_vendor_fees, state.vendor_fees),
    }),
    shallowEqual,
  );

  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(strategy_vendor_fees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor_fees, global_vendor_fees]);

  const closeModal = () => {
    dispatch(clearVendorFees());
    setData([]);
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_strategy_vendor_fees: false }));
  };

  const selectVendorFee = (row) => {
    const new_row = {
      checked: row.checked,
      uuid: row.uuid,
    };
    if (!row.fee_vendor_fee_strategy_id) {
      updateData(new_row);
    }
  };

  const updateData = (row) => {
    setData((prev) => {
      const copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === row.uuid) {
          copy[i] = { ...copy[i], ...row };
        }
      }
      return copy;
    });
  };

  const onSubmit = async () => {
    try {
      const data_filtered = data
        .filter((d) => d.checked && !d.vendor_fee_line_item_id)
        .map((d) => ({ fee_vendor_fee_id: d.fee_vendor_fee_id, strategy_id: query.strategy_id }));
      await dispatch(createStrategyVendorFees(data_filtered));
      dispatch(updateSuccess('strategy vendor fees have been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const onChange = (query) => {
    const { pathname, search } = location;
    const queries = mergeQuery(search, query);
    history.push(`${pathname}${queries}`);
  };

  const filterData = () =>
    data.filter((v) => {
      if (query.associated_vendor_fees) {
        return v.strategy_id === Number(query.strategy_id);
      }
      return true;
    });

  const { search } = location;
  const query = parseQueries(search);

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_strategy_vendor_fees"
      show={modals.manage_strategy_vendor_fees}
      width={1100}
      onClose={closeModal}
      standard={false}
    >
      <h3>Manage Strategy Vendor Fees</h3>
      <Row>
        <Input left_icon={['fas', 'search']} width="300px" margin="0px 0px 15px 0px" onChange={setFilter} />
        <div className={cn.flex} />
        <Checkbox
          onClick={() => onChange({ associated_vendor_fees: !query.associated_vendor_fees })}
          checked={query.associated_vendor_fees}
          margin="auto 0px 10px auto"
          width="max-content"
        >
          Associated Vendor Fees
        </Checkbox>
      </Row>
      <Table headers={headers} rows={filterData()} filter={filter} settings={{ filter_key: 'vendor_fee_name' }}>
        <SelectCell onClick={selectVendorFee} />
        <FeeStartDateCell />
        <VendorFeeNameCell />
        <VendorFeeCell />
        <ImpressionMinCell />
        <ImpressionMaxCell />
        <ParentFeeNameCell />
        <FeeStartDateCell />
        <FeeFinishDateCell />
      </Table>
      <div className={cn.buttonContainer}>
        <div className={cn.flex} />
        <Button onClick={onSubmit} button_size="small" type="submit">
          Associate Vendor Fees
        </Button>
      </div>
    </Modal>
  );
};

ManageStrategyVendorFees.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

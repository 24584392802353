import React from 'react';
import PropTypes from 'prop-types';

import cn from './Row.module.scss';

export const Row = ({ height, padding, children, grow }) => (
  <div className={cn.row} style={{ padding, minHeight: height, maxHeight: height, flexGrow: grow && 1 }}>
    {children}
  </div>
);

Row.defaultProps = {
  padding: 0,
  height: 'max-content',
  children: null,
  grow: false,
};
Row.propTypes = {
  padding: PropTypes.any,
  height: PropTypes.any,
  children: PropTypes.any,
  grow: PropTypes.any,
};

export const Spacer = () => <div className={cn.spacer} />;

import * as constants from 'ducks/types';

export const setGlobalDeals = (payload = {}) => ({
  type: constants.SET_GLOBAL_DEALS,
  payload,
});

export const clearGlobalDeals = () => ({
  type: constants.CLEAR_GLOBAL_DEALS,
});

export const setDeal = (payload = {}) => ({
  type: constants.SET_DEAL,
  payload,
});

export const clearDeal = () => ({
  type: constants.CLEAR_DEAL,
});

export const setDeals = (payload = {}) => ({
  type: constants.SET_DEALS,
  payload,
});

export const clearDeals = () => ({
  type: constants.CLEAR_DEALS,
});

import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Input, InputCurrency, Modal } from '@cognitiv/cyprus-ui';
import { clearDeal } from 'ducks/actions/deals';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createDeal, getDeals, updateDeal } from 'ducks/operators/deals';
import { dealValidation } from 'ducks/validations/deals';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  deal_id: null,
  deal_name: '',
  inventory_source_id: null,
  inventory_source_name: '',
  inventory_source_identifier: '',
  min_price: null,
  max_price: null,
  state_id: null,
  is_ott: false,
  modified: false,
};

export const ManageDeal = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const { modals, deal, inventory_sources } = useSelector((state) => ({
    inventory_sources: state.inventory_sources_list,
    deal: state.deal,
    modals: state.modals,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...deal });
  }, [deal]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on deal page
    if (!match.params.deal_id) {
      dispatch(clearDeal());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_deal: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        deal_name: form.deal_name,
        inventory_source_id: form.inventory_source_id,
        inventory_source_identifier: form.inventory_source_identifier,
        min_price_micro_usd: form.min_price_micro_usd,
        max_price_micro_usd: form.max_price_micro_usd,
        is_ott: form.is_ott,
        state_id: form.state_id || 1,
      };
      if (deal_id) {
        await dispatch(updateDeal(deal_id, data));
      }
      if (!deal_id) {
        await dispatch(createDeal(data));
      }
      await dispatch(getDeals());
      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('deal has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { deal_id } = deal;
  const { deal_name, inventory_source_name, inventory_source_identifier, min_price_cpm, max_price_cpm, is_ott } = form;

  const disabled = dealValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_deal"
      show={modals.manage_deal}
      width={500}
      onClose={closeModal}
      standard={false}
    >
      <h3>{deal_id ? 'Update Deal' : 'Create Deal'}</h3>
      <form onSubmit={onSubmit}>
        <Input label="Deal Name" auto_focus value={deal_name} onChange={(value) => onChange({ deal_name: value })} />
        <Dropdown
          label="Inventory Source"
          options={inventory_sources}
          option_key="name"
          value={inventory_source_name}
          margin="15px 0px 0px 0px"
          onSelect={(item) => onChange({ inventory_source_name: item.name, inventory_source_id: item.id })}
        />
        <Input
          label="Source Identifier"
          value={inventory_source_identifier}
          onChange={(value) => onChange({ inventory_source_identifier: value })}
          margin="15px 0px 0px 0px"
        />
        <div style={{ display: 'flex' }}>
          <InputCurrency
            label="Min. Price"
            value={min_price_cpm}
            onChange={(input) =>
              onChange({
                min_price_micro_usd: input.float_value * 1000,
                min_price_cpm: input.float_value,
                min_price_cpm_formatted: input.formatted_value,
              })
            }
            width="calc(50% - 7px)"
            margin="15px 7px 0px 0px"
          />
          <InputCurrency
            label="Max Price"
            value={max_price_cpm}
            onChange={(input) =>
              onChange({
                max_price_micro_usd: input.float_value * 1000,
                max_price_cpm: input.float_value,
                max_price_cpm_formatted: input.formatted_value,
              })
            }
            width="calc(50% - 7px)"
            margin="15px 0px 0px 7px"
          />
        </div>
        <Checkbox
          margin="15px 7px 0px 0px"
          width="max-content"
          checked={is_ott}
          onClick={(checked) => onChange({ is_ott: checked })}
        >
          OTT
        </Checkbox>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            {deal_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageDeal.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import { CLEAR_GLOBAL_VENDOR_FEES, SET_GLOBAL_VENDOR_FEES } from '../types';

export const globalVendorFeesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_GLOBAL_VENDOR_FEES:
      return action.payload;
    case CLEAR_GLOBAL_VENDOR_FEES:
      return [];
    default:
      return state;
  }
};

import { getEndDateType, getStartDateIncrement, getStartDateType, subtractFromDate } from 'utils/dates';

import { CLEAR_DATE_RANGES, SET_DATE_RANGES } from '../types';

const initial_state = [
  {
    start_date_utc: getStartDateIncrement(new Date(), { type: 'week' }),
    finish_date_utc: subtractFromDate(new Date(), { utc: false }),
    range_name: 'Current Week',
    range_id: 1,
    uuid: 1,
  },
  {
    start_date_utc: getStartDateIncrement(new Date(), { type: 'month' }),
    finish_date_utc: subtractFromDate(new Date(), { utc: false }),
    range_name: 'Current Month',
    range_id: 2,
    uuid: 2,
  },
  {
    start_date_utc: getStartDateIncrement(new Date(), { type: 'quarter' }),
    finish_date_utc: subtractFromDate(new Date(), { utc: false }),
    range_name: 'Current Quarter',
    range_id: 3,
    uuid: 3,
  },
  {
    start_date_utc: getStartDateIncrement(new Date(), { type: 'year' }),
    finish_date_utc: subtractFromDate(new Date(), { utc: false }),
    range_name: 'Current Year',
    range_id: 4,
    uuid: 4,
  },
  {
    start_date_utc: getStartDateType(new Date(), { decrement: 1, type: 'week', start_type: 'week' }),
    finish_date_utc: getEndDateType(new Date(), { type: 'week', start_type: 'week' }),
    range_name: 'Last Week',
    range_id: 5,
    uuid: 5,
  },
  {
    start_date_utc: getStartDateType(new Date(), { decrement: 1, type: 'month', start_type: 'month' }),
    finish_date_utc: getEndDateType(new Date(), { type: 'month', start_type: 'month' }),
    range_name: 'Last Month',
    range_id: 6,
    uuid: 6,
  },
  {
    start_date_utc: getStartDateType(new Date(), { decrement: 3, type: 'month', start_type: 'quarter' }),
    finish_date_utc: getEndDateType(new Date(), { type: 'month', start_type: 'quarter' }),
    range_name: 'Last Quarter',
    range_id: 7,
    uuid: 7,
  },
  {
    start_date_utc: getStartDateType(new Date(), { decrement: 1, type: 'year', start_type: 'year' }),
    finish_date_utc: getEndDateType(new Date(), { type: 'year', start_type: 'year' }),
    range_name: 'Last Year',
    range_id: 8,
    uuid: 8,
  },
];
export const dateRangesListReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_DATE_RANGES:
      return action.payload;
    case CLEAR_DATE_RANGES:
      return initial_state;
    default:
      return state;
  }
};

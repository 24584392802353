import { CLEAR_MACHINE_LEARNING_JOBS, SET_MACHINE_LEARNING_JOBS } from '../types';

export const machineLearningJobsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_MACHINE_LEARNING_JOBS:
      return action.payload;
    case CLEAR_MACHINE_LEARNING_JOBS:
      return [];
    default:
      return state;
  }
};

import { setCreativeVideoEvents } from 'ducks/actions/creative-video-events';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCreativeVideoEvents } from 'ducks/normalizers/creative-video-events';
import { Gemini } from 'utils/axios';

export const getCreativeVideoEvents = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/${entity_id}/video_events`);
  const creative_video_events = await normalizeCreativeVideoEvents(res.data);
  await dispatch(setCreativeVideoEvents(creative_video_events));
  return creative_video_events;
};

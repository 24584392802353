import { CLEAR_CHANGE_ORDERS, SET_CHANGE_ORDERS } from '../types';

export const changeOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CHANGE_ORDERS:
      return action.payload;
    case CLEAR_CHANGE_ORDERS:
      return [];
    default:
      return state;
  }
};

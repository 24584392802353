import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateSettings } from 'ducks/actions/settings';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import cn from './CreativePreview.module.scss';

const CreativePreview = ({
  tag_html,
  creative_width,
  creative_height,
  creative_ratio,
  height,
  width,
  is_transformed = true,
  point_events = 'all',
  creative_type_id,
  video_url,
}) => {
  const divRef = useRef();
  const dispatch = useDispatch();

  const calculatePosition = (ratio = 1, measurement = 1) => {
    if (ratio > 3) {
      return `50%`;
    }
    const base = (1 - measurement) / 2;
    return `${base * 100}%`;
  };

  const handleShowVideo = () => {
    dispatch(updateSettings({ loading: false }));
  };

  useEffect(() => {
    if (creative_type_id === 1) {
      const { current } = divRef;
      if (!current || !tag_html) {
        return;
      }

      let height_ratio = 1;
      const height_padding = height - 10;
      let width_ratio = 1;
      const width_padding = width - 10;
      let transform = '0% 0%';

      if (creative_height > height_padding) {
        height_ratio = height_padding / creative_height;
      }

      if (creative_width > width_padding) {
        width_ratio = width_padding / creative_width;
      }

      // magic pixel numbers
      // probably dont do anything
      if (creative_ratio > 3) {
        const position = calculatePosition(creative_ratio, height_ratio);
        transform = `7px ${position}`;
      } else {
        const position = calculatePosition(creative_ratio, height_ratio);
        transform = `${position} 7px`;
      }

      const ratio = height_ratio < width_ratio ? height_ratio : width_ratio;
      if (is_transformed) {
        divRef.current.style.transform = `scale(${ratio})`;
      }
      divRef.current.style.transformOrigin = transform;

      const ifr = document.createElement('iframe');
      ifr.setAttribute('frameborder', '0');
      ifr.setAttribute(
        'style',
        `width: ${creative_width}px; height: ${creative_height}px; pointer-events: ${point_events};`,
      );

      current.appendChild(ifr);

      if (ifr.contentWindow) {
        ifr.contentWindow.document.open();
        ifr.contentWindow.document.write(`<body style='margin: 0;'>${tag_html}</body>`);
        ifr.contentWindow.document.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag_html, is_transformed, creative_type_id]);

  if (creative_type_id === 2) {
    return (
      <div className={cn.creative} style={{ height, width }}>
        {video_url.length === 0 && <FontAwesomeIcon size="2x" icon="play" />}
        <video preload="auto" onCanPlay={handleShowVideo} autoPlay width={width} height={height} src={video_url} />
      </div>
    );
  }

  return <div ref={divRef} className={is_transformed ? cn.creativeTop : cn.creative} style={{ height, width }} />;
};

CreativePreview.propTypes = {
  tag_html: PropTypes.string,
  creative_width: PropTypes.number,
  creative_height: PropTypes.number,
  creative_ratio: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  is_transformed: PropTypes.bool,
  point_events: PropTypes.string,
  creative_type_id: PropTypes.number,
  video_url: PropTypes.string,
};

export default CreativePreview;

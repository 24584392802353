import { CLEAR_CHANGE_ORDER, SET_CHANGE_ORDER, UPDATE_CHANGE_ORDER } from '../types';

export const changeOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CHANGE_ORDER:
      return action.payload;
    case UPDATE_CHANGE_ORDER:
      return { ...state, ...action.payload };
    case CLEAR_CHANGE_ORDER:
      return {};
    default:
      return state;
  }
};

export const creativeValidation = (creative = {}) => {
  if (!creative.creative_name) {
    return false;
  }
  if (!creative.creative_type_id) {
    return false;
  }
  if (!creative.tag_html) {
    return false;
  }
  if (!creative.ad_server_id) {
    return false;
  }
  return true;
};

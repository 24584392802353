import * as constants from 'ducks/types';

export const setCreativeContents = (payload = {}) => ({
  type: constants.SET_CREATIVE_CONTENTS,
  payload,
});

export const clearCreativeContents = () => ({
  type: constants.CLEAR_CREATIVE_CONTENTS,
});

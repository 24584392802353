import { CLEAR_CAMPAIGN_DISCREPANCIES, SET_CAMPAIGN_DISCREPANCIES, UPDATE_CAMPAIGN_DISCREPANCIES } from '../types';

// empty header needs to be available for the table
const initial_state = {
  headers: [{ flex_grow: 1, name: 'N/A', uuid: 1 }],
  rows: [],
  rows_dcm: [],
};
export const campaignDiscrepanciesReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_DISCREPANCIES:
      return action.payload;
    case UPDATE_CAMPAIGN_DISCREPANCIES:
      return { ...state, ...action.payload };
    case CLEAR_CAMPAIGN_DISCREPANCIES:
      return { ...initial_state };
    default:
      return state;
  }
};

import { formatDate, offsetTimezoneUTC } from 'utils/dates';

import {
  CLEAR_CAMPAIGN_MOVING_AVERAGE,
  SET_CAMPAIGN_CUMULATIVE_PACING_MOVING_AVERAGE,
  SET_CAMPAIGN_MOVING_AVERAGE,
  SET_CAMPAIGN_PROSPECTING_MOVING_AVERAGE,
  SET_CAMPAIGN_PROSPECTING_RETARGETING_MOVING_AVERAGE,
  SET_CAMPAIGN_PROSPECTING_SHARES_MOVING_AVERAGE,
  SET_CAMPAIGN_RETARGETING_MOVING_AVERAGE,
} from '../types';

// empty header needs to be available for the table
const initial_state = {
  moving_average: {
    advertiser_name: null,
    advertiser_id: null,
    campaign_id: null,
    campaign_name: '',
    kpi_group_name: '',
    kpi_group_id: null,
    measure_type_name: '',
    measure_type_id: null,
    measure_unit_name: '',
    measure_unit_id: null,
    measure_unit_format: 'currency-dynamic',
    chart_title: 'Moving Average',
    data: [],
    tooltips: [
      {
        name: 'External CPA',
        format: 'currency-dynamic',
      },
      {
        name: 'External CPA Average',
        format: 'currency-dynamic',
      },
    ],
    x: [],
    y1: [],
    y2: [],
  },
  retargeting: {
    advertiser_name: null,
    advertiser_id: null,
    campaign_id: null,
    campaign_name: '',
    kpi_group_name: '',
    kpi_group_id: null,
    measure_type_name: '',
    measure_type_id: null,
    measure_unit_name: '',
    measure_unit_id: null,
    measure_unit_format: 'currency-dynamic',
    chart_title: 'Retargeting Moving Average',
    data: [],
    tooltips: [
      {
        name: 'External CPA',
        format: 'currency-dynamic',
      },
      {
        name: 'External CPA Average',
        format: 'currency-dynamic',
      },
    ],
    x: [],
    y1: [],
    y2: [],
  },
  prospecting: {
    advertiser_name: null,
    advertiser_id: null,
    campaign_id: null,
    campaign_name: '',
    kpi_group_name: '',
    kpi_group_id: null,
    measure_type_name: '',
    measure_type_id: null,
    measure_unit_name: '',
    measure_unit_id: null,
    measure_unit_format: 'currency-dynamic',
    chart_title: 'Prospecting Moving Average',
    data: [],
    tooltips: [
      {
        name: 'External CPA',
        format: 'currency-dynamic',
      },
      {
        name: 'External CPA Average',
        format: 'currency-dynamic',
      },
    ],
    x: [],
    y1: [],
    y2: [],
  },
  prospecting_retargeting: {
    advertiser_name: null,
    advertiser_id: null,
    campaign_id: null,
    campaign_name: '',
    kpi_group_name: '',
    kpi_group_id: null,
    measure_type_name: '',
    measure_type_id: null,
    measure_unit_name: '',
    measure_unit_id: null,
    measure_unit_format: 'number',
    chart_title: 'Prospecting Retargeting Moving Average',
    data: [],
    tooltips: [
      {
        name: 'Prospecting',
        format: 'number',
      },
      {
        name: 'Prospecting Average',
        format: 'number',
      },
      {
        name: 'Retargeting',
        format: 'number',
      },
      {
        name: 'Retargeting Average',
        format: 'number',
      },
    ],
    x: [],
    y1: [],
    y2: [],
    y3: [],
    y4: [],
  },
  prospecting_shares: {
    advertiser_name: null,
    advertiser_id: null,
    campaign_id: null,
    campaign_name: '',
    kpi_group_name: '',
    kpi_group_id: null,
    measure_type_name: '',
    measure_type_id: null,
    measure_unit_name: '',
    measure_unit_id: null,
    measure_unit_format: 'percentage-decimals',
    chart_title: 'Prospecting Shares Moving Average',
    data: [],
    tooltips: [
      {
        name: 'Imp',
        format: 'percentage-decimals',
      },
      {
        name: 'Imp Rolling Average',
        format: 'percentage-decimals',
      },
      {
        name: 'Actions',
        format: 'percentage-decimals',
      },
      {
        name: 'Actions Rolling Average',
        format: 'percentage-decimals',
      },
    ],
    x: [],
    y1: [],
    y2: [],
    y3: [],
    y4: [],
  },
  cumulative_pacing: {
    advertiser_name: null,
    advertiser_id: null,
    campaign_id: null,
    campaign_name: '',
    kpi_group_name: '',
    kpi_group_id: null,
    measure_type_name: '',
    measure_type_id: null,
    measure_unit_name: '',
    measure_unit_id: null,
    measure_unit_format: 'percentage-decimals',
    chart_title: 'Cumulative Pacing Moving Average',
    data: [],
    tooltips: [
      {
        name: 'Combined Pacing',
        format: 'percentage-decimals',
      },
      {
        name: 'Prospecting Pacing',
        format: 'percentage-decimals',
      },
      {
        name: 'Retargeting Pacing',
        format: 'percentage-decimals',
      },
    ],
    x: [],
    y1: [],
    y2: [],
    y3: [],
  },
};
export const campaignMovingAverageReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_MOVING_AVERAGE:
      return { ...state, moving_average: { ...state.moving_average, ...action.payload } };
    case SET_CAMPAIGN_CUMULATIVE_PACING_MOVING_AVERAGE:
      return { ...state, cumulative_pacing: { ...state.cumulative_pacing, ...action.payload } };
    case SET_CAMPAIGN_RETARGETING_MOVING_AVERAGE:
      return { ...state, retargeting: { ...state.retargeting, ...action.payload } };
    case SET_CAMPAIGN_PROSPECTING_MOVING_AVERAGE:
      return { ...state, prospecting: { ...state.prospecting, ...action.payload } };
    case SET_CAMPAIGN_PROSPECTING_RETARGETING_MOVING_AVERAGE:
      return { ...state, prospecting_retargeting: { ...state.prospecting_retargeting, ...action.payload } };
    case SET_CAMPAIGN_PROSPECTING_SHARES_MOVING_AVERAGE:
      return { ...state, prospecting_shares: { ...state.prospecting_shares, ...action.payload } };
    case CLEAR_CAMPAIGN_MOVING_AVERAGE:
      return { ...initial_state };
    default:
      return state;
  }
};

import { CLEAR_THIRD_PARTY_REPORTS, SET_THIRD_PARTY_REPORTS } from '../types';

export const thirdPartyReportsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_THIRD_PARTY_REPORTS:
      return action.payload;
    case CLEAR_THIRD_PARTY_REPORTS:
      return [];
    default:
      return state;
  }
};

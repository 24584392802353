import { CLEAR_GLOBAL_DEALS, SET_GLOBAL_DEALS } from '../types';

export const globalDealsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_GLOBAL_DEALS:
      return action.payload;
    case CLEAR_GLOBAL_DEALS:
      return [];
    default:
      return state;
  }
};

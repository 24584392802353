import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { AutoSizer } from 'react-virtualized';
import { handleError } from 'utils/errors';
import { formatNumber, numberArrayCheck } from 'utils/numbers';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import { x1_scale, y1_line, y1_scale, y2_bar, y2_scale, y3_bar, y3_scale } from './constants';

import cn from './CampaignOverviewPage.module.scss';

Chart.register({
  id: 'horizontalLinePlugin',
  beforeDatasetsDraw: (chart) => {
    const { ctx, scales } = chart;
    const y1Scale = scales.y1;

    const yValue = y1Scale.getPixelForValue(100);

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(scales.x1.left, yValue);
    ctx.lineTo(scales.x1.right, yValue);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#b6bac0';
    ctx.setLineDash([5, 5]);
    ctx.stroke();
    ctx.restore();
  },
});

export const PacingPerformanceChart = ({ data }) => {
  const [chart, setChart] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x1: x1_scale,
      y1: y1_scale,
      y2: y2_scale,
      y3: y3_scale,
    },
  });

  useEffect(() => {
    const initialSetup = async () => {
      try {
        await getChartData();
        await setChartOptions();
      } catch (err) {
        handleError(err);
      }
    };
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getChartData = async () =>
    new Promise((resolve) => {
      const { x, y1, y2, y3 } = data;
      const datasets = [];

      if (numberArrayCheck(y1)) {
        datasets.push({
          ...y1_line,
          data: [...y1],
        });
      }

      if (numberArrayCheck(y2)) {
        datasets.push({
          ...y2_bar,
          data: [...y2],
        });
      }

      if (numberArrayCheck(y3)) {
        datasets.push({
          ...y3_bar,
          data: [...y3],
        });
      }

      setChart({
        labels: [...x],
        datasets: [...datasets],
      });

      resolve();
    });

  const setChartOptions = async () => {
    const { suggested_max } = data;
    const { scales } = options;

    const y2 = {
      ...scales.y2,
      suggestedMax: suggested_max,
    };

    const y3 = {
      ...scales.y3,
      suggestedMax: suggested_max,
    };

    const tooltips = ['percentage-performance', 'currency-abbreviated', 'currency-abbreviated'];

    setOptions((prev) => ({
      ...prev,
      plugins: {
        legend: {
          padding: 10,
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        tooltip: {
          mode: 'index',
          callbacks: {
            label(chart) {
              const tooltip = tooltips[chart.datasetIndex] || '';
              return ` ${chart.dataset.label}: ${formatNumber(chart.raw, { format: tooltip })}`;
            },
          },
        },
        horizontalLinePlugin: {
          id: 'horizontalLinePlugin',
          afterDraw: (chart) => {
            const { ctx, scales } = chart;
            const y1Scale = scales.y1;

            const yValue = y1Scale.getPixelForValue(100);

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(scales.x1.left, yValue);
            ctx.lineTo(scales.x1.right, yValue);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#b6bac0';
            ctx.setLineDash([5, 5]);
            ctx.stroke();
            ctx.restore();
          },
        },
      },
      scales: {
        x1: x1_scale,
        y1: y1_scale,
        y2: y2,
        y3: y3,
      },
    }));
  };

  return (
    <div className={cn.chart}>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }}>
              <Bar key={height + width} data={chart} options={options} height={height} width={width} />
            </div>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

PacingPerformanceChart.defaultProps = {
  data: {
    suggested_max: 10,
    x: [],
    y1: [],
    y2: [],
    y3: [],
  },
};

PacingPerformanceChart.propTypes = {
  data: PropTypes.object,
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action } from 'components/actions/Action';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import { updateModal } from 'ducks/actions/modals';
import * as PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';

import cn from './Navigation.module.scss';

export const FilterWithRouter = ({ history, match }) => {
  const dispatch = useDispatch();
  const { advertisers, filters } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      filters: state.filters,
    }),
    shallowEqual,
  );

  const editFilter = () => {
    dispatch(updateModal({ manage_filter: true }));
  };

  const { advertiser_id, filter_id, page } = match.params;
  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const filter = filters.find((r) => Number(r.filter_id) === Number(filter_id)) || {};
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  const filter_width = getTextWidth(filter.filter_name);
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
          locations={location}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/filters`)}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu labels={filter.filter_name} values={filters} page="Filter" width={filter_width} height={47} />
        <div className={cn.flex} />
        <Action tooltip="Edit Filter" onClick={editFilter} icon={['fas', 'edit']} font_size={12} />
      </div>
    </>
  );
};

FilterWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const Filter = withRouter(FilterWithRouter);

import { Button, Input, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';

import { createCreativeMessage } from 'ducks/operators/creative-messages';
import cn from './Modal.module.scss';
import { getCreativeMessagesList } from 'ducks/operators/lookups';

const default_state = {
  creative_message_name: '',
  creative_message_id: null,
};

export const ManageCreativeMessage = ({ match, onCallback = () => {} }) => {
  const dispatch = useDispatch();

  const { modals } = useSelector(
    (state) => ({
      modals: state.modals,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state });
  }, []);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    setForm({ ...default_state });
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_creative_message: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {

      const data = {
        creative_message_name: form.creative_message_name,
      };

      const creative_message_id = await dispatch(createCreativeMessage(data));
      onCallback({...data, creative_message_id: creative_message_id})
      dispatch(updateSuccess('creative message has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    dispatch(updateSettings({ loading: false }));
    closeModal();
  };

  const { creative_message_name } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_creative_message"
      show={modals.manage_creative_message}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>Create Creative Message</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus
          label="Creative Message"
          value={creative_message_name}
          onChange={(value) => onChange({ creative_message_name: value })}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button
            button_size="small"
            width="100px"
            margin="10px 0px 0px 0px"
            type="submit"
            disabled={!creative_message_name}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageCreativeMessage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

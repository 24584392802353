import { CLEAR_TAG_VARIABLE_RULES, SET_TAG_VARIABLE_RULES } from '../types';

export const tagVariableRulesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TAG_VARIABLE_RULES:
      return action.payload;
    case CLEAR_TAG_VARIABLE_RULES:
      return [];
    default:
      return state;
  }
};

import { setLineItemCreatives } from 'ducks/actions/line-item-creatives';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCreatives } from 'ducks/normalizers/creatives';
import { Gemini } from 'utils/axios';
import { checkSort } from 'utils/string';

export const getFlightLineItemCreatives = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/flights/${entity_id}/line_item_creatives`, data);
  const creatives = await normalizeCreatives(res.data);
  await dispatch(setLineItemCreatives(creatives));
  return creatives;
};

export const getCreativeLineItemCreatives = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/${entity_id}/line_item_creatives`);
  const creatives = await normalizeCreatives(res.data)
    .sort((a, b) => checkSort(a, b, 'line_item_name', 'desc'))
    .sort((a, b) => checkSort(a, b, 'line_item_state_id', 'asc'));
  await dispatch(setLineItemCreatives(creatives));
  return creatives;
};

export const associateLineItemCreatives = (entity_id, data) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/line_item_creatives/update`, data);
  return res.data;
};

export const activateLineItemCreative = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    throw new Error('line item creatives need to be associated before attempting to activate.');
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_item_creatives/${entity_id}/activate`);
  return res.data;
};

export const deactivateLineItemCreative = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    throw new Error('line item creatives need to be associated before attempting to deactivate.');
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_item_creatives/${entity_id}/deactivate`);
  return res.data;
};

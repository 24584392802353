import { v4 as uuidv4 } from 'uuid';

import { offsetTimezoneUTC } from '../../utils/dates';
import { checkSort } from '../../utils/string';

export const normalizeModelAdvertisers = (data = []) =>
  data.map((d) => normalizeModelAdvertiser(d)).sort((a, b) => checkSort(a, b, 'advertiser_name', 'asc'));

export const normalizeModelAdvertiser = (data = {}) => ({
  ...data,
  uuid: uuidv4(),
});

export const normalizeModelOutputs = (data = []) =>
  data.map((d) => normalizeModelOutput(d)).sort((a, b) => checkSort(a, b, 'output_name', 'asc'));

export const normalizeModelOutput = (data = {}) => ({
  ...data,
  uuid: uuidv4(),
});

export const normalizeModel = (data = {}) => ({
  ...data,
  created_date_formatted: offsetTimezoneUTC(data.created_date_utc, { format: 'M/D/YY h:mm:ss A' }),
  imported_date_formatted: offsetTimezoneUTC(data.imported_date_utc, { format: 'M/D/YY h:mm:ss A' }),
  uuid: uuidv4(),
});

import { CLEAR_IDENTITY_TYPES_LIST, SET_IDENTITY_TYPES_LIST } from '../types';

const initial_state = [];

export const identityTypesListReducer = (state = [...initial_state], action) => {
  switch (action.type) {
    case SET_IDENTITY_TYPES_LIST:
      return action.payload;
    case CLEAR_IDENTITY_TYPES_LIST:
      return [];
    default:
      return state;
  }
};

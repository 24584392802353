/* eslint-disable no-undef */
export function selectAuthenticate(user = {}) {
  return user && user.private_key && user.public_key;
}

export const selectUser = (state) => state.user;

export const loadUser = () => {
  try {
    const user = localStorage.getItem('user');
    if (user === null || user === undefined) {
      return {};
    }
    return JSON.parse(user);
  } catch (err) {
    return {};
  }
};

export const saveUser = (user) => {
  try {
    if (user === undefined || user === null) {
      localStorage.setItem('user', JSON.stringify({}));
    } else {
      localStorage.setItem('user', JSON.stringify(user));
    }
  } catch (e) {
    localStorage.setItem('user', JSON.stringify({}));
  }
};

import { setAdvertiser, setAdvertisers } from 'ducks/actions/advertisers';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeAdvertiser, normalizeAdvertisers } from 'ducks/normalizers/advertisers';
import { getAdvertiserCampaigns, getCampaigns } from 'ducks/operators/campaigns';
import { getAdvertiserCreatives } from 'ducks/operators/creatives';
import { getPartners } from 'ducks/operators/partners';
import { Gemini } from 'utils/axios';

import { getAdvertiserCampaignGroups } from './campaign-groups';
import { getAdvertiserFilters } from './filters';
import { setCampaignFlights } from './flights';
import { getAdvertiserLists } from './lists';
import { getAdvertiserSegments } from './segments';
import { getAdvertiserPhysicalTags, getAdvertiserTags } from './tags';

export const getAdvertisersPage =
  (queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const query = {
      include_archived: false,
      ...queries,
    };

    await dispatch(getCampaigns({ include_archived: false }));
    const [advertisers] = await Promise.all([dispatch(getAdvertisers(query)), dispatch(getPartners())]);
    await dispatch(updateSettings({ loading: false }));
    return advertisers;
  };

export const getAdvertiserPage = (params) => async (dispatch) => {
  const { advertiser_id } = params;
  const [advertiser] = await Promise.all([
    dispatch(getAdvertisers()),
    dispatch(getPartners({ include_archived: false })),
    dispatch(getAdvertiser(advertiser_id)),
    dispatch(getAdvertiserCampaignGroups(advertiser_id)),
  ]);
  return advertiser;
};

export const getAdvertiserOverviewPage =
  (params = {}, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id } = params;
    const query = {
      campaigns_archived: false,
      segments_archived: false,
      tags_archived: false,
      ...queries,
    };

    // get advertiser tags so that segments can use the state
    await dispatch(getAdvertiserTags(advertiser_id, { include_archived: query.tags_archived }));

    const [campaigns] = await Promise.all([
      dispatch(getAdvertiserCampaigns(advertiser_id, { include_archived: query.campaigns_archived })),
      dispatch(getAdvertiserSegments(advertiser_id, { include_archived: query.segments_archived })),
    ]);

    await Promise.all(campaigns.map((c) => dispatch(setCampaignFlights(c.campaign_id))));

    await dispatch(updateSettings({ loading: false }));
    return campaigns;
  };

export const getAdvertiserCreativesPage =
  (params, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id } = params;
    const query = {
      include_archived: false,
      ...queries,
    };
    const [campaign] = await Promise.all([dispatch(getAdvertiserCreatives(advertiser_id, query))]);

    await dispatch(updateSettings({ loading: false }));
    return campaign;
  };

export const getAdvertiserFiltersPage =
  (params = {}, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id } = params;
    const query = {
      include_archived: false,
      ...queries,
    };

    const [filters] = await Promise.all([dispatch(getAdvertiserFilters(advertiser_id, query))]);

    await dispatch(updateSettings({ loading: false }));
    return filters;
  };

export const getAdvertiserTagsPage =
  (params, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id } = params;
    const query = {
      include_archived: true,
      ...queries,
    };

    const [tags] = await Promise.all([dispatch(getAdvertiserPhysicalTags(advertiser_id, query))]);

    await dispatch(updateSettings({ loading: false }));
    return tags;
  };

export const getAdvertiserListsPage =
  (params, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id } = params;
    const query = {
      include_archived: false,
      ...queries,
    };

    const [lists] = await Promise.all([dispatch(getAdvertiserLists(advertiser_id, query))]);

    await dispatch(updateSettings({ loading: false }));
    return lists;
  };

export const getAdvertisers = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/list`, data);
  const advertisers = await normalizeAdvertisers(res.data);
  await dispatch(setAdvertisers(advertisers));
  return res.data;
};

export const getAdvertiser = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/advertisers/${entity_id}`);
  const advertiser = await normalizeAdvertiser(res.data);
  await dispatch(setAdvertiser(advertiser));
  return advertiser;
};

export const createAdvertiser = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/advertisers/create', data);
  return res.data;
};

export const updateAdvertiser = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/update`, data);
  return res.data;
};

export const archiveAdvertiser = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/archive`);
  return res.data;
};

export const unarchiveAdvertiser = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/unarchive`);
  return res.data;
};

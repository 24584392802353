import * as constants from 'ducks/types';

export const setCampaignGroups = (payload = {}) => ({
  type: constants.SET_CAMPAIGN_GROUPS,
  payload,
});

export const clearCampaignGroups = () => ({
  type: constants.CLEAR_CAMPAIGN_GROUPS,
});

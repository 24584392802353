import * as constants from 'ducks/types';

export const setBidAlgorithmsList = (payload = []) => ({
  type: constants.SET_BID_ALGORITHMS_LIST,
  payload,
});

export const clearBidAlgorithmsList = () => ({
  type: constants.CLEAR_BID_ALGORITHMS_LIST,
});

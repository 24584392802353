import { CLEAR_LISTS, SET_LISTS } from '../types';

export const listsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LISTS:
      return action.payload;
    case CLEAR_LISTS:
      return [];
    default:
      return state;
  }
};

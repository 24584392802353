export const mergeLineItemVendorFees = (global_vendor_fees = [], vendor_fees = []) =>
  global_vendor_fees.map((g) => {
    const line_item_vendor_fee = vendor_fees.find((v) => v.fee_vendor_fee_id === g.fee_vendor_fee_id) || {};
    return {
      ...g,
      ...line_item_vendor_fee,
      checked: line_item_vendor_fee.fee_vendor_fee_line_item_id !== undefined,
    };
  });

export const mergeStrategyVendorFees = (global_vendor_fees = [], vendor_fees = []) =>
  global_vendor_fees.map((g) => {
    const strategy_vendor_fee = vendor_fees.find((v) => v.fee_vendor_fee_id === g.fee_vendor_fee_id) || {};
    return {
      ...g,
      ...strategy_vendor_fee,
      checked: strategy_vendor_fee.fee_vendor_fee_strategy_id !== undefined,
    };
  });

import moment from 'moment';
import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeVendorFees = (vendor_fees = []) =>
  vendor_fees.map((v) => normalizeVendorFee(v)).sort((a, b) => checkSort(a, b, 'fee_vendor_fee_name', 'asc'));

export const normalizeVendorFee = (vendor_fee = {}) => {
  const {
    fee_value,
    impression_minimum,
    impression_maximum,
    contract_start_date,
    contract_finish_date,
    start_date,
    finish_date,
  } = vendor_fee;
  let state_id = 3;
  // checking to see if today is within the date range to display state id circle
  if (new Date(start_date) >= new Date() && new Date(finish_date) <= new Date()) {
    state_id = 1;
  }

  const impression_minimum_formatted = formatNumber(impression_minimum, { format: 'number-comma' });
  const impression_maximum_formatted = formatNumber(impression_maximum, { format: 'number-comma' });
  return {
    ...vendor_fee,
    impression_minimum_formatted,
    impression_maximum_formatted,
    fee_value_formatted: formatNumber(fee_value, { format: 'currency' }),
    contract_start_date_formatted: formatDate(contract_start_date, { format: 'MM/DD/YYYY' }),
    contract_finish_date_formatted: formatDate(contract_finish_date, { format: 'MM/DD/YYYY' }),
    state_id,
    uuid: uuidv4(),
  };
};

export const normalizeLineItemVendorFees = (vendor_fees = []) =>
  vendor_fees.map((v) => normalizeLineItemVendorFee(v)).sort((a, b) => checkSort(a, b, 'fee_vendor_fee_name', 'asc'));

export const normalizeLineItemVendorFee = (vendor_fee = {}) => {
  const { fee_vendor_fee_line_item_start_date } = vendor_fee;
  return {
    ...vendor_fee,
    fee_vendor_fee_line_item_start_date_formatted: moment(fee_vendor_fee_line_item_start_date).format('MM/DD/YYYY'),
    uuid: uuidv4(),
  };
};

export const normalizeStrategyVendorFees = (vendor_fees = []) =>
  vendor_fees.map((v) => normalizeStrategyVendorFee(v)).sort((a, b) => checkSort(a, b, 'fee_vendor_fee_name', 'asc'));

export const normalizeStrategyVendorFee = (vendor_fee = {}) => {
  const { fee_vendor_fee_strategy_start_date } = vendor_fee;
  return {
    ...vendor_fee,
    fee_vendor_fee_strategy_start_date_formatted: moment(fee_vendor_fee_strategy_start_date).format('MM/DD/YYYY'),
    uuid: uuidv4(),
  };
};

import * as constants from 'ducks/types';

export const setAdvertiser = (payload = {}) => ({
  type: constants.SET_ADVERTISER,
  payload,
});

export const clearAdvertiser = () => ({
  type: constants.CLEAR_ADVERTISER,
});

export const setAdvertisers = (payload = {}) => ({
  type: constants.SET_ADVERTISERS,
  payload,
});

export const clearAdvertisers = () => ({
  type: constants.CLEAR_ADVERTISERS,
});

import { setAbTestSegments, setSegments, updateAbTestSegments } from 'ducks/actions/segments';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeAbTestSegments, normalizeSegments } from 'ducks/normalizers/segments';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

export const getAbTestSegments = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/ab_tests/${entity_id}/segments`);
  const ab_test_segments = normalizeAbTestSegments(res.data);
  await dispatch(setAbTestSegments(ab_test_segments));
  return ab_test_segments;
};

export const setFlightAbTestSegments = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.get(`/ab_tests/${entity_id}/segments`);
  const ab_test_segments = normalizeAbTestSegments(res.data);
  await dispatch(updateAbTestSegments(ab_test_segments));
  return ab_test_segments;
};

export const getAdvertiserSegments = (entity_id, queries) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    include_archived: false,
    ...queries,
  };

  const { tags } = getState();

  const res = await Gemini.get(`/advertisers/${entity_id}/segments${replaceQuery(query)}`);
  const segments = normalizeSegments(res.data, tags);
  await dispatch(setSegments(segments));
  return segments;
};

export const createAbTestSegment = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/ab_tests/${entity_id}/segments/create`, data);
  return res.data;
};

export const updateAbTestSegment = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/segments/${entity_id}/update`, data);
  return res.data;
};

export const createSegment = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  // normalizing payload - API needs to be update
  data.advertiser_id = entity_id;

  const res = await Gemini.post('/segments/create', data);
  return res.data;
};

export const updateSegment = (entitiy_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/segments/${entitiy_id}/update`, data);
  return res.data;
};

export const unarchiveSegment = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/segments/${entity_id}/unarchive`);
  return res.data;
};

export const archiveSegment = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/segments/${entity_id}/archive`);
  return res.data;
};

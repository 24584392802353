import React, { useState } from 'react';
import { Button, Modal, Table } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createCreative, updateCreatives } from 'ducks/operators/creatives';
import { transformCreative } from 'ducks/transforms/creatives';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import { CreativeNameCell, ErrorCell, OptionalCell } from './UploadCreativesCells';

import cn from './Modal.module.scss';

const headers = [
  {
    name: 'Creative Name',
    width: '100px',
    flex_grow: 1,
    uuid: 2,
  },
  {
    name: 'CPM',
    width: '80px',
    uuid: 3,
  },
  {
    name: 'Creative Type',
    width: '100px',
    uuid: 4,
  },
  {
    name: 'Creative Tag',
    width: '100px',
    uuid: 5,
  },
  {
    name: 'Ad Server',
    width: '80px',
    uuid: 6,
  },
  {
    name: 'Add Ons',
    width: '80px',
    uuid: 7,
  },
  {
    name: 'Extras',
    width: '80px',
    uuid: 8,
  },
  {
    name: 'Vast',
    width: '80px',
    uuid: 9,
  },
  {
    name: 'PSA',
    width: '80px',
    uuid: 10,
  },
  {
    name: 'App Nexus',
    width: '80px',
    uuid: 11,
  },
];

const schema = {
  'CREATIVE NAME': {
    prop: 'creative_name',
    type: String,
  },
  'CREATIVE TYPE': {
    prop: 'creative_type',
    type: String,
  },
  'CREATIVE CPM': {
    prop: 'price_usd',
    type: Number,
    parse: (value) => value || 0,
  },
  'AD SERVER': {
    prop: 'ad_server',
    type: String,
  },
  'CREATIVE STATE': {
    prop: 'creative_state',
    type: String,
  },
  'CREATIVE CONTENT': {
    prop: 'creative_content',
    type: String,
  },
  'CREATIVE ADD ONS': {
    prop: 'creative_add_ons',
    type: String,
  },
  'CATEGORY OVERRIDE': {
    prop: 'category',
    type: String,
  },
  'DOMAIN OVERRIDE': {
    prop: 'domain',
    type: String,
  },
  'IS PSA': {
    prop: 'is_psa',
    type: Boolean,
  },
  'REMOTE VAST': {
    prop: 'remote_vast',
    type: Boolean,
  },
  'SUBMIT TO APP NEXUS': {
    prop: 'app_nexus',
    type: Boolean,
  },
  'CREATIVE TAG': {
    prop: 'creative_tag',
    type: String,
  },
  'AD SERVER CAMPAIGN IDENTIFIER': {
    prop: 'ad_server_campaign_identifier_id',
    type: String,
  },
  'AD SERVER IDENTIFIER': {
    prop: 'ad_server_identifier',
    type: Number,
  },
  'CREATIVE EXTRAS': {
    prop: 'creative_extras',
    type: String,
  },
  PIXELS: {
    prop: 'pixels',
    type: String,
  },
  'CLICK URL': {
    prop: 'click_url',
    type: String,
  },
  'VIDEO EVENTS': {
    prop: 'video_events',
    type: String,
  },
};

export const UploadCreatives = ({ history, match, location }) => {
  const dispatch = useDispatch();

  const {
    modals,
    states_list,
    creative_extras_list,
    creative_types_list,
    creative_contents,
    categories_list,
    ad_servers_list,
    ad_server_campaign_identifiers_list,
    creative_add_ons_list,
    creative_video_events_list,
  } = useSelector((state) => ({
    modals: state.modals,
    states_list: state.states_list,
    creative_types_list: state.creative_types_list,
    creative_extras_list: state.creative_extras_list,
    creative_contents: state.creative_contents,
    categories_list: state.categories_list,
    ad_servers_list: state.ad_servers_list,
    ad_server_campaign_identifiers_list: state.ad_server_campaign_identifiers_list,
    creative_add_ons_list: state.creative_add_ons_list,
    creative_video_events_list: state.creative_video_events_list,
  }));

  const [data, setData] = useState([]);

  const fileRef = React.createRef();

  const onFileOpen = () => {
    fileRef.current.click();
  };

  const creativeTypeValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const creative_type =
      creative_types_list.find((ct) => {
        const formatted_id = String(ct.id);
        const formatted_name = String(ct.name).toLowerCase();
        return formatted_id === formatted || formatted_name === formatted;
      }) || {};
    return {
      creative_type_name: creative_type.name,
      creative_type_id: creative_type.id,
      creative_type_error: !creative_type.id,
    };
  };

  const creativeNameValidation = (value) => ({
    creative_name: value,
    creative_name_error: !value,
  });

  const adServerValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const ad_server =
      ad_servers_list.find((ct) => {
        const formatted_id = String(ct.ad_server_id);
        const formatted_name = String(ct.ad_server_name).toLowerCase();
        return formatted_id === formatted || formatted_name === formatted;
      }) || {};
    return {
      ad_server_name: ad_server.ad_server_name,
      ad_server_id: ad_server.ad_server_id,
      ad_server_error: !ad_server.ad_server_id,
    };
  };

  const adServerCampaignIdentifierIdValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const ad_server_campaign_identifier =
      ad_server_campaign_identifiers_list.find((ct) => {
        const formatted_id = String(ct.id);
        const formatted_name = String(ct.name).toLowerCase();
        return formatted_id === formatted || formatted_name === formatted;
      }) || {};
    return {
      ad_server_campaign_identifier_name: ad_server_campaign_identifier.name,
      ad_server_campaign_identifier_id: ad_server_campaign_identifier.id,
      ad_server_campaign_identifier_error: !ad_server_campaign_identifier.id,
    };
  };

  const creativeStateValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const state =
      states_list.find((ct) => {
        const formatted_id = String(ct.id);
        const formatted_name = String(ct.name).toLowerCase();
        return formatted_id === formatted || formatted_name === formatted;
      }) || {};
    return {
      state_name: state.name,
      state_id: state.id,
      state_error: !state.id,
    };
  };

  const creativeContentValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const creative_content =
      creative_contents.find((ct) => {
        const formatted_id = String(ct.creative_content_id);
        const formatted_name = String(ct.creative_content_name).toLowerCase();
        return formatted_id === formatted || formatted_name === formatted;
      }) || {};
    return {
      creative_content_name: creative_content.creative_content_name,
      creative_content_id: creative_content.creative_content_id,
      creative_content_error: !creative_content.creative_content_id,
    };
  };

  const creativeAddOnValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const formatted_split = formatted.split(';');
    const filtered_creative_addons = creative_add_ons_list
      .filter((as) => {
        const formatted_id = String(as.add_on_id);
        const formatted_name = String(as.add_on_name).toLowerCase();

        const found_id = formatted_split.includes(formatted_id);
        const found_name = formatted_split.includes(formatted_name);
        return found_id || found_name;
      })
      .map((add_on) => ({ ...add_on, checked: true }));
    return {
      add_ons: filtered_creative_addons,
      add_ons_check: filtered_creative_addons.length !== 0,
    };
  };

  const creativeExtrasValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const formatted_split = formatted.split(';');
    const filtered_creative_extras = creative_extras_list
      .filter((as) => {
        const formatted_id = String(as.creative_extra_id);
        const formatted_name = String(as.creative_extra_name).toLowerCase();

        const found_id = formatted_split.includes(formatted_id);
        const found_name = formatted_split.includes(formatted_name);
        return found_id || found_name;
      })
      .map((extra) => ({ ...extra, checked: true }));
    return {
      extras: filtered_creative_extras,
      extras_check: filtered_creative_extras.length !== 0,
    };
  };

  const categoryValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const category =
      categories_list.find((ct) => {
        const formatted_id = String(ct.category_id);
        const formatted_name = String(ct.category_name).toLowerCase();
        return formatted_id === formatted || formatted_name === formatted;
      }) || {};
    return {
      category_name: category.category_name,
      category_id: category.category_id,
      category_error: !category.category_id,
    };
  };

  const domainValidation = (value) => ({
    domain_url: value,
    domain_url_error: !!value,
  });

  const psaValidation = (value) => {
    const formatted = String(value).toLowerCase();
    return {
      is_psa: formatted === 'true',
    };
  };

  const remoteVastValidation = (value) => {
    const formatted = String(value).toLowerCase();
    return {
      remote_vast: formatted === 'true',
    };
  };

  const appNexusValidation = (value) => {
    const formatted = String(value).toLowerCase();
    return {
      app_nexus: formatted === 'true',
    };
  };

  const creativeTagValidation = (value) => ({
    tag_html: value,
    tag_html_error: !!value,
  });

  const pixelsValidation = (value) => {
    const formatted_split = value ? value.split(';') : [];
    const trimmed_pixels = formatted_split.map((p) => p.trim());
    let pixels = [];
    try {
      pixels = trimmed_pixels.map((p) => {
        const pixel = p.split(',').map((t) => t.trim());
        return {
          url: pixel[0],
          pixel_type_id: pixel[1],
        };
      });
    } catch (e) {
      handleError(e);
    }

    return {
      pixels,
      pixels_check: pixels.length !== 0,
    };
  };

  const clickValidation = (value) => ({
    click_url: value,
    click_url_error: !!value,
  });

  const videoEventsValidation = (value) => {
    const formatted = String(value).toLowerCase();
    const formatted_split = formatted.split(';');
    const formatted_events = formatted_split
      .map((e) => {
        const event_split = e.split(':');
        const [event, url] = event_split;

        const formatted_event = String(event).toLowerCase();
        const video_event =
          creative_video_events_list.find((ct) => {
            const formatted_id = String(ct.video_event_id);
            const formatted_name = String(ct.video_event_name).toLowerCase();
            return formatted_id === formatted_event || formatted_name === formatted_event;
          }) || {};
        return {
          event_id: video_event.video_event_id,
          event_name: video_event.video_event_name,
          video_event_error: !!video_event.video_event_id,
          url,
        };
      })
      .filter((e) => e.video_event_error);

    return {
      video_events: formatted_events,
    };
  };

  const creativeCpmValidation = (value) => ({
    price_usd: value,
    price_usd_error: !!value,
  });

  const validateCreative = (r) => {
    const { advertiser_id, flight_id } = match.params;
    const creative_type_validation = creativeTypeValidation(r.creative_type);
    const creative_name_validation = creativeNameValidation(r.creative_name);
    const ad_server_validation = adServerValidation(r.ad_server);
    const ad_server_campaign_identifier_validation = adServerCampaignIdentifierIdValidation(r.ad_server_campaign_identifier_id);
    const extra_ids_validation = creativeExtrasValidation(r.creative_extras);
    const creative_state_validation = creativeStateValidation(r.creative_state);
    const creative_content_validation = creativeContentValidation(r.creative_content);
    const creative_add_on_validation = creativeAddOnValidation(r.creative_add_ons);
    const category_validation = categoryValidation(r.category);
    const domain_validation = domainValidation(r.domain);
    const is_psa_validation = psaValidation(r.is_psa);
    const remote_vast_validation = remoteVastValidation(r.remote_vast);
    const app_nexus_validation = appNexusValidation(r.app_nexus);
    const creative_tag_validation = creativeTagValidation(r.creative_tag);
    const pixels_validation = pixelsValidation(r.pixels);
    const click_url_validation = clickValidation(r.click_url);
    const video_events_validation = videoEventsValidation(r.video_events);
    const creative_cpm_validation = creativeCpmValidation(r.price_usd);
    return {
      advertiser_id,
      flight_id,
      creative_id: null,
      ad_server_identifier: r.ad_server_identifier,
      ad_server_campaign_identifier_id: r.ad_server_campaign_identifier_id,
      ...extra_ids_validation,
      ...remote_vast_validation,
      ...creative_type_validation,
      ...creative_name_validation,
      ...ad_server_validation,
      ...creative_state_validation,
      ...creative_content_validation,
      ...creative_add_on_validation,
      ...category_validation,
      ...domain_validation,
      ...is_psa_validation,
      ...app_nexus_validation,
      ...creative_tag_validation,
      ...pixels_validation,
      ...click_url_validation,
      ...video_events_validation,
      ...creative_cpm_validation,
    };
  };

  const handleExcel = async (e) => {
    try {
      const creatives = await creativeValidation(e);
      setData(creatives);
    } catch (err) {
      handleError(err);
    }
  };

  const creativeValidation = (e) =>
    new Promise((resolve, reject) => {
      readXlsxFile(e.target.files[0], { schema })
        .then(({ rows }) => {
          const filtered_rows = rows.filter((r, i) => {
            // remove the headers and supporting ex. rows
            if (i >= rows.length - 2) {
              return false;
            }
            return true;
          });

          const validate_rows = filtered_rows.map((r) => validateCreative(r));
          resolve(validate_rows);
        })
        .catch((err) => reject(err));
    });

  const closeModal = () => {
    setData([]);
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ upload_creatives: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;

    const { advertiser_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const creatives = data.map((d) => transformCreative(d));
      const create_creatives = creatives.filter((c) => c.creative_id);
      const update_creatives = creatives.filter((c) => !c.creative_id);

      await dispatch(updateCreatives(advertiser_id, update_creatives));
      await Promise.all(create_creatives.map((c) => dispatch(createCreative(advertiser_id, c))));

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('creatives have been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  return (
    <Modal
      padding={16}
      close_box={46}
      name="upload_creatives"
      show={modals.upload_creatives}
      width={1100}
      onClose={closeModal}
      standard={false}
    >
      <h3>Upload Flight Creatives</h3>
      <div>
        <p style={{ fontSize: '13px', marginTop: 0 }}>
          Make sure to download the
          <a className={cn.link} href="/creatives-template.xlsx" style={{ fontWeight: 400 }} download>
            creatives template
          </a>
          before uploading any creatives. The template has special spacing for headers and examples that will ignore
          creatives on a custom sheets.
        </p>
        <p style={{ fontSize: '13px', marginBottom: 20 }}>
          The template accepts a variety of inputs including case-insensitive names and id's. All inputs that require
          lists will be semicolon delimited and colon delimited for special cases (video events). For a list of string
          values refer to the creatives modal and the matching dropdowns. The error table is the first check prior to
          uploading creatives and does not cover all scenarios. Green boxes represent a value is present and red boxes
          represent missing required values.
        </p>
      </div>
      <form>
        <Table headers={headers} rows={data}>
          <CreativeNameCell />
          <ErrorCell row_key="price_usd_error" />
          <ErrorCell row_key="creative_type_name" />
          <ErrorCell row_key="tag_html_error" />
          <ErrorCell row_key="ad_server_name" />
          <OptionalCell row_key="add_ons_check" />
          <OptionalCell row_key="extras_check" />
          <OptionalCell row_key="remote_vast" />
          <OptionalCell row_key="is_psa" />
          <OptionalCell row_key="app_nexus" />
        </Table>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" onClick={onFileOpen} width="140px" margin="10px 0px 0px 0px">
            Upload Creatives
          </Button>
          <input
            type="file"
            ref={fileRef}
            accept=".xlsx"
            style={{ display: 'none' }}
            onChange={handleExcel}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          {data.length !== 0 && (
            <Button button_size="small" onClick={onSubmit} width="140px" margin="10px 0px 0px 10px">
              Save Creatives
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

UploadCreatives.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from '@cognitiv/cyprus-ui';
import { Select } from 'components/cells/Select';
import { State } from 'components/cells/State';

export const SelectCell = ({ row, onClick, settings }) => {
  if (row.deal_strategy_id) {
    return <Cell />;
  }
  return (
    <Cell onClick={() => onClick({ ...row, checked: !row.checked })}>
      <Select
        disabled={row.deal_strategy_id}
        tooltip="Associate Deal"
        checked={row.checked}
        height={settings.row_height}
      />
    </Cell>
  );
};

export const DealCell = ({ row }) => (
  <Cell>
    <State state_id={row.state_id}>{row.deal_name}</State>
  </Cell>
);

export const InventorySourceCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.inventory_source_name}</p>
  </Cell>
);

export const SourceIdentifierCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.inventory_source_identifier}</p>
  </Cell>
);

export const MinPriceCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.min_price_cpm_formatted}</p>
  </Cell>
);

export const MaxPriceCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.max_price_cpm_formatted}</p>
  </Cell>
);

export const StartDateCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.deal_strategy_start_date_formatted}</p>
  </Cell>
);

import * as constants from 'ducks/types';

export const setReportParameters = (payload = []) => ({
  type: constants.SET_REPORT_PARAMETERS,
  payload,
});

export const clearReportParameters = () => ({
  type: constants.CLEAR_REPORT_PARAMETERS,
});

export const setReportTypes = (payload = []) => ({
  type: constants.SET_REPORT_TYPES,
  payload,
});

export const clearReportTypes = () => ({
  type: constants.CLEAR_REPORT_TYPES,
});

export const setReportTypeParameterGroups = (payload = []) => ({
  type: constants.SET_REPORT_TYPE_PARAMETER_GROUPS,
  payload,
});

export const clearReportTypeParameterGroups = () => ({
  type: constants.CLEAR_REPORT_TYPE_PARAMETER_GROUPS,
});

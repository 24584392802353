import * as constants from 'ducks/types';

export const setRoles = (payload = {}) => ({
  type: constants.SET_ROLES,
  payload,
});

export const clearRoles = () => ({
  type: constants.CLEAR_ROLES,
});

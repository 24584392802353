import { compareDateRange, compareDates, differenceDates, formatDate, subtractFromDate } from 'utils/dates';
import { formatBudgetSpend, formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeCampaigns = (data = []) =>
  data.map((d) => normalizeCampaign(d)).sort((a, b) => checkSort(a, b, 'campaign_name', 'asc'));

export const normalizeCampaign = (row = {}) => {
  const {
    advertiser_name,
    campaign_name,
    campaign_group_name,
    spend_budget_micro_usd,
    impression_budget,
    kpi_group_ids,
    state_id,
    start_date,
    finish_date,
    current_flight_start_date,
    current_flight_end_date,
    finish_offset_hours,
    identity_graph_id,
    identity_graph_name,
  } = row;

  const campaign_kpi_group_id = kpi_group_ids && kpi_group_ids.length && kpi_group_ids[0];
  const campaign_group_check = campaign_name.endsWith(campaign_group_name) || campaign_group_name === 'Default';
  const spend_budget_usd = formatBudgetSpend(spend_budget_micro_usd, { append: 0 });
  const finish_offset_hours_formatted = formatNumber(finish_offset_hours, { format: 'number-comma' });

  const min_start_date = subtractFromDate(start_date, { decrement: 1, type: 'days', format: 'MM/DD/YYYY' });
  const min_start_date_formatted = formatDate(min_start_date, { format: 'MM/DD/YYYY' });
  const start_date_formatted = formatDate(start_date, { format: 'MM/DD/YYYY' });
  const current_date_formatted = formatDate(new Date(), { format: 'MM/DD/YYYY' });
  const finish_date_formatted = formatDate(finish_date, { format: 'MM/DD/YYYY' });

  const spend_budget_usd_formatted = formatNumber(spend_budget_usd, { format: 'currency-abbreviated' });
  const spend_budget_usd_comma = formatNumber(spend_budget_usd, { format: 'currency-comma', append: '$0' });

  const impression_budget_formatted = formatNumber(impression_budget, { format: 'number-abbreviated', append: 0 });
  const impression_budget_comma = formatNumber(impression_budget, { format: 'number-comma' });

  const campaign_dates = `${formatDate(start_date, { format: 'MM/DD/YY' })} - ${formatDate(finish_date, {
    format: 'MM/DD/YY',
  })}`;
  const campaign_flight_dates_name = `${campaign_name} (${campaign_dates})`;
  const flight_dates = `${formatDate(current_flight_start_date, { format: 'MM/DD/YY' })} - ${formatDate(
    current_flight_end_date,
    {
      format: 'MM/DD/YY',
    },
  )}`;
  return {
    ...row,
    campaign_flight_dates_name,
    finish_offset_hours_formatted,
    campaign_kpi_group_id,
    campaign_group_check,
    spend_budget_usd: spend_budget_usd,
    spend_budget_usd_formatted,
    spend_budget_usd_comma,
    impression_budget_formatted,
    impression_budget_comma,
    campaign_dates,
    flight_dates,
    flight_start_date: formatDate(current_flight_start_date, { format: 'MM/DD/YYYY' }),
    flight_end_date: formatDate(current_flight_end_date, { format: 'MM/DD/YYYY' }),
    flight_hours_remaining: differenceDates(new Date(), current_flight_end_date),
    start_date_formatted,
    current_date_formatted,
    finish_date_formatted,
    campaign_has_started: compareDates(start_date_formatted, current_date_formatted),
    campaign_is_live: compareDateRange(start_date_formatted, current_date_formatted, finish_date_formatted),
    campaign_has_ended: compareDates(finish_date_formatted, current_date_formatted),
    advertiser_campaign_name: `${advertiser_name} ${campaign_name}`,
    campaign_state_sort: `${start_date} ${state_id} ${campaign_name}`,
    min_start_date_formatted,
    identity_graph_id,
    identity_graph_name,
    flights: [],
    uuid: uuidv4(),
  };
};

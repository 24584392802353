import { CLEAR_AB_TEST_SEGMENTS, SET_AB_TEST_SEGMENTS, UPDATE_AB_TEST_SEGMENTS } from '../types';

export const abTestSegmentsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_AB_TEST_SEGMENTS:
      return action.payload;
    case UPDATE_AB_TEST_SEGMENTS: {
      const ab_test_segments = action.payload;
      for (let i = 0; i < ab_test_segments.length; i++) {
        const ab_test_segment = ab_test_segments[i];
        const index = state.findIndex((s) => s.segment_id === ab_test_segment.segment_id);

        if (index === -1) {
          state.push(ab_test_segment);
        }
      }

      return [...state];
    }
    case CLEAR_AB_TEST_SEGMENTS:
      return [];
    default:
      return state;
  }
};

import { CLEAR_CAMPAIGN_CUMULATIVE_PERFORMANCE, SET_CAMPAIGN_CUMULATIVE_PERFORMANCE } from '../types';

const initial_state = {
  measure_unit_id: 2,
  attribution_window: 7,
  labels: [],
  tooltips: [],
  x: [],
  y1: [],
  y2: [],
  y3: [],
  y4: [],
  y5: [],
  y6: [],
};
export const campaignCumulativePerformanceReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_CUMULATIVE_PERFORMANCE:
      return action.payload;
    case CLEAR_CAMPAIGN_CUMULATIVE_PERFORMANCE:
      return { ...initial_state };
    default:
      return state;
  }
};

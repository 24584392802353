import * as constants from 'ducks/types';

export const setAbTest = (payload = {}) => ({
  type: constants.SET_AB_TEST,
  payload,
});
export const clearAbTest = () => ({
  type: constants.CLEAR_AB_TEST,
});

export const setAbTests = (payload = {}) => ({
  type: constants.SET_AB_TESTS,
  payload,
});
export const clearAbTests = () => ({
  type: constants.CLEAR_AB_TESTS,
});

export const setStrategyAbTest = (payload = {}) => ({
  type: constants.SET_STRATEGY_AB_TEST,
  payload,
});
export const clearStrategyAbTest = () => ({
  type: constants.CLEAR_STRATEGY_AB_TEST,
});
export const setStrategyAbTests = (payload = {}) => ({
  type: constants.SET_STRATEGY_AB_TESTS,
  payload,
});
export const clearStrategyAbTests = () => ({
  type: constants.CLEAR_STRATEGY_AB_TESTS,
});

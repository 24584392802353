import * as constants from 'ducks/types';

export const setKpiGroup = (payload = {}) => ({
  type: constants.SET_KPI_GROUP,
  payload,
});

export const clearKpiGroup = () => ({
  type: constants.CLEAR_KPI_GROUP,
});

export const setKpiInterval = (payload = {}) => ({
  type: constants.SET_KPI_INTERVAL,
  payload,
});

export const clearKpiInterval = () => ({
  type: constants.CLEAR_KPI_INTERVAL,
});

export const setKpiIntervals = (payload = {}) => ({
  type: constants.SET_KPI_INTERVALS,
  payload,
});

export const clearKpiIntervals = () => ({
  type: constants.CLEAR_KPI_INTERVALS,
});

export const setKpiGroups = (payload = {}) => ({
  type: constants.SET_KPI_GROUPS,
  payload,
});

export const clearKpiGroups = () => ({
  type: constants.CLEAR_KPI_GROUPS,
});

import { CLEAR_FILTER, SET_FILTER } from '../types';

const initial_state = {
  criteria_domains: [],
  criteria_geo: [],
  criteria_other: [],
  criteria_segments: [],
  criteria_video: [],
  filter_json: [],
  mongo_filter_json: {},
};
export const filterReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_FILTER:
      return action.payload;
    case CLEAR_FILTER:
      return { ...initial_state };
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setTag = (payload = {}) => ({
  type: constants.SET_TAG,
  payload,
});

export const clearTag = () => ({
  type: constants.CLEAR_TAG,
});

export const setTags = (payload = {}) => ({
  type: constants.SET_TAGS,
  payload,
});

export const clearTags = () => ({
  type: constants.CLEAR_TAGS,
});

export const setVirtualTag = (payload = {}) => ({
  type: constants.SET_VIRTUAL_TAG,
  payload,
});

export const clearVirtualTag = () => ({
  type: constants.CLEAR_VIRTUAL_TAG,
});

export const setVirtualTags = (payload = {}) => ({
  type: constants.SET_VIRTUAL_TAGS,
  payload,
});

export const clearVirtualTags = () => ({
  type: constants.CLEAR_VIRTUAL_TAGS,
});

export const setPhysicalTags = (payload = {}) => ({
  type: constants.SET_PHYSICAL_TAGS,
  payload,
});

export const clearPhysicalTags = () => ({
  type: constants.CLEAR_PHYSICAL_TAGS,
});

export const setPhysicalTag = (payload = {}) => ({
  type: constants.SET_PHYSICAL_TAG,
  payload,
});

export const clearPhysicalTag = () => ({
  type: constants.CLEAR_PHYSICAL_TAG,
});

import { CLEAR_OPERATORS_LIST, SET_OPERATORS_LIST } from '../types';

export const operatorsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_OPERATORS_LIST:
      return action.payload;
    case CLEAR_OPERATORS_LIST:
      return [];
    default:
      return state;
  }
};

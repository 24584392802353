import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Modal } from '@cognitiv/cyprus-ui';
import { clearAdvertiser } from 'ducks/actions/advertisers';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createAdvertiser, updateAdvertiser } from 'ducks/operators/advertisers';
import { advertiserValidation } from 'ducks/validations/advertisers';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  advertiser_id: null,
  advertiser_name: '',
  partner_name: '',
  category_name: '',
  category_id: '',
  domain_url: '',
  partner_id: '',
  valid: false,
};

export const ManageAdvertiser = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const { advertiser, modals, partners, categories_list } = useSelector((state) => ({
    partners: state.partners,
    categories_list: state.categories_list,
    modals: state.modals,
    advertiser: state.advertiser,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...advertiser });
  }, [advertiser]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on advertiser page
    if (!match.params.advertiser_id) {
      dispatch(clearAdvertiser());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_advertiser: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { advertiser_id } = advertiser;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        advertiser_name: form.advertiser_name,
        partner_id: form.partner_id,
        category_id: form.category_id,
        domain_url: form.domain_url,
      };

      if (advertiser_id) {
        await dispatch(updateAdvertiser(advertiser_id, data));
        history.push(`${pathname}${queries}`);
      }
      if (!advertiser_id) {
        const entity_id = await dispatch(createAdvertiser(data));
        history.push(`/advertisers/${entity_id}/overview`);
      }
      dispatch(updateSuccess('advertiser has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { advertiser_id } = advertiser;
  const { advertiser_name, partner_name, domain_url, category_name } = form;

  const disabled = advertiserValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_advertiser"
      show={modals.manage_advertiser}
      width={500}
      onClose={closeModal}
      standard={false}
    >
      <h3>{advertiser_id ? 'Update Advertiser' : 'Create Advertiser'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          label="Advertiser Name"
          value={advertiser_name}
          auto_focus
          onChange={(value) => onChange({ advertiser_name: value })}
        />
        <Dropdown
          options={partners}
          option_key="partner_name"
          value={partner_name}
          label="Partner"
          margin="15px 0px 0px 0px"
          onSelect={(item) => onChange({ partner_name: item.partner_name, partner_id: item.partner_id })}
        />
        <Dropdown
          label="Advertiser Category"
          options={categories_list}
          option_key="category_name"
          value={category_name}
          margin="15px 0px 0px 0px"
          onSelect={(item) => onChange({ category_name: item.category_name, category_id: item.category_id })}
        />
        <Input
          label="Advertiser Domain"
          value={domain_url}
          margin="15px 0px 0px 0px"
          onChange={(value) => onChange({ domain_url: value })}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            {advertiser_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageAdvertiser.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import { formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeLists = (data = []) =>
  data.map((d) => normalizeList(d)).sort((a, b) => checkSort(a, b, 'list_name', 'asc'));

export const normalizeList = (row = {}) => ({
  ...row,
  list_size_formatted: formatNumber(row.list_size, { format: 'number-comma' }),
  list_name_with_id: `${row.list_id} ${row.list_name}`,
  uuid: uuidv4(),
});

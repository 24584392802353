import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, InputNumber, Modal } from '@cognitiv/cyprus-ui';
import { clearAbTest } from 'ducks/actions/ab-tests';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createAbTestSegment, updateAbTestSegment } from 'ducks/operators/segments';
import { abTestSegmentValidation } from 'ducks/validations/ab-test-segments';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  ab_test_id: null,
  ab_test_name: null,
  segment_id: null,
  segment_name: '',
  allocation_weight: null,
  ttl_days: null,
  tag_ids: [],
  change_order_id: null,
};

export const ManageAbTestSegment = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const { modals, segment, ab_tests, identity_types_list, campaign } = useSelector((state) => ({
    ab_tests: state.ab_tests,
    segment: state.segment,
    modals: state.modals,
    identity_types_list: state.identity_types_list,
    campaign: state.campaign,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...segment });
  }, [segment]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on ab test segment page
    if (!match.params.ab_test_segment_id) {
      dispatch(clearAbTest());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_ab_test_segment: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        advertiser_id: match.params.advertiser_id,
        ab_test_type_id: form.ab_test_type_id,
        segment_name: form.segment_name,
        segment_id: form.segment_id,
        ttl_days: form.ttl_days,
        allocation_weight: form.allocation_weight,
        tag_ids: form.tag_ids,
        change_order_id: form.change_order_id,
        identity_type_id: form.identity_type_id,
      };

      if (segment_id) {
        await dispatch(updateAbTestSegment(segment_id, data));
      }
      if (!segment_id) {
        await dispatch(createAbTestSegment(form.ab_test_id, data));
      }

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('ab test segment has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { ab_test_name, segment_id, segment_name, ttl_days, allocation_weight, identity_type_name } = form;

  const disabled = abTestSegmentValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_ab_test_segment"
      show={modals.manage_ab_test_segment}
      width={500}
      onClose={closeModal}
      standard={false}
    >
      <h3>{segment_id ? 'Update AB Test Segment' : 'Create AB Test Segment'}</h3>
      <form onSubmit={onSubmit}>
        <Dropdown
          label="AB Test"
          options={ab_tests}
          value={ab_test_name}
          option_key="ab_test_name"
          margin="0px 0px 10px 0px"
          onSelect={(item) => onChange({ ab_test_name: item.ab_test_name, ab_test_id: item.ab_test_id })}
        />
        <Input
          label="AB Test Segment Name"
          value={segment_name}
          margin="0px 0px 10px 0px"
          onChange={(value) => onChange({ segment_name: value })}
        />
        <InputNumber
          label="TTL Days"
          value={ttl_days}
          margin="0px 0px 10px 0px"
          onChange={(value) => {
            onChange({ ttl_days: value.float_value });
          }}
        />
        <div style={{ display: 'flex' }}>
          <InputNumber
            label="Allocation Weight"
            value={allocation_weight}
            decimal_scale={2}
            margin="0px 7px 0px 0px"
            onChange={(value) => onChange({ allocation_weight: value.float_value })}
          />
          <Dropdown
            disabled={segment_id}
            options={identity_types_list.filter((i) => campaign?.supported_identity_type_ids?.includes(i.id))}
            option_key="name"
            value={identity_type_name}
            label="Identity Type"
            margin="0px 0px 0px 7px"
            onSelect={(item) => onChange({ identity_type_id: item.id, identity_type_name: item.name })}
          />
        </div>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            {segment_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageAbTestSegment.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

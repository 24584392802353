import { CLEAR_CAMPAIGN_GROUPS, SET_CAMPAIGN_GROUPS } from '../types';

export const campaignGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CAMPAIGN_GROUPS:
      return action.payload;
    case CLEAR_CAMPAIGN_GROUPS:
      return [];
    default:
      return state;
  }
};

import { setAdvertiserReport, setAdvertiserReports } from 'ducks/actions/advertiser-reports';
import { clearCampaignDailyMargin } from 'ducks/actions/campaign-daily-margin';
import { clearCampaignDailyPacing } from 'ducks/actions/campaign-daily-pacing';
import { clearCampaignDiscrepancies } from 'ducks/actions/campaign-discrepancies';
import { clearPortfolioMargin } from 'ducks/actions/portfolio-margin';
import { updateSettings } from 'ducks/actions/settings';
import { clearTagSummary } from 'ducks/actions/tag-summary';
import { normalizeAdvertiserReports } from 'ducks/normalizers/reports';
import { getKpiCampaigns } from 'ducks/operators/campaigns';
import { Gemini } from 'utils/axios';
import { formatDate, getStartDateIncrement, subtractFromDate } from 'utils/dates';

import { getAdvertisers } from './advertisers';
import { getCampaignDailyPacing } from './campaign-daily-pacing';
import { getMachineLearningJobs } from './machine-learning-jobs';
import { getReportParameters } from './report-parameters';
import { getTagSummary } from './tag-summary';

export const getPortfolioOverviewPage = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const [report] = await Promise.all([dispatch(getPortfolioOverview())]);
  await dispatch(updateSettings({ loading: false }));
  return report;
};

export const getMachineLearningJobsPage = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const [report] = await Promise.all([dispatch(getMachineLearningJobs())]);
  await dispatch(updateSettings({ loading: false }));
  return report;
};

export const getTagSummaryPage =
  (queries = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));

      const query = {
        include_archived: false,
        report_date_utc: formatDate(new Date(), { format: 'MM/DD/YYYY' }),
        ...queries,
      };

      await dispatch(clearTagSummary());

      const [report] = await Promise.all([dispatch(getTagSummary(query))]);
      await dispatch(updateSettings({ loading: false }));
      return report;
    };

export const getAdvertiserReportPage =
  (params = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));

      const { advertiser_id, report_id } = params;

      const [advertiser] = await Promise.all([
        dispatch(getAdvertisers()),
        dispatch(getAdvertiserReports(advertiser_id)),
        dispatch(getAdvertiserReport(report_id)),
        dispatch(getReportParameters(report_id)),
      ]);
      await dispatch(updateSettings({ loading: false }));
      return advertiser;
    };

export const getAdvertiserReport = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/${entity_id}/view`);
  await dispatch(setAdvertiserReport(res.data));
  return res.data;
};

export const getAdvertiserReportsPage =
  ({ advertiser_id }) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));
      const [reports] = await dispatch(getAdvertiserReports(advertiser_id));

      await dispatch(updateSettings({ loading: false }));
      return reports;
    };

export const getAdvertiserReports = (entity_id) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/advertisers/${entity_id}/reports`);
  const advertiser_reports = normalizeAdvertiserReports(res.data);
  dispatch(setAdvertiserReports(advertiser_reports));
  return advertiser_reports;
};

export const updateAdvertiserReport = (entity_id, payload) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/reports/update`, payload);
  return res.data;
};

import { CLEAR_TITAN_KPI_TYPES, SET_TITAN_KPI_TYPES } from '../types';

export const titanKpiTypesReducer = (state = [], action) => {
    switch (action.type) {
        case SET_TITAN_KPI_TYPES:
            return action.payload;
        case CLEAR_TITAN_KPI_TYPES:
            return [];
        default:
            return state;
    }
};

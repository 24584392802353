import React, { useEffect, useState } from 'react';
import { Button, DropdownCalendar, Input, Modal } from '@cognitiv/cyprus-ui';
import { clearFlight } from 'ducks/actions/flights';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { transitionFlights } from 'ducks/normalizers/flights';
import { createCampaignFlight, updateFlight } from 'ducks/operators/flights';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  start_date_formatted: null,
  finish_date_formatted: null,
  state_id: 1,
};

export const ManageFlight = ({ history, location, match }) => {
  const dispatch = useDispatch();

  const { flight, modals } = useSelector(
    (state) => ({
      flight: state.flight,
      modals: state.modals,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...flight });
  }, [flight]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on flight page
    if (!match.params.flight_id) {
      dispatch(clearFlight());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_flight: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { flight_id } = flight;
    const { advertiser_id, campaign_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        flight_name: form.flight_name,
        campaign_id: form.campaign_id,
        start_date_utc: form.start_date_formatted,
        finish_date_utc: form.finish_date_formatted,
        state_id: form.state_id,
      };

      // accounting for utc offsets
      const flight_dates = transitionFlights(data.start_date_utc, data.finish_date_utc);

      data.start_date_utc = flight_dates.start_date_utc;
      data.finish_date_utc = flight_dates.finish_date_utc;

      if (flight_id) {
        await dispatch(updateFlight(flight_id, data));
        history.push(`${pathname}${queries}`);
      }
      if (!flight_id) {
        const entity_id = await dispatch(createCampaignFlight(campaign_id, data));
        history.push(
          `/advertisers/${advertiser_id}/campaigns/${campaign_id}/flights/${entity_id}/line-items?highlights=true`,
        );
      }

      dispatch(updateSuccess('flight has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { flight_id } = flight;
  const { flight_name, start_date_formatted, finish_date_formatted } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_flight"
      show={modals.manage_flight}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{flight_id ? 'Update Flight' : 'Create Flight'}</h3>
      <form onSubmit={onSubmit}>
        {flight_id && (
          <Input
            auto_focus
            label="Flight Name"
            value={flight_name}
            margin="0px 0px 15px 0px"
            onChange={(value) => onChange({ flight_name: value })}
          />
        )}
        <div style={{ display: 'flex' }}>
          <DropdownCalendar
            label="Start Date"
            selected_date={start_date_formatted}
            max_date={finish_date_formatted}
            onSelect={(date) => onChange({ start_date_formatted: date.selected_date })}
            margin="0px 7px 0px 0px"
            width="calc(50% - 7px)"
            calendar_display={false}
          />
          <DropdownCalendar
            label="Finish Date"
            selected_date={finish_date_formatted}
            min_date={start_date_formatted}
            onSelect={(date) => onChange({ finish_date_formatted: date.selected_date })}
            margin="0px 0px 0px 7px"
            width="calc(50% - 7px)"
            calendar_display={false}
          />
        </div>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit">
            {flight_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageFlight.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

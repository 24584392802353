import { CLEAR_DEALS, SET_DEALS } from '../types';

export const dealsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_DEALS:
      return action.payload;
    case CLEAR_DEALS:
      return [];
    default:
      return state;
  }
};

import React from 'react';
import { selectAuthenticate } from 'ducks/selectors/users';
import { PublicLayout } from 'layouts/PublicLayout';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { routes } from 'routes/routes';

const Router = ({ component: Component, ...rest }) => {
  const auth_check = useSelector((state) => selectAuthenticate(state.user));
  if (!auth_check) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <PublicLayout location={rest.location} history={rest.history} match={rest.match}>
            <Component {...props} />
          </PublicLayout>
        )}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: routes.REPORT_PORTFOLIO_OVERVIEW,
        state: { referer: rest.location },
      }}
    />
  );
};

Router.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export const PublicRouter = withRouter(Router);

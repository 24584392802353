import { CLEAR_LINE_ITEMS, SET_LINE_ITEMS } from '../types';

export const lineItemsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LINE_ITEMS:
      return action.payload;
    case CLEAR_LINE_ITEMS:
      return [];
    default:
      return state;
  }
};

import { getTimeZone } from '../../utils/timeZone';

export const headers = [
  {
    name: '',
    width: '35px',
    bulk_select: true,
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'ID',
    width: '100px',
    sort_key: 'model_id',
    sort_direction: 'default',
    uuid: 2, // required key
  },
  {
    name: 'Name',
    width: '100px',
    sort_key: 'model_name',
    sort_direction: 'default',
    flex_grow: 2,
    uuid: 3, // required key
  },
  {
    name: 'Display Name',
    width: '100px',
    sort_key: 'model_display_name',
    sort_direction: 'default',
    flex_grow: 1,
    uuid: 4, // required key
  },
  {
    name: 'Advertisers',
    width: '125px',
    sort_key: 'advertiser_count',
    sort_direction: 'default',
    uuid: 5, // required key
  },
  {
    name: 'Strategies',
    width: '125px',
    sort_key: 'strategies_count',
    sort_direction: 'default',
    uuid: 6, // required key
  },
  {
    name: `Date Created (${getTimeZone()})`,
    width: '150px',
    uuid: 7,
  },
  {
    name: `Date Imported (${getTimeZone()})`,
    width: '150px',
    uuid: 8,
  },
  {
    name: '',
    width: '35px',
    resize: false,
    uuid: 1, // required key
  },
];

export const bulk_options = [
  {
    name: 'Archive Model',
    state_id: 1,
    uuid: 1, // required key
  },
  {
    name: 'Unarchive Model',
    state_id: 2,
    uuid: 2, // required key
  },
];

export const bulk_options_advertisers = [
  {
    name: 'Assosiate',
    state_id: 1,
    uuid: 1, // required key
  },
  {
    name: 'Deactivate',
    state_id: 2,
    uuid: 2, // required key
  },
];

export const features = [
  {
    feature_name: 'Model Configuration',
    feature_id: 1,
    uuid: 1,
  },
  {
    feature_name: 'Model Advertisers',
    feature_id: 2,
    uuid: 2,
  },
  {
    feature_name: 'Model Outputs',
    feature_id: 3,
    uuid: 3,
  },
];

export const advertisers_headers = [
  {
    name: '',
    width: '35px',
    bulk_select: true,
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'ID',
    width: '100px',
    sort_key: 'advertiser_id',
    sort_direction: 'desc',
    uuid: 2, // required key
  },
  {
    name: 'Advertiser Name',
    width: '100px',
    sort_key: 'advertiser_name',
    sort_direction: 'desc',
    flex_grow: 1,
    uuid: 3, // required key
  },
  {
    name: '',
    width: '35px',
    resize: false,
    uuid: 4, // required key
  },
];

export const output_headers = [
  {
    name: 'Output Index',
    width: '135px',
    sort_key: 'output_id',
    sort_direction: 'desc',
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'Output Name',
    width: '200px',
    uuid: 2, // required key
  },
  {
    name: 'Default Score Type',
    flex_grow: 1,
    uuid: 3, // required key
  },
];

import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownCalendar,
  Input,
  InputCurrency,
  InputNumber,
  Modal,
  Textarea,
} from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createVendorFee, getVendorFees } from 'ducks/operators/vendor-fees';
import { vendorFeeValidation } from 'ducks/validations/vendor-fees';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { formatNumber } from 'utils/numbers';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  fee_vendor_fee_name: '',
  fee_value: null,
  description: '',
  fee_vendor_name: '',
  fee_vendor_id: null,
  contract_start_date: null,
  contract_finish_date: null,
  impression_minimum: null,
  impression_minimum_formatted: '',
  impression_minimum_disabled: false,
  impression_maximum: null,
  impression_maximum_formatted: '',
};

export const ManageVendorFee = ({ location, history }) => {
  const dispatch = useDispatch();

  const { modals, vendor_fees_list, global_vendor_fees } = useSelector((state) => ({
    modals: state.modals,
    vendor_fees_list: state.vendor_fees_list,
    global_vendor_fees: state.global_vendor_fees,
  }));

  const [form, setForm] = useState({ ...default_state });

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const post_data = {
        fee_vendor_fee_name,
        fee_value,
        fee_vendor_id,
        description,
        contract_start_date,
        contract_finish_date,
        impression_minimum,
        impression_maximum,
        parent_fee_id,
      };
      await dispatch(createVendorFee(post_data));
      await dispatch(getVendorFees());
      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('vendor has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const closeModal = () => {
    setForm({ ...default_state });
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_vendor_fee: false }));
  };

  const {
    fee_vendor_fee_name,
    fee_value_formatted,
    fee_value,
    description,
    fee_vendor_name,
    contract_start_date,
    contract_finish_date,
    impression_minimum_formatted,
    impression_maximum_formatted,
    fee_vendor_id,
    impression_minimum,
    impression_maximum,
    parent_fee_id,
    parent_fee_name,
    impression_minimum_disabled,
  } = form;

  const disabled = vendorFeeValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_vendor_fee"
      show={modals.manage_vendor_fee}
      onClose={closeModal}
      standard={false}
    >
      <h3>Create Vendor Fee</h3>
      <form onSubmit={onSubmit}>
        <Input
          label="Fee Name"
          auto_focus
          value={fee_vendor_fee_name}
          onChange={(value) => onChange({ fee_vendor_fee_name: value })}
        />
        <Textarea
          label="Fee Description"
          value={description}
          min_height="50px"
          width="100%"
          onChange={(value) => onChange({ description: value })}
          margin="15px 0px 0px 0px"
        />
        <div style={{ display: 'flex' }}>
          <InputCurrency
            label="Fee"
            value={fee_value_formatted}
            onChange={(input) => onChange({ fee_value: input.float_value, fee_value_formatted: input.formatted_value })}
            margin="15px 7px 0px 0px"
            width="calc(50% - 7px)"
          />
          <Dropdown
            label="Fee Vendor"
            options={vendor_fees_list}
            option_key="name"
            value={fee_vendor_name}
            margin="15px 0px 0px 7px"
            width="calc(50% - 7px)"
            onSelect={(item) => onChange({ fee_vendor_name: item.name, fee_vendor_id: item.id })}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <InputNumber
            label="Impression Min."
            value={impression_minimum_formatted}
            onChange={(input) =>
              onChange({ impression_minimum: input.float_value, impression_minimum_formatted: input.formatted_value })
            }
            disabled={impression_minimum_disabled}
            margin="15px 7px 0px 0px"
            width="calc(50% - 7px)"
          />
          <InputNumber
            label="Impression Max"
            value={impression_maximum_formatted}
            onChange={(input) =>
              onChange({ impression_maximum: input.float_value, impression_maximum_formatted: input.formatted_value })
            }
            margin="15px 0px 0px 7px"
            width="calc(50% - 7px)"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <DropdownCalendar
            label="Start Date"
            selected_date={contract_start_date}
            max_date={contract_finish_date}
            onSelect={(date) => onChange({ contract_start_date: date.selected_date })}
            margin="15px 7px 0px 0px"
            width="calc(50% - 7px)"
            calendar_display={false}
          />
          <DropdownCalendar
            label="Finish Date"
            selected_date={contract_finish_date}
            min_date={contract_start_date}
            onSelect={(date) => onChange({ contract_finish_date: date.selected_date })}
            margin="15px 0px 0px 7px"
            width="calc(50% - 7px)"
            calendar_display={false}
          />
        </div>
        <Dropdown
          label="Parent Fee"
          options={global_vendor_fees.filter((v) => v.impression_minimum && v.impression_maximum)}
          option_key="fee_vendor_fee_name"
          value={parent_fee_name}
          margin="15px 0px 0px 0px"
          onSelect={(item) => {
            const impression_minimum = item.impression_maximum + 1;
            const impression_minimum_formatted = formatNumber(impression_minimum, { format: 'number-comma' });
            onChange({
              parent_fee_id: item.fee_vendor_fee_id,
              parent_fee_name: item.fee_vendor_fee_name,
              impression_minimum,
              impression_minimum_formatted,
              impression_minimum_disabled: true,
            });
          }}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageVendorFee.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

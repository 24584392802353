import * as constants from 'ducks/types';

export const setLineItemsPacing = (payload = {}) => ({
  type: constants.SET_LINE_ITEMS_PACING,
  payload,
});

export const clearLineItemsPacing = () => ({
  type: constants.CLEAR_LINE_ITEMS_PACING,
});

import * as constants from 'ducks/types';

export const setCreativeVideoEvents = (payload = {}) => ({
  type: constants.SET_CREATIVE_VIDEO_EVENTS,
  payload,
});

export const clearCreativeVideoEvents = () => ({
  type: constants.CLEAR_CREATIVE_VIDEO_EVENTS,
});

import { faCheckCircle as fadCheckCircle, faCircleNotch } from '@fortawesome/pro-duotone-svg-icons';
import {
  faAnalytics, // verified
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowAltLeft,
  faBadgeDollar, // verified
  faBriefcase, // verified
  faCaretUp as farCaretUp,
  faChartBar, // verified
  faChartNetwork,
  faCheckCircle as farCheckCircle,
  faClipboardList,
  faCodeMerge,
  faCopy as farCopy,
  faDatabase,
  faExclamationTriangle as farExclamationTriangle,
  faEye,
  faFileAlt,
  faFileCode, // verified
  faFileCsv, // verified
  faFileDownload, // verified
  faFileImage, // verified
  faFileUpload,
  faFileUpload as farFileUpload, // verified,
  faFunnelDollar,
  faHandshake, // verified
  faHistory,
  faKey as farKey,
  faKeyboard,
  faLayerMinus,
  faLayerPlus,
  faLightbulbDollar,
  faLineColumns,
  faNewspaper, // verified
  faRandom,
  faShieldCheck,
  faStream, // verified
  faTableLayout,
  faUnlockAlt,
  faWaveform,
  faWeightHanging,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArchive, // verified
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowsRotate,
  faBallPile,
  faBars,
  faBracketsCurly,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCog,
  faCompress, // verified
  faCopy,
  faDatabase as fasDatabase,
  faDisplayChartUp,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExpand, // verified
  faFilter,
  faGridHorizontal,
  faHorizontalRule,
  faInfoCircle,
  faKey,
  faLineColumns as fasLineColumns,
  faLink,
  faList,
  faListUl, // verified
  faLock,
  faLockAlt,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMinus, // verified
  faMinusCircle,
  faPause, // verified
  faPercentage,
  faPlay,
  faPlus,
  faPresentationScreen,
  faRandom as fasRandom,
  faRedoAlt,
  faSave,
  faSearch,
  faServer,
  faSignOutAlt,
  faSortAmountDown,
  faSortAmountUp,
  faSortDown, // verified
  faSortUp, // verified
  faStop as fasStop,
  faStop,
  faTag,
  faTimes,
  faTint,
  faTintSlash,
  faTools,
  faUnlink,
  faUnlock,
  faWindowClose,
  faCircle
} from '@fortawesome/pro-solid-svg-icons';

export const icons = {
  faAngleLeft,
  faAngleRight,
  faCaretUp,
  faInfoCircle,
  faLock,
  faEnvelope,
  faUnlock,
  faWaveform,
  faList,
  faUnlink,
  faLightbulbDollar,
  fadCheckCircle,
  faChevronUp,
  faPercentage,
  faWeightHanging,
  farCheckCircle,
  faTint,
  faTintSlash,
  faClipboardList,
  faLayerMinus,
  faFunnelDollar,
  farCopy,
  faCheckCircle,
  faFileAlt,
  faKey,
  faHorizontalRule,
  faFileDownload,
  farFileUpload,
  faFileImage,
  faFileCode,
  faCompress,
  faExpand,
  faSortUp,
  faSortDown,
  faMinus,
  faFileCsv,
  faChevronRight,
  fasStop,
  faPlay,
  faPause,
  faHandshake,
  faBriefcase,
  faArrowsRotate,
  faChartNetwork,
  faBadgeDollar,
  faNewspaper,
  faStream,
  faListUl,
  faChartBar,
  faAnalytics,
  faShieldCheck,
  faUnlockAlt,
  faArrowAltLeft,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faChevronDown,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSortAmountDown,
  faSortAmountUp,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faFilter,
  faWindowClose,
  faMinusCircle,
  faPlus,
  faRedoAlt,
  faSignOutAlt,
  faBracketsCurly,
  faServer,
  fasDatabase,
  faDownload,
  faTag,
  fasRandom,
  faCaretDown,
  faCaretRight,
  faTimes,
  faBars,
  faEdit,
  fasLineColumns,
  faLockAlt,
  faCheck,
  faEllipsisV,
  faStop,
  farKey,
  faRandom,
  faBallPile,
  faCog,
  faSearch,
  faKeyboard,
  faLayerPlus,
  faDatabase,
  faLineColumns,
  faFileUpload,
  faCodeMerge,
  faHistory,
  faArchive,
  faCircleNotch,
  faCircle,
  farExclamationTriangle,
  fasExclamationTriangle,
  faExclamationCircle,
  faSave,
  faCopy,
  faLink,
  faEye,
  farCaretUp,
  faTools,
  faTableLayout,
  faGridHorizontal,
  faPresentationScreen,
  faDisplayChartUp,
};

import { CLEAR_REPORT_AUDITS, SET_REPORT_AUDITS } from '../types';

export const reportAuditsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_REPORT_AUDITS:
      return action.payload;
    case CLEAR_REPORT_AUDITS:
      return [];
    default:
      return state;
  }
};

import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeCreativeVideoEvents = (data = []) =>
  data.map((row) => normalizeCreativeVideoEvent(row)).sort((a, b) => checkSort(a, b, 'event_id', 'asc'));

export const normalizeCreativeVideoEvent = (row) => ({
  ...row,
  modified: true,
  saved: true,
  uuid: uuidv4(),
});

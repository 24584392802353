import { CLEAR_MODEL_ADVERTISERS, SET_MODEL_ADVERTISERS } from '../types';

export const modelAdvertisersReducer = (state = [], action) => {
  switch (action.type) {
    case SET_MODEL_ADVERTISERS:
      return action.payload;
    case CLEAR_MODEL_ADVERTISERS:
      return [];
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setModels = (payload = []) => ({
  type: constants.SET_MODELS,
  payload,
});

export const clearModels = () => ({
  type: constants.CLEAR_MODELS,
});

export const setAdvertiserModels = (payload = []) => ({
  type: constants.SET_ADVERTISER_MODELS,
  payload,
});

export const clearAdvertiserModels = () => ({
  type: constants.CLEAR_ADVERTISER_MODELS,
});

import { setChangeOrder, setChangeOrders, setChangeOrderValidation } from 'ducks/actions/change-orders';
import { clearAbTestSegments } from 'ducks/actions/segments';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeChangeOrder, normalizeChangeOrders } from 'ducks/normalizers/change-orders';
import { Gemini } from 'utils/axios';

import { getChangeOrderAbTests } from './ab-tests';
import { getAdvertiser, getAdvertisers } from './advertisers';
import { getChangeOrderAuditLog } from './audit-logs';
import { getAdvertiserCampaigns, getCampaign } from './campaigns';
import { getCampaignFlights, getFlight } from './flights';
import { getFlightLineItems } from './line-items';
import { getAdvertiserModels } from './models';
import { setFlightAbTestSegments } from './segments';
import { getChangeOrderStrategies } from './strategies';
import { getStrategyTemplates } from './strategy-templates';

export const getChangeOrderPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const { advertiser_id, campaign_id, flight_id, change_order_id } = params;

  const [ab_tests, advertiser] = await Promise.all([
    dispatch(getChangeOrderAbTests(change_order_id)),
    dispatch(getAdvertisers()),
    dispatch(getAdvertiser(advertiser_id)),
    dispatch(getAdvertiserCampaigns(advertiser_id)),
    dispatch(getAdvertiserModels(advertiser_id)),
    dispatch(getCampaign(campaign_id)),
    dispatch(getCampaignFlights(campaign_id)),
    dispatch(getFlight(flight_id)),
    dispatch(getFlightLineItems(flight_id)),
    dispatch(getFlightChangeOrders(flight_id)),
    dispatch(getFlightLineItems(flight_id)),
    dispatch(getChangeOrder(change_order_id)),
    dispatch(getChangeOrderStrategies(change_order_id)),
    dispatch(getChangeOrderAuditLog(change_order_id)),
    dispatch(getStrategyTemplates()),
    dispatch(getAdvertiserModels(advertiser_id)),
  ]);

  // clearing ab test segments before getting from ab test list
  await dispatch(clearAbTestSegments());
  await Promise.all(ab_tests.map((a) => dispatch(setFlightAbTestSegments(a.ab_test_id))));

  await dispatch(updateSettings({ loading: false }));
  return advertiser;
};

export const getCampaignChangeOrders = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/change_orders`);
  const change_orders = await normalizeChangeOrders(res.data);
  await dispatch(setChangeOrders(change_orders));
  return change_orders;
};

export const getFlightChangeOrders = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/flights/${entity_id}/change_orders`);
  const change_orders = await normalizeChangeOrders(res.data);
  await dispatch(setChangeOrders(change_orders));
  return change_orders;
};

export const createFlightChangeOrder = (entity_id) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/change_orders/create`);
  return res.data;
};

export const createChangeOrderExperiment = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/default_experiment/create`, data);
  return res.data;
};

export const getChangeOrder = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/change_orders/${entity_id}/view`);
  const change_order = await normalizeChangeOrder(res.data);
  await dispatch(setChangeOrder(change_order));
  return change_order;
};

export const updateChangeOrder = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/update`, data);
  return res.data;
};

export const requestChangeOrderChanges = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/request_changes`, data);
  return res.data;
};

export const submitChangeOrder = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/submit`, data);
  return res.data;
};

export const approveChangeOrder = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/approve`, data);
  return res.data;
};

export const publishChangeOrder = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/publish`, data);
  return res.data;
};

export const cancelChangeOrder = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/cancel`, data);
  return res.data;
};

export const commentChangeOrder = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/change_orders/${entity_id}/conversations/create`, data);
  return res.data;
};

export const validateChangeOrderStrategies = (entity_id) => async (dispatch) => {
  const res = await Gemini.get(`/change_orders/${entity_id}/validation_status`);
  dispatch(setChangeOrderValidation(res.data));
  return res.data;
};

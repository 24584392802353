import { CLEAR_CHANGE_ORDER_STATES_LIST, SET_CHANGE_ORDER_STATES_LIST } from '../types';

export const changeOrderStatesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CHANGE_ORDER_STATES_LIST:
      return action.payload;
    case CLEAR_CHANGE_ORDER_STATES_LIST:
      return [];
    default:
      return state;
  }
};

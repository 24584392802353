import { setReportParameters } from 'ducks/actions/report-parameters';
import { updateSettings } from 'ducks/actions/settings';
import { Gemini } from 'utils/axios';
import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';

export const getReportParameters = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/${entity_id}/report_parameters`);
  const report_parameters = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'parameter_name', 'asc'));
  dispatch(setReportParameters(report_parameters));
  return report_parameters;
};

export const updateReportParameter =
  (entity_id, data = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const res = await Gemini.post(`/reports/${entity_id}/report_parameter/update`, data);
    return res.data;
  };

export const updateReportType = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/reports/report_type/update', data);
  return res.data;
};

export const updateReportTypeParameterGroup = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/reports/report_type_parameter_group/update`, data);
  return res.data;
};

import { formatDate } from 'utils/dates';
import { formatMicroUsd, formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeCreativePrices = (data = []) =>
  data.map((row) => normalizeCreativePrice(row)).sort((a, b) => checkSort(a, b, 'start_date_utc', 'asc'));

export const normalizeCreativePrice = (row) => {
  const price_usd = formatMicroUsd(row.price_micro_usd);
  const price_usd_formatted = formatNumber(price_usd, { format: 'currency-abbreviated', append: '$0' });
  const price_usd_comma = formatNumber(price_usd, { format: 'currency-comma', append: '$0' });

  return {
    ...row,
    price_usd,
    price_usd_formatted,
    price_usd_comma,
    start_date_utc_formatted: formatDate(row.start_date_utc, { format: 'M/D/YYYY' }),
    modified: false,
    saved: true,
    uuid: uuidv4(),
  };
};

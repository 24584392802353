import React, { useEffect, useState } from 'react';
import { Input, TableFlex } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { shallowEqual, useSelector } from 'react-redux';

import { OutputDefaultScoreTypeCell, OutputIdCell, OutputNameCell } from '../Cells';
import { output_headers } from '../constants';

import cn from '../ModelsPage.module.scss';

export const ModelOutput = ({ onChange, model }) => {
  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);

  const { outputs } = useSelector(
    (state) => ({
      outputs: state.outputs,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setData(model.model_outputs);
  }, [model.model_outputs]);

  const updateData = (row) => {
    setData((prev) => {
      const copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].output_index === row.uuid) {
          copy[i] = { ...copy[i], ...row };
        }
      }
      onChange('model_outputs', copy);

      return copy;
    });
  };

  return (
    <div>
      <Row>
        <Input left_icon={['fas', 'search']} margin="0px 15px 15px 15px" width="300px" onChange={setFilter} />
      </Row>
      <div className={cn.table} style={{ height: 200 }}>
        <TableFlex
          filter={filter}
          headers={output_headers}
          rows={data}
          settings={{
            rows_fill: true,
            filter_key: 'output_name',
          }}
        >
          <OutputIdCell />
          <OutputNameCell />
          <OutputDefaultScoreTypeCell options={outputs} onSelect={updateData} />
        </TableFlex>
      </div>
    </div>
  );
};

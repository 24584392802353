import { updateSettings } from 'ducks/actions/settings';
import { setTagFields } from 'ducks/actions/tag-variable-inputs';
import { setTagVariableRules } from 'ducks/actions/tag-variable-rules';
import { setPhysicalTag, setPhysicalTags, setTags, setVirtualTags } from 'ducks/actions/tags';
import {
  normalizePhysicalTag,
  normalizePhysicalTags,
  normalizeTagFields,
  normalizeTags,
  normalizeTagVariableRules,
  normalizeVirtualTags,
} from 'ducks/normalizers/tags';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

import { getAdvertisers } from './advertisers';

export const getAdvertiserTags =
  (entity_id, queries = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));

      const query = {
        include_archived: false,
        ...queries,
      };

      const res = await Gemini.get(`/advertisers/${entity_id}/tags${replaceQuery(query)}`);
      const tags = normalizeTags(res.data);
      await dispatch(setTags(tags));
      return tags;
    };

export const createTag = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  // normalizing payload - API needs to be update
  data.advertiser_id = entity_id;

  const res = await Gemini.post('/tags/create', data);
  return res.data;
};

export const updateTag = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/tags/${entity_id}/update`, data);
  return res.data;
};

export const unarchiveTag = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/tags/${entity_id}/unarchive`);
  return res.data;
};

export const archiveTag = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/tags/${entity_id}/archive`);
  return res.data;
};

export const createTagFields = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/tags/physical_tags/${entity_id}/create_tag_variables`, data);
  return res.data;
};

export const createUniversalCaptureTag = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/universal_capture_tag/create`, data);
  return res.data;
};

export const getAdvertiserPhysicalTags =
  (entity_id, queries = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));

      const query = {
        include_archived: false,
        ...queries,
      };

      if (query.include_archived) {
        query.include_state_ids = [1, 2, 3, 4];
      }

      const res = await Gemini.get(`/advertisers/${entity_id}/physical_tags${replaceQuery(query)}`);

      const tags = normalizePhysicalTags(res.data);
      await dispatch(setPhysicalTags(tags));
      return tags;
    };

export const getTagPage =
  (params, queries = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));
      const { tag_id, advertiser_id } = params;

      const query = {
        include_archived: true,
        ...queries,
      };

      // using physical tag and tag fields in the virtual tag dispatch
      await Promise.all([dispatch(getPhysicalTag(tag_id)), dispatch(getTagFields(tag_id))]);

      const [tags] = await Promise.all([
        dispatch(getAdvertisers()),
        dispatch(getAdvertiserPhysicalTags(advertiser_id, query)),
        dispatch(getVirtualTags(tag_id, query)),
        dispatch(getTagVariableRules(tag_id)),
      ]);
      await dispatch(updateSettings({ loading: false }));
      return tags;
    };

export const getTagFields = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/tag_variable_input/${entity_id}/list`);
  const tag_fields = normalizeTagFields(res.data);
  dispatch(setTagFields(tag_fields));
  return tag_fields;
};

export const getPhysicalTag = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/tags/universal_capture/${entity_id}`);
  const tag = normalizePhysicalTag(res.data);
  dispatch(setPhysicalTag(tag));
  return tag;
};

export const getVirtualTags =
  (entity_id, queries = {}) =>
    async (dispatch, getState) => {
      await dispatch(updateSettings({ loading: true }));

      const query = {
        include_archived: false,
        ...queries,
      };

      if (query.include_archived) {
        query.include_state_ids = [1, 2, 3, 4];
      }
      const { physical_tag, tag_operators_list, tag_fields } = getState();
      const res = await Gemini.get(`/tags/universal_capture/${entity_id}/virtual_tags${replaceQuery(query)}`);

      const tags = normalizeVirtualTags(res.data, physical_tag, tag_operators_list, tag_fields);
      await dispatch(setVirtualTags(tags));
      return tags;
    };

export const updateVirtualTag = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/tags/virtual_tags/${entity_id}/update`, data);
  return res.data;
};

export const getTagVariableRules = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/tags/physical_tags/${entity_id}/tag_variable_rules`);
  const { tag_fields } = getState();
  const tag_variable_rules = normalizeTagVariableRules(res.data, tag_fields);
  dispatch(setTagVariableRules(tag_variable_rules));
  return tag_variable_rules;
};

export const updateTagVariableRule = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/tag_variable_rules/${entity_id}/update`, data);
};

export const deleteTagVariableRule = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/tag_variable_rules/${entity_id}/delete`, data);
};

export const createTagVariableRule = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/tag_variable_rules/create`, data);
};

import { setFrequencyCaps } from 'ducks/actions/frequency-caps';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeFrequencyCaps } from 'ducks/normalizers/frequency-caps';
import { Gemini } from 'utils/axios';

export const getCampaignFrequencyCaps = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/frequency_caps`);
  const { frequency_cap_intervals_list } = getState();
  const frequency_caps = await normalizeFrequencyCaps(res.data, frequency_cap_intervals_list);
  await dispatch(setFrequencyCaps(frequency_caps));
  return frequency_caps;
};

export const getLineItemFrequencyCaps = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/line_items/${entity_id}/frequency_caps`);
  const { frequency_cap_intervals_list } = getState();
  const frequency_caps = await normalizeFrequencyCaps(res.data, frequency_cap_intervals_list);
  await dispatch(setFrequencyCaps(frequency_caps));
  return frequency_caps;
};

export const activateCampaignFrequencyCaps = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/frequency_caps/activate`, data);
  return res.data;
};

export const deactivateCampaignFrequencyCaps = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/frequency_caps/deactivate`, data);
  return res.data;
};
export const upsertCampaignFrequencyCaps =
  (entity_id, data = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const res = await Gemini.post(`/campaigns/${entity_id}/frequency_caps/upsert`, data);
    return res.data;
  };

export const upsertLineItemFrequencyCaps =
  (entity_id, data = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const res = await Gemini.post(`/line_items/${entity_id}/frequency_caps/upsert`, data);
    return res.data;
  };

import { CLEAR_TAG_TYPES_LIST, SET_TAG_TYPES_LIST } from '../types';

export const tagTypesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TAG_TYPES_LIST:
      return action.payload;
    case CLEAR_TAG_TYPES_LIST:
      return [];
    default:
      return state;
  }
};

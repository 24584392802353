import { CLEAR_VENDOR_FEES_LIST, SET_VENDOR_FEES_LIST } from '../types';

export const vendorFeesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_VENDOR_FEES_LIST:
      return action.payload;
    case CLEAR_VENDOR_FEES_LIST:
      return [];
    default:
      return state;
  }
};

import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { formatNumber } from 'utils/numbers';
import { v4 as uuidv4 } from 'uuid';

export const normalizeLineItemDcpms = (data = []) =>
  data.map((d, i) => {
    let is_previous_day_locked = false;
    if (i === 0) {
      is_previous_day_locked = true;
    } else if (data[i - 1].locked_cpm) {
      is_previous_day_locked = true;
    }
    return {
      ...d,
      reporting_date_formatted: formatDate(d.reporting_date, { format: 'MM/DD/YY' }),
      actual_impressions_formatted: formatNumber(d.actual_impressions, { format: 'number-comma', append: null }),
      actual_conversions_formatted: formatNumber(d.actual_conversions, {
        format: 'number-decimal',
        decimals: 2,
        append: null,
      }),
      locked_spend_formatted: formatNumber(d.locked_spend, { format: 'currency', append: null }),
      unlocked_spend_formatted: formatNumber(d.unlocked_spend, { format: 'currency', append: null }),
      daily_margin_formatted: formatNumber(d.daily_margin, { format: 'currency', append: null }),
      daily_sales_formatted: formatNumber(d.daily_sales, { format: 'currency', append: null }),
      daily_cpa_formatted: formatNumber(d.daily_cpa, { format: 'currency', append: null }),
      combined_cumulative_cpa_formatted: formatNumber(d.combined_cumulative_cpa, { format: 'currency', append: null }),
      target_cpm_formatted: formatNumber(d.target_cpm, { format: 'currency', append: null }),
      locked_cpm_formatted: formatNumber(d.locked_cpm, { format: 'currency', append: null }),
      is_previous_day_locked,
      uuid: uuidv4(),
    };
  });

export const normalizeLineItemDcpm = (data = {}) => ({
  ...data,
  start_date_formatted: offsetTimezoneUTC(data.job_start_date_utc, { format: 'MM/DD/YY hh:mm A' }),
  finish_date_formatted: offsetTimezoneUTC(data.job_stop_date_utc, { format: 'MM/DD/YY hh:mm A' }),
  target_cpa_formatted: formatNumber(data.target_cpa, { format: 'currency', append: null }),
  max_cpm_formatted: formatNumber(data.max_cpm, { format: 'currency', append: null }),
  max_impression_budget_formatted: formatNumber(data.max_impression_budget, { format: 'number-comma', append: null }),
  min_cpm_formatted: formatNumber(data.min_cpm, { format: 'currency', append: null }),
  uuid: uuidv4(),
});

import * as constants from 'ducks/types';

export const setCreativeAddons = (payload = {}) => ({
  type: constants.SET_CREATIVE_ADDONS,
  payload,
});

export const clearCreativeAddons = () => ({
  type: constants.CLEAR_CREATIVE_ADDONS,
});

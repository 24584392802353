/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Dropdown, InputNumber } from '@cognitiv/cyprus-ui';
import { Select } from 'components/cells/Select';
import { State } from 'components/cells/State';

export const SelectCell = ({ row, onClick, settings }) => {
  if (row.saved) {
    return (
      <Cell onClick={() => onClick({ ...row, checked: !row.checked })}>
        <Select tooltip="Select Frequency Cap" checked={row.checked} height={settings.row_height} />
      </Cell>
    );
  }
  return <Cell />;
};

export const FrequencyCapNameCell = ({ row }) => (
  <Cell>{row.frequency_cap_id && <State state_id={row.state_id}>{row.frequency_cap_name}</State>}</Cell>
);

export const ImpressionCell = ({ row, onChange }) => (
  <Cell>
    <InputNumber
      value={row.impression_count_formatted}
      onChange={(input) =>
        onChange({
          impression_count: input.float_value,
          impression_count_formatted: input.formatted_value,
          uuid: row.uuid,
        })
      }
      transparent
    />
  </Cell>
);

export const IntervalCountCell = ({ row, onChange }) => (
  <Cell>
    <InputNumber
      value={row.duration_in_interval}
      onChange={(input) =>
        onChange({
          duration_in_interval: input.float_value,
          duration_in_interval_formatted: input.formatted_value,
          uuid: row.uuid,
        })
      }
      transparent
    />
  </Cell>
);

export const IntervalTypeCell = ({ row, options, onChange }) => (
  <Cell>
    <Dropdown
      options={options}
      option_key="interval_name"
      value={row.interval_name}
      onSelect={(input) =>
        onChange({
          interval_name: input.interval_name,
          interval_id: input.interval_id,
          duration_seconds: input.duration_seconds,
          uuid: row.uuid,
        })
      }
      transparent
    />
  </Cell>
);

export const IdentityTypeCell = ({ row, disabled, onChange, identity_types_list, campaign }) => (
  <Cell>
    <Dropdown
      options={identity_types_list.filter((i) => campaign?.supported_identity_type_ids?.includes(i.id))}
      transparent
      disabled={disabled}
      option_key="name"
      value={row.identity_type_name}
      onSelect={(item) => onChange({ identity_type_id: item.id, identity_type_name: item.name, uuid: row.uuid })}
    />
  </Cell>
);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action } from 'components/actions/Action';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import { updateSettings } from 'ducks/actions/settings';
import { selectUserChangeOrders } from 'ducks/selectors/change-orders';
import * as PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';
import { parseQueries } from 'utils/queries';
import { getSettings, setSettings } from 'utils/storage';

import cn from './Navigation.module.scss';

export const ChangeOrderWithRouter = ({ location, match, history }) => {
  const dispatch = useDispatch();
  const query = parseQueries(location.search);

  const { advertisers, campaigns, flights, change_orders, settings } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      campaigns: state.campaigns,
      flights: state.flights,
      change_orders: selectUserChangeOrders(state.change_orders, query),
      settings: state.settings,
    }),
    shallowEqual,
  );

  const setExpand = () => {
    const current_settings = getSettings();
    const merged_settings = { ...current_settings, compress_change_order: !compress_change_order };
    setSettings(merged_settings);
    dispatch(updateSettings(merged_settings));
  };

  const { advertiser_id, campaign_id, flight_id, change_order_id, page } = match.params;
  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const campaign = campaigns.find((r) => Number(r.campaign_id) === Number(campaign_id)) || {};
  const flight = flights.find((r) => Number(r.flight_id) === Number(flight_id)) || {};
  const change_order = change_orders.find((r) => Number(r.change_order_id) === Number(change_order_id)) || {};
  const campaign_width = getTextWidth(campaign.campaign_name);
  const { compress_change_order } = settings;
  const changeOrder_width = getTextWidth(change_order.change_order_name);
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  const flight_width = getTextWidth(flight.flight_name);

  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
          locations={location}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/overview`)}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={campaign.campaign_name}
          values={campaigns}
          page="Campaign"
          width={campaign_width}
          height={47}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/campaigns/${campaign_id}/flights`)}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={flight.flight_name}
          values={flights}
          page="Flight"
          width={flight_width}
          height={47}
          onKeyDown={() =>
            history.push(`/advertisers/${advertiser_id}/campaigns/${campaign_id}/flights/${flight_id}/change-orders`)
          }
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={change_order.change_order_name}
          values={change_orders}
          page="ChangeOrder"
          width={changeOrder_width}
          height={47}
        />
        <div className={cn.flex} />
        <Action
          tooltip={!compress_change_order ? 'Compress Change Order' : 'Expand Change Order'}
          onClick={setExpand}
          icon={!compress_change_order ? ['fas', 'compress'] : ['fas', 'expand']}
        />
      </div>
    </>
  );
};

ChangeOrderWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const ChangeOrder = withRouter(ChangeOrderWithRouter);

export const mergeLineItemDeals = (global_deals = [], deals = []) =>
  global_deals.map((g) => {
    const line_item_deal = deals.find((d) => d.deal_id === g.deal_id) || {};
    return {
      ...g,
      ...line_item_deal,
      checked: line_item_deal.deal_line_item_id !== undefined,
    };
  });

export const mergeStrategyDeals = (global_deals = [], deals = []) =>
  global_deals.map((g) => {
    const strategy_deal = deals.find((d) => d.deal_id === g.deal_id) || {};
    return {
      ...g,
      ...strategy_deal,
      checked: strategy_deal.deal_strategy_id !== undefined,
    };
  });

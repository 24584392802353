import { CLEAR_AB_TESTS, SET_AB_TESTS } from '../types';

export const abTestsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_AB_TESTS:
      return action.payload;
    case CLEAR_AB_TESTS:
      return [];
    default:
      return state;
  }
};

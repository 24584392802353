import * as constants from 'ducks/types';

export const setModelOutputs = (payload = []) => ({
  type: constants.SET_MODEL_OUTPUTS,
  payload,
});

export const cleatModelOutputs = () => ({
  type: constants.CLEAR_MODEL_ADVERTISERS,
});

export const setModelAdvertisers = (payload = []) => ({
  type: constants.SET_MODEL_ADVERTISERS,
  payload,
});

export const cleatModelAdvertisers = () => ({
  type: constants.CLEAR_MODEL_ADVERTISERS,
});

export const setModel = (payload = {}) => ({
  type: constants.SET_MODEL,
  payload,
});

export const clearModel = () => ({
  type: constants.CLEAR_MODEL,
});

import { CLEAR_MODALS, UPDATE_MODAL } from '../types';

export const modalsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MODAL:
      return { ...state, ...action.payload };
    case CLEAR_MODALS:
      return {};
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setCreativeScripts = (payload = []) => ({
  type: constants.SET_CREATIVE_SCRIPTS,
  payload,
});

export const clearCreativeScripts = () => ({
  type: constants.CLEAR_CREATIVE_SCRIPTS,
});

import React from 'react';
import { Button, Modal } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateModal } from 'ducks/actions/modals';
import { useDispatch, useSelector } from 'react-redux';

import cn from './Modal.module.scss';

export const SuccessRequest = () => {
  const dispatch = useDispatch();
  const { success_request } = useSelector((state) => state.modals);

  const closeModal = async () => {
    dispatch(updateModal({ success_request: false }));
  };

  return (
    <Modal name="success_request" show={success_request} width={400} padding={0} onClose={closeModal}>
      <div className={cn.modalMessage}>
        <div className={cn.iconContainer}>
          <FontAwesomeIcon className={cn.icon} icon={['far', 'shield-check']} />
        </div>
        <p className={cn.text} style={{ margin: '10px 0px' }}>
          Please check your email account for the reset link. If you have not received an email please try again or
          contact your account manager directly.
        </p>
        <div className={cn.buttonContainer}>
          <Button button_size="small" width="100%" onClick={closeModal}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import {
  CLEAR_CAMPAIGN_INCREMENTALITY,
  SET_CAMPAIGN_INCREMENTALITY_CONTROL,
  SET_CAMPAIGN_INCREMENTALITY_EXPERIMENT,
  SET_CAMPAIGN_INCREMENTALITY_OVERVIEW,
} from '../types';

const initial_state = {
  overview: {
    y1_min: 0,
    y1_max: 1,
    y2_min: 0,
    y2_max: 1,
    tooltips: [],
    x: [],
    y1: [],
    y2: [],
    y3: [],
    y4: [],
    y5: [],
    y6: [],
    y7: [],
    y8: [],
    z1: [],
    z2: [],
  },
  experiment: {
    x: [],
    y1: [],
    y2: [],
    y3: [],
  },
  control: {
    x: [],
    y1: [],
    y2: [],
    y3: [],
  },
};
export const campaignIncrementalityReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_INCREMENTALITY_OVERVIEW:
      return { ...state, overview: { ...state.overview, ...action.payload } };
    case SET_CAMPAIGN_INCREMENTALITY_EXPERIMENT:
      return { ...state, experiment: { ...state.experiment, ...action.payload } };
    case SET_CAMPAIGN_INCREMENTALITY_CONTROL:
      return { ...state, control: { ...state.control, ...action.payload } };
    case CLEAR_CAMPAIGN_INCREMENTALITY:
      return { ...initial_state };
    default:
      return state;
  }
};

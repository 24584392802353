import React from 'react';
import { Tooltip } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import cn from './Cells.module.scss';

export const State = ({ to, tooltip, state_id, padding, align, children }) => (
  <p className={cn.state} to={to} style={{ padding, textAlign: align, cursor: 'pointer' }}>
    {state_id && (
      <FontAwesomeIcon
        className={classNames(cn.state, {
          [cn.green]: state_id === 1,
          [cn.orange]: state_id === 2,
          [cn.red]: state_id === 3,
          [cn.grey]: state_id === 4,
        })}
        icon={['fad', 'circle']}
      />
    )}
    {tooltip && <Tooltip tooltip={tooltip} caret="right" width="max-content" position={{ left: null, right: 50 }} />}
    {children}
  </p>
);

State.defaultProps = {
  height: 30,
  align: 'left',
  padding: null,
  tooltip: null,
  children: null,
  icon: ['fad', 'circle'],
};
State.propTypes = {
  height: PropTypes.any,
  tooltip: PropTypes.any,
  icon: PropTypes.any,
  children: PropTypes.element,
  align: PropTypes.string,
  padding: PropTypes.number,
  to: PropTypes.string,
  state_id: PropTypes.number,
};

import { offsetTimezoneUTC } from 'utils/dates';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeModels = (data = []) =>
  data.map((d) => normalizeModel(d)).sort((a, b) => checkSort(a, b, 'model_name', 'asc'));

export const normalizeModel = (data = {}) => ({
  ...data,
  model_search_formatted: `${data.model_id} ${data.model_name} ${data.model_display_name}`,
  created_date_formatted: offsetTimezoneUTC(data.created_date_utc, { format: 'M/D/YY h:mm:ss A' }),
  imported_date_formatted: offsetTimezoneUTC(data.imported_date_utc, { format: 'M/D/YY h:mm:ss A' }),
  uuid: uuidv4(),
});

export const normalizeAdvertiserModels = (data = []) =>
  data.map((d) => normalizeAdvertiseModel(d)).sort((a, b) => checkSort(a, b, 'model_display_name', 'asc'));

export const normalizeAdvertiseModel = (data = {}) => ({
  ...data,
  model_name_formatted: `${data.model_id}: ${data.model_display_name || data.model_name}`,
  created_date_formatted: offsetTimezoneUTC(data.created_date_utc, { format: 'M/D/YY h:mm:ss A' }),
  imported_date_formatted: offsetTimezoneUTC(data.imported_date_utc, { format: 'M/D/YY h:mm:ss A' }),
  uuid: uuidv4(),
});

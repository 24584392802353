/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from '@cognitiv/cyprus-ui';
import { Select } from 'components/cells/Select';
import { State } from 'components/cells/State';

export const SelectCell = ({ row, onClick, settings }) => {
  if (row.strategy_id) {
    return <Cell />;
  }
  return (
    <Cell onClick={() => onClick({ ...row, checked: !row.checked })}>
      <Select tooltip="Associate Vendor Fee" checked={row.checked} height={settings.row_height} />
    </Cell>
  );
};

export const FeeNameCell = ({ row }) => (
  <Cell>
    <State state_id={row.state_id}>{row.fee_vendor_fee_name}</State>
  </Cell>
);

export const ParentFeeNameCell = ({ row }) => (
  <Cell>
    <p>{row.parent_fee_name}</p>
  </Cell>
);

export const ParentFeeCell = ({ row }) => (
  <Cell>
    <p>{row.parent_fee_formatted}</p>
  </Cell>
);

export const FeeDescriptionCell = ({ row }) => (
  <Cell>
    <p>{row.description}</p>
  </Cell>
);
export const VendorFeeNameCell = ({ row }) => (
  <Cell>
    <p>{row.fee_vendor_fee_name}</p>
  </Cell>
);
export const VendorFeeCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.fee_value_formatted}</p>
  </Cell>
);
export const ImpressionMinCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.impression_minimum}</p>
  </Cell>
);

export const ImpressionMaxCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.impression_maximum}</p>
  </Cell>
);
export const VendorNameCell = ({ row }) => (
  <Cell>
    <p>{row.fee_vendor_name}</p>
  </Cell>
);
export const FeeStartDateCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.contract_start_date_formatted}</p>
  </Cell>
);
export const FeeFinishDateCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.contract_finish_date_formatted}</p>
  </Cell>
);

export const StartDateCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.fee_vendor_fee_strategy_start_date_formatted}</p>
  </Cell>
);

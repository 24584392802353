import { formatBudgetSpend, formatNumber } from 'utils/numbers';
import { v4 as uuidv4 } from 'uuid';

export const normalizeExternalLineItems = (data = []) => data.map((row) => normalizeExternalLineItem(row));

export const normalizeExternalLineItem = (row) => {
  const { external_impressions, external_spend_micro_usd, impression_budget, spend_budget_micro_usd } = row;

  const impression_budget_formatted = formatNumber(impression_budget, { format: 'number-abbreviated', append: 0 });
  const impression_budget_formatted_comma = formatNumber(impression_budget, { format: 'number-comma', append: 0 });

  const external_impressions_formatted = formatNumber(external_impressions, {
    format: 'number-abbreviated',
    append: 0,
  });
  const external_impressions_comma = formatNumber(external_impressions, { format: 'number-comma', append: 0 });

  const external_spend_usd = formatBudgetSpend(external_spend_micro_usd);
  const external_spend_usd_formatted = formatNumber(external_spend_usd, { format: 'currency', append: '$0' });
  const external_spend_usd_comma = formatNumber(external_spend_usd, { format: 'currency-comma', append: '$0' });

  const spend_budget_usd = formatBudgetSpend(spend_budget_micro_usd);
  const spend_budget_usd_formatted = formatNumber(spend_budget_usd, { format: 'currency', append: '$0' });
  const spend_budget_usd_comma = formatNumber(spend_budget_usd, { format: 'currency-comma', append: '$0' });
  return {
    ...row,
    external_impressions_formatted,
    external_impressions_comma,
    external_spend_usd,
    external_spend_usd_formatted,
    external_spend_usd_comma,
    impression_budget_formatted,
    impression_budget_formatted_comma,
    spend_budget_usd,
    spend_budget_usd_formatted,
    spend_budget_usd_comma,
    uuid: uuidv4(),
  };
};

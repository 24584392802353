import { CLEAR_CREATIVE_ADDONS, SET_CREATIVE_ADDONS } from '../types';

export const creativeAddonsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CREATIVE_ADDONS:
      return action.payload;
    case CLEAR_CREATIVE_ADDONS:
      return [];
    default:
      return state;
  }
};

import { offsetTimezoneUTC } from 'utils/dates';
import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeAdvertiserReports = (data = []) =>
  uniqueIdentifiers(
    data.map((report) => ({ ...report, report_name_with_id: `${report.report_id} ${report.report_name}` })),
  ).sort((a, b) => checkSort(a, b, 'report_name', 'asc'));

export const normalizeReports = (data = []) => data.map((d) => normalizeReport(d));

export const normalizeReport = (data = {}) => ({
  ...data,
  created_date_local_formatted: offsetTimezoneUTC(data.created_date, { format: 'M/D/YY h:mm:ss A' }),
  uuid: uuidv4(),
});

import { useCallback, useEffect, useMemo } from 'react';
import { tableFilter } from 'utils/string';

export const useBulkSelect = ({ filter, setData, bulk_options, data, filter_key }) => {
  useEffect(() => {
    setData?.((prev) => {
      const copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        const filtered = tableFilter(copy[i][filter_key], filter);
        if (!filtered) {
          copy[i] = { ...copy[i], checked: false };
        }
      }
      return copy;
    });
  }, [filter, filter_key, setData]);

  const bulkSelect = useCallback(
    (item, key) => {
      const { checked } = item;
      setData?.((prev) => {
        const copy = [...prev];
        for (let i = 0; i < copy.length; i++) {
          const filtered = tableFilter(copy[i][key], filter);
          if (filtered && copy[i].checked !== checked) {
            copy[i] = { ...copy[i], checked };
          }
        }
        return copy;
      });
    },
    [setData, filter],
  );

  const hydrateOptions = useMemo(() => {
    const count = data?.filter((item) => item.checked).length;
    return bulk_options?.map((o) => ({
      ...o,
      name: `${o.name} (${count || 0})`,
    }));
  }, [bulk_options, data]);

  return {
    bulkSelect,
    hydrateOptions,
  };
};

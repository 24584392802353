import { setFlight, setFlights, updateFlights } from 'ducks/actions/flights';
import { setKpiGroup } from 'ducks/actions/kpi-groups';
import { clearAbTestSegments } from 'ducks/actions/segments';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeFlight, normalizeFlights } from 'ducks/normalizers/flights';
import { getCampaignExternalLineItems, getFlightLineItems } from 'ducks/operators/line-items';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

import { getFlightAbTests, getFlightStrategyAbTests } from './ab-tests';
import { getAdvertiser, getAdvertisers } from './advertisers';
import { getAdvertiserCampaigns, getCampaign, getCampaignMetrics } from './campaigns';
import { getFlightChangeOrders } from './change-orders';
import { getAdvertiserCreatives, getFlightCreatives } from './creatives';
import { getDeals } from './deals';
import { getAdvertiserFilters } from './filters';
import { getCampaignFrequencyCaps } from './frequency-caps';
import { getCampaignKpiGroups } from './kpi-groups';
import { getAdvertiserSegments, setFlightAbTestSegments } from './segments';
import { getFlightStrategies } from './strategies';
import { getAdvertiserTags } from './tags';
import { getVendorFees } from './vendor-fees';

export const getFlightPage = (params) => async (dispatch) => {
  const { advertiser_id, campaign_id, flight_id } = params;

  const [kpi_groups] = await Promise.all([
    dispatch(getCampaignKpiGroups(campaign_id)),
    dispatch(getAdvertisers()),
    dispatch(getAdvertiser(advertiser_id)),
    dispatch(getAdvertiserCampaigns(advertiser_id)),
    dispatch(getCampaign(campaign_id)),
    dispatch(getCampaignExternalLineItems(campaign_id)),
    dispatch(getCampaignMetrics(campaign_id)),
    dispatch(getCampaignFlights(campaign_id)),
    dispatch(getFlight(flight_id)),
    dispatch(getFlightLineItems(flight_id)),
  ]);

  if (kpi_groups && kpi_groups.length > 0) {
    await dispatch(setKpiGroup(kpi_groups[0]));
  }

  return kpi_groups;
};

export const getFlightCopyPage =
  (params, queries = {}) =>
  async (dispatch) => {
    const { advertiser_id, campaign_id, flight_id } = params;

    const query = {
      include_archived: false,
      ...queries,
    };

    const [advertiser] = await Promise.all([
      dispatch(getAdvertisers()),
      dispatch(getAdvertiser(advertiser_id)),
      dispatch(getAdvertiserCampaigns(advertiser_id)),
      dispatch(getCampaign(campaign_id)),
      dispatch(getCampaignFlights(campaign_id)),
      dispatch(getFlight(flight_id)),
      dispatch(getFlightLineItems(flight_id, query)),
      dispatch(getFlightCreatives(flight_id, query)),
    ]);

    await dispatch(updateSettings({ loading: false }));
    return advertiser;
  };

export const getFlightLineItemsPage =
  (params, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { flight_id, campaign_id, advertiser_id } = params;
    const query = {
      include_archived: false,
      ...queries,
    };

    const [campaign] = await Promise.all([
      dispatch(getFlightLineItems(flight_id, query)),
      dispatch(getAdvertiserFilters(advertiser_id)),
      dispatch(getAdvertiserSegments(advertiser_id)),
    ]);

    await dispatch(updateSettings({ loading: false }));
    return campaign;
  };

export const getFlightExperimentsPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { flight_id } = params;

  const [ab_tests] = await Promise.all([
    dispatch(getFlightStrategyAbTests(flight_id)),
    dispatch(getFlightStrategies(flight_id)),
    dispatch(getFlightLineItems(flight_id)),
  ]);

  // clearing ab test segments before getting from ab test list
  dispatch(clearAbTestSegments());
  await Promise.all(ab_tests.map((a) => dispatch(setFlightAbTestSegments(a.ab_test_id))));

  await dispatch(updateSettings({ loading: false }));
  return ab_tests;
};

export const getFlightStrategiesPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { flight_id } = params;

  const [strategies] = await Promise.all([
    dispatch(getFlightStrategies(flight_id)),
    dispatch(getDeals()),
    dispatch(getVendorFees()),
  ]);

  await dispatch(updateSettings({ loading: false }));
  return strategies;
};

export const getFlightCreativesPage =
  (params, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id, flight_id } = params;
    const query = {
      include_archived: false,
      ...queries,
    };
    const [campaign] = await Promise.all([
      dispatch(getAdvertiserCreatives(advertiser_id, query)),
      dispatch(getFlightCreatives(flight_id, query)),
    ]);

    await dispatch(updateSettings({ loading: false }));
    return campaign;
  };

export const getFlightChangeOrdersPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { flight_id } = params;

  const [change_orders] = await Promise.all([dispatch(getFlightChangeOrders(flight_id))]);

  await dispatch(updateSettings({ loading: false }));
  return change_orders;
};

export const getFlightAbTestsPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { flight_id } = params;

  const [ab_tests] = await Promise.all([dispatch(getFlightAbTests(flight_id))]);

  await dispatch(updateSettings({ loading: false }));
  return ab_tests;
};

export const getFlightAbTestSegmentsPage =
  (params, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { advertiser_id, flight_id } = params;

    // get advertiser tags so that segments can use the state
    await dispatch(getAdvertiserTags(advertiser_id, { include_archived: queries.tags_archived }));

    const [segments] = await Promise.all([
      dispatch(getAdvertiserSegments(advertiser_id, { include_archived: queries.segments_archived })),
      dispatch(getFlightAbTests(flight_id)),
    ]);

    await dispatch(updateSettings({ loading: false }));
    return segments;
  };

export const setCampaignFlights = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.get(`/campaigns/${entity_id}/flights`);
  const flights = await normalizeFlights(res.data);
  await dispatch(updateFlights(flights));
  return flights;
};

export const getCampaignFlights = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    state_id: null,
    include_state_ids: [],
    exclude_state_ids: [],
    ...queries,
  };
  const res = await Gemini.get(`/campaigns/${entity_id}/flights${replaceQuery(query)}`);
  const flights = await normalizeFlights(res.data);
  await dispatch(setFlights(flights));
  return flights;
};

export const getCampaignCopyFlights = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    source_flight_id: null,
    ...queries,
  };
  const res = await Gemini.get(`/campaigns/${entity_id}/copy/eligible_flights${replaceQuery(query)}`);
  const flights = normalizeFlights(res.data);
  await dispatch(updateSettings({ loading: false }));
  return flights;
};

export const createCampaignFlight = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/flights/create`, data);
  return res.data;
};

export const createFlightCopy = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/copy`, data);
  return res.data;
};

export const getFlight = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/flights/${entity_id}`);
  const flight = normalizeFlight(res.data);
  dispatch(setFlight(flight));
  return flight;
};

export const updateFlight = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/update`, data);
  return res.data;
};

export const activateFlight = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/activate`);
  return res.data;
};

export const pauseFlight = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/pause`);
  return res.data;
};

export const deactivateFlight = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/deactivate`);
  return res.data;
};

export const archiveFlight = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/archive`);
  return res.data;
};

export const unarchiveFlight = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/unarchive`);
  return res.data;
};

import { CLEAR_REPORT_TYPE_PARAMETER_GROUPS, SET_REPORT_TYPE_PARAMETER_GROUPS } from '../types';

export const reportTypeParameterGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_REPORT_TYPE_PARAMETER_GROUPS:
      return action.payload;
    case CLEAR_REPORT_TYPE_PARAMETER_GROUPS:
      return [];
    default:
      return state;
  }
};

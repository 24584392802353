import * as constants from 'ducks/types';

export const setFrequencyCaps = (payload = {}) => ({
  type: constants.SET_FREQUENCY_CAPS,
  payload,
});

export const clearFrequencyCaps = () => ({
  type: constants.CLEAR_FREQUENCY_CAPS,
});

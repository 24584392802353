import { colors, default_bar, default_line } from 'utils/charts';
import { formatNumber } from 'utils/numbers';

export const x1_scale = {
  grid: {
    display: false,
  },
  title: {
    text: 'Campaign Days',
  },
  ticks: { display: true, maxTicksLimit: 20 },
};

export const y1_scale = {
  id: 'y1',
  position: 'left',
  beginAtZero: true,
  display: true,
  suggestedMax: 100,
  maxTicksLimit: 8,
  grid: {
    display: false,
  },
  title: {
    display: true,
    text: 'Pacing Performance',
  },
  ticks: {
    callback(value) {
      return `${formatNumber(value, { format: 'percentage' })}`;
    },
  },
};

export const y2_scale = {
  id: 'y2',
  position: 'right',
  beginAtZero: true,
  display: true,
  suggestedMin: 0,
  suggestedMax: 10,
  maxTicksLimit: 8,
  grid: {
    display: false,
  },
  title: {
    display: true,
    text: 'Spend',
  },
  ticks: {
    callback(value) {
      return `${formatNumber(value, { format: 'currency-dynamic' })}`;
    },
  },
};

export const y3_scale = {
  id: 'y3',
  position: 'right',
  display: false,
  beginAtZero: true,
  suggestedMin: 0,
  suggestedMax: 10,
  maxTicksLimit: 8,
  grid: {
    display: false,
  },
  title: {
    display: true,
    text: 'Spend',
  },
  ticks: {
    callback(value) {
      return `${formatNumber(value, { format: 'currency-dynamic' })}`;
    },
  },
};

export const y1_line = {
  ...default_line,
  yAxisID: 'y1',
  label: ' Pacing',
  backgroundColor: colors.grey,
  borderColor: colors.grey,
  pointBackgroundColor: colors.grey,
  pointBorderColor: colors.grey,
  pointHoverBackgroundColor: colors.grey,
  pointHoverBorderColor: colors.grey,
};
export const y2_bar = {
  ...default_bar,
  yAxisID: 'y2',
  stack: 'y2',
  label: ' Budget',
  borderColor: colors.blue,
  backgroundColor: colors.white_opacity,
};
export const y3_bar = {
  ...default_bar,
  yAxisID: 'y3',
  stack: 'y2',
  label: ' DCM Spend',
  borderColor: colors.blue,
  backgroundColor: colors.blue,
};

import {
  Button,
  Checkbox,
  Dropdown,
  DropdownCheckbox,
  Input,
  InputCurrency,
  InputNumber,
  Modal,
  Textarea,
} from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { clearCreativeAddons } from 'ducks/actions/creative-addons';
import { clearCreativeExtras } from 'ducks/actions/creative-extras';
import { clearCreativePrices } from 'ducks/actions/creative-prices';
import { clearCreativeVideoEvents } from 'ducks/actions/creative-video-events';
import { clearCreative } from 'ducks/actions/creatives';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createCreative, updateCreatives } from 'ducks/operators/creatives';
import { selectCreativeAddOns, selectCreativeExtras } from 'ducks/selectors/creatives';
import { transformCreative } from 'ducks/transforms/creatives';
import { creativeValidation } from 'ducks/validations/creatives';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';
import { ManageCreativeMessage } from './ManageCreativeMessage';

const default_state = {
  creative_id: null,
  creative_name: '',
  advertiser_id: null,
  advertiser_name: '',
  partner_id: null,
  partner_mame: '',
  dsp_id: null,
  dsp_name: '',
  dsp_account_id: null,
  dsp_account_name: '',
  dsp_identifier: '',
  category_name: '',
  domain_url: '',
  state_id: null,
  state_name: '',
  creative_type_id: null,
  creative_type_name: '',
  creative_size_id: null,
  creative_size_name: '',
  price_micro_usd: null,
  price_micro_usd_formatted: '',
  pixels: [],
  scripts: [],
  ad_server_id: null,
  ad_server_name: '',
  click_url: null,
  skippable: null,
  preview_url: '',
  is_psa: false,
  video_duration_seconds: null,
  creative_content_id: null,
  creative_content_name: '',
  companion_ads: [],
  video_media_files: [],
  playback_method_id: null,
  playback_method_name: '',
  vast_ad_title: '',
  vast_description: '',
  ad_server_campaign_identifier_id: null,
  ad_server_campaign_identifier_name: '',
  remote_vast: false,
  ad_server_identifier: null,
  add_ons: [],
  extras: [],
  creative_message_name: '',
  creative_message_id: null,
  tag_html: '',
};

export const ManageCreative = ({ history, location, match }) => {
  const dispatch = useDispatch();

  const {
    modals,
    creative,
    creative_types_list,
    creative_messages_list,
    ad_servers_list,
    creative_add_ons,
    creative_contents,
    creative_add_ons_list,
    selected_creative_add_ons,
    categories_list,
    ad_server_campaign_identifiers_list,
    creative_extras,
    creative_extras_list,
    selected_creative_extras,
  } = useSelector((state) => ({
    creative_extras: state.creative_extras,
    creative_extras_list: state.creative_extras_list,
    selected_creative_extras: selectCreativeExtras(state.creative_extras, state.creative_extras_list),
    creative_add_ons: state.creative_add_ons,
    ad_server_campaign_identifiers_list: state.ad_server_campaign_identifiers_list,
    creative_add_ons_list: state.creative_add_ons_list,
    categories_list: state.categories_list,
    selected_creative_add_ons: selectCreativeAddOns(state.creative_add_ons, state.creative_add_ons_list),
    creative_contents: state.creative_contents,
    ad_servers_list: state.ad_servers_list,
    creative_types_list: state.creative_types_list,
    creative: state.creative,
    creative_messages_list: state.creative_messages_list,
    modals: state.modals,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({
      ...default_state,
      ...creative,
      add_ons: selected_creative_add_ons,
      extras: selected_creative_extras,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creative, creative_add_ons, creative_add_ons_list, creative_extras, creative_extras_list]);

  const setCreativeMessage = (item) => {
    if (!item.creative_message_id) {
      dispatch(updateModal({ manage_creative_message: true }));
    }
    if (item.creative_message_id) {
      onChange({
        creative_message_name: item.creative_message_name,
        creative_message_id: item.creative_message_id,
      });
    }
  };

  const onCallback = (item) => {
    creative_messages_list.push(item)
    setCreativeMessage(item)
  }

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on creative page
    if (!match.params.creative_id) {
      dispatch(clearCreativeAddons());
      dispatch(clearCreativeExtras());
      dispatch(clearCreativeVideoEvents());
      dispatch(clearCreativePrices());
      dispatch(clearCreative());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_creative: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { pathname, search } = location;
    const { advertiser_id, flight_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = transformCreative({
        ...form,
        advertiser_id,
        creative_id,
        flight_id: form.flight_id || flight_id,
        pixels: form.pixels,
      });

      if (creative_id) {
        await dispatch(updateCreatives(advertiser_id, [data]));
      }
      if (!creative_id) {
        await dispatch(createCreative(data));
      }
      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('creative has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const {
    creative_name,
    creative_id,
    creative_type_name,
    creative_type_id,
    creative_message_name,
    creative_message_id,
    ad_server_name,
    creative_content_name,
    price_usd_formatted,
    add_ons,
    domain_url,
    category_name,
    ad_server_campaign_identifier_name,
    ad_server_identifier,
    extras,
    is_psa,
    remote_vast,
    click_url,
    tag_html,
  } = form;

  const disabled = creativeValidation(form);

  return (
    <>
      <Modal
        padding={16}
        close_box={46}
        name="manage_creative"
        show={modals.manage_creative}
        width={650}
        onClose={closeModal}
        standard={false}
      >
        <h3>{creative_id ? 'Update Creative' : 'Create Creative'}</h3>
        <form onSubmit={onSubmit}>
          <Input
            label="Creative Name"
            auto_focus
            value={creative_name}
            margin="0px 0px 15px 0px"
            onChange={(value) => onChange({ creative_name: value })}
          />
          <Row>
            <Dropdown
              label="Creative Type"
              options={creative_types_list}
              option_key="name"
              value={creative_type_name}
              width="calc(50% - 7px)"
              margin="0px 7px 15px 0px"
              onSelect={(item) => onChange({ creative_type_name: item.name, creative_type_id: item.id })}
            />
            <Dropdown
              label="Ad Server"
              options={ad_servers_list}
              option_key="ad_server_name"
              value={ad_server_name}
              width="calc(50% - 7px)"
              margin="0px 0px 15px 7px"
              onSelect={(item) => onChange({ ad_server_name: item.ad_server_name, ad_server_id: item.ad_server_id })}
            />
          </Row>
          <Row>
            {!creative_id && (
              <InputCurrency
                label="Creative CPM"
                value={price_usd_formatted}
                margin="0px 7px 15px 0px"
                width="calc(50% - 7px)"
                onChange={(input) =>
                  onChange({
                    price_usd: input.float_value,
                    price_usd_formatted: input.formatted_value,
                  })
                }
              />
            )}
            <Dropdown
              label="Creative Content"
              options={creative_contents}
              option_key="creative_content_name"
              value={creative_content_name}
              margin={!creative_id ? '0px 0px 15px 7px' : '0px 0px 15px 0px'}
              width={!creative_id && 'calc(50% - 7px)'}
              onSelect={(item) =>
                onChange({
                  creative_content_name: item.creative_content_name,
                  creative_content_id: item.creative_content_id,
                })
              }
            />
          </Row>
          {creative_type_id === 2 && (
            <Input
              label="Click URL"
              value={click_url}
              margin="0px 0px 15px 0px"
              onChange={(value) => onChange({ click_url: value })}
            />
          )}
          <DropdownCheckbox
            label="Add Ons"
            options={add_ons}
            option_key="add_on_name"
            onSelect={(items) => onChange({ add_ons: items })}
            margin="0px 0px 15px 0px"
          />
          <Row>
            <Dropdown
              label="Category Override"
              options={categories_list}
              option_key="category_name"
              value={category_name}
              width="calc(50% - 7px)"
              margin="0px 7px 15px 0px"
              onSelect={(item) =>
                onChange({
                  category_name: item.category_name,
                  category_id: item.category_id,
                })
              }
            />
            <Input
              label="Domain Override"
              value={domain_url}
              width="calc(50% - 7px)"
              margin="0px 0px 15px 7px"
              onChange={(value) => onChange({ domain_url: value })}
            />
          </Row>
          <Row>
            <Dropdown
              label="Ad Server Campaign Identifier"
              options={ad_server_campaign_identifiers_list}
              option_key="name"
              value={ad_server_campaign_identifier_name}
              width="calc(50% - 7px)"
              margin="0px 7px 15px 0px"
              onSelect={(item) =>
                onChange({
                  ad_server_campaign_identifier_name: item.name,
                  ad_server_campaign_identifier_id: item.id,
                })
              }
            />
            <InputNumber
              label="Ad Server Identifier"
              value={ad_server_identifier}
              width="calc(50% - 7px)"
              margin="0px 0px 15px 7px"
              thousand_separator={false}
              onChange={(item) => onChange({ ad_server_identifier: item.float_value })}
            />
          </Row>

          <Row>
            <DropdownCheckbox
              label="Extras"
              options={extras}
              option_key="creative_extra_name"
              onSelect={(items) => onChange({ extras: items })}
              margin="0px 7px 15px 0px"
            />
            <Dropdown
              options={[
                {
                  creative_message_name: '(Create Creative Message)',
                  creative_message_id: null,
                },
                ...creative_messages_list
              ]}
              option_key="creative_message_name"
              value={creative_message_name}
              label="Creative Message"
              margin="0px 0px 15px 7px"
              onSelect={setCreativeMessage}
            />
          </Row>
          <Textarea
            label="Tag"
            value={tag_html}
            min_height="100px"
            width="100%"
            onChange={(value) => onChange({ tag_html: value })}
            margin="0px 0px 15px 0px"
          />
          <Row>
            <Checkbox margin="0px 0px 0px 0px" width="max-content" checked={is_psa} onClick={(checked) => onChange({ is_psa: checked })}>
              PSA
            </Checkbox>
            <Checkbox
              margin="0px 0px 0px 50px"
              width="max-content"
              checked={remote_vast}
              onClick={(checked) => onChange({ remote_vast: checked })}
            >
              Remote Vast
            </Checkbox>
          </Row>
          <div className={cn.buttonContainer}>
            <div className={cn.flex} />
            <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
              {creative_id ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </Modal>
      <ManageCreativeMessage history={history} location={location} match={match} onCallback={onCallback} />
    </>

  );
};

ManageCreative.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

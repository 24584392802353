import * as constants from 'ducks/types';

export const setAbTestSegments = (payload = []) => ({
  type: constants.SET_AB_TEST_SEGMENTS,
  payload,
});
export const updateAbTestSegments = (payload = []) => ({
  type: constants.UPDATE_AB_TEST_SEGMENTS,
  payload,
});
export const clearAbTestSegments = () => ({
  type: constants.CLEAR_AB_TEST_SEGMENTS,
});

export const setSegments = (payload = []) => ({
  type: constants.SET_SEGMENTS,
  payload,
});
export const clearSegments = () => ({
  type: constants.CLEAR_SEGMENTS,
});

export const setSegment = (payload = {}) => ({
  type: constants.SET_SEGMENT,
  payload,
});
export const clearSegment = () => ({
  type: constants.CLEAR_SEGMENT,
});

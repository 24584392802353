import { Gemini } from '../../utils/axios';
import { setOutputs } from '../actions/outputs';
import { normalizeOutputs } from '../normalizers/outputs';

export const getOutputs = () => async (dispatch) => {
  const res = await Gemini.get('/models/score_types');
  const outputs = await normalizeOutputs(res.data);
  await dispatch(setOutputs(outputs));
  return outputs;
};

import { CLEAR_CAMPAIGN_DAILY_MARGIN, SET_CAMPAIGN_DAILY_MARGIN } from '../types';

// empty header needs to be available for the table
const initial_state = {
  headers: [{ flex_grow: 1, name: 'N/A', uuid: 1 }],
  rows: [],
};
export const campaignDailyMarginReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_DAILY_MARGIN:
      return action.payload;
    case CLEAR_CAMPAIGN_DAILY_MARGIN:
      return { ...initial_state };
    default:
      return state;
  }
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateCircle } from 'components/icons/CreateCircle';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import { updateModal } from 'ducks/actions/modals';
import { clearUserAdmin } from 'ducks/actions/users';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';

import cn from './Navigation.module.scss';

const options = [
  {
    pathname: '/admin/users',
    query: {
      organization_id: 1,
    },
    name: 'Users',
    uuid: 1,
  },
  {
    name: 'Security Audit',
    pathname: '/admin/security-audit',
    query: {},
    uuid: 2,
  },
];

export const AdminWithRouter = ({ location }) => {
  const { pathname } = location;
  const dispatch = useDispatch();
  const admin = options.find((o) => o.pathname === pathname) || {};
  const selectAdminRoute = () => `/admin/users`;

  const createUser = () => {
    dispatch(updateModal({ manage_user: true }));
    dispatch(clearUserAdmin());
  };
  const admin_width = getTextWidth(admin.name);
  return (
    <div className={cn.items}>
      <Link className={cn.page} to={selectAdminRoute}>
        Admin
      </Link>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
      <MegaMenu
        labels={admin.name}
        values={options}
        page="Admin"
        width={admin_width}
        height={200}
        locations={location}
      />
      <CreateCircle tooltip="Create User" onClick={createUser} />
    </div>
  );
};

AdminWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export const Admin = withRouter(AdminWithRouter);

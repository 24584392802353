import { CLEAR_CREATIVE_CONTENTS, SET_CREATIVE_CONTENTS } from '../types';

export const creativeContentsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CREATIVE_CONTENTS:
      return action.payload;
    case CLEAR_CREATIVE_CONTENTS:
      return [];
    default:
      return state;
  }
};

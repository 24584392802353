
import { setThirdPartyReports } from 'ducks/actions/third-party-reports';
import { setTitanKpiTypes } from 'ducks/actions/titan-kpi-types';
import { Titan } from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

export const getTitanKpiTypes = () => async (dispatch) => {
  const res = await Titan.get(`/kpi_types`);
  const titan_kpi_types = normalizeTitanKpiTypesList(res.data)
  dispatch(setTitanKpiTypes(titan_kpi_types));
  return titan_kpi_types;
};


export const normalizeTitanKpiTypesList = (data = []) => {
  return data.map((row) => normalizeTitanKpiTypeList(row)).sortObject('kpi_type_name', 'asc');
};

export const normalizeTitanKpiTypeList = (row) => {
  return {
    ...row,
    uuid: uuidv4(),
  };
};
import { setCreativeExtras } from 'ducks/actions/creative-extras';
import { updateSettings } from 'ducks/actions/settings';
import { Gemini } from 'utils/axios';
import { uniqueIdentifiers } from 'utils/helpers';

export const getCreativeExtras = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/${entity_id}/creative_extras`);
  const creative_extras = await uniqueIdentifiers(res.data);
  await dispatch(setCreativeExtras(creative_extras));
  return creative_extras;
};

import React from 'react';
import { Tooltip } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';

import cn from './Navigation.module.scss';

import { icons } from 'FaIcons';
const { PUBLIC_URL } = process.env;

const EntitiesNavigationWithRouter = ({ location }) => {
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual,
  );

  const trimPath = () => {
    const pathname = String(location.pathname).toLowerCase();
    const split_path = pathname.split('/');
    if (split_path.length > 0) {
      return split_path[1];
    }
  };

  const setSearch = (entity) => `/${entity}`;

  const entity = trimPath();
  return (
    <div className={cn.entities}>
      <Link className={cn.logo} to="/advertisers">
        <img src={`${PUBLIC_URL}/brain-logo-white.svg`} alt="Cognitiv Logo" />
      </Link>
      <div className={cn.wrapper}>
        <Menu
          page="advertisers"
          tooltip="Advertisers"
          onClick={() => setSearch('advertisers')}
          entity={entity}
          icon={['far', 'briefcase']}
        />
        <Menu
          page="deals"
          tooltip="Deals"
          onClick={() => setSearch('deals?archived=false')}
          entity={entity}
          icon={['far', 'badge-dollar']}
        />
        <Menu
          page="vendor-fees"
          tooltip="Vendor Fees"
          onClick={() => setSearch('vendor-fees')}
          entity={entity}
          icon={['far', 'newspaper']}
        />
        <Menu
          page="strategy-templates"
          tooltip="Strategy Templates"
          onClick={() => setSearch('strategy-templates')}
          entity={entity}
          icon={['far', 'file-alt']}
        />
        <Menu
          page="models"
          tooltip="Models"
          onClick={() => setSearch('models')}
          entity={entity}
          icon={icons.faChartNetwork}
        />
        <div className={cn.flex} />
      </div>
      <div>
        <div className={cn.entity} style={{ fontSize: 12 }}>
          v{process.env.REACT_APP_VERSION}
        </div>
      </div>
    </div>
  );
};

const Menu = ({ onClick, icon, entity, page, tooltip, font_size }) => (
  <Link className={classNames(cn.entity, { [cn.active]: entity === page })} to={onClick}>
    <FontAwesomeIcon className={cn.icon} icon={icon} style={{ fontSize: font_size }} />
    {tooltip && <Tooltip tooltip={tooltip} caret="left" width={200} position={{ left: 60 }} />}
  </Link>
);

EntitiesNavigationWithRouter.propTypes = {
  location: PropTypes.object,
};

Menu.defaultProps = {
  onClick: () => { },
  icon: '',
  selected: false,
  page: '',
  tooltip: false,
};
Menu.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.any,
  selected: PropTypes.any,
  page: PropTypes.any,
  tooltip: PropTypes.any,
  font_size: PropTypes.number,
  entity: PropTypes.any,
};

export const EntitiesNavigation = withRouter(EntitiesNavigationWithRouter);

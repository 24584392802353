import { setKpiGroups } from 'ducks/actions/kpi-groups';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeKpiGroups } from 'ducks/normalizers/kpi-groups';
import { Gemini } from 'utils/axios';

export const getCampaignKpiGroups = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  if (!entity_id) {
    return;
  }

  const { measure_types_list, measure_units_list } = getState();
  const res = await Gemini.get(`/campaigns/${entity_id}/kpi_groups`);

  const kpi_groups = normalizeKpiGroups(res.data, measure_types_list, measure_units_list);
  await dispatch(setKpiGroups(kpi_groups));
  return kpi_groups;
};

export const updateKpiGroup = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/kpi_groups/${entity_id}/update`, data);
  return res.data;
};

/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from '@cognitiv/cyprus-ui';
import { State } from 'components/cells/State';

export const AbTestSegmentNameCell = ({ row }) => (
  <Cell>
    <State state_id={row.state_id}>{row.segment_name}</State>
  </Cell>
);

export const IdCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.segment_id}</p>
  </Cell>
);

export const CreatedByCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.created_by}</p>
  </Cell>
);

export const CreatedAtCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.created_date_formatted}</p>
  </Cell>
);

export const IdentityTypeCell = ({ row }) => (
  <Cell>
    <p style={{ textAlign: 'center' }}>{row.identity_type_name}</p>
  </Cell>
);

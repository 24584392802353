import * as constants from 'ducks/types';

export const updateSettings = (payload = {}) => ({
  type: constants.UPDATE_SETTINGS,
  payload,
});

export const updateSuccess = (message = '') => ({
  type: constants.UPDATE_SUCCESS,
  payload: {
    snackbar: true,
    snackbar_variant: 'secondary',
    snackbar_label: message,
    snackbar_duration: 2500,
  },
});
export const clearSettings = () => ({
  type: constants.CLEAR_SETTINGS,
});

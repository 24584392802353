import { CLEAR_REGIONS_LIST, SET_REGIONS_LIST } from '../types';

export const regionsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_REGIONS_LIST:
      return action.payload;
    case CLEAR_REGIONS_LIST:
      return [];
    default:
      return state;
  }
};

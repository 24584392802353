import { CLEAR_TAG_SUMMARY, SET_TAG_SUMMARY } from '../types';

export const tagSummaryReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TAG_SUMMARY:
      return action.payload;
    case CLEAR_TAG_SUMMARY:
      return [];
    default:
      return state;
  }
};

/* eslint-disable no-undef */
export const getStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return user;
  }
  return {};
};

export const setStorage = (user = {}) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const deleteStorage = () => {
  localStorage.setItem('user', '{}');
};

export const getSettings = () => {
  const settings = JSON.parse(localStorage.getItem('settings'));
  if (settings) {
    return settings;
  }
  return {};
};

export const setSettings = (settings = {}) => {
  localStorage.setItem('settings', JSON.stringify(settings));
};

export const deleteSettings = () => {
  localStorage.setItem('settings', '{}');
};

export const getQuery = () => {
  const query = JSON.parse(localStorage.getItem('query'));
  if (query) {
    return query;
  }
  return {};
};

export const setQuery = (query = {}) => {
  localStorage.setItem('query', JSON.stringify(query));
};

export const setChangeReportModal = (showModal) => {
  localStorage.setItem('show_change_report_modal', JSON.stringify(showModal));
};

export const getChangeReportModal = () => {
  const query = JSON.parse(localStorage.getItem('show_change_report_modal'));
  return !!query;
};

export const getLocalStorage = (item = 'user') => {
  let store = null;
  const stored_item = localStorage.getItem(item);
  if (stored_item) {
    store = JSON.parse(stored_item);
  }
  if (store) {
    return store;
  }
  return {};
};

export const setLocalStorage = (item = 'user', store) => {
  const data = JSON.stringify(store);
  localStorage.setItem(item, data);
};

import React from 'react';
import { CreateCircle } from 'components/icons/CreateCircle';
import { clearDeal } from 'ducks/actions/deals';
import { updateModal } from 'ducks/actions/modals';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import cn from './Navigation.module.scss';

export const DealsWithRouter = () => {
  const dispatch = useDispatch();

  const selectDeals = () => `/deals?archived=false`;

  const createDeal = () => {
    dispatch(clearDeal());
    dispatch(updateModal({ manage_deal: true }));
  };

  return (
    <>
      <div className={cn.items}>
        <Link className={cn.item} to={selectDeals}>
          Deals
        </Link>
        <div className={cn.flex} />
      </div>
      <CreateCircle tooltip="Create Deal" onClick={createDeal} />
    </>
  );
};

DealsWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export const Deals = withRouter(DealsWithRouter);

import { CLEAR_ADVERTISER_REPORTS, SET_ADVERTISER_REPORTS } from '../types';

export const advertiserReportsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ADVERTISER_REPORTS:
      return action.payload;
    case CLEAR_ADVERTISER_REPORTS:
      return [];
    default:
      return state;
  }
};

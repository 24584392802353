import * as constants from 'ducks/types';

export const setFilter = (payload = {}) => ({
  type: constants.SET_FILTER,
  payload,
});

export const clearFilter = () => ({
  type: constants.CLEAR_FILTER,
});

export const setFilters = (payload = {}) => ({
  type: constants.SET_FILTERS,
  payload,
});

export const clearFilters = () => ({
  type: constants.CLEAR_FILTERS,
});

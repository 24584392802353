import { CLEAR_SORT_DIRECTIONS_LIST, SET_SORT_DIRECTIONS_LIST } from '../types';

export const sortDirectionsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_SORT_DIRECTIONS_LIST:
      return action.payload;
    case CLEAR_SORT_DIRECTIONS_LIST:
      return [];
    default:
      return state;
  }
};

import { CLEAR_COUNTRIES_LIST, SET_COUNTRIES_LIST } from '../types';

export const countriesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_COUNTRIES_LIST:
      return action.payload;
    case CLEAR_COUNTRIES_LIST:
      return [];
    default:
      return state;
  }
};

import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeFrequencyCapIntervals = (data = []) =>
  data.map((d) => normalizeFrequencyCapInterval(d)).sort((a, b) => checkSort(a, b, 'interval_duration_seconds', 'asc'));

export const normalizeFrequencyCapInterval = (row) => ({
  ...row,
  interval_id: row.interval_id,
  interval_name: row.interval_name,
  interval_duration_seconds: row.duration_seconds,
  uuid: uuidv4(),
});

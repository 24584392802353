import React from 'react';
import { Loading } from 'components/loading/Loading';
import { Snackbar } from 'components/snackbar/Snackbar';
import { Warning } from 'components/warning/Warning';
import { Modals } from 'modals';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import cn from './Layout.module.scss';

export const PublicLayout = ({ children }) => {
  const { loading } = useSelector(
    (state) => ({
      settings: state.settings,
    }),
    shallowEqual,
  );
  return (
    <div className={cn.public}>
      <Warning />
      <div className={cn.content}>{children}</div>
      <Modals />
      <Snackbar />
      {loading && <Loading variant="dark" />}
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.element,
};

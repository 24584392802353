import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearTag } from 'ducks/actions/tags';
import { getCampaignCopyFlights } from 'ducks/operators/flights';
import { copyStrategies } from 'ducks/operators/strategies';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { replaceQuery } from 'utils/queries';

import cn from './Modal.module.scss';

const default_state = {
  destination_campaign_name: '',
  destination_campaign_id: null,
  destination_flight_name: '',
  destination_flight_id: true,
  only_active: true,
  state_id: 1,
  include_state_ids: [],
};

export const CopyStrategies = ({ history, match }) => {
  const dispatch = useDispatch();

  const { campaigns, modals, flight_strategies } = useSelector(
    (state) => ({
      campaigns: state.campaigns,
      modals: state.modals,
      flight_strategies: state.query_parameters.flight_strategies,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });
  const [flights, setFlights] = useState([]);

  useEffect(() => {
    setForm({ ...default_state });
  }, [campaigns]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const onCampaignSelect = async (item) => {
    try {
      const { flight_id } = match.params;
      const res = await dispatch(getCampaignCopyFlights(item.destination_campaign_id, { source_flight_id: flight_id }));
      setFlights(res);
    } catch (err) {
      handleError(err);
    }
    setForm((prev) => ({
      ...prev,
      ...item,
      destination_flight_id: null,
      destination_flight_name: '',
    }));
  };

  const closeModal = () => {
    // checking params to validate on tag page
    if (!match.params.tag_id) {
      dispatch(clearTag());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ copy_strategies: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { advertiser_id, flight_id } = match.params;
    const { destination_flight_id, destination_campaign_id } = form;
    try {
      const query = flight_strategies || {
        compress: true,
        strategies_active: true,
        strategies_reference: true,
        weights: false,
      };
      const data = {
        state_id: form.state_id,
        include_state_ids: form.include_state_ids,
        exclude_state_ids: [],
        source_flight_id: flight_id,
        destination_flight_id: form.destination_flight_id,
      };
      await dispatch(copyStrategies(data));
      history.push(
        `/advertisers/${advertiser_id}/campaigns/${destination_campaign_id}/flights/${destination_flight_id}/strategies${replaceQuery(
          query,
        )}`,
      );
      dispatch(updateSuccess('strategies have been successfully copied'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { destination_campaign_name, destination_flight_name, only_active } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="copy_strategies"
      show={modals.copy_strategies}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>Copy Strategies</h3>
      <form onSubmit={onSubmit}>
        <Dropdown
          label="Destination Campaign"
          options={campaigns}
          option_key="campaign_name"
          value={destination_campaign_name}
          onSelect={(item) =>
            onCampaignSelect({
              destination_campaign_id: item.campaign_id,
              destination_campaign_name: item.campaign_name,
            })
          }
        />
        <Dropdown
          label="Destination Flight"
          options={flights}
          option_key="flight_name"
          margin="15px 0px 0px 0px"
          value={destination_flight_name}
          onSelect={(item) =>
            onChange({ destination_flight_id: item.flight_id, destination_flight_name: item.flight_name })
          }
        />
        <Checkbox
          margin="15px 0px 0px 0px"
          checked={only_active}
          width="max-content"
          onClick={(checked) =>
            onChange({
              state_id: checked ? 1 : null,
              include_state_ids: checked ? [] : [1, 2, 3, 4],
              only_active: checked,
            })
          }
        >
          Copy Only Active Strategies
        </Checkbox>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="max-content" margin="10px 0px 0px 0px" type="submit">
            Copy Strategies
          </Button>
        </div>
      </form>
    </Modal>
  );
};

CopyStrategies.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import { CLEAR_REPORT_ENTITIES_LIST, SET_REPORT_ENTITIES_LIST } from '../types';

export const reportEntitiesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_REPORT_ENTITIES_LIST:
      return action.payload;
    case CLEAR_REPORT_ENTITIES_LIST:
      return [];
    default:
      return state;
  }
};

import { SET_SECURITY_AUDITS } from '../types';

export const securityAuditsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_SECURITY_AUDITS:
      return action.payload;
    default:
      return state;
  }
};

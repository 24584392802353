export const initErrorState = Object.freeze({
  new_password: {
    level: null,
    message: '',
  },
  password_confirmation: {
    level: null,
    message: '',
  },
});

export const validateForm = ({ new_password, password_confirmation }, setError) => {
  let errorPattern = { ...initErrorState };
  if (!new_password.length) {
    errorPattern = {
      ...errorPattern,
      new_password: {
        level: 3,
        message: 'Password cannot be empty',
      },
    };
  }
  if (new_password !== password_confirmation) {
    errorPattern = {
      ...errorPattern,
      password_confirmation: {
        level: 3,
        message: 'Passwords should be equal',
      },
    };
  }
  setError(errorPattern);
};

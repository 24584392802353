import { CLEAR_LOGS, SET_LOGS } from '../types';

export const logsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LOGS:
      return action.payload;
    case CLEAR_LOGS:
      return [];
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setUser = (payload = {}) => ({
  type: constants.SET_USER,
  payload,
});
export const clearUser = () => ({
  type: constants.CLEAR_USER,
});

export const setUserAdmin = (payload = {}) => ({
  type: constants.SET_USER_ADMIN,
  payload,
});
export const clearUserAdmin = () => ({
  type: constants.CLEAR_USER_ADMIN,
});
export const setUsersAdmin = (payload = {}) => ({
  type: constants.SET_ADMIN_USERS,
  payload,
});

export const clearUsersAdmin = () => ({
  type: constants.CLEAR_USERS_ADMIN,
});

import React from 'react';
import { Modal } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { clearCampaignPacingPerformance } from 'ducks/actions/campaign-pacing-performance';
import { updateModal } from 'ducks/actions/modals';
import { PacingPerformanceChart } from 'pages/campaign/overview/PacingPerformanceChart';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import cn from './Modal.module.scss';

export const ViewCampaignPerformance = () => {
  const dispatch = useDispatch();

  const { campaign_pacing_performance, line_item, modals } = useSelector(
    (state) => ({
      line_item: state.line_item,
      campaign_pacing_performance: state.campaign_pacing_performance,
      modals: state.modals,
    }),
    shallowEqual,
  );

  const closeModal = () => {
    dispatch(clearCampaignPacingPerformance());
    dispatch(updateModal({ view_campaign_performance: false }));
  };

  const { line_item_name } = line_item;
  return (
    <Modal
      name="view_campaign_performance"
      show={modals.view_campaign_performance}
      width={900}
      onClose={closeModal}
      standard={false}
      padding={0}
    >
      <h3 style={{ padding: '15px 15px 10px 15px' }}>{line_item_name}</h3>
      <Row height="350px">
        <div className={cn.chartWrapper}>
          <PacingPerformanceChart data={campaign_pacing_performance} />
        </div>
      </Row>
    </Modal>
  );
};

import { setPartners } from 'ducks/actions/partners';
import { updateSettings } from 'ducks/actions/settings';
import { normalizePartners } from 'ducks/normalizers/partners';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

export const getPartnersPage =
  (queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));
    const query = {
      include_archived: false,
      ...queries,
    };

    const [partners] = await Promise.all([dispatch(getPartners(query))]);
    await dispatch(updateSettings({ loading: false }));
    return partners;
  };

export const getPartners = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/partners/list`, data);
  const partners = await normalizePartners(res.data);
  await dispatch(setPartners(partners));
  return partners;
};

export const getFlightCreatives = (queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const query = {
    include_archived: false,
    ...queries,
  };

  if (query.include_archived) {
    query.include_state_ids = [1, 2, 3, 4];
  }

  const res = await Gemini.get(`/partners/list${replaceQuery(query)}`);
  const partners = await normalizePartners(res.data);
  await dispatch(setPartners(partners));
  return partners;
};

export const createPartner = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/partners/create', data);
  return res.data;
};

export const updatePartner = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/partners/update`, data);
  return res.data;
};

export const archivePartner = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/partners/${entity_id}/archive`);
  return res.data;
};

export const unarchivePartner = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/partners/${entity_id}/unarchive`);
  return res.data;
};

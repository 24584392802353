import React from 'react';
import { Tooltip } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import cn from './Cells.module.scss';

export const Action = ({ height, color, tooltip, icon, position, caret, font_size, width }) => (
  <div className={cn.action} style={{ height, fontSize: font_size, width }}>
    <FontAwesomeIcon icon={icon} className={classNames(cn[color])} />
    {tooltip && <Tooltip tooltip={tooltip} caret={caret} width="max-content" position={position} />}
  </div>
);

Action.defaultProps = {
  height: 30,
  font_size: 11,
  color: null,
  tooltip: null,
  width: null,
  caret: 'right',
  position: { left: null, right: 50 },
  icon: ['fad', 'circle'],
};
Action.propTypes = {
  height: PropTypes.any,
  tooltip: PropTypes.any,
  icon: PropTypes.any,
  font_size: PropTypes.number,
  color: PropTypes.string,
  caret: PropTypes.string,
  position: PropTypes.object,
};

import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeFeatureDefinitions = (data) =>
  data
    .filter((row) => row.is_list)
    .map((row) => normalizeFeatureDefinition(row))
    .sort((a, b) => checkSort(a, b, 'feature_definition_name', 'asc'));

export const normalizeFeatureDefinition = (row) => {
  switch (row.feature_definition_id) {
    case 5: // postal
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };
    case 8: // app identifier
      return {
        ...row,
        is_supported: false,
        support_list: false,
        support_exclude_list: false,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };
    case 40: // browser
      return {
        ...row,
        is_supported: false,
        support_list: false,
        support_exclude_list: false,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };
    case 30: // countries
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };
    case 31: // region
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };
    case 32: // metro areas
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };
    case 33: // cities
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };

    case 42: // device
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };
    case 7: // domains
      return {
        ...row,
        is_supported: true,
        support_list: true,
        support_exclude_list: true,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };
    case 107: // video
      return {
        ...row,
        is_supported: false,
        support_list: false,
        support_exclude_list: false,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };
    case 154: // segments
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: true,
        support_exclude_features: true,
        uuid: uuidv4(),
      };
    case 157: // regex
      return {
        ...row,
        is_supported: true,
        support_list: true,
        support_exclude_list: true,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };
    case 158: // custom input
      return {
        ...row,
        is_supported: true,
        support_list: false,
        support_exclude_list: false,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };

    default:
      return {
        ...row,
        is_supported: false,
        support_list: false,
        support_exclude_list: false,
        support_features: false,
        support_exclude_features: false,
        uuid: uuidv4(),
      };
  }
};

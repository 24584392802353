import { CLEAR_SLACK_CHANNELS, SET_SLACK_CHANNELS } from '../types';

export const slackChannelsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_SLACK_CHANNELS:
      return action.payload;
    case CLEAR_SLACK_CHANNELS:
      return [];
    default:
      return state;
  }
};

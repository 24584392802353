import { Gemini } from 'utils/axios';

import { setRoles } from '../actions/roles';
import { updateSettings } from '../actions/settings';
import { normalizeRoles } from '../normalizers/roles';

export const getRoles = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/roles`);
  const roles = await normalizeRoles(res.data);
  await dispatch(setRoles(roles));
  return roles;
};

import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearUserAdmin } from 'ducks/actions/users';
import { createUser, updateUser } from 'ducks/operators/users';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { initErrorState, validateForm } from 'utils/modal/adminUserModal';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const initState = (form = {}) => ({
  display_name: form?.display_name,
  email_address: form?.email_address,
  user_id: form?.user_id,
  organization_id: form?.organization_id || 1,
  organization_name: form?.organization_name || 'Cognitiv',
  role_id: form?.role_id || null,
  role_name: form?.role_name || '',
  password: '',
});

export const ManageUser = ({ location, history, match }) => {
  const dispatch = useDispatch();
  const { modal, user_admin, organizations, roles } = useSelector((state) => ({
    modal: state.modals,
    user_admin: state.user_admin,
    roles: state.roles,
    organizations: state.organizations,
  }));

  const [form, setForm] = useState(() => initState(user_admin));
  const [validation, setValidation] = useState(initErrorState);

  useEffect(() => {
    setForm(() => initState(user_admin));
  }, [user_admin]);

  useEffect(() => {
    validateForm(form, setValidation);
  }, [form]);

  const closeModal = () => {
    // checking params to validate on user page
    if (!match.params.user_id) {
      dispatch(clearUserAdmin());
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_user: false }));
  };

  const handleSubmit = async () => {
    try {
      const { search, pathname } = location;
      const { user_id } = user_admin;

      const { display_name, email_address, organization_id, role_id, password } = form;
      const queries = mergeQuery(search, { update: uuidv4() });

      if (user_id) {
        await dispatch(updateUser(user_id, { display_name, email_address, organization_id, role_id }));
      } else {
        await dispatch(createUser({ display_name, email_address, password, organization_id, role_id }));
      }

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('User has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const onChange = (value, name) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSelect = (item = {}) => {
    setForm((prev) => ({ ...prev, ...item }));
  };

  const { user_id } = user_admin;
  const { organization_name, display_name, email_address, role_name, password } = form;
  return (
    <Modal padding={16} close_box={46} name="manage_user" show={modal?.manage_user} width={450} onClose={closeModal}>
      <h3>{user_id ? 'Update User' : 'Create User'}</h3>
      <div>
        <Dropdown
          label="Organization"
          options={organizations}
          option_key="organization_name"
          margin="0px 0px 15px 0px"
          value={organization_name}
          onSelect={(item) =>
            onSelect({ organization_name: item.organization_name, organization_id: item.organization_id })
          }
        />
        <Dropdown
          label="Role"
          options={roles}
          option_key="role_name"
          margin="0px 0px 15px 0px"
          value={role_name}
          onSelect={(item) => onSelect({ role_name: item.role_name, role_id: item.role_id })}
        />

        <Input
          label="Display Name"
          margin="0px 0px 15px 0px"
          value={display_name}
          onChange={(value) => onChange(value, 'display_name')}
          error_level={validation.display_name.level}
          error_message={validation.display_name.message}
        />
        <Input
          label="Email Address"
          value={email_address}
          margin="0px 0px 15px 0px"
          onChange={(value) => onChange(value, 'email_address')}
          error_level={validation.email_address.level}
          error_message={validation.email_address.message}
        />
        {!user_id && (
          <Input
            label="Password"
            value={password}
            type="password"
            onChange={(value) => onChange(value, 'password')}
            error_level={validation.password.level}
            error_message={validation.password.message}
          />
        )}
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" onClick={handleSubmit}>
            {user_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ManageUser.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

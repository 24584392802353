import axios from 'axios';
import cryptoJS from 'crypto-js';
import { getStorage } from 'utils/storage';

import { onError, onSuccess } from './errors';
const { REACT_APP_SOL, REACT_APP_GEMINI, REACT_APP_HYPERION, REACT_APP_TITAN } = process.env;

const SOL_SERVICES = {
  SOL: 1,
  GEMINI: 2,
  HYPERION: 3,
  NOVA: 4,
  ORION: 7,
  ANDROMEDA: 8,
  TITAN: 9,
  LIBRA: 10,
};

class Axios {
  private_key = '';
  public_key = '';
  host = '';

  constructor(host) {
    const storage = getStorage();

    this.private_key = storage.private_key || '';
    this.public_key = storage.public_key || '';
    this.host = host;
  }

  persistKeys({ private_key, public_key }) {
    this.private_key = private_key || '';
    this.public_key = public_key || '';
  }

  generateHeaders(url) {
    const hash = cryptoJS.HmacSHA256(url, this.private_key).toString().replace('-', '').toLowerCase();
    return {
      'Content-Type': 'application/json',
      public_key: this.public_key,
      hash,
    };
  }

  async auth(data) {
    data.required_service_ids = [SOL_SERVICES.GEMINI, SOL_SERVICES.HYPERION];
    const res = await this.post('/authentication/login', data);
    this.private_key = res.data.private_key;
    this.public_key = res.data.public_key;
    return res;
  }

  async get(path, mock) {
    const paths = path.split('?');
    const [url] = paths;
    const headers = this.generateHeaders(url || '');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${this.host}${paths.join('?')}`,
        headers,
      })
        .then((res) => onSuccess(res, resolve, reject, mock))
        .catch((err) => onError(err, reject));
    });
  }

  async post(path, payload = {}, mock) {
    const paths = path.split('?');
    const [url] = paths;
    const string = JSON.stringify(payload);
    const headers = this.generateHeaders(`${url || ''}${string}`);
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        headers,
        url: `${this.host}${paths.join('?')}`,
        data: payload,
      })
        .then((res) => onSuccess(res, resolve, reject, mock))
        .catch((err) => onError(err, reject));
    });
  }
}

export const Sol = new Axios(REACT_APP_SOL);
export const Gemini = new Axios(REACT_APP_GEMINI);
export const Hyperion = new Axios(REACT_APP_HYPERION);
export const Titan = new Axios(REACT_APP_TITAN);
import { setCreativeContents } from 'ducks/actions/creative-contents';
import { updateSettings } from 'ducks/actions/settings';
import { Gemini } from 'utils/axios';
import { uniqueIdentifiers } from 'utils/helpers';

export const getCreativeContents = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/advertisers/${entity_id}/creative_contents`);
  const creative_contents = uniqueIdentifiers(res.data);
  await dispatch(setCreativeContents(creative_contents));
  return creative_contents;
};

import { clearModals } from 'ducks/actions/modals';
import { clearSettings, updateSettings } from 'ducks/actions/settings';
import { clearUser, setUser, setUsersAdmin } from 'ducks/actions/users';
import { normalizeAdminUsers } from 'ducks/normalizers/admin';
import { normalizeUser } from 'ducks/normalizers/users';
import { Titan, Gemini, Hyperion, Sol } from 'utils/axios';
import { replaceQuery } from 'utils/queries';
import { deleteStorage } from 'utils/storage';

export const AUTHENTICATION_RESULTS = {
  Unknown: 0,
  Success: 1,
  InvalidLogin: 2,
  AccountInactive: 3,
  AccountLocked: 4,
  InvalidPassword: 5,
  InvalidOrganization: 6,
  HashMismatch: 7,
  InvalidPrivateKey: 8,
  NoDefaultOrganization: 9,
  ServiceDeniedLogin: 10,
};

export const AUTHENTICATION_ERRORS = {
  0: 'unknown',
  1: 'success login',
  2: 'invalid login attempt',
  3: 'account is inactive',
  4: 'account is locked',
  5: 'invalid password',
  6: 'invalid organization',
  7: 'hash mismatch',
  8: 'invalid private key',
  9: 'no default organization',
  10: 'service denied login',
};

export const activateUser = (user_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/users/${user_id}/activate`);
  return res.data;
};

export const updateUser = (user_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/users/${user_id}/update`, data);
  return res;
};

export const createUser = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/users/create', data);
  return res;
};

export const updateUserPassword =
  (user_id, data = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));
      const res = await Gemini.post(`/users/${user_id}/admin/update_password`, { ...data });
      return res;
    };

export const deactivateUser = (user_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/users/${user_id}/deactivate`);
  return res.data;
};

export const lockUser = (user_id) => async () => {
  const res = await Gemini.post(`/users/${user_id}/lock`);
  return res.data;
};

export const resetUser = (user_id) => async () => {
  const res = await Gemini.post(`/users/${user_id}/reset_password`);
  return res.data;
};

export const unlockUser = (user_id) => async () => {
  const res = await Gemini.post(`/users/${user_id}/unlock`);
  return res.data;
};

export const getUsers =
  ({ organization_id }) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));

      const res = await Gemini.get(`/users${replaceQuery({ organization_id })}`);
      const users = await normalizeAdminUsers(res.data);
      await dispatch(setUsersAdmin(users));
      return res.data;
    };

export const authUser = (data) => async (dispatch) => {
  const res = await Sol.auth(data);

  const { authentication_result, public_key, private_key } = res.data;

  if (authentication_result === AUTHENTICATION_RESULTS.Success) {
    Gemini.persistKeys({ public_key, private_key });
    Hyperion.persistKeys({ public_key, private_key });
    Titan.persistKeys({ public_key, private_key });

    const user = await normalizeUser(res.data);
    await dispatch(clearSettings());
    await dispatch(clearModals());
    await dispatch(setUser(user));
    return user;
  }
  throw new Error(AUTHENTICATION_ERRORS[authentication_result] || 'Invalid response from the API');
};

export const logoutUser = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  try {
    await dispatch(clearUser());
    deleteStorage();
  } catch {
    throw new Error('Unable to clear store');
  }
};

export const requestUserReset = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/authentication/reset_password`, data);
  return res.data;
};

export const resetUserPassword =
  ({ code, password }) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));
      const res = await Gemini.post(`/authentication/reset_password/${code}`, { password });
      return res.data;
    };

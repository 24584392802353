import { CLEAR_CREATIVE_EXTRAS, SET_CREATIVE_EXTRAS } from '../types';

export const creativeExtrasReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CREATIVE_EXTRAS:
      return action.payload;
    case CLEAR_CREATIVE_EXTRAS:
      return [];
    default:
      return state;
  }
};

import { v4 as uuidv4 } from 'uuid';

export function normalizeMeasureUnits(data) {
  return data.map((d) => normalizeMeasureUnit(d));
}

export function normalizeMeasureUnit(measure_unit) {
  const { id } = measure_unit;
  let measure_unit_unformatted;
  let measure_unit_abbreviated;
  let measure_unit_format;
  let measure_unit_charts;

  switch (id) {
    case 1:
      measure_unit_unformatted = 'unformatted';
      measure_unit_abbreviated = 'number-abbreviated';
      measure_unit_charts = 'number-abbreviated';
      measure_unit_format = 'number';
      break;
    case 2:
      measure_unit_unformatted = 'unformatted';
      measure_unit_abbreviated = 'currency-abbreviated';
      measure_unit_charts = 'currency-dynamic';
      measure_unit_format = 'currency';
      break;
    case 3:
      measure_unit_unformatted = 'unformatted';
      measure_unit_abbreviated = 'percentage-abbreviated';
      measure_unit_charts = 'percentage-decimals';
      measure_unit_format = 'percentage';
      break;
    case 4:
      measure_unit_unformatted = 'unformatted';
      measure_unit_abbreviated = 'rate';
      measure_unit_charts = 'rate';
      measure_unit_format = 'rate';
      break;
    default:
      measure_unit_format = '';
  }
  return {
    ...measure_unit,
    measure_unit_unformatted,
    measure_unit_abbreviated,
    measure_unit_format,
    measure_unit_charts,
    uuid: uuidv4(),
  };
}

export function normalizeMeasureTypes(measure_types) {
  const masks = ['number-abbreviated', 'currency-abbreviated', 'percent-abbreviated', 'rate-abbreviated'];
  const formats = ['number', 'currency', 'percentage', 'rate'];
  return measure_types
    .sort((a, b) => (a.measure_type_name.toLowerCase() > b.measure_type_name.toLowerCase() ? 1 : -1))
    .map((measure_type) => ({
      ...measure_type,
      measure_type_abbreviated: masks[measure_type.measure_unit_id - 1],
      measure_type_format: formats[measure_type.measure_unit_id - 1],
      uuid: uuidv4(),
    }));
}

import * as constants from 'ducks/types';

export const setOutputs = (payload = {}) => ({
  type: constants.SET_OUTPUTS,
  payload,
});

export const clearOutputs = () => ({
  type: constants.CLEAR_OUTPUTS,
});

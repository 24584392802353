import * as constants from 'ducks/types';

export const setLineItemDcpm = (payload = []) => ({
  type: constants.SET_LINE_ITEM_DCPM,
  payload,
});

export const clearLineItemDcpm = () => ({
  type: constants.CLEAR_LINE_ITEM_DCPM,
});

export const setLineItemDcpmPreviewed = (payload = []) => ({
  type: constants.SET_LINE_ITEM_DCPM_PREVIEWED,
  payload,
});

export const clearLineItemDcpmPreviewed = () => ({
  type: constants.CLEAR_LINE_ITEM_DCPM_PREVIEWED,
});

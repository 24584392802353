import * as constants from 'ducks/types';

export const setTitanKpiTypes = (payload = []) => ({
    type: constants.SET_TITAN_KPI_TYPES,
    payload,
});

export const clearTitanKpiTypes = () => ({
    type: constants.CLEAR_TITAN_KPI_TYPES,
});

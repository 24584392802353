import moment from 'moment';

export const formatDate = (date, option = {}) => {
  const options = {
    format: 'MM/DD/YYYY HH:mm',
    utc: true,
    ...option,
  };

  const { format, utc } = options;

  if (utc) {
    return moment(date).utc().format(format);
  }
  return moment(date).format(format);
};

export const isBeforeDate = (date, compare_date = new Date()) => moment(date).utc() < moment(compare_date).utc();

export const compareDates = (a, b, option = {}) => {
  const options = {
    precision: 'minute',
    ...option,
  };
  const { precision } = options;
  return moment(a).isBefore(b, precision);
};

export const validDate = (date, option = {}) => {
  const options = {
    format: 'MM/DD/YYYY HH:mm',
    ...option,
  };
  const valid = moment(date, options.format).isValid();
  return valid;
};

export const compareDateRange = (a, b, c, option = {}) => {
  // a === start date && b === current date && c === finish date
  const options = {
    precision: 'minute',
    ...option,
  };
  const { precision } = options;
  return moment(a).isBefore(b, precision) && moment(b).isBefore(c, precision);
};

export const differenceDates = (a, b, option = {}) => {
  const options = {
    precision: 'hours',
    ...option,
  };
  const { precision } = options;
  const a_diff = moment(a);
  const b_diff = moment(b);
  return b_diff.diff(a_diff, precision);
};

export const getEndDateType = (date, option) => {
  const options = {
    type: 'days',
    decrement: 0,
    start_type: 'quarter',
    format: 'MM/DD/YYYY',
    ...option,
  };
  const { type, start_type, decrement, format } = options;
  return moment(date).subtract(decrement, type).startOf(start_type).subtract(1, 'days').format(format);
};

export const getStartDateType = (date, option) => {
  const options = {
    type: 'days',
    decrement: 1,
    start_type: 'quarter',
    format: 'MM/DD/YYYY',
    ...option,
  };
  const { type, start_type, decrement, format } = options;
  return moment(date).subtract(decrement, type).startOf(start_type).format(format);
};

export const getStartDateIncrement = (date, option = {}) => {
  const options = {
    type: 'days',
    format: 'MM/DD/YYYY',
    ...option,
  };
  const { type, format } = options;
  return moment(date).startOf(type).format(format);
};

export const addToDate = (date, option = {}) => {
  const options = {
    increment: 0,
    type: 'days',
    utc: true,
    format: 'M/D/YYYY',
    ...option,
  };
  const { increment, type, format, utc } = options;
  if (utc) {
    return moment(date).add(increment, type).utc().format(format);
  }
  return moment(date).add(increment, type).format(format);
};

export const subtractFromDate = (date, option = {}) => {
  const options = {
    decrement: 0,
    type: 'days',
    utc: true,
    format: 'M/D/YYYY',
    ...option,
  };
  const { decrement, type, format, utc } = options;
  if (utc) {
    return moment(date).subtract(decrement, type).utc().format(format);
  }
  return moment(date).subtract(decrement, type).format(format);
};

export const offsetTimezoneLocal = (date, option = {}) => {
  const options = {
    format: 'M/D/YYYY hh:mm A',
    local_timezone: true,
    decrement: 0,
    type: 'days',
    ...option,
  };
  const { format, local_timezone, decrement, type } = options;

  const valid = moment(date).isValid();

  if (!valid || !date) {
    return null;
  }

  if (local_timezone) {
    return moment(date).subtract(decrement, type).utc().format(format);
  }
  return moment(date).subtract(decrement, type).format(format);
};

export const offsetTimezoneUTC = (date, option = {}) => {
  const options = {
    format: 'M/D/YYYY hh:mm A',
    local_timezone: true,
    decrement: 0,
    type: 'days',
    ...option,
  };
  const { format, local_timezone, decrement, type } = options;

  const valid = moment(date).isValid();

  if (!valid || !date) {
    return null;
  }

  const offset = -(moment(date, format).toDate().getTimezoneOffset() / 60);

  if (local_timezone) {
    return moment(date).add(offset, 'hours').subtract(decrement, type).format(format);
  }
  return moment(date).subtract(decrement, type).format(format);
};

export const getClosestDateBasedOnDayNumber = (day) => {
  const now = moment();
  const max_days = now.daysInMonth();
  const target_date = moment(now).date(day);
  const is_past = target_date.isBefore(now);

  if (day > max_days) {
    return moment(now).endOf('month');
  } else if (is_past) {
    return moment(now).add(1, 'month').date(day);
  } else {
    return target_date;
  }
};

export const getTimeFromDateTime = (datetime) => {
  const arr = datetime.split(' ');
  arr.shift();
  return arr.join(' ');
};

export const getClosestDate = (start_date, available_weekdays) => {
  const current_weekday = start_date.weekday();

  let next_available_weekday;
  for (let i = 0; i < 7; i++) {
    const weekday = (current_weekday + i) % 7;
    if (available_weekdays.includes(weekday)) {
      next_available_weekday = weekday;
      break;
    }
  }

  const days_until_next_available_weekday = (next_available_weekday + 7 - current_weekday) % 7;
  const closest_date = start_date.add(days_until_next_available_weekday, 'days');

  return closest_date;
};

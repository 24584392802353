import { CLEAR_ORGANIZATIONS, SET_ORGANIZATIONS } from '../types';

export const organizationsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return action.payload;
    case CLEAR_ORGANIZATIONS:
      return [];
    default:
      return state;
  }
};

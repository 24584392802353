import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export function normalizeMeasureTypes(data) {
  return data.map((d) => normalizeMeasureType(d)).sort((a, b) => checkSort(a, b, 'measure_type_name', 'asc'));
}

export function normalizeMeasureType(measure_type) {
  const masks = ['number-abbreviated', 'currency-abbreviated', 'percent-abbreviated', 'rate-abbreviated'];
  const formats = ['number', 'currency', 'percentage', 'rate'];
  return {
    ...measure_type,
    measure_type_abbreviated: masks[measure_type.measure_unit_id - 1],
    measure_type_format: formats[measure_type.measure_unit_id - 1],
    uuid: uuidv4(),
  };
}

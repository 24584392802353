import * as constants from 'ducks/types';

export const setChangeOrder = (payload = {}) => ({
  type: constants.SET_CHANGE_ORDER,
  payload,
});

export const updateChangeOrder = (payload = {}) => ({
  type: constants.UPDATE_CHANGE_ORDER,
  payload,
});

export const clearChangeOrder = () => ({
  type: constants.CLEAR_CHANGE_ORDER,
});

export const setChangeOrders = (payload = []) => ({
  type: constants.SET_CHANGE_ORDERS,
  payload,
});

export const clearChangeOrders = () => ({
  type: constants.CLEAR_CHANGE_ORDERS,
});

export const setChangeOrderValidation = (payload = []) => ({
  type: constants.SET_CHANGE_ORDER_VALIDATION,
  payload,
});

export const clearChangeOrderValidation = () => ({
  type: constants.CLEAR_CHANGE_ORDER_VALIDATION,
});

import { CLEAR_LINE_ITEM_CREATIVES, SET_LINE_ITEM_CREATIVES } from '../types';

export const lineItemCreativesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LINE_ITEM_CREATIVES:
      return action.payload;
    case CLEAR_LINE_ITEM_CREATIVES:
      return [];
    default:
      return state;
  }
};

export const abTestSegmentValidation = (segment) => {
  if (!segment.segment_name) {
    return false;
  }
  if (!segment.ab_test_id) {
    return false;
  }
  if (!segment.tag_ids) {
    return false;
  }
  if (!segment.identity_type_name) {
    return false;
  }
  return true;
};

import { CLEAR_POSTAL_CODES_LIST, SET_POSTAL_CODES_LIST } from '../types';

export const postalCodesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_POSTAL_CODES_LIST:
      return action.payload;
    case CLEAR_POSTAL_CODES_LIST:
      return [];
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setLineItemsExternal = (payload = {}) => ({
  type: constants.SET_LINE_ITEMS_EXTERNAL,
  payload,
});

export const clearLineItemsExternal = () => ({
  type: constants.CLEAR_LINE_ITEMS_EXTERNAL,
});

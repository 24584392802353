import * as constants from 'ducks/types';

export const setCampaign = (payload = {}) => ({
  type: constants.SET_CAMPAIGN,
  payload,
});

export const clearCampaign = () => ({
  type: constants.CLEAR_CAMPAIGN,
});

export const setCampaigns = (payload = {}) => ({
  type: constants.SET_CAMPAIGNS,
  payload,
});

export const clearCampaigns = () => ({
  type: constants.CLEAR_CAMPAIGNS,
});

export const routes = {
  ROOT: '/',
  LOGIN: '/login',
  HEALTH: '/health',
  PASSWORD_REQUEST: '/request-password',
  PASSWORD_RESET: '/reset-password/:secret_code',
  ADVERTISERS: '/advertisers',
  ADVERTISER: '/advertisers/:advertiser_id/:page',
  ADVERTISER_AUDIT_LOG: '/advertisers/:advertiser_id/audit-log',
  ADVERTISER_REPORTS: '/advertisers/:advertiser_id/reports',
  ADVERTISER_REPORT: '/advertisers/:advertiser_id/reports/:report_id/overview',
  ADVERTISER_OVERVIEW: '/advertisers/:advertiser_id/overview',
  ADVERTISER_CREATIVES: '/advertisers/:advertiser_id/creatives',
  ADVERTISER_FILTERS: '/advertisers/:advertiser_id/filters',
  ADVERTISER_LISTS: '/advertisers/:advertiser_id/lists',
  ADVERTISER_TAGS_PAGE: '/advertisers/:advertiser_id/tags',
  TAG: '/advertisers/:advertiser_id/tags/:tag_id/:page',
  TAG_CONFIGURATION: '/advertisers/:advertiser_id/tags/:tag_id/configuration',
  FILTER: '/advertisers/:advertiser_id/filters/:filter_id/:page',
  FILTER_FEATURES: '/advertisers/:advertiser_id/filters/:filter_id/features',
  FILTER_EXCLUDE: '/advertisers/:advertiser_id/filters/:filter_id/exclude',
  FILTER_CUSTOM: '/advertisers/:advertiser_id/filters/:filter_id/custom',
  FILTER_AUDIT: '/advertisers/:advertiser_id/filters/:filter_id/audit',
  CAMPAIGN: '/advertisers/:advertiser_id/campaigns/:campaign_id/:page',
  CAMPAIGN_OVERVIEW: '/advertisers/:advertiser_id/campaigns/:campaign_id/overview',
  CAMPAIGN_AUDIT_LOG: '/advertisers/:advertiser_id/campaigns/:campaign_id/audit-log',
  CAMPAIGN_PACING: '/advertisers/:advertiser_id/campaigns/:campaign_id/pacing',
  CAMPAIGN_FLIGHTS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights',
  CAMPAIGN_BUDGET: '/advertisers/:advertiser_id/campaigns/:campaign_id/budget',
  CAMPAIGN_KPI_GROUP: '/advertisers/:advertiser_id/campaigns/:campaign_id/kpi-group',
  CAMPAIGN_THIRD_PARTY_REPORTS: '/advertisers/:advertiser_id/campaigns/:campaign_id/third-party-reports',
  FLIGHT_COPY: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights-copy/:flight_id/:page',
  FLIGHT_COPY_LINE_ITEMS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights-copy/:flight_id/line-items',
  FLIGHT_COPY_CREATIVES: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights-copy/:flight_id/creatives',
  FLIGHT: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/:page',
  FLIGHT_LINE_ITEMS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items',
  FLIGHT_CREATIVES: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/creatives',
  FLIGHT_STRATEGIES: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/strategies',
  FLIGHT_CHANGE_ORDERS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/change-orders',
  FLIGHT_EXPERIMENTS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/experiments',
  FLIGHT_AB_TESTS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/ab-tests',
  FLIGHT_AB_TEST_SEGMENTS: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/ab-segments',
  LINE_ITEM: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/:page',
  LINE_ITEM_CONFIGURATION:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/configuration',
  LINE_ITEM_FREQUENCY_CAPS:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/frequency-caps',
  LINE_ITEM_CREATIVES:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/creatives',
  LINE_ITEM_STRATEGIES:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/strategies',
  LINE_ITEM_DEALS:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/deals',
  LINE_ITEM_VENDOR_FEES:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/vendor-fees',
  LINE_ITEM_FILTER:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/filter',
  LINE_ITEM_DCPM: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/line-items/:line_item_id/dcpm',
  CREATIVE: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/creatives/:creative_id/:page',
  CREATIVE_CONFIGURATION:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/creatives/:creative_id/configuration',
  CREATIVE_LINE_ITEMS:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/creatives/:creative_id/creative-line-items',
  CREATIVE_PRICES: '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/creatives/:creative_id/cpm',
  CREATIVE_PIXELS:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/creatives/:creative_id/pixels',
  CHANGE_ORDER:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/change-orders/:change_order_id/:page',
  CHANGE_ORDER_CONFIGURATION:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/change-orders/:change_order_id/configuration',
  CHANGE_ORDER_EXPERIMENTS:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/change-orders/:change_order_id/experiments',
  CHANGE_ORDER_AUDIT:
    '/advertisers/:advertiser_id/campaigns/:campaign_id/flights/:flight_id/change-orders/:change_order_id/audit',
  PARTNERS: '/partners',
  DEALS: '/deals',
  VENDOR_FEES: '/vendor-fees',
  DASHBOARDS: '/dashboards',
  DASHBOARD: '/dashboards/:dashboard_id/dashboard',
  REPORTS: '/reports',
  REPORT: '/reports/:report_id/report',
  PARAMETERS: '/parameters/parameters',
  REPORT_PARAMETERS: '/parameters/report-parameters',
  STRATEGY_TEMPLATES: '/strategy-templates',
  ADMIN: '/admin/:admin',
  ADMIN_USER: '/admin/users',
  ADMIN_SECURITY_AUDIT: '/admin/security-audit',
  MODELS: '/models',
};

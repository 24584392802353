import { CLEAR_PARAMETER_TYPES, SET_PARAMETER_TYPES } from '../types';

export const parameterTypesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PARAMETER_TYPES:
      return action.payload;
    case CLEAR_PARAMETER_TYPES:
      return [];
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearUserAdmin } from 'ducks/actions/users';
import { updateUserPassword } from 'ducks/operators/users';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { initErrorState, validateForm } from 'utils/modal/changePassword';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const initState = Object.freeze({
  new_password: '',
  password_confirmation: '',
});

export const ChangePassword = ({ location, history, match }) => {
  const dispatch = useDispatch();
  const { modals, user_admin } = useSelector((state) => ({ modals: state.modals, user_admin: state.user_admin }));
  const [form, setForm] = useState(initState);
  const [validation, setValidation] = useState(initErrorState);

  useEffect(() => {
    validateForm(form, setValidation);
  }, [form]);

  const closeModal = () => {
    // checking params to validate on user page
    if (!match.params.user_id) {
      dispatch(clearUserAdmin());
    }

    setForm(initState);
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ change_password: false }));
  };

  const handleChange = (value, name) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const { search, pathname } = location;
      const { user_id } = user_admin;

      const { new_password } = form;
      const queries = mergeQuery(search, { update: uuidv4() });
      await dispatch(updateUserPassword(user_id, { new_password }));

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('User has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  return (
    <Modal
      padding={16}
      close_box={46}
      name="change_password"
      show={modals.change_password}
      width={400}
      onClose={closeModal}
    >
      <h3>Change Password</h3>
      <div>
        <Input
          type="password"
          label="New Password"
          value={form.new_password}
          onChange={(value) => handleChange(value, 'new_password')}
          error_level={validation.new_password.level}
          error_message={validation.new_password.message}
        />
        <Input
          type="password"
          label="Confirm Password"
          margin="15px 0px 0px 0px"
          value={form.password_confirmation}
          onChange={(value) => handleChange(value, 'password_confirmation')}
          error_level={validation.password_confirmation.level}
          error_message={validation.password_confirmation.message}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button
            disabled={validation.new_password.level || validation.password_confirmation.level}
            button_size="small"
            width="100px"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ChangePassword.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

import * as constants from 'ducks/types';

export const setLineItem = (payload = {}) => ({
  type: constants.SET_LINE_ITEM,
  payload,
});

export const updateLineItem = (payload = {}) => ({
  type: constants.UPDATE_LINE_ITEM,
  payload,
});

export const clearLineItem = () => ({
  type: constants.CLEAR_LINE_ITEM,
});

export const setLineItems = (payload = {}) => ({
  type: constants.SET_LINE_ITEMS,
  payload,
});

export const clearLineItems = () => ({
  type: constants.CLEAR_LINE_ITEMS,
});

import { CLEAR_STRATEGY_TEMPLATES, INSERT_STRATEGY_TEMPLATE, SET_STRATEGY_TEMPLATES } from 'ducks/types';

export const strategyTemplatesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_STRATEGY_TEMPLATES:
      return action.payload;
    case INSERT_STRATEGY_TEMPLATE: {
      const strategies_templates = [...state];
      const strategy_template = action.payload;
      strategies_templates.push(strategy_template);
      return strategies_templates;
    }
    case CLEAR_STRATEGY_TEMPLATES:
      return [];
    default:
      return state;
  }
};

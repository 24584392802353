import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownCalendar, Input, Modal } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { clearThirdPartyReport } from 'ducks/actions/third-party-reports';
import { createCampaignThirdPartyReport, updateCampaignThirdPartyReport } from 'ducks/operators/third-party-reports';
import { thirdPartyReportValidation } from 'ducks/validations/third-party-reports';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  third_party_report_id: null,
  third_party_report_name: '',
  third_party_report_source_id: null,
  third_party_report_source_name: '',
  third_party_report_cadence_id: null,
  third_party_report_cadence_name: '',
  third_party_report_delivery_id: null,
  third_party_report_delivery_name: '',
  description: '',
  initial_expected_delivery_date: null,
  s3_location: '',
};

export const ManageThirdPartyReport = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const {
    modals,
    third_party_report,
    third_party_report_cadences_list,
    third_party_report_deliveries_list,
    third_party_report_sources_list,
  } = useSelector((state) => ({
    third_party_report: state.third_party_report,
    third_party_report_cadences_list: state.third_party_report_cadences_list,
    third_party_report_deliveries_list: state.third_party_report_deliveries_list,
    third_party_report_sources_list: state.third_party_report_sources_list,
    modals: state.modals,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...third_party_report });
  }, [third_party_report]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on third party report page
    if (!match.params.third_party_report_id) {
      dispatch(clearThirdPartyReport());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_third_party_report: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { campaign_id } = match.params;
    const { third_party_report_id } = third_party_report;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        third_party_report_id: form.third_party_report_id,
        third_party_report_name: form.third_party_report_name,
        description: form.description,
        third_party_report_source_id: form.third_party_report_source_id,
        third_party_report_cadence_id: form.third_party_report_cadence_id,
        third_party_report_delivery_id: form.third_party_report_delivery_id,
        initial_expected_delivery_date: form.initial_expected_delivery_date,
        s3_location: form.s3_location,
      };

      if (third_party_report_id) {
        await dispatch(updateCampaignThirdPartyReport(campaign_id, data));
      }
      if (!third_party_report_id) {
        await dispatch(createCampaignThirdPartyReport(campaign_id, data));
      }
      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('third party report has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const {
    third_party_report_id,
    third_party_report_name,
    third_party_report_source_name,
    third_party_report_cadence_name,
    third_party_report_delivery_name,
    description,
    initial_expected_delivery_date_formatted,
    s3_location,
  } = form;

  const disabled = thirdPartyReportValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_third_party_report"
      show={modals.manage_third_party_report}
      width={500}
      onClose={closeModal}
      standard={false}
    >
      <h3>{third_party_report_id ? 'Update Third Party Report' : 'Create Third Party Report'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          label="Name"
          auto_focus
          value={third_party_report_name}
          onChange={(value) => onChange({ third_party_report_name: value })}
        />
        <Input
          label="Description"
          value={description}
          margin="15px 0px 0px 0px"
          onChange={(value) => onChange({ description: value })}
        />
        <Dropdown
          label="Source"
          options={third_party_report_sources_list}
          option_key="name"
          value={third_party_report_source_name}
          margin="15px 0px 0px 0px"
          onSelect={(item) =>
            onChange({ third_party_report_source_name: item.name, third_party_report_source_id: item.id })
          }
        />
        <Dropdown
          label="Cadence"
          options={third_party_report_cadences_list}
          option_key="name"
          value={third_party_report_cadence_name}
          margin="15px 0px 0px 0px"
          onSelect={(item) =>
            onChange({ third_party_report_cadence_name: item.name, third_party_report_cadence_id: item.id })
          }
        />
        <Dropdown
          label="Delivery"
          options={third_party_report_deliveries_list}
          option_key="name"
          value={third_party_report_delivery_name}
          margin="15px 0px 0px 0px"
          onSelect={(item) =>
            onChange({ third_party_report_delivery_name: item.name, third_party_report_delivery_id: item.id })
          }
        />
        <Input
          label="S3 Location"
          value={s3_location}
          margin="15px 0px 0px 0px"
          onChange={(value) => onChange({ s3_location: value })}
        />
        <DropdownCalendar
          label="Finish Date"
          selected_date={initial_expected_delivery_date_formatted}
          onSelect={(date) =>
            onChange({
              initial_expected_delivery_date: date.selected_date,
              initial_expected_delivery_date_formatted: date.selected_date,
            })
          }
          margin="15px 0px 0px 0px"
          calendar_display={false}
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            {third_party_report_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageThirdPartyReport.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import { CLEAR_SEGMENT, SET_SEGMENT } from '../types';

export const segmentReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SEGMENT:
      return action.payload;
    case CLEAR_SEGMENT:
      return {};
    default:
      return state;
  }
};

import { CLEAR_FEATURE_DEFINITIONS_LIST, SET_FEATURE_DEFINITIONS_LIST } from '../types';

export const featureDefinitionsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FEATURE_DEFINITIONS_LIST:
      return action.payload;
    case CLEAR_FEATURE_DEFINITIONS_LIST:
      return [];
    default:
      return state;
  }
};

/* eslint-disable no-useless-escape */
export const initErrorState = Object.freeze({
  email_address: {
    level: null,
    message: '',
  },
  display_name: {
    level: null,
    message: '',
  },
  password: {
    level: null,
    message: '',
  },
  validated: false,
});

export const validateForm = ({ email_address, display_name, password }, setError) => {
  const validation = { ...initErrorState };
  if (
    !email_address?.match(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    )
  ) {
    validation.email_address = {
      level: 3,
      message: 'wrong email format',
    };
  }
  if (!display_name) {
    validation.display_name = {
      level: 3,
      message: 'name cannot be empty',
    };
  }
  if (!password.length) {
    validation.password = {
      level: 3,
      message: 'password cannot be empty',
    };
  }
  setError(validation);
};

export const colors = {
  red: 'rgba(247, 79, 111, 1)',
  red_opacity: 'rgba(247, 79, 111, 0.1)',
  orange: 'rgba(240, 173, 78, 1)',
  orange_opacity: 'rgba(240, 173, 78, 0.1)',
  grey: '#777777',
  purple_grey: '#717389',
  blue: 'rgba(55, 160, 225, .8)',
  blue_opacity: 'rgba(55, 160, 225, .2)',
  purple: 'rgba(147,112,219, .8)',
  purple_opacity: 'rgba(147,112,219, .1)',
  white: '#ffffff',
  white_opacity: 'rgba(255, 255, 255, 0.4)',
  green: '#71eeb8',
};

export const default_line = {
  type: 'line',
  data: [],
  label: '',
  fill: false,
  lineTension: 0,
  borderCapStyle: 'round',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  borderWidth: 2,
  pointBorderWidth: 0,
  pointHitRadius: 14,
  pointRadius: 2,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 2,
  spanGaps: false,
  backgroundColor: 'pink',
  borderColor: 'pink',
  pointBackgroundColor: 'pink',
  pointBorderColor: 'pink',
  pointHoverBackgroundColor: 'pink',
  pointHoverBorderColor: 'pink',
  yAxisID: 'default_id',
};

export const default_bar = {
  type: 'bar',
  data: [],
  label: '',
  borderWidth: 1,
  borderColor: 'pink',
  backgroundColor: 'pink',
  yAxisID: 'default_id',
};

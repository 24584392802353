import { CLEAR_AD_SERVERS_LIST, SET_AD_SERVERS_LIST } from '../types';

export const adServersListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_AD_SERVERS_LIST:
      return action.payload;
    case CLEAR_AD_SERVERS_LIST:
      return [];
    default:
      return state;
  }
};

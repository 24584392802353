import { CLEAR_OVERLAY, SHOW_OVERLAY } from '../types';

export const overlayReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_OVERLAY:
      return action.payload;
    case CLEAR_OVERLAY:
      return {};
    default:
      return state;
  }
};

import { CLEAR_LINE_ITEM_DCPM, SET_LINE_ITEM_DCPM } from '../types';

export const lineItemDcpmReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LINE_ITEM_DCPM:
      return action.payload;
    case CLEAR_LINE_ITEM_DCPM:
      return [];
    default:
      return state;
  }
};

import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeAbTests = (data = []) =>
  data.map((row) => normalizeAbTest(row)).sort((a, b) => checkSort(a, b, 'ab_test_name', 'asc'));

export const normalizeAbTest = (row = {}) => ({
  ...row,
  created_date_formatted: formatDate(row.created_date, { format: 'M/D/YY h:mm:ss A' }),
  created_date_local_formatted: offsetTimezoneUTC(row.created_date, { format: 'M/D/YY h:mm:ss A' }),
  uuid: uuidv4(),
});

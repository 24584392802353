import { CLEAR_INTERVALS_LIST, SET_INTERVALS_LIST } from '../types';

export const intervalsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_INTERVALS_LIST:
      return action.payload;
    case CLEAR_INTERVALS_LIST:
      return [];
    default:
      return state;
  }
};

import React from 'react';
import { Button, Modal, Table } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { clearAbTestSegments } from 'ducks/actions/segments';
import { updateSettings } from 'ducks/actions/settings';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeZone } from 'utils/timeZone';

import {
  AbTestSegmentNameCell,
  CreatedAtCell,
  CreatedByCell,
  IdCell,
  IdentityTypeCell,
} from './ViewAbTestSegmentsCells';

import cn from './Modal.module.scss';

const headers = [
  {
    name: 'ID',
    width: '75px',
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'AB Test Segment',
    width: '100px',
    sort_key: 'segment_name',
    sort_direction: 'default',
    flex_grow: 1,
    uuid: 2, // required key
  },
  {
    name: 'Created By',
    width: '175px',
    uuid: 3, // required key
  },
  {
    name: `Created At (${getTimeZone()})`,
    width: '175px',
    uuid: 4, // required key
  },
  {
    name: `Identity Type`,
    width: '175px',
    uuid: 5, // required key
  },
];

export const ViewAbTestSegments = () => {
  const dispatch = useDispatch();

  const { modals, ab_test_segments } = useSelector((state) => ({
    modals: state.modals,
    ab_test_segments: state.ab_test_segments,
  }));

  const closeModal = () => {
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ view_ab_test_segments: false }));
    dispatch(clearAbTestSegments());
  };

  return (
    <Modal
      padding={16}
      close_box={46}
      name="view_ab_test_segments"
      show={modals.view_ab_test_segments}
      width={900}
      onClose={closeModal}
      standard={false}
    >
      <h3>AB Test Segments</h3>
      <Table headers={headers} rows={ab_test_segments}>
        <IdCell />
        <AbTestSegmentNameCell />
        <CreatedByCell />
        <CreatedAtCell />
        <IdentityTypeCell />
      </Table>
      <div className={cn.buttonContainer}>
        <div className={cn.flex} />
        <Button button_size="small" onClick={closeModal} width="100px" type="submit">
          Close
        </Button>
      </div>
    </Modal>
  );
};

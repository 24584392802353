export const lineItemsBudgetValidation = (line_items = [], remaining = {}) => {
  const { impression_budget, spend_budget_usd } = remaining;

  if (line_items.length === 0) {
    return false;
  }

  for (let i = 0; i < line_items.length; i++) {
    if (line_items[i].impression_budget < 0) {
      return false;
    }
    if (line_items[i].spend_budget_usd < 0) {
      return false;
    }
  }

  if (impression_budget < -0.01 || impression_budget > 0.01) {
    return false;
  }

  if (spend_budget_usd < -0.01 || spend_budget_usd > 0.01) {
    return false;
  }

  return true;
};

export const lineItemValidation = (line_item = {}) => {
  const { line_item_name, platform_id, overserve_factor } = line_item;
  return line_item_name && platform_id && overserve_factor;
};

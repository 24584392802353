import React, { memo, useCallback } from 'react';
import { Snackbar as SnackbarBase } from '@cognitiv/cyprus-ui';
import { updateSettings } from 'ducks/actions/settings';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const SnackbarMemo = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(
    (state) => ({
      settings: state.settings,
    }),
    shallowEqual,
  );
  const { snackbar_label, snackbar_action, snackbar_duration, snackbar, snackbar_icon } = settings;

  const updateServiceWorker = useCallback(() => {
    const registrationWaiting = settings?.sw_registration?.waiting;
    if (registrationWaiting) {
      dispatch(updateSettings({ loading: true }));
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [dispatch, settings?.sw_registration?.waiting]);

  const onSnackbar = useCallback(() => {
    if (settings.snackbar_action) {
      updateServiceWorker();
    }
    dispatch(
      updateSettings({
        snackbar: false,
        snackbar_variant: 'primary',
        snackbar_action: null,
        snackbar_label: null,
        snackbar_duration: null,
      }),
    );
  }, [dispatch, settings.snackbar_action, updateServiceWorker]);

  const snackbarClose = useCallback(() => {
    dispatch(
      updateSettings({
        snackbar: false,
        snackbar_variant: 'primary',
        snackbar_action: null,
        snackbar_label: null,
        snackbar_duration: null,
      }),
    );
  }, [dispatch]);
  if (!snackbar_label || !snackbar) return null;

  return (
    <SnackbarBase
      show={snackbar}
      theme={{ snackbar_background: '#102c60', action_snackbar_color: '#276EF1', font_snackbar_size: 14 }}
      label={snackbar_label}
      snackbar_variant="primary"
      position={{ top: 40, right: 0 }}
      duration={snackbar_duration}
      left_icon={snackbar_icon}
      icon_color="#fff"
      action={snackbar_action}
      onChange={onSnackbar}
      onClose={snackbarClose}
    />
  );
};

export const Snackbar = memo(SnackbarMemo);

import * as constants from 'ducks/types';

export const setStrategy = (payload = {}) => ({
  type: constants.SET_STRATEGY,
  payload,
});

export const updateStrategy = (payload = {}) => ({
  type: constants.UPDATE_STRATEGY,
  payload,
});

export const mergeStrategy = (payload = {}) => ({
  type: constants.MERGE_STRATEGY,
  payload,
});

export const mergeChangeOrderStrategies = (payload = {}) => ({
  type: constants.MERGE_CHANGE_ORDER_STRATEGIES,
  payload,
});

export const insertStrategy = (payload = {}) => ({
  type: constants.INSERT_STRATEGY,
  payload,
});

export const clearStrategy = () => ({
  type: constants.CLEAR_STRATEGY,
});

export const setStrategyWarnings = (payload = {}) => ({
  type: constants.SET_STRATEGY_WARNINGS,
  payload,
});

export const updateStrategyWarnings = (payload = {}) => ({
  type: constants.UPDATE_STRATEGY_WARNINGS,
  payload,
});

export const clearStrategyWarnings = () => ({
  type: constants.CLEAR_STRATEGY_WARNINGS,
});

export const setStrategies = (payload = {}) => ({
  type: constants.SET_STRATEGIES,
  payload,
});
export const clearStrategies = () => ({
  type: constants.CLEAR_STRATEGIES,
});

export const updateStrategies = (payload = {}) => ({
  type: constants.UPDATE_STRATEGIES,
  payload,
});

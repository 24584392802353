import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Modal } from '@cognitiv/cyprus-ui';
import { clearAbTest } from 'ducks/actions/ab-tests';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createAdvertiserAbTest, updateAbTest } from 'ducks/operators/ab-tests';
import { abTestValidation } from 'ducks/validations/ab-tests';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  ab_test_id: null,
  ab_test_name: '',
  // default ab test type set to line item ab tests because users can only create line item ab tests from this modal
  ab_test_type_id: 1,
  ab_test_type_name: 'Line Item AB Test',
};

const ab_test_types_list = [
  {
    ab_test_type_id: 1,
    ab_test_type_name: 'Line Item AB Test',
    uuid: uuidv4(),
  },
  {
    ab_test_type_id: 2,
    ab_test_type_name: 'Strategy AB Test',
    uuid: uuidv4(),
  },
];

export const ManageAbTest = ({ location, history, match }) => {
  const dispatch = useDispatch();

  const { modals, ab_test } = useSelector((state) => ({
    ab_test: state.ab_test,
    modals: state.modals,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...ab_test });
  }, [ab_test]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on ab test page
    if (!match.params.ab_test_id) {
      dispatch(clearAbTest());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_ab_test: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        ab_test_name: form.ab_test_name,
        ab_test_type_id: form.ab_test_type_id,
      };

      if (ab_test_id) {
        await dispatch(updateAbTest(ab_test_id, data));
      }
      if (!ab_test_id) {
        // DEV NOTE: this route takes identity_type_id, but we are not ever actually hitting this code block.
        await dispatch(createAdvertiserAbTest(match.params.advertiser_id, data));
      }

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('ab test has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { ab_test_id } = ab_test;
  const { ab_test_name, ab_test_type_name } = form;

  const disabled = abTestValidation(form);
  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_ab_test"
      show={modals.manage_ab_test}
      width={500}
      onClose={closeModal}
      standard={false}
    >
      <h3>{ab_test_id ? 'Update AB Test' : 'Create AB Test'}</h3>
      <form onSubmit={onSubmit}>
        <Input
          label="AB Test Name"
          auto_focus
          value={ab_test_name}
          margin="0px 0px 10px 0px"
          onChange={(value) => onChange({ ab_test_name: value })}
        />
        <Dropdown
          options={ab_test_types_list}
          option_key="ab_test_type_name"
          label="AB Test Type"
          disabled
          value={ab_test_type_name}
          onSelect={(item) =>
            onChange({ ab_test_type_name: item.ab_test_type_name, ab_test_type_id: item.ab_test_type_id })
          }
        />
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
            {ab_test_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageAbTest.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

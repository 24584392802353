/* eslint-disable no-undef */
import { AUTHENTICATION_RESULTS } from 'ducks/operators/users';
import { v4 as uuidv4 } from 'uuid';

export const normalizeUser = (data) => {
  if (data.authentication_result_id === AUTHENTICATION_RESULTS.Success) {
    localStorage.setItem('user', data);
  }
  return {
    ...data,
    uuid: uuidv4(),
  };
};

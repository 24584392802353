export const vendorFeeValidation = (vendor_fee) => {
  const {
    fee_vendor_fee_name,
    fee_value,
    contract_start_date,
    contract_finish_date,
    impression_minimum,
    impression_maximum,
  } = vendor_fee;
  if (!fee_vendor_fee_name) {
    return false;
  }
  if (!fee_value) {
    return false;
  }
  if (!contract_start_date) {
    return false;
  }
  if (!contract_finish_date) {
    return false;
  }
  if (impression_minimum && impression_maximum && impression_maximum <= impression_minimum) {
    return false;
  }
  return true;
};

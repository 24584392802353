import { CLEAR_OUTPUT_TYPES_LIST, SET_OUTPUT_TYPES_LIST } from '../types';

const initial_state = [
  {
    output_type_id: 1,
    output_type_name: 'CSV',
    uuid: '3075183c-3fec-4540-b28c-51dc21250562',
  },
  {
    output_type_id: 2,
    output_type_name: 'Excel',
    uuid: 'dc275b81-0bb2-416b-a7c9-120a314f95cd',
  },
];

export const outputTypesListReducer = (state = [...initial_state], action) => {
  switch (action.type) {
    case SET_OUTPUT_TYPES_LIST:
      return action.payload;
    case CLEAR_OUTPUT_TYPES_LIST:
      return [];
    default:
      return state;
  }
};

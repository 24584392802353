import React, { PureComponent, Suspense } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import cn from './Loading.module.scss';
const { PUBLIC_URL } = process.env;

export const WaitingComponent = (Component) => (props) =>
  (
    <Suspense fallback={<Loading variant="light" />}>
      <Component {...props} />
    </Suspense>
  );

export const Spinner = () => <img className={cn.spinner} src={`${PUBLIC_URL}/spinner.gif`} alt="Loading Dashboard" />;

export const SmallSpinner = () => (
  <img className={cn.spinnerSmall} src={`${PUBLIC_URL}/spinner.gif`} alt="Loading Dashboard" />
);

export class Loading extends PureComponent {
  static propTypes = {
    variant: PropTypes.oneOf(['dark', 'light']),
  };

  render() {
    const { variant } = this.props;
    return (
      <div className={classNames(cn.loading, { [cn.dark]: variant === 'dark' }, { [cn.light]: variant === 'light' })}>
        <img className={cn.spinner} src={`${PUBLIC_URL}/spinner.gif`} alt="Loading Dashboard" />
      </div>
    );
  }
}

import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeOutputs = (data = []) =>
  data.map((d) => normalizeOutput(d)).sort((a, b) => checkSort(a, b, 'output_name', 'asc'));

export const normalizeOutput = (data = {}) => ({
  ...data,
  uuid: uuidv4(),
});

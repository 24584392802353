import moment from 'moment';
import { formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeDeals = (deals = []) =>
  deals.map((d) => normalizeDeal(d)).sort((a, b) => checkSort(a, b, 'deal_name', 'asc'));

export const normalizeDeal = (deal = {}) => {
  const { max_price_micro_usd, min_price_micro_usd } = deal;
  const min_price_cpm = formatNumber(min_price_micro_usd / 1000 || 0, { format: 'none', append: '' });
  const max_price_cpm = formatNumber(max_price_micro_usd / 1000 || 0, { format: 'none', append: '' });
  const min_price_cpm_formatted = formatNumber(min_price_cpm, { format: 'currency', append: '' });
  const max_price_cpm_formatted = formatNumber(max_price_cpm, { format: 'currency', append: '' });
  return {
    ...deal,
    min_price_cpm,
    max_price_cpm,
    min_price_cpm_formatted,
    max_price_cpm_formatted,
    deal_name_with_id: `${deal.deal_id} ${deal.deal_name}`,
    uuid: uuidv4(),
  };
};

export const normalizeLineItemDeals = (deals = []) =>
  deals.map((d) => normalizeLineItemDeal(d)).sort((a, b) => checkSort(a, b, 'deal_name', 'asc'));

export const normalizeLineItemDeal = (deal = {}) => {
  const { deal_line_item_start_date } = deal;
  return {
    ...deal,
    deal_line_item_start_date_formatted: moment(deal_line_item_start_date).format('MM/DD/YYYY'),
    uuid: uuidv4(),
  };
};

export const normalizeStrategyDeals = (deals = []) =>
  deals.map((d) => normalizeStrategyDeal(d)).sort((a, b) => checkSort(a, b, 'deal_name', 'asc'));

export const normalizeStrategyDeal = (deal = {}) => {
  const { deal_strategy_start_date } = deal;
  return {
    ...deal,
    deal_strategy_start_date_formatted: moment(deal_strategy_start_date).format('MM/DD/YYYY'),
    uuid: uuidv4(),
  };
};

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Cell, CodeEditor, Input, InputNumber, Modal, TableFlex } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings } from 'ducks/actions/settings';
import { getKeywordDomains, getKeywordFilter, getKeywordNeighbors } from 'ducks/operators/keywords';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { jsonBeautify } from 'utils/string';

import cn from './Modal.module.scss';

const default_state = {
  keyword: '',
  threshold: 0.5,
  threshold_formatted: '0.5',
  neighbors: false,
  domains: false,
  filter: false,
};

export const neighbor_headers = [
  {
    name: 'Keyword',
    width: '100px',
    flex_grow: 1,
    uuid: 1, // required key
  },
  {
    name: 'Probability',
    width: '100px',
    flex_grow: 1,
    uuid: 2, // required key
  },
];

export const domain_headers = [
  {
    name: 'Domain',
    width: '100px',
    flex_grow: 1,
    uuid: 1, // required key
  },
];

export const ViewFilterKeywords = () => {
  const dispatch = useDispatch();

  const { modals } = useSelector(
    (state) => ({
      modals: state.modals,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const onSubmitNeighbors = async () => {
    try {
      const { keyword } = form;
      const res = await dispatch(getKeywordNeighbors({ keyword }));
      onChange({
        neighbors: res,
        domains: false,
        filter: false,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const onSubmitDomains = async () => {
    try {
      const { keyword, threshold } = form;
      const res = await dispatch(getKeywordDomains({ keyword, threshold }));
      onChange({
        neighbors: false,
        domains: res,
        filter: false,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const onSubmitFilter = async () => {
    try {
      const { keyword, threshold } = form;
      const res = await dispatch(getKeywordFilter({ keyword, threshold }));

      onChange({
        neighbors: false,
        domains: false,
        filter: res,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const copyFilter = () => {
    dispatch(
      updateSettings({
        snackbar: true,
        snackbar_variant: 'secondary',
        snackbar_label: 'Press Ctrl + V to paste the Filter JSON.',
        snackbar_duration: 4000,
      }),
    );
  };

  const closeModal = () => {
    setForm({ ...default_state });
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ view_filter_keywords: false }));
  };

  const { keyword, threshold_formatted, neighbors, domains, filter } = form;
  const empty = !neighbors && !domains && !filter;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="view_filter_keywords"
      show={modals.view_filter_keywords}
      width={600}
      onClose={closeModal}
      standard={false}
    >
      <h3>Filter JSON Keyword Search</h3>
      <form style={{ display: 'flex' }}>
        <Input
          auto_focus
          label="Filter Keyword"
          value={keyword}
          width="calc(50% - 7px)"
          margin="0px 7px 0px 0px"
          onChange={(input) => onChange({ keyword: input })}
        />
        <InputNumber
          label="Filter Threshold"
          is_allowed={({ floatValue }) => {
            if (!floatValue) {
              return true;
            }
            return floatValue <= 1 && floatValue >= 0;
          }}
          value={threshold_formatted}
          decimal_scale={3}
          width="calc(50% - 7px)"
          margin="0px 0px 0px 7px"
          onChange={(input) => onChange({ threshold: input.float_value, threshold_formatted: input.formatted_value })}
        />
        <div className={cn.flex} />
      </form>
      <div className={cn.filter}>
        {empty && <FilterUnselected />}
        {neighbors && (
          <TableFlex headers={neighbor_headers} rows={neighbors}>
            <SimpleCell identifier="keyword" />
            <SimpleCell identifier="probability" />
          </TableFlex>
        )}
        {domains && (
          <TableFlex headers={domain_headers} rows={domains}>
            <SimpleCell identifier="url" />
          </TableFlex>
        )}
        {filter && (
          <CodeEditor
            height={250}
            label={`${keyword} ${threshold_formatted}`}
            languages={['json']}
            unique_identifier="keyword"
            key={`${keyword} ${threshold_formatted}`}
            value={jsonBeautify(filter || '{}')}
            onCopy={copyFilter}
            read_only
          />
        )}
      </div>
      <div className={cn.buttonContainer}>
        <div className={cn.flex} />
        <Button onClick={onSubmitNeighbors} button_size="small" width="125px" margin="10px 0px 0px 0px">
          Show Neighbors
        </Button>
        <Button onClick={onSubmitDomains} button_size="small" width="125px" margin="10px 0px 0px 10px">
          Test Keywords
        </Button>
        <Button onClick={onSubmitFilter} button_size="small" width="125px" margin="10px 0px 0px 10px">
          Show JSON
        </Button>
      </div>
    </Modal>
  );
};

const FilterUnselected = () => (
  <div className={cn.grow}>
    <FontAwesomeIcon icon={['far', 'funnel-dollar']} />
    <p>Keyword Filters</p>
  </div>
);

const SimpleCell = ({ row, identifier }) => (
  <Cell>
    <p>{row[identifier]}</p>
  </Cell>
);

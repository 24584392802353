import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeTags = (data) =>
  data.map((d) => normalizeTag(d)).sort((a, b) => checkSort(a, b, 'tag_name', 'asc'));

export const normalizeTag = (data) => ({
  ...data,
  expose_insights: data.attributes.includes('centauri'),
  tag_name_with_id: `${data.tag_id} ${data.tag_name}`,
  uuid: uuidv4(),
});

export const normalizeTagFields = (data) => data.map((d) => normalizeTagField(d));

export const normalizeTagField = (row = {}) => {
  const { tag_index } = row;
  if (tag_index < 0) {
    return {
      ...row,
      tag_variable_inputs: [],
      uuid: uuidv4(),
    };
  }
  return {
    ...row,
    uuid: uuidv4(),
  };
};

export const normalizePhysicalTags = (data = []) =>
  data.map((d) => normalizePhysicalTag(d)).sort((a, b) => checkSort(a, b, 'tag_name', 'asc'));

export const normalizePhysicalTag = (data = {}) => {
  const tag_variables = data.tag_variables || [];
  const tag_variables_formatted = [];

  // pulling out the lowest index from tag variables
  const tag_index = tag_variables.reduce((acc, next) => {
    if (acc > next.tag_index) {
      return next.tag_index;
    }
    return acc;
  }, 0);

  for (let i = tag_index; i <= 20; i++) {
    // hydrating tag information with U variables
    const tag = tag_variables.find((t) => t.tag_index === i);

    if (tag) {
      tag_variables_formatted.push({
        physical_client_variable_name: tag.client_variable_name,
        physical_cognitiv_variable_name: tag.client_variable_name,
        physical_tag_id: tag.tag_id,
        physical_tag_variable_id: tag.tag_variable_id,
        physical_tag_index_name: i <= 0 ? tag.cognitiv_variable_name : `U${i}`,
        physical_tag_index_name_match: i <= 0 ? String(tag.cognitiv_variable_name || '').toLowerCase() : `u${i}`,
        physical_tag_index: tag.tag_index,
        physical_tag_hydrate: true,
        rule_operator_color: '#fff',
        rule_operator_id: null,
        rule_operator_name: '',
        rule_operator_value: '',
        rule_variable_name: '',
        rule_variable_object: {},
        rule_variable_options: [],
        uuid: uuidv4(),
      });
    }
    if (!tag) {
      tag_variables_formatted.push({
        physical_client_variable_name: null,
        physical_cognitiv_variable_name: null,
        physical_tag_id: data.tag_id,
        physical_tag_variable_id: null,
        physical_tag_index_name: `U${i}`,
        physical_tag_index_name_match: `u${i}`,
        physical_tag_index: i,
        physical_tag_hydrate: false,
        rule_operator_color: '#fff',
        rule_operator_id: null,
        rule_operator_name: '',
        rule_operator_value: '',
        rule_variable_name: '',
        rule_variable_object: {},
        rule_variable_options: [],
        uuid: uuidv4(),
      });
    }
  }

  return {
    ...data,
    tag_variables_formatted,
    tag_name_with_id: `${data.tag_id} ${data.tag_name}`,
    uuid: uuidv4(),
  };
};

export const normalizeVirtualTags = (data = []) =>
  uniqueIdentifiers(data.map((tag) => ({ ...tag, tag_name_with_id: `${tag.tag_id} ${tag.tag_name}` }))).sort((a, b) =>
    checkSort(a, b, 'tag_name', 'asc'),
  );

export const normalizeTagVariableRules = (data = [], tag_fields = []) =>
  data
    .map((d) => {
      const tag_inputs = tag_fields.find((t) => t.tag_index === d.tag_index) || {
        tag_variable_inputs: [],
      };

      let { tag_variable_inputs } = tag_inputs;
      let tag_rule_array = [];

      // hydrating tag variables with custom option inputs
      if (d.tag_operator_name.includes('in') && d.tag_rule) {
        tag_rule_array = d.tag_rule.split(',');
        const custom_options = tag_rule_array.filter(
          (r) => !tag_variable_inputs.some((t) => t.tag_variable_input_name === r),
        );

        for (let i = 0; i < custom_options.length; i++) {
          tag_variable_inputs.unshift({
            tag_variable_input_name: custom_options[i],
            checked: true,
            uuid: uuidv4(),
          });
        }

        tag_variable_inputs = tag_variable_inputs
          .map((t) => {
            const index = tag_rule_array.findIndex((o) => t.tag_variable_input_name === o);
            return {
              ...t,
              checked: index !== -1,
              uuid: uuidv4(),
            };
          })
          .sort((a, b) => b.checked - a.checked);
      }

      return {
        ...d,
        uuid: uuidv4(),
        tag_rule_array,
        tag_index_name: d.tag_index <= 0 ? d.cognitiv_variable_name : `U${d.tag_index}`,
        tag_index_name_match: d.tag_index <= 0 ? String(d.cognitiv_variable_name || '').toLowerCase() : `u${d.tag_index}`,
        tag_variable_input_options: tag_variable_inputs,
      };
    })
    .sort((a, b) => checkSort(a, b, 'tag_index', 'asc'));

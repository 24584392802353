import moment from 'moment';
import { compareDateRange, compareDates, differenceDates } from 'utils/dates';
import { padNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { getNewYorkDST } from 'utils/timeZone';
import { v4 as uuidv4 } from 'uuid';

export const normalizeFlights = (data = []) =>
  data.map((d) => normalizeFlight(d)).sort((a, b) => checkSort(a, b, 'flight_name', 'desc'));

export const normalizeFlight = (data = {}) => {
  const { flight_name, start_date_utc, finish_date_utc, state_id } = data;
  const flight = getFlightDates(start_date_utc, finish_date_utc);
  const flight_dates_name = `${flight_name} (${flight.flight_dates_formatted})`;

  let state_name = '';

  switch (state_id) {
    case 1:
      state_name = 'Active';
      break;
    case 2:
      state_name = 'Paused';
      break;
    case 3:
      state_name = 'Inactive';
      break;
    case 4:
      state_name = 'Archived';
      break;
    default:
      state_name = '';
  }
  return {
    ...data,
    ...flight,
    state_name,
    flight_dates_name,
    creative_name_with_id: `${data.creative_id} ${data.creative_name}`,
    uuid: uuidv4(),
  };
};

export const getFlightDates = (a, b) => {
  const start_date_utc_trimmed = moment(a).utc().format('M/D/YY HH:mm');
  const current_date_utc_trimmed = moment().utc().format('M/D/YY HH:mm');
  const finish_date_utc_trimmed = moment(b).utc().format('M/D/YY HH:mm');

  const start_date_utc_formatted = moment.utc(a).format('MM/DD/YYYY HH:mm');
  const current_date_utc_formatted = moment.utc().format('MM/DD/YYYY HH:mm');
  const finish_date_utc_formatted = moment.utc(b).format('MM/DD/YYYY HH:mm');

  const start_date_formatted = moment(a).utc().format('MM/DD/YYYY');
  const current_date_formatted = moment().utc().format('MM/DD/YYYY');
  const finish_date_formatted = moment(b).utc().format('MM/DD/YYYY');

  const start_date_short = moment(start_date_utc_formatted).format('M/D/YY');
  const finish_date_short = moment(finish_date_utc_formatted).format('M/D/YY');

  return {
    start_date_utc_trimmed,
    current_date_utc_trimmed,
    finish_date_utc_trimmed,
    start_date_utc_formatted,
    current_date_utc_formatted,
    finish_date_utc_formatted,
    start_date_formatted,
    current_date_formatted,
    finish_date_formatted,
    start_date_short,
    finish_date_short,
    flight_dates_formatted: `${start_date_short} - ${finish_date_short}`,
    flight_has_started: compareDates(start_date_utc_formatted, current_date_utc_formatted),
    flight_is_live: compareDateRange(start_date_utc_formatted, current_date_utc_formatted, finish_date_utc_formatted),
    flight_has_ended: compareDates(finish_date_utc_formatted, current_date_utc_formatted),
    flight_hours_remaining: differenceDates(current_date_utc_formatted, finish_date_utc_formatted),
  };
};

export const transitionFlights = (a, b) => {
  const a_utc = moment(a).format('YYYY-MM-DD');
  const b_utc = moment(b).format('YYYY-MM-DD');
  const is_dst = getNewYorkDST();

  const dst_offset = is_dst ? 4 : 5;

  const start_offset_padded = padNumber(dst_offset, 2);
  const finish_offset_padded = padNumber(dst_offset, 2);
  return {
    start_date_utc: `${a_utc}T${start_offset_padded}:00:00+00:00`,
    finish_date_utc: `${b_utc}T${finish_offset_padded}:00:00+00:00`,
  };
};

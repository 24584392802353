export const dealValidation = (deal) => {
  const { deal_name, inventory_source_id, inventory_source_identifier, min_price_micro_usd, max_price_micro_usd } =
    deal;
  if (!deal_name) {
    return false;
  }
  // Inventory source "Unknown" has id === 0
  if (!inventory_source_id && inventory_source_id !== 0) {
    return false;
  }
  if (!inventory_source_identifier) {
    return false;
  }
  if (min_price_micro_usd && max_price_micro_usd && min_price_micro_usd > max_price_micro_usd) {
    return false;
  }
  return true;
};

import { CLEAR_FLIGHTS, SET_FLIGHTS, UPDATE_FLIGHTS } from '../types';

export const flightsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FLIGHTS:
      return action.payload;
    case UPDATE_FLIGHTS: {
      const flights = action.payload;
      for (let i = 0; i < flights.length; i++) {
        const flight = flights[i];
        const index = state.findIndex((s) => s.flight_id === flight.flight_id);

        if (index === -1) {
          state.push(flight);
        }
      }

      return [...state];
    }
    case CLEAR_FLIGHTS:
      return [];
    default:
      return state;
  }
};

import { setKpiIntervals } from 'ducks/actions/kpi-groups';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeKpiIntervals } from 'ducks/normalizers/kpi-groups';
import { Gemini } from 'utils/axios';

export const getCampaignKpiIntervals = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  if (!entity_id) {
    return;
  }
  const res = await Gemini.get(`/campaigns/${entity_id}/kpi_group_intervals/view`);
  const kpi_intervals = normalizeKpiIntervals(res.data);
  await dispatch(setKpiIntervals(kpi_intervals));
  return kpi_intervals;
};

export const createCampaignKpiInterval = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/kpi_group_intervals/create`, data);
  return res.data;
};

export const updateCampaignKpiInterval = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/kpi_group_intervals/update`, data);
  return res.data;
};

export const deleteCampaignKpiInterval = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.post(`/campaigns/${entity_id}/kpi_group_intervals/delete`, data);
  return res.data;
};

import { CLEAR_TAGS, SET_TAGS } from '../types';

export const tagsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TAGS:
      return action.payload;
    case CLEAR_TAGS:
      return [];
    default:
      return state;
  }
};

import { setCreativePixels } from 'ducks/actions/creative-pixels';
import { setCreativeScripts } from 'ducks/actions/creative-scripts';
import { setCreative, setCreatives } from 'ducks/actions/creatives';
import { setFlightCreatives } from 'ducks/actions/flight-creatives';
import { updateSettings } from 'ducks/actions/settings';
import {
  normalizeCreative,
  normalizeCreativePixels,
  normalizeCreatives,
  normalizeCreativeScripts,
} from 'ducks/normalizers/creatives';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

import { getAdvertiser, getAdvertisers } from './advertisers';
import { getAdvertiserCampaigns, getCampaign } from './campaigns';
import { getCreativeAddons } from './creative-addons';
import { getCreativeContents } from './creative-contents';
import { getCreativeExtras } from './creative-extras';
import { getCreativePrices } from './creative-prices';
import { getCreativeVideoEvents } from './creative-video-events';
import { getCampaignFlights, getFlight } from './flights';
import { getCreativeLineItemCreatives } from './line-item-creatives';

export const getCreativePage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const { advertiser_id, campaign_id, flight_id, creative_id } = params;

  const [advertiser] = await Promise.all([
    dispatch(getAdvertisers()),
    dispatch(getAdvertiser(advertiser_id)),
    dispatch(getAdvertiserCampaigns(advertiser_id)),
    dispatch(getCampaignCreatives(campaign_id, { include_archived: true })),
    dispatch(getCampaign(campaign_id)),
    dispatch(getCampaignFlights(campaign_id)),
    dispatch(getFlight(flight_id)),
    dispatch(getCreativeLineItemCreatives(creative_id)),
    dispatch(getCreative(creative_id)),
    dispatch(getCreativeExtras(creative_id)),
    dispatch(getCreativeVideoEvents(creative_id)),
    dispatch(getCreativePrices(creative_id)),
    dispatch(getCreativeAddons(creative_id)),
    dispatch(getCreativeContents(advertiser_id)),
  ]);
  await dispatch(updateSettings({ loading: false }));
  return advertiser;
};

export const getAdvertiserCreatives = (entity_id, queries) => async (dispatch) => {
  const query = {
    include_archived: false,
    ...queries,
  };
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/advertisers/${entity_id}/creatives${replaceQuery(query)}`);
  const creatives = await normalizeCreatives(res.data);
  await dispatch(setCreatives(creatives));
  return creatives;
};

export const getCampaignCreatives = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    include_archived: false,
    ...queries,
  };

  if (query.include_archived) {
    query.include_state_ids = [1, 2, 3, 4];
  }

  const res = await Gemini.get(`/campaigns/${entity_id}/creatives${replaceQuery(query)}`);
  const creatives = await normalizeCreatives(res.data);
  await dispatch(setCreatives(creatives));
  return creatives;
};

export const getFlightCreatives =
  (entity_id, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const query = {
      include_archived: false,
      ...queries,
    };

    if (query.include_archived) {
      query.include_state_ids = [1, 2, 3, 4];
    }

    const res = await Gemini.get(`/flights/${entity_id}/creatives${replaceQuery(query)}`);
    const creatives = await normalizeCreatives(res.data);
    await dispatch(setFlightCreatives(creatives));
    return creatives;
  };

export const getCreativeConfig = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const { creative_id, advertiser_id } = params;
  await Promise.all([
    dispatch(getCreative(creative_id)),
    dispatch(getCreativeAddons(creative_id)),
    dispatch(getCreativeContents(advertiser_id)),
    dispatch(getCreativeExtras(creative_id)),
  ]);
};

export const getCreative = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/${entity_id}`);
  const creative = await normalizeCreative(res.data);

  const creative_pixels = normalizeCreativePixels(creative.pixels);
  const creative_scripts = normalizeCreativeScripts(creative.scripts);

  await dispatch(setCreativePixels(creative_pixels));
  await dispatch(setCreativeScripts(creative_scripts));
  await dispatch(setCreative(creative));
  return creative;
};

export const createCreative = (data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/creatives/create', data);
  return res.data;
};

export const updateCreatives = (entity_id, data) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/update_creatives`, data);
  return res.data;
};

export const activateCreative = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/creatives/${entity_id}/activate`);
  return res.data;
};

export const archiveCreative = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/creatives/${entity_id}/archive`);
  return res.data;
};
export const deactivateCreative = (entity_id) => async (dispatch) => {
  if (!entity_id) {
    return null;
  }

  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/creatives/${entity_id}/deactivate`);
  return res.data;
};

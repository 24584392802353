import { CLEAR_REVENUE_TYPES_LIST, SET_REVENUE_TYPES_LIST } from '../types';

export const revenueTypesList = (state = [], action) => {
  switch (action.type) {
    case SET_REVENUE_TYPES_LIST:
      return action.payload;
    case CLEAR_REVENUE_TYPES_LIST:
      return [];
    default:
      return state;
  }
};

import { setLineItemDcpm, setLineItemDcpmPreviewed } from 'ducks/actions/line-item-dpcm';
import { updateLineItem } from 'ducks/actions/line-items';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeLineItemDcpm, normalizeLineItemDcpms } from 'ducks/normalizers/line-item-dcpm';
import { Gemini } from 'utils/axios';

export const getLineItemDcpm = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/line_items/${entity_id}/line_item_prices`);
  const prices = normalizeLineItemDcpms(res.data.line_item_prices);
  const line_item = normalizeLineItemDcpm(res.data);
  dispatch(updateLineItem(line_item));
  dispatch(setLineItemDcpm(prices));
  return res.data;
};

export const getLineItemCalculated = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  await Gemini.post(`/line_items/${entity_id}/calculate_pricing`, { start_date: null, stop_date: null });
  await dispatch(getLineItemDcpm(entity_id));
};

export const lockLineItemDcpm = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_items/${entity_id}/line_item_price/lock`, data);
  if (data.preview) {
    const previewed_line_dcpm = normalizeLineItemDcpms(res.data);
    dispatch(setLineItemDcpmPreviewed(previewed_line_dcpm));
    dispatch(updateLineItem({ preview_dcpm: true }));
  }
  return res.data;
};

export const updateLineItemDcpmControls = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/line_items/${entity_id}/line_item_price_controls/update`, data);
  return res.data;
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action } from 'components/actions/Action';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import * as PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';
import { mergeQuery, parseQueries } from 'utils/queries';

import cn from './Navigation.module.scss';

export const TagWithRouter = ({ history, match, location }) => {
  const { advertisers, physical_tags } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      physical_tags: state.physical_tags,
    }),
    shallowEqual,
  );
  const toggleEditor = () => {
    const queries = mergeQuery(search, {
      show_json: !query.show_json,
    });

    history.push(`${pathname}${queries}`);
  };
  const { advertiser_id, tag_id, page } = match.params;
  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const tag = physical_tags.find((r) => Number(r.tag_id) === Number(tag_id)) || {};

  const { search, pathname } = location;

  const query = parseQueries(search);
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  const tag_width = getTextWidth(tag.tag_name);
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/tags`)}
          locations={location}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu labels={tag.tag_name} values={physical_tags} page="Tag" width={tag_width} height={47} />
        <div className={cn.flex} />
        <Action
          tooltip={query.show_json ? 'Show Filtered Data' : 'Show JSON Rule'}
          onClick={toggleEditor}
          icon={['fas', 'tools']}
          font_size={12}
        />
      </div>
    </>
  );
};

TagWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const Tag = withRouter(TagWithRouter);

import { formatNumber } from 'utils/numbers';
import { checkSort, mergeStrategyOverride, strategyUpdateCount } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeFrequencyCaps = (data = [], frequency_intervals = []) =>
  data.map((d) => normalizeFrequencyCap(d, frequency_intervals)).sort((a, b) => checkSort(a, b, 'flight_name', 'asc'));

export const normalizeChangeOrderFrequencyCaps = (data = [], frequency_intervals = []) =>
  data
    .map((d) => hydrateChangeOrderFrequencyCap(d))
    .map((d) => normalizeChangeOrderFrequencyCap(d, frequency_intervals))
    .sort((a, b) => checkSort(a, b, 'flight_name', 'asc'));

export const hydrateChangeOrderFrequencyCap = (row = {}) => {
  const frequency_cap_updates = countFrequencyCapUpdates(row);
  const frequency_cap_changes = mergeFrequencyCapChanges(row);

  return {
    ...row,
    ...frequency_cap_updates,
    ...frequency_cap_changes,
  };
};
export const normalizeChangeOrderFrequencyCap = (row = {}, frequency_intervals = []) => {
  const { impression_count_combined, duration_seconds_combined, interval_id_combined, duration_in_interval_combined } =
    row;

  const impression_count_formatted_combined = formatNumber(impression_count_combined, { format: 'number-comma' });
  const duration_formatted_combined = formatNumber(duration_seconds_combined, { format: 'number-comma' });
  const frequency_interval_combined = frequency_intervals.find((r) => r.interval_id === interval_id_combined) || {};
  const duration_in_interval_formatted_combined = formatNumber(duration_in_interval_combined, {
    format: 'number-comma',
  });

  const frequency_cap_name = getFrequencyCapName(
    { impression_count: impression_count_formatted_combined, duration_in_interval: duration_in_interval_combined },
    frequency_interval_combined,
  );

  return {
    ...row,
    ...frequency_interval_combined,
    frequency_cap_name,
    impression_count_formatted_combined,
    duration_formatted_combined,
    duration_in_interval_formatted_combined,
    is_campaign_frequency_cap:
      !!row.campaign_id && !row.line_item_id && !row.strategy_id && !row.change_order_strategy_id,
    is_line_item_frequency_cap: !!row.line_item_id && !row.strategy_id && !row.change_order_strategy_id,
    is_strategy_frequency_cap: !!row.strategy_id && !row.change_order_strategy_id,
    is_change_order_strategy_frequency_cap: !!row.change_order_strategy_id,
    saved: true,
    modified: true,
    uuid: uuidv4(),
  };
};

export const normalizeFrequencyCap = (row = {}, frequency_intervals = []) => {
  const { impression_count, duration_in_interval, interval_id } = row;

  const impression_count_formatted = formatNumber(impression_count, { format: 'number-comma' });
  const duration_formatted = formatNumber(duration_in_interval, { format: 'number-comma' });
  const frequency_interval = frequency_intervals.find((r) => r.interval_id === interval_id) || {};
  const frequency_cap_name = getFrequencyCapName(row, frequency_interval);
  return {
    ...row,
    ...frequency_interval,
    frequency_cap_name,
    impression_count_formatted,
    duration_formatted,
    is_campaign_frequency_cap:
      !!row.campaign_id && !row.line_item_id && !row.strategy_id && !row.change_order_strategy_id,
    is_line_item_frequency_cap: !!row.line_item_id && !row.strategy_id && !row.change_order_strategy_id,
    is_strategy_frequency_cap: !!row.strategy_id && !row.change_order_strategy_id,
    is_change_order_strategy_frequency_cap: !!row.change_order_strategy_id,
    saved: true,
    modified: false,
    uuid: uuidv4(),
  };
};

export const getFrequencyCapName = (fcap = {}, interval = {}) => {
  const { impression_count, duration_in_interval } = fcap;
  let impression = 'Impression';
  let { interval_name } = interval;
  if (duration_in_interval > 1) {
    interval_name += 's';
  }
  if (impression_count > 1) {
    impression = 'Impressions';
  }
  const frequency_cap_name = `${impression_count} ${impression} | ${duration_in_interval} ${interval_name}`;
  return frequency_cap_name;
};

export const getFrequencyCapsName = (fcaps = []) => {
  let frequency_cap_name = '';
  if (fcaps && fcaps.length === 0) {
    return frequency_cap_name;
  }

  if (fcaps.length === 1) {
    const [fcap] = fcaps;
    frequency_cap_name = `${fcap.impression_count} | ${fcap.duration_in_interval} ${fcap.interval_name}`;
  }

  if (fcaps.length > 1) {
    frequency_cap_name = `${fcaps.length} Frequency Caps`;
  }
  return frequency_cap_name;
};

export const getFrequencyCapsNameCombined = (rows = []) => {
  let frequency_cap_name_combined = '';
  if (rows && rows.length === 0) {
    return frequency_cap_name_combined;
  }

  if (rows.length === 1) {
    const [fcap] = rows;
    frequency_cap_name_combined = `${fcap.impression_count_combined} | ${fcap.duration_in_interval_combined} ${fcap.interval_name_combined}`;
  }

  if (rows.length > 1) {
    frequency_cap_name_combined = `${rows.length} Frequency Caps`;
  }
  return frequency_cap_name_combined;
};

const mergeFrequencyCapChanges = (data) => ({
  state_id_combined: mergeStrategyOverride(data.state_id_override, data.state_id),
  state_name_combined: mergeStrategyOverride(data.state_name_override, data.state_name),
  duration_in_interval_combined: mergeStrategyOverride(data.duration_in_interval_override, data.duration_in_interval),
  interval_name_combined: mergeStrategyOverride(data.interval_name_override, data.interval_name),
  interval_id_combined: mergeStrategyOverride(data.interval_id_override, data.interval_id),
  duration_seconds_combined: mergeStrategyOverride(data.duration_seconds_override, data.duration_seconds),
  impression_count_combined: mergeStrategyOverride(data.impression_count_override, data.impression_count),
  identity_type_id_combined: mergeStrategyOverride(data.identity_type_id_override, data.identity_type_id),
  identity_type_name_combined: mergeStrategyOverride(data.identity_type_name_override, data.identity_type_name),
});

const countFrequencyCapUpdates = (data) => {
  const frequency_cap_changes = {
    state_id_updated: strategyUpdateCount(data.state_id_override, data.state_id),
    duration_seconds_updated: strategyUpdateCount(data.duration_seconds_override, data.duration_seconds),
    impression_count_updated: strategyUpdateCount(data.impression_count_override, data.impression_count),
    identity_type_id_updated: strategyUpdateCount(data.identity_type_id_override, data.identity_type_id),
  };

  // handle the warning counts
  const frequency_cap_count = Object.keys(frequency_cap_changes).reduce((a, b) => a + frequency_cap_changes[b], 0);

  return {
    frequency_cap_count,
  };
};

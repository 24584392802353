import { CLEAR_DASHBOARDS, SET_DASHBOARDS } from '../types';

export const dashboardsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_DASHBOARDS:
      return action.payload;
    case CLEAR_DASHBOARDS:
      return [];
    default:
      return state;
  }
};

import { CLEAR_KPI_GROUP, SET_KPI_GROUP } from '../types';

const initial_state = {
  primary_tag_ids: [],
  secondary_tag_ids: [],
};
export const kpiGroupReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_KPI_GROUP:
      return action.payload;
    case CLEAR_KPI_GROUP:
      return { ...initial_state };
    default:
      return state;
  }
};

import { updateSettings } from 'ducks/actions/settings';
import { setGlobalVendorFees, setVendorFees } from 'ducks/actions/vendor-fees';
import {
  normalizeLineItemVendorFees,
  normalizeStrategyVendorFees,
  normalizeVendorFees,
} from 'ducks/normalizers/vendor-fees';
import { Gemini } from 'utils/axios';

export const getVendorFeesPage = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const [vendors] = await Promise.all([dispatch(getVendorFees())]);
  await dispatch(updateSettings({ loading: false }));
  return vendors;
};

export const getVendorFees = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/fees/fee_vendor_fees');
  const vendor_fees = normalizeVendorFees(res.data);
  dispatch(setGlobalVendorFees(vendor_fees));
  return vendor_fees;
};

export const createVendorFee = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/fees/fee_vendor_fee/create`, data);
  return res.data;
};

export const getLineItemVendorFees = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/fees/fee_vendor_fees/line_items/${entity_id}`);
  const vendor_fees = normalizeLineItemVendorFees(res.data);
  dispatch(setVendorFees(vendor_fees));
  return vendor_fees;
};

export const createLineItemVendorFees = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/fees/fee_vendor_fees/line_items/create`, data);
  return res.data;
};

export const getStrategyVendorFees = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/fees/fee_vendor_fees/strategies/${entity_id}`);
  const vendor_fees = normalizeStrategyVendorFees(res.data);
  dispatch(setVendorFees(vendor_fees));
  return vendor_fees;
};

export const createStrategyVendorFees = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/fees/fee_vendor_fees/strategies/create`, data);
  return res.data;
};

import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { defaults } from 'react-chartjs-2';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import store from './store';

import 'utils/prototype';

import 'scss/index.scss';
import '@cognitiv/cassiopeia-ui/dist/index.css';
import '@cognitiv/cyprus-ui/dist/index.css';

import { App } from 'App';
import { icons } from 'FaIcons';

library.add(icons);
window.global = window;

global.store = store;

defaults.font.family = 'Roboto';
defaults.font.size = 11;
defaults.color = '#404244';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Cell, Checkbox, Dropdown, Input, InputNumber, Modal, Table } from '@cognitiv/cyprus-ui';
import { Action } from 'components/cells/Action';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createChangeOrderExperiment } from 'ducks/operators/change-orders';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { formatNumber } from 'utils/numbers';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  experiment_name: '',
  auto_create_strategies: true,
  segment_added: false,
  bid_algorithm_id: 6,
  bid_algorithm_name: 'Incrementality',
  creative_selection_id: 3,
  creative_selection_name: 'Ctr Random',
  minutes_per_interval: 4,
};

const default_row = {
  ab_test_segment_name: '',
  allocation_weight: 1,
  identity_type_name: '',
  identity_type_id: null,
  modified: false,
  saved: false,
  uuid: null,
};

const headers = [
  {
    name: 'Segment',
    flex_grow: 1,
    min_width: '150px',
    uuid: 1,
  },
  {
    name: 'Identity Type',
    min_width: '150px',
    uuid: 2,
  },
  {
    name: 'Budget Weight',
    min_width: '150px',
    uuid: 3,
  },
  {
    name: '',
    min_width: '35px',
    uuid: 4,
  },
];

export const CreateExperiment = ({ location, history }) => {
  const dispatch = useDispatch();

  const { modals, campaign, bid_algorithms_list, creative_selections_list, identity_types_list, change_order } =
    useSelector((state) => ({
      campaign: state.campaign,
      change_order: state.change_order,
      bid_algorithms_list: state.bid_algorithms_list,
      creative_selections_list: state.creative_selections_list,
      modals: state.modals,
      identity_types_list: state.identity_types_list,
    }));

  const [form, setForm] = useState({ ...default_state });
  const [data, setData] = useState([]);

  useEffect(() => {
    const { campaign_group_name } = campaign;
    const { flight_name } = change_order;
    setForm((prev) => ({
      ...prev,
      experiment_name: `${flight_name} - ${campaign_group_name}`,
    }));
    setData([
      { ...default_row, uuid: uuidv4() },
      { ...default_row, uuid: uuidv4() },
      { ...default_row, uuid: uuidv4() },
    ]);
  }, [campaign, change_order]);

  const onRemoveRow = (row) => {
    setData((prev) => prev.filter((p) => p.uuid !== row.uuid));
  };
  const onChangeRow = (row) => {
    setData((prev) => {
      const prev_copy = [...prev];
      const last_index = prev_copy.length - 1;

      for (let i = 0; i < prev_copy.length; i++) {
        if (prev_copy[i].uuid === row.uuid) {
          prev_copy[i] = { ...prev_copy[i], ...row, modified: true };
          break;
        }
      }

      if (prev_copy[last_index].modified) {
        prev_copy.push({ ...default_row, uuid: uuidv4() });
      }

      return prev_copy;
    });
  };

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    setForm({ ...default_state });
    setData([
      { ...default_row, uuid: uuidv4() },
      { ...default_row, uuid: uuidv4() },
      { ...default_row, uuid: uuidv4() },
    ]);
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ create_experiment: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const queries = mergeQuery(search, { update: uuidv4() });

    const { change_order_id, flight_id } = change_order;
    const { experiment_name, auto_create_strategies, bid_algorithm_id, creative_selection_id } = form;
    try {
      const ab_test_segments = data
        .filter((d) => d.modified)
        .map((d) => ({
          ab_test_segment_name: d.ab_test_segment_name,
          budget_weight: d.allocation_weight,
          identity_type_id: d.identity_type_id,
        }));

      const payload = {
        ab_test_name: experiment_name,
        flight_id,
        ab_test_segments,
        auto_create_strategies,
        bid_algorithm_id,
        creative_selection_id,
        intervals_per_day: formatNumber(1440 / minutes_per_interval, { format: 'number-rounded', decimals: 0 }),
      };

      await dispatch(createChangeOrderExperiment(change_order_id, payload));
      dispatch(updateSuccess('experiment has been successfully updated'));
      history.push(`${pathname}${queries}`);
      closeModal();
    } catch (err) {
      handleError(err);
    }
  };

  const { experiment_name, bid_algorithm_name, creative_selection_name, minutes_per_interval, auto_create_strategies } =
    form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="create_experiment"
      show={modals.create_experiment}
      width={600}
      onClose={closeModal}
      standard={false}
    >
      <h3>Create Change Order Experiment</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus
          label="Experiment"
          margin="0px 0px 30px 0px"
          value={experiment_name}
          onChange={(value) => onChange({ experiment_name: value })}
        />
        <Table headers={headers} rows={data}>
          <SegmentCell onChange={onChangeRow} />
          <IdentityTypeCell identity_types_list={identity_types_list} campaign={campaign} onChange={onChangeRow} />
          <BudgetWeightCell onChange={onChangeRow} />
          <RemoveCell onClick={onRemoveRow} />
        </Table>
        <Checkbox
          margin="15px 7px 0px 0px"
          width="max-content"
          checked={auto_create_strategies}
          onClick={(checked) => onChange({ auto_create_strategies: checked })}
        >
          Auto-Create Strategies
        </Checkbox>
        {auto_create_strategies && (
          <>
            <div style={{ display: 'flex' }}>
              <Dropdown
                label="Bid Algorithm"
                options={bid_algorithms_list}
                option_key="bid_algorithm_name"
                value={bid_algorithm_name}
                margin="15px 0px 0px 0px"
                width="33%"
                onSelect={(item) =>
                  onChange({ bid_algorithm_name: item.bid_algorithm_name, bid_algorithm_id: item.bid_algorithm_id })
                }
              />
              <Dropdown
                label="Creative Selection"
                options={creative_selections_list}
                option_key="creative_selection_name"
                value={creative_selection_name}
                margin="15px 15px 0px 15px"
                width="calc(34% - 30px)"
                onSelect={(item) =>
                  onChange({
                    creative_selection_name: item.creative_selection_name,
                    creative_selection_id: item.creative_selection_id,
                  })
                }
              />
              <InputNumber
                label="Mins / Int"
                value={minutes_per_interval}
                onChange={(input) => onChange({ minutes_per_interval: input.float_value })}
                margin="15px 0px 0px 0px"
                width="33%"
              />
            </div>
          </>
        )}
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit">
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const SegmentCell = ({ row, onChange }) => (
  <Cell>
    <Input
      value={row.ab_test_segment_name}
      transparent
      onChange={(value) => onChange({ ab_test_segment_name: value, uuid: row.uuid })}
    />
  </Cell>
);

export const IdentityTypeCell = ({ row, onChange, identity_types_list, campaign }) => (
  <Cell>
    <Dropdown
      options={identity_types_list.filter((i) => campaign?.supported_identity_type_ids?.includes(i.id))}
      transparent
      option_key="name"
      value={row.identity_type_name}
      onSelect={(item) => onChange({ identity_type_id: item.id, identity_type_name: item.name, uuid: row.uuid })}
    />
  </Cell>
);

export const BudgetWeightCell = ({ row, onChange }) => (
  <Cell>
    <InputNumber
      value={row.allocation_weight}
      transparent
      onChange={(input) =>
        onChange({
          allocation_weight: input.float_value,
          allocation_weight_formatted: input.formatted_value,
          uuid: row.uuid,
        })
      }
    />
  </Cell>
);

export const RemoveCell = ({ row, row_index, onClick }) => {
  if (row.saved || row_index === 0 || !row.modified) {
    return <Cell />;
  }
  return (
    <Cell onClick={() => onClick(row)}>
      <Action tooltip="Remove Segment" icon={['fas', 'times']} color="red" />
    </Cell>
  );
};

import { CLEAR_CREATIVE_MESSAGES_LIST, SET_CREATIVE_MESSAGES_LIST } from '../types';

export const creativeMessagesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CREATIVE_MESSAGES_LIST:
      return action.payload;
    case CLEAR_CREATIVE_MESSAGES_LIST:
      return [];
    default:
      return state;
  }
};

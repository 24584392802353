import { CLEAR_FLIGHT, SET_FLIGHT } from '../types';

export const flightReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_FLIGHT:
      return action.payload;
    case CLEAR_FLIGHT:
      return {};
    default:
      return state;
  }
};

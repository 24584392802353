import * as constants from 'ducks/types';

export const setCampaignCumulativePerformance = (payload = {}) => ({
  type: constants.SET_CAMPAIGN_CUMULATIVE_PERFORMANCE,
  payload,
});

export const clearCampaignCumulativePerformance = () => ({
  type: constants.CLEAR_CAMPAIGN_CUMULATIVE_PERFORMANCE,
});

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action } from 'components/actions/Action';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings } from 'ducks/actions/settings';
import * as PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';
import { getSettings, setSettings } from 'utils/storage';

import cn from './Navigation.module.scss';

export const AdvertiserWithRouter = ({ history, match }) => {
  const dispatch = useDispatch();
  const { advertisers, settings } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      settings: state.settings,
    }),
    shallowEqual,
  );

  const setExpand = () => {
    const current_settings = getSettings();
    const merged_settings = { ...current_settings, expand_advertiser: !settings.expand_advertiser };
    setSettings(merged_settings);
    dispatch(updateSettings(merged_settings));
  };

  const editAdvertiser = () => {
    dispatch(updateModal({ manage_advertiser: true }));
  };

  const { advertiser_id, page } = match.params;
  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const { expand_advertiser } = settings;
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
        />
        <div className={cn.flex} />
        <Action
          tooltip={expand_advertiser ? 'Compress Advertiser' : 'Expand Advertiser'}
          onClick={setExpand}
          icon={expand_advertiser ? ['fas', 'compress'] : ['fas', 'expand']}
        />
        <Action tooltip="Edit Advertiser" onClick={editAdvertiser} icon={['fas', 'edit']} font_size={12} />
      </div>
    </>
  );
};

AdvertiserWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const Advertiser = withRouter(AdvertiserWithRouter);

import { CLEAR_FLIGHT_CREATIVES, SET_FLIGHT_CREATIVES } from '../types';

export const flightCreativesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FLIGHT_CREATIVES:
      return action.payload;
    case CLEAR_FLIGHT_CREATIVES:
      return [];
    default:
      return state;
  }
};

export const selectUserChangeOrders = (change_orders = [], query = {}) => {
  const { change_orders_cancelled } = query;

  return change_orders.filter((c) => {
    // 91 - service account
    if (c.created_by_user_id === 91) {
      return false;
    }
    if (change_orders_cancelled) {
      return true;
    }
    return true;
  });
};

export const selectChangeOrderUpdates = (strategies = []) =>
  strategies.reduce(
    (acc, next) => ({
      strategies_updated: acc.strategies_updated + next.strategy_updated,
      strategies_json_updated: acc.strategies_json_updated + next.strategy_json_count,
      strategies_budget_updated: acc.strategies_budget_updated + next.strategy_budget_count,
      strategies_general_updated: acc.strategies_general_updated + next.strategy_general_count,
      strategies_cumulative_updated:
        acc.strategies_cumulative_updated + next.strategy_cumulative_count + next.strategy_frequency_cap_count,
    }),
    {
      strategies_updated: 0,
      strategies_json_updated: 0,
      strategies_budget_updated: 0,
      strategies_general_updated: 0,
      strategies_cumulative_updated: 0,
    },
  );

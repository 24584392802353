import { CLEAR_ADMIN_USERS, SET_ADMIN_USERS } from '../types';

export const usersAdminReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ADMIN_USERS:
      return action.payload;
    case CLEAR_ADMIN_USERS:
      return [];
    default:
      return state;
  }
};

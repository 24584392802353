import { CLEAR_CATEGORIES_LIST, SET_CATEGORIES_LIST } from '../types';

export const categoriesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CATEGORIES_LIST:
      return action.payload;
    case CLEAR_CATEGORIES_LIST:
      return [];
    default:
      return state;
  }
};

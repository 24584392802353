/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import queries from 'query-string';

export const generateParams = (arg = {}) => {
  let uri = '?';
  // iterate over objects
  // check types for each key
  for (const [key, value] of Object.entries(arg)) {
    if (validateArg(value)) {
      uri = `${uri}${key}=${value}&`;
    }
    if (Array.isArray(value) && !validateArg(value)) {
      if (value.length !== 0) {
        value.forEach((v, i) => {
          const prefix_concat = `${key}[${i}]`;
          uri = `${uri}${recursiveObject(prefix_concat, v, '.')}`;
        });
      }
    }
    if (typeof value === 'object' && !Array.isArray(value) && !validateArg(value) && value !== null) {
      const prefix_concat = `${key}`;
      uri = `${uri}${recursiveObject(prefix_concat, value)}`;
    }
  }
  return uri.replace(/&$/, '');
};

const validateArg = (value) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'undefined' ||
    typeof value === 'boolean' ||
    value === ''
  ) {
    return true;
  }
  return false;
};

const recursiveObject = (prefix, arg, insert = '') => {
  let uri = '';

  if (validateArg(arg)) {
    return `${uri}${prefix}=${arg}&`;
  }

  for (const [key, value] of Object.entries(arg)) {
    if (validateArg(value)) {
      uri = `${uri}${prefix}${insert}${key}=${value}&`;
    }
    if (Array.isArray(value) && !validateArg(value)) {
      if (value.length !== 0) {
        value.forEach((v, i) => {
          const prefix_concat = `${prefix}${insert}${key}[${i}]`;
          uri = `${uri}${recursiveObject(prefix_concat, v, '.')}`;
        });
      }
    }

    if (typeof value === 'object' && !Array.isArray(value) && !validateArg(value) && value !== null) {
      const prefix_concat = `${key}`;
      uri = `${uri}${prefix}${recursiveObject(prefix_concat, value)}`;
    }
  }
  return uri;
};

export const replaceQuery = (query) => `?${queries.stringify(query, { arrayFormat: 'index' })}`;


export const mergeQuery = (uri, query = {}) => {
  const search = parseQueries(uri);
  return replaceQuery({ ...search, ...query });
};

export const mergeQueryReverse = (uri, query = {}) => {
  const search = parseQueries(uri);
  return replaceQuery({ ...query, ...search });
};

export const parseQueries = (uri) => {
  const query = queries.parse(uri, {
    arrayFormat: 'index',
    parseBooleans: true,
    parseNumbers: true,
  });
  for (const property in query) {
    if (query[property] === 'false') {
      query[property] = false;
    }
    if (query[property] === 'true') {
      query[property] = true;
    }
  }
  return query;
};

export const mergeSearchQuery = (uri, query = {}) => {
  const search = parseQueries(uri);
  return replaceQuery({ ...search, ...query });
};

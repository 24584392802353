import * as constants from 'ducks/types';

export const setStrategyTemplate = (payload = {}) => ({
  type: constants.SET_STRATEGY_TEMPLATE,
  payload,
});

export const clearStrategyTemplate = () => ({
  type: constants.CLEAR_STRATEGY_TEMPLATE,
});

export const setStrategyTemplates = (payload = []) => ({
  type: constants.SET_STRATEGY_TEMPLATES,
  payload,
});

export const clearStrategyTemplates = () => ({
  type: constants.CLEAR_STRATEGY_TEMPLATES,
});

export const insertStrategyTemplate = (payload = {}) => ({
  type: constants.INSERT_STRATEGY_TEMPLATE,
  payload,
});

import { CLEAR_PLATFORMS_LIST, SET_PLATFORMS_LIST } from '../types';

export const platformsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PLATFORMS_LIST:
      return action.payload;
    case CLEAR_PLATFORMS_LIST:
      return [];
    default:
      return state;
  }
};

import React from 'react';
import { selectAuthenticate } from 'ducks/selectors/users';
import { PrivateLayout } from 'layouts/PrivateLayout';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { routes } from 'routes/routes';

const Router = ({ component: Component, ...rest }) => {
  const { authenticated } = useSelector((state) => ({
    authenticated: selectAuthenticate(state.user),
  }));

  if (authenticated) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <PrivateLayout location={rest.location} history={rest.history} match={rest.match}>
            <Component {...props} />
          </PrivateLayout>
        )}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: routes.LOGIN,
        state: { referer: rest.location },
      }}
    />
  );
};

Router.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export const PrivateRouter = withRouter(Router);

export const selectCreativeAddOns = (creative_add_ons = [], creative_add_ons_list = []) =>
  creative_add_ons_list.map((add_on) => ({
    ...add_on,
    checked: creative_add_ons.some((c) => c.add_on_id === add_on.add_on_id),
  }));

export const selectCreativeExtras = (creative_extas = [], creative_extas_list = []) =>
  creative_extas_list.map((extra) => ({
    ...extra,
    checked: creative_extas.some((c) => c.creative_extra_id === extra.creative_extra_id),
  }));

import { CLEAR_FILTERS, SET_FILTERS } from '../types';

export const filtersReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FILTERS:
      return action.payload;
    case CLEAR_FILTERS:
      return [];
    default:
      return state;
  }
};

export const transformFrequencyCaps = (data = []) => data.map((d) => transformFrequencyCap(d));

export const transformFrequencyCap = (data = {}) => ({
  impression_count: data.impression_count,
  duration_seconds: data.duration_seconds * data.duration_in_interval,
  state_id: data.state_id,
  identity_type_id: data.identity_type_id,
});

export const transformChangeOrderFrequencyCaps = (data = []) => {
  const filtered_data = data.filter((d) => d.saved || d.modified);
  return filtered_data.map((d) => ({
    impression_count_override: d.impression_count_combined,
    interval_id_override: d.interval_id_combined,
    duration_in_interval_override: d.duration_in_interval_combined,
    state_id_override: d.state_id_combined,
    identity_type_id_override: d.identity_type_id_combined,
  }));
};

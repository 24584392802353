import { formatNumber, formatPercentages } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeStrategyTemplates = (data = []) =>
  data
    .map((d) => {
      const { intervals_per_day, budget_weight, strategy_budget_type_id, daily_overspend_factor } = d;

      const minutes_per_interval = 1440 / intervals_per_day;
      const minutes_per_interval_formatted = formatNumber(minutes_per_interval, {
        format: 'number-comma',
        append: null,
      });
      const intervals_per_day_formatted = formatNumber(intervals_per_day, { format: 'number-comma' });

      const daily_overspend_factor_formatted = formatNumber(daily_overspend_factor, {
        format: 'number-comma',
        append: null,
      });
      let budget_weight_formatted = formatNumber(budget_weight, { format: 'number-comma', append: 0 });

      if (strategy_budget_type_id !== 1) {
        budget_weight_formatted = formatPercentages(budget_weight, { format: 'percentage-fixed', decimals: 3 });
      }
      return {
        ...d,
        daily_overspend_factor_formatted,
        minutes_per_interval,
        minutes_per_interval_formatted,
        intervals_per_day_formatted,
        budget_weight_formatted,
        uuid: uuidv4(),
      };
    })
    .sort((a, b) => checkSort(a, b, 'strategy_template_name', 'asc'));

import { CLEAR_CREATIVE, SET_CREATIVE } from '../types';

export const creativeReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CREATIVE:
      return action.payload;
    case CLEAR_CREATIVE:
      return {};
    default:
      return state;
  }
};

import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { uniqueIdentifiers } from 'utils/helpers';
import { formatNumber } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeKpiGroups = (data, measure_types = [], measure_units = []) =>
  data.map((d) => normalizeKpiGroup(d, measure_types, measure_units));

export const normalizeKpiGroup = (data, measure_types = [], measure_units = []) => {
  const {
    advertiser_name,
    campaign_name,
    p_kpi_measure_type_id,
    p_kpi_dcm_columns,
    p_kpi_target,
    s_kpi_dcm_columns,
    s_kpi_measure_type_id,
    s_kpi_target,
    primary_action_attribution_days,
    secondary_action_attribution_days,
  } = data;

  const p_kpi_measure_type = measure_types.find((m) => m.measure_type_id === p_kpi_measure_type_id) || {
    measure_type_format: 'number',
  };
  const p_kpi_target_formatted = formatNumber(p_kpi_target, { format: p_kpi_measure_type.measure_type_abbreviated });

  const s_kpi_measure_type = measure_types.find((m) => m.measure_type_id === s_kpi_measure_type_id) || {
    measure_type_format: 'number',
  };
  const s_kpi_target_formatted = formatNumber(s_kpi_target, { format: s_kpi_measure_type.measure_type_abbreviated });

  const p_kpi_measure_unit = measure_units.find((m) => m.id === p_kpi_measure_type.measure_unit_id) || {};
  const s_kpi_measure_unit = measure_units.find((m) => m.id === s_kpi_measure_type.measure_unit_id) || {};
  const primary_action_attribution_days_formatted = formatNumber(primary_action_attribution_days, {
    format: 'number-comma',
  });

  const secondary_action_attribution_days_formatted = formatNumber(secondary_action_attribution_days, {
    format: 'number-comma',
  });
  return {
    ...data,
    p_kpi_target_formatted,
    s_kpi_target_formatted,
    p_kpi_measure_type,
    s_kpi_measure_type,
    p_kpi_measure_unit,
    s_kpi_measure_unit,
    primary_action_attribution_days_formatted,
    secondary_action_attribution_days_formatted,
    p_measure_type_format: p_kpi_measure_type.measure_type_format,
    s_measure_type_format: s_kpi_measure_type.measure_type_format,
    p_kpi_dcm_columns: uniqueIdentifiers(p_kpi_dcm_columns),
    s_kpi_dcm_columns: uniqueIdentifiers(s_kpi_dcm_columns),
    advertiser_campaign_name: `${advertiser_name} ${campaign_name}`,
    uuid: uuidv4(),
  };
};

export const normalizeKpiIntervals = (data = []) =>
  data
    .map((row) => ({
      ...row,
      start_date_formatted: formatDate(row.start_date, { format: 'MM/DD/YYYY' }),
      finish_date_formatted: formatDate(row.finish_date, { format: 'MM/DD/YYYY' }),
      saved: true,
      modified: false,
      uuid: uuidv4(),
    }))
    .sort((a, b) => checkSort(a, b, 'kpi_group_interval_name', 'asc'));

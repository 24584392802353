import React from 'react';
import { Button, Modal } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { updateModal } from 'ducks/actions/modals';
import useKeyDownEventHook from 'pages/report/hooks/useKeyDownEventHook';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import cn from './Modal.module.scss';

export const Error = () => {
  const dispatch = useDispatch();
  const { settings, modals } = useSelector(
    (state) => ({
      settings: state.settings,
      modals: state.modals,
    }),
    shallowEqual,
  );

  const closeModal = () => {
    dispatch(updateModal({ api_error: false }));
  };

  useKeyDownEventHook(closeModal, 'api_error');

  const { status, error } = settings;
  return (
    <Modal
      padding={16}
      close_box={46}
      name="api_error"
      show={modals.api_error}
      width={400}
      onClose={closeModal}
      standard={false}
    >
      <div className={cn.errorModal}>
        <div className={cn.warning}>
          <FontAwesomeIcon className={classNames(cn.icon, cn.orange)} icon="exclamation-triangle" />
          {status && <p className={cn.status}>{status}</p>}
        </div>
        {!error && (
          <p className={cn.text}>
            Something went wrong! Please close this popup and try accessing the page again. If the error persists please
            contact Cognitiv.
          </p>
        )}
        {error && <p className={cn.text}>{error}</p>}
        <Button button_size="small" width="100%" variant="orange" onClick={closeModal} margin="20px 0px 0px 0px">
          Ok
        </Button>
      </div>
    </Modal>
  );
};

import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';

export const normalizeReportTypes = (report_types = []) =>
  uniqueIdentifiers(report_types).sort((a, b) => checkSort(a, b, 'report_type_name', 'asc'));

export const normalizeReportTypeParameterGroups = (report_type_parameter_groups = []) =>
  uniqueIdentifiers(report_type_parameter_groups).sort((a, b) => {
    if (a.report_type_name !== b.report_type_name) {
      return a.report_type_name > b.report_type_name ? 1 : -1;
    }
    return a.parameter_group_name > b.parameter_group_name ? 1 : -1;
  });

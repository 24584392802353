import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizePartners = (data = []) =>
  data.map((d) => normalizePartner(d)).sort((a, b) => checkSort(a, b, 'partner_name', 'asc'));

export const normalizePartner = (data = {}) => ({
  ...data,
  uuid: uuidv4(),
});

import React from 'react';
import * as PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import cn from './Navigation.module.scss';

export const ModelsWithRouter = () => {
  const selectModels = () => `/models`;
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.item} to={selectModels}>
          Models
        </Link>
        <div className={cn.flex} />
      </div>
    </>
  );
};

ModelsWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export const Models = withRouter(ModelsWithRouter);

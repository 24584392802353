import React, { useEffect, useState } from 'react';
import { Input, TableFlex } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { updateSettings } from 'ducks/actions/settings';
import { updateModelAdvertisers } from 'ducks/operators/model';
import { useBulkSelect } from 'hooks/useBulkTable';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { AdvertiserIdCell, AdvertiserNameCell, AdvertiserSelectCell, StatusCell } from '../Cells';
import { advertisers_headers, bulk_options_advertisers } from '../constants';

import cn from '../ModelsPage.module.scss';

const FILTER_KEY = 'advertiser_name';

export const AdvertisersAssociation = ({ onChange, model }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);

  const { bulkSelect } = useBulkSelect({
    filter,
    setData,
    bulk_options: bulk_options_advertisers,
    data,
    filter_key: FILTER_KEY,
  });

  useEffect(() => {
    setData(model.model_advertisers);
  }, [model.model_advertisers]);

  const selectAdvertiser = (row) => {
    const new_row = {
      checked: row.checked,
      uuid: row.uuid,
    };
    updateData(new_row);
  };

  const handleOnSelect = (item) => {
    bulkSelect(item, FILTER_KEY);
    onChange('model_advertisers', data);
  };

  const updateData = (row) => {
    setData((prev) => {
      const copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === row.uuid) {
          copy[i] = { ...copy[i], ...row };
        }
      }
      onChange('model_advertisers', copy);

      return copy;
    });
  };

  const handleStatus = (row, state_id) => {
    try {
      dispatch(
        updateModelAdvertisers(
          model.model_id,
          [
            {
              state_id,
              advertiser_id: row.advertiser_id,
            },
          ],
          true,
        ),
      );
    } catch (e) {
      dispatch(updateSettings({ loading: false }));
    }
    dispatch(updateSettings({ loading: false }));
  };

  return (
    <div>
      <Row>
        <Input left_icon={['fas', 'search']} margin="0px 15px 15px 15px" width="300px" onChange={setFilter} />
      </Row>
      <div className={cn.table} style={{ height: 300 }}>
        <TableFlex
          filter={filter}
          onSelect={handleOnSelect}
          headers={advertisers_headers}
          rows={data}
          settings={{
            rows_fill: true,
            filter_key: FILTER_KEY,
          }}
        >
          <AdvertiserSelectCell onClick={selectAdvertiser} />
          <AdvertiserIdCell />
          <AdvertiserNameCell />
          <StatusCell onClick={handleStatus} />
        </TableFlex>
      </div>
    </div>
  );
};

AdvertisersAssociation.propTypes = {
  onChange: PropTypes.func,
  model: PropTypes.object,
};

import { CLEAR_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST, SET_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST } from '../types';

export const adServerCampaignIdentifiersListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST:
      return action.payload;
    case CLEAR_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST:
      return [];
    default:
      return state;
  }
};

import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeCampaignRepresentatives = (data = []) =>
  data.map((d) => normalizeCampaignRepresentative(d)).sort((a, b) => checkSort(a, b, 'user_name', 'desc'));

export const normalizeCampaignRepresentative = (data = {}) => ({
  ...data,
  uuid: uuidv4(),
});

import { CLEAR_VIDEO_PLACEMENTS_LIST, SET_VIDEO_PLACEMENTS_LIST } from '../types';

export const videoPlacementsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_VIDEO_PLACEMENTS_LIST:
      return action.payload;
    case CLEAR_VIDEO_PLACEMENTS_LIST:
      return [];
    default:
      return state;
  }
};

import * as Sentry from '@sentry/browser';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings } from 'ducks/actions/settings';
import { logoutUser } from 'ducks/operators/users';
import store from 'store';

import { deleteStorage } from './storage';

const { REACT_APP_ENVIRONMENT, REACT_APP_VERSION, REACT_APP_SERVICE, REACT_APP_SENTRY_DNS } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DNS,
  release: `${REACT_APP_SERVICE}@${REACT_APP_VERSION}`,
  environment: REACT_APP_ENVIRONMENT,
});

export const onSuccess = (res, resolve, reject, mock) => {
  // capture specific status codes to resolve appropriately
  if (res?.status === 204) {
    // this is used when we expect an empty array or object
    // 204 status will always return an empty string when no data is recieved
    if (mock) {
      resolve({ data: mock });
    } else {
      const error = buildErrorMessage(res);
      error.message = '204: Please contact Cognitiv.';
      error.error = 'Error: If the error persists please contact Cognitiv.';
      reject(error);
    }
  }
  resolve(res);
};

export const onError = (err, reject) => {
  const error = buildErrorMessage(err);

  if (err?.message === 'timeout of 0ms exceeded') {
    error.status = 408;
  }

  if (error.status === 401) {
    error.error = 'Session has Expired';
  }

  if (error.status === 404) {
    error.error = 'Not Found';
  }
  reject(error);
};

export const handleError = async (err) => {
  await store.dispatch(updateSettings({ loading: false }));
  if (err?.status === 401) {
    store.dispatch(logoutUser());
    deleteStorage();
    store.dispatch(updateSettings({ ...err, loading: false }));
    return err;
  }
  await store.dispatch(updateSettings(err));
  await store.dispatch(updateModal({ api_error: true }));

  if (err.message === 'Network Error') {
    return err;
  }
  const { user } = store.getState();
  Sentry.configureScope((scope) => {
    scope.setLevel(REACT_APP_ENVIRONMENT === 'production' ? 'error' : 'warning');
    scope.setExtra('user.name', user.display_name);
    scope.setExtra('organization', user.organization_name);
    scope.setExtra('path', err.path);
    scope.setExtra('enpoint', err.enpoint);
    scope.setExtra('message', err.message);
    scope.setExtra('error', err.error);
    scope.setExtra('status', err.status);
    scope.setExtra('data', err.data);
    scope.setTag('service', REACT_APP_SERVICE);
    scope.setTag('name', `${err.name}: ${err.status}`);
    Sentry.captureException(err.raw);
  });

  return err;
};

export const buildErrorMessage = (res) => ({
  name: res?.name || 'No Content',
  message: res?.message || null,
  endpoint: res?.config?.url || null,
  path: window.location.pathname,
  status: res?.status || res?.response?.status,
  data: res?.response?.data || null,
  error: res?.response?.data?.error || JSON.stringify(res?.data) || null,
  raw: res || null,
});

export const getValidationError = ({ errors, key }) => {
  const error = errors.find((e) => e.key === key);

  return !!error;
};

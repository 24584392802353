import { CLEAR_CAMPAIGN_REPRESENTATIVES, SET_CAMPAIGN_REPRESENTATIVES } from '../types';

export const campaignRepresentativesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CAMPAIGN_REPRESENTATIVES:
      return action.payload;
    case CLEAR_CAMPAIGN_REPRESENTATIVES:
      return [];
    default:
      return state;
  }
};

import { CLEAR_TAG_FIELDS, SET_TAG_FIELDS } from '../types';

export const tagFieldsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TAG_FIELDS:
      return action.payload;
    case CLEAR_TAG_FIELDS:
      return [];
    default:
      return state;
  }
};

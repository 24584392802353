import { CLEAR_VIRTUAL_TAGS, SET_VIRTUAL_TAGS } from '../types';

export const virtualTagsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_VIRTUAL_TAGS:
      return action.payload;
    case CLEAR_VIRTUAL_TAGS:
      return [];
    default:
      return state;
  }
};

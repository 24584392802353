import { CLEAR_LINE_ITEM, SET_LINE_ITEM, UPDATE_LINE_ITEM } from '../types';

const initial_state = {
  line_item_id: null,
  line_item_name: '',
  frequency_caps: [],
  creative_sizes: [],
  configuration_json: {},
};
export const lineItemReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_LINE_ITEM:
      return action.payload;
    case UPDATE_LINE_ITEM:
      return { ...state, ...action.payload };
    case CLEAR_LINE_ITEM:
      return { ...initial_state };
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setCampaignRepresentatives = (payload = {}) => ({
  type: constants.SET_CAMPAIGN_REPRESENTATIVES,
  payload,
});

export const clearCampaignRepresentatives = () => ({
  type: constants.CLEAR_CAMPAIGN_REPRESENTATIVES,
});

import React, { useEffect, useState } from 'react';
import { Modal, Wizard } from '@cognitiv/cyprus-ui';
import { WizardButtons } from 'components/wizard-button/WizardButtons';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { v4 as uuidv4 } from 'uuid';

import { updateModal } from '../ducks/actions/modals';
import { clearModel } from '../ducks/actions/model';
import { getModel, updateModelAdvertisers, updateModelOutputs, updateSingleModel } from '../ducks/operators/model';
import { getModels } from '../ducks/operators/models';
import { selectModelAdvertisers } from '../ducks/selectors/advertisers';
import { AdvertisersAssociation } from '../pages/models/features/AdvertisersAssociation';
import { ConfigurationFeature } from '../pages/models/features/ConfigurationFeature';
import { ModelOutput } from '../pages/models/features/ModelOutput';
import { mergeQuery } from '../utils/queries';

import cn from './Modal.module.scss';

export const EditModel = ({ location, history }) => {
  const dispatch = useDispatch();
  const { model, modals, model_advertisers, advertisers, model_outputs } = useSelector(
    (state) => ({
      model: state.model,
      modals: state.modals,
      model_advertisers: state.model_advertisers,
      advertisers: selectModelAdvertisers(state.advertisers, state.model_advertisers),
      model_outputs: state.model_outputs,
      outputs: state.outputs,
    }),
    shallowEqual,
  );
  const [wizard_width, setWidth] = useState(750);
  const [model_form, setModelForm] = useState({});

  useEffect(() => {
    setModelForm({ ...model, model_advertisers: advertisers, model_outputs });
  }, [model, model_advertisers, model_outputs]);

  const handleOnChangeForm = (field, value) => {
    setModelForm((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const prepare_advertisers = model_form.model_advertisers
        .filter((advertiser) => advertiser.checked)
        .map((item) => ({
          advertiser_id: item.advertiser_id,
          state_id: item.state_id,
        }));
      const prepare_output = model_form.model_outputs.map((output) => ({
        output_index: output.output_index,
        output_name: output.output_name,
        default_score_type_id: output.default_score_type_id,
      }));
      await Promise.all([
        dispatch(
          updateSingleModel(model.model_id, {
            model_display_name: model_form.model_display_name,
            state_id: model_form.state_id,
          }),
        ),
        dispatch(updateModelAdvertisers(model.model_id, prepare_advertisers)),
        dispatch(updateModelOutputs(model.model_id, prepare_output)),
        dispatch(getModel(model.model_id)),
      ]);

      dispatch(updateSuccess(`Model has been updated successfully`));
      closeModal(true);
    } catch (err) {
      handleError({ ...err, no_content: true });
    }
  };

  const closeModal = (refresh) => {
    setWidth(600);
    dispatch(getModels());
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ edit_model: false }));
    dispatch(clearModel());
    if (refresh) {
      const { search, pathname } = location;
      const queries = mergeQuery(search, { update: uuidv4() });
      history.push(`${pathname}${queries}`);
    }
  };

  return (
    <Modal
      padding={0}
      close_box={46}
      name="edit_model"
      show={modals.edit_model}
      width={wizard_width}
      onClose={closeModal}
      standard={false}
      wizard
    >
      <h2 className={cn.header}>Edit Model</h2>
      <form onSubmit={onSubmit}>
        <Wizard
          padding={0}
          theme={{ progress_bar_background: '#e855b6' }}
          buttons={(props) => <WizardButtons {...props} save onClose={closeModal} />}
          onSubmit={onSubmit}
        >
          <ConfigurationFeature onChange={handleOnChangeForm} model={model_form} />
          <AdvertisersAssociation model={model_form} onChange={handleOnChangeForm} />
          <ModelOutput model={model_form} onChange={handleOnChangeForm} />
        </Wizard>
      </form>
    </Modal>
  );
};

import { Gemini } from '../../utils/axios';
import { setBidAlgorithmsList } from '../actions/bid-algorithms';
import { updateSettings } from '../actions/settings';
import { normalizeBidAlgorithms } from '../normalizers/bid-algorithms';

export const getBidAlgorithms = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/bid_algorithms/list?state_id=1');
  const bid_algorithms = normalizeBidAlgorithms(res.data);
  await dispatch(setBidAlgorithmsList(bid_algorithms));
  return bid_algorithms;
};

export const createBidAlgorithms = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post('/bid_algorithms/create', data);
  return res.data;
};

export const updateBidAlgorithms = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/bid_algorithms/update`, data);
  return res.data;
};

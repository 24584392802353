/* eslint-disable no-async-promise-executor */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable promise/param-names */
import axios from 'axios';
import { updateSettings } from 'ducks/actions/settings';
import { Gemini } from 'utils/axios';
import { uniqueIdentifiers } from 'utils/helpers';

export const getKeywordNeighbors = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const payload = {
    keyword: '',
    ...data,
  };
  const res = await Gemini.get(`/keywords/nearest_neighbors?keyword=${payload.keyword}`);
  const keywords = uniqueIdentifiers(res.data);
  await dispatch(updateSettings({ loading: false }));
  return keywords;
};

export const getKeywordDomains = (data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const payload = {
    keyword: '',
    threshold: 0.5,
    ...data,
  };

  const res = await new Promise(async (resolve, reject) => {
    const cancelTokenSource = axios.CancelToken.source();
    const res = Gemini.get(
      `/keywords/url_samples?keyword=${payload.keyword}&threshold=${payload.threshold}`,
      cancelTokenSource,
    );
    for (let i = 0; i < 20; i++) {
      res.then((r) => resolve(r));
      await new Promise((r) => setTimeout(r, 1000));
    }
    cancelTokenSource.cancel();
    reject({ error: 'The HTTP response has timed out, please try again momentarily.' });
  });

  const domains = uniqueIdentifiers(res.data);
  await dispatch(updateSettings({ loading: false }));
  return domains;
};

export const getKeywordFilter = (data) => async (dispatch) => {
  const payload = {
    keyword: '',
    threshold: 0.5,
    ...data,
  };
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/keywords/filter_json?keyword=${payload.keyword}&threshold=${payload.threshold}`);
  await dispatch(updateSettings({ loading: false }));
  return JSON.parse(res.data.replace(/'/g, '"'));
};

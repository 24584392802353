import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal, Table } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { clearDeals } from 'ducks/actions/deals';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createStrategyDeals } from 'ducks/operators/deals';
import { mergeStrategyDeals } from 'ducks/selectors/deals';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery, parseQueries } from 'utils/queries';

import {
  DealCell,
  InventorySourceCell,
  MaxPriceCell,
  MinPriceCell,
  SelectCell,
  SourceIdentifierCell,
  StartDateCell,
} from './ManageStrategyDealsCells';

import cn from './Modal.module.scss';

const headers = [
  {
    name: '',
    width: '35px',
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'Effective Date',
    width: '120px',
    uuid: 2, // required key
  },
  {
    name: 'Deal',
    width: '100px',
    flex_grow: 1,
    uuid: 3, // required key
  },
  {
    name: 'Inventory Source',
    width: '150px',
    uuid: 4, // required key
  },
  {
    name: 'Source Identifier',
    width: '150px',
    uuid: 5, // required key
  },
  {
    name: 'Min. Price (CPM)',
    width: '120px',
    uuid: 6, // required key
  },
  {
    name: 'Max. Price (CPM)',
    width: '120px',
    uuid: 7, // required key
  },
];

export const ManageStrategyDeals = ({ location, history }) => {
  const dispatch = useDispatch();

  const { deals, global_deals, strategy_deals, modals } = useSelector(
    (state) => ({
      deals: state.deals,
      global_deals: state.global_deals,
      strategy_deals: mergeStrategyDeals(state.global_deals, state.deals),
      modals: state.modals,
    }),
    shallowEqual,
  );

  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(strategy_deals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deals, global_deals]);

  const closeModal = () => {
    dispatch(clearDeals());
    setData([]);

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_strategy_deals: false }));
  };

  const selectDeal = (row) => {
    const new_row = {
      checked: row.checked,
      uuid: row.uuid,
    };
    if (!row.deal_strategy_id) {
      updateData(new_row);
    }
  };

  const updateData = (row) => {
    setData((prev) => {
      const copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === row.uuid) {
          copy[i] = { ...copy[i], ...row };
        }
      }
      return copy;
    });
  };

  const onSubmit = async () => {
    try {
      const data_filtered = data
        .filter((d) => d.checked && !d.deal_strategy_id)
        .map((d) => ({ deal_id: d.deal_id, strategy_id: query.strategy_id }));

      await dispatch(createStrategyDeals(data_filtered));
      dispatch(updateSuccess('strategy deals have been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const onChange = (query) => {
    const { pathname, search } = location;
    const queries = mergeQuery(search, query);
    history.push(`${pathname}${queries}`);
  };

  const filterData = () =>
    data.filter((d) => {
      if (query.associated_deals) {
        return d.strategy_id === Number(query.strategy_id);
      }
      return true;
    });

  const { search } = location;
  const query = parseQueries(search);

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_strategy_deals"
      show={modals.manage_strategy_deals}
      width={1000}
      onClose={closeModal}
      standard={false}
    >
      <h3>Manage Strategy Deals</h3>
      <Row>
        <Input left_icon={['fas', 'search']} width="300px" margin="0px 0px 15px 0px" onChange={setFilter} />
        <div className={cn.flex} />
        <Checkbox
          onClick={() => onChange({ associated_deals: !query.associated_deals })}
          checked={query.associated_deals}
          margin="auto 0px 10px auto"
          width="max-content"
        >
          Associated Deals
        </Checkbox>
      </Row>
      <Table headers={headers} rows={filterData()} filter={filter} settings={{ filter_key: 'deal_name' }}>
        <SelectCell onClick={selectDeal} />
        <StartDateCell />
        <DealCell />
        <InventorySourceCell />
        <SourceIdentifierCell />
        <MinPriceCell />
        <MaxPriceCell />
      </Table>
      <div className={cn.buttonContainer}>
        <div className={cn.flex} />
        <Button onClick={onSubmit} button_size="small" type="submit">
          Associate Deals
        </Button>
      </div>
    </Modal>
  );
};

ManageStrategyDeals.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

import { setLogs } from 'ducks/actions/logs';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeAuditLogs, normalizeChangeOrderAuditLogs } from 'ducks/normalizers/audit-logs';
import { Gemini } from 'utils/axios';

export const getAuditLogPage =
  (queries = {}) =>
  async (dispatch) => {
    dispatch(updateSettings({ loading: true }));

    const query = {
      advertiser_id: null,
      campaign_id: null,
      filter_id: null,
      ...queries,
    };
    const [logs] = await Promise.all([dispatch(getAuditLog(query))]);
    dispatch(updateSettings({ loading: false }));
    return logs;
  };

export const getAuditLog =
  (queries = {}) =>
  async (dispatch) => {
    dispatch(updateSettings({ loading: true }));
    const query = {
      advertiser_id: null,
      campaign_id: null,
      filter_id: null,
      ...queries,
    };

    let res = {};
    if (query.advertiser_id) {
      res = await Gemini.get(`/advertisers/${query.advertiser_id}/creatives/audit_log/view`);
    }
    if (query.campaign_id) {
      res = await Gemini.get(`/campaigns/${query.campaign_id}/audit_log/view`);
    }
    if (query.filter_id) {
      res = await Gemini.get(`/filters/${query.filter_id}/audit_log/view`);
    }
    const logs = normalizeAuditLogs(res.data);
    dispatch(setLogs(logs));
    return logs;
  };

export const getChangeOrderAuditLog = (entity_id) => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/change_orders/${entity_id}/conversations`);
  const audit_logs = await normalizeChangeOrderAuditLogs(res.data);
  await dispatch(setLogs(audit_logs));
  return audit_logs;
};

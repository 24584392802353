import * as constants from 'ducks/types';

export const setAdvertiserReports = (payload = []) => ({
  type: constants.SET_ADVERTISER_REPORTS,
  payload,
});

export const clearAdvertiserReports = () => ({
  type: constants.CLEAR_ADVERTISER_REPORTS,
});

export const setAdvertiserReport = (payload = []) => ({
  type: constants.SET_ADVERTISER_REPORT,
  payload,
});

export const clearAdvertiserReport = () => ({
  type: constants.CLEAR_ADVERTISER_REPORT,
});

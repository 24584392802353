import { CLEAR_ATTRIBUTION_TYPES_LIST, SET_ATTRIBUTION_TYPES_LIST } from '../types';

export const attributionTypesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ATTRIBUTION_TYPES_LIST:
      return action.payload;
    case CLEAR_ATTRIBUTION_TYPES_LIST:
      return [];
    default:
      return state;
  }
};

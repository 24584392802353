import { CLEAR_PORTFOLIO_MARGIN, SET_PORTFOLIO_MARGIN } from '../types';

// empty header needs to be available for the table
const initial_state = {
  headers: [
    {
      name: 'N/A',
      flex_grow: 1,
      children: [
        { name: 'Revenue', flex_grow: 1, uuid: 2 },
        { name: 'Cost', flex_grow: 1, uuid: 3 },
        { name: 'Margin', flex_grow: 1, uuid: 4 },
        { name: 'Margin %', flex_grow: 1, uuid: 5 },
      ],
      uuid: 1,
    },
  ],
  rows: [],
};
export const portfolioMarginReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_PORTFOLIO_MARGIN:
      return action.payload;
    case CLEAR_PORTFOLIO_MARGIN:
      return { ...initial_state };
    default:
      return state;
  }
};

import { CLEAR_CAMPAIGN, SET_CAMPAIGN } from '../types';

export const campaignReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CAMPAIGN:
      return action.payload;
    case CLEAR_CAMPAIGN:
      return {};
    default:
      return state;
  }
};

import * as constants from 'ducks/types';

export const setCreative = (payload = {}) => ({
  type: constants.SET_CREATIVE,
  payload,
});

export const clearCreative = () => ({
  type: constants.CLEAR_CREATIVE,
});

export const setCreatives = (payload = {}) => ({
  type: constants.SET_CREATIVES,
  payload,
});

export const clearCreatives = () => ({
  type: constants.CLEAR_CREATIVES,
});

import { CLEAR_INVENTORY_SOURCES_LIST, SET_INVENTORY_SOURCES_LIST } from '../types';

export const inventorySourcesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_INVENTORY_SOURCES_LIST:
      return action.payload;
    case CLEAR_INVENTORY_SOURCES_LIST:
      return [];
    default:
      return state;
  }
};

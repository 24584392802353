import * as constants from 'ducks/types';

export const setPartner = (payload = {}) => ({
  type: constants.SET_PARTNER,
  payload,
});

export const clearPartner = () => ({
  type: constants.CLEAR_PARTNER,
});

export const setPartners = (payload = {}) => ({
  type: constants.SET_PARTNERS,
  payload,
});

export const clearPartners = () => ({
  type: constants.CLEAR_PARTNERS,
});

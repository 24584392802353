import { CLEAR_PORTFOLIO_OVERVIEW, SET_PORTFOLIO_OVERVIEW } from '../types';

export const portfolioOverviewReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PORTFOLIO_OVERVIEW:
      return action.payload;
    case CLEAR_PORTFOLIO_OVERVIEW:
      return [];
    default:
      return state;
  }
};

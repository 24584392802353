import { colors } from 'utils/colors';
import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { getColorFromRange } from 'utils/helpers';
import { formatNumber } from 'utils/numbers';
import { checkSort, getCharCount } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeAbTestSegments = (segments = []) =>
  segments.map((d, i) => normalizeAbTestSegment(d, i)).sort((a, b) => checkSort(a, b, 'segment_name', 'asc'));

export const normalizeAbTestSegment = (row = {}, index) => {
  const count = getCharCount(String(row.ab_test_segment_id) || index);

  return {
    ...row,
    color: getColorFromRange(colors, count),
    created_date_formatted: formatDate(row.created_date, { format: 'M/D/YY h:mm:ss A' }),
    created_date_local_formatted: offsetTimezoneUTC(row.created_date, { format: 'M/D/YY h:mm:ss A' }),
    uuid: uuidv4(),
  };
};

export const normalizeSegments = (data, tags = []) =>
  data.map((d, i) => normalizeSegment(d, tags, i)).sort((a, b) => checkSort(a, b, 'segment_name', 'asc'));

export const normalizeSegment = (data, tags = [], index) => {
  const { tag_ids, ttl_days } = data;

  const segment_tags = tags.filter((tag) => tag_ids.includes(tag.tag_id));
  let segment_tag_name = '';

  if (segment_tags.length === 1) {
    segment_tag_name = segment_tags[0].tag_name;
  }
  if (segment_tags.length > 1) {
    segment_tag_name = `Selected ${segment_tags.length} Tags`;
  }

  const ttl_days_formatted = formatNumber(ttl_days, { format: 'number-comma' });

  return {
    ...data,
    color: colors[(data.segment_id || index) % colors.length],
    created_date_formatted: formatDate(data.created_date, { format: 'M/D/YY h:mm:ss A' }),
    created_date_local_formatted: offsetTimezoneUTC(data.created_date, { format: 'M/D/YY h:mm:ss A' }),
    segment_tag_name,
    tag_count: segment_tags.length,
    ttl_days_formatted,
    segment_name_with_id: `${data.segment_id} ${data.segment_name}`,
    uuid: uuidv4(),
  };
};

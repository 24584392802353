import React from 'react';
import { Input, InputNumber } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import PropTypes from 'prop-types';
import { getTimeZone } from 'utils/timeZone';

import cn from './Features.module.scss';

export const ConfigurationFeature = ({ onChange, model }) => {
  const {
    model_id,
    model_display_name,
    advertisers_count,
    strategies_count,
    created_date_formatted,
    imported_date_formatted,
  } = model || {};

  return (
    <div className={cn.configuration}>
      <Row padding="15px">
        <Input
          label="Model Display Name"
          value={model_display_name}
          onChange={(value) => onChange('model_display_name', value)}
          margin="0px 7px 0px 0px"
          width="calc(50% - 7px)"
        />
        <Input
          label="Model ID"
          text_align="right"
          disabled
          value={model_id}
          margin="0px 0px 0px 7px"
          width="calc(50% - 7px)"
        />
      </Row>
      <Row padding="0px 15px 15px 15px">
        <InputNumber
          label="Advertiser Count"
          disabled
          value={advertisers_count ?? 0}
          margin="0px 7px 0px 0px"
          width="calc(50% - 7px)"
        />
        <InputNumber
          label="Strategies Count"
          disabled
          value={strategies_count ?? 0}
          margin="0px 0px 0px 7px"
          width="calc(50% - 7px)"
        />
      </Row>
      <Row padding="0px 15px 15px 15px">
        <Input
          label={`Created Date (${getTimeZone()})`}
          value={created_date_formatted}
          disabled
          margin="0px 7px 0px 0px"
          width="calc(50% - 7px)"
        />
        <Input
          label={`Imported Date (${getTimeZone()})`}
          value={imported_date_formatted}
          margin="0px 0px 0px 7px"
          width="calc(50% - 7px)"
          disabled
        />
      </Row>
    </div>
  );
};

ConfigurationFeature.propTypes = {
  onChange: PropTypes.func,
  model: PropTypes.any,
};

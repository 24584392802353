import { CLEAR_REPORT_FOLDER, SET_REPORT_FOLDER } from '../types';

const initial_state = {
  folder_id: null,
  parent_folder_id: null,
  folder_name: '',
  identity_id: null,
  owned_by: '',
  is_archived: false,
  created_date: '',
};
export const reportFolderReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_REPORT_FOLDER:
      return action.payload;
    case CLEAR_REPORT_FOLDER:
      return { ...initial_state };
    default:
      return state;
  }
};

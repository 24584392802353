import { CLEAR_CAMPAIGN_KPI_PERFORMANCE, SET_CAMPAIGN_KPI_PERFORMANCE } from '../types';

const initial_state = {
  primary_measure_unit_id: '',
  secondary_measure_unit_id: '',
  primary_measure_unit_format: '',
  secondary_measure_unit_format: '',
  primary_kpi: '',
  secondary_kpi: '',
  tooltips: [],
  x: [],
  y1: [],
  y2: [],
  y3: [],
  y4: [],
  y5: [],
  y6: [],
  y7: [],
  y8: [],
};
export const campaignKpiPerformanceReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_KPI_PERFORMANCE:
      return action.payload;
    case CLEAR_CAMPAIGN_KPI_PERFORMANCE:
      return { ...initial_state };
    default:
      return state;
  }
};

import { CLEAR_METRO_AREAS_LIST, SET_METRO_AREAS_LIST } from '../types';

export const metroAreasListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_METRO_AREAS_LIST:
      return action.payload;
    case CLEAR_METRO_AREAS_LIST:
      return [];
    default:
      return state;
  }
};

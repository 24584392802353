import * as constants from 'ducks/types';

export const setLogs = (payload = {}) => ({
  type: constants.SET_LOGS,
  payload,
});

export const clearLogs = () => ({
  type: constants.CLEAR_LOGS,
});

import { CLEAR_LIST, SET_LIST } from '../types';

export const listReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LIST:
      return action.payload;
    case CLEAR_LIST:
      return {};
    default:
      return state;
  }
};

import { CLEAR_AB_TEST_SEGMENT, SET_AB_TEST_SEGMENT } from '../types';

export const abTestSegmentReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_AB_TEST_SEGMENT:
      return action.payload;
    case CLEAR_AB_TEST_SEGMENT:
      return {};
    default:
      return state;
  }
};

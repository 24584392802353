import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useKeyDownEventHook = (callback, modal_name) => {
  const modals = useSelector((state) => state.modals, shallowEqual);

  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && modals[modal_name]) {
        e.preventDefault();

        const selected_all = document.querySelectorAll('#modal');
        const arrayNodes = Array.prototype.slice.call(selected_all, 0);
        const reversed_array = arrayNodes.reverse();
        for (const modal of reversed_array) {
          const modal_name = modal.getAttribute('name');
          if (modals[modal_name]) {
            callback(e, modal_name);
            break;
          }
        }
      }
    },
    [callback, modal_name, modals],
  );
  useEffect(() => {
    if (modals[modal_name]) {
      document.addEventListener('keydown', handleOnKeyDown);
    } else {
      document.removeEventListener('keydown', handleOnKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [handleOnKeyDown, modal_name, modals, modals.api_error]);
};

export default useKeyDownEventHook;

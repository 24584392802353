export const thirdPartyReportValidation = (third_party_report = {}) => {
  const {
    third_party_report_name,
    third_party_report_source_id,
    third_party_report_delivery_id,
    third_party_report_cadence_id,
    initial_expected_delivery_date,
  } = third_party_report;
  if (!third_party_report_name) {
    return false;
  }
  if (!third_party_report_source_id) {
    return false;
  }
  if (!third_party_report_delivery_id) {
    return false;
  }
  if (!third_party_report_cadence_id) {
    return false;
  }
  if (!initial_expected_delivery_date) {
    return false;
  }
  return true;
};

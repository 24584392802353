import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action } from 'components/actions/Action';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings } from 'ducks/actions/settings';
import * as PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';
import { getSettings, setSettings } from 'utils/storage';

import cn from './Navigation.module.scss';

export const CampaignWithRouter = ({ history, match }) => {
  const dispatch = useDispatch();
  const { advertisers, campaigns, settings } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      campaigns: state.campaigns,
      settings: state.settings,
    }),
    shallowEqual,
  );

  const manageFrequencyCaps = () => {
    dispatch(updateModal({ manage_campaign_frequency_caps: true }));
  };

  const setExpand = () => {
    const current_settings = getSettings();
    const merged_settings = { ...current_settings, compress_campaign: !settings.compress_campaign };
    setSettings(merged_settings);
    dispatch(updateSettings(merged_settings));
  };

  const editCampaign = () => {
    dispatch(updateModal({ manage_campaign: true }));
  };

  const { advertiser_id, campaign_id, page } = match.params;

  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const campaign = campaigns.find((r) => Number(r.campaign_id) === Number(campaign_id)) || {};
  const { compress_campaign } = settings;
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  const campaign_width = getTextWidth(campaign.campaign_name);
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
          locations={location}
          color="#1D1D1D"
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/overview`)}
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          match={match}
          labels={campaign.campaign_name}
          values={campaigns}
          page="Campaign"
          width={campaign_width}
          height={47}
        />
        <div className={cn.flex} />
        <Action
          tooltip={!compress_campaign ? 'Compress Campaign' : 'Expand Campaign'}
          onClick={setExpand}
          icon={!compress_campaign ? ['fas', 'compress'] : ['fas', 'expand']}
        />
        <Action tooltip="Campaign Frequency Caps" onClick={manageFrequencyCaps} icon={['far', 'waveform']} />
        <Action tooltip="Edit Campaign" onClick={editCampaign} icon={['fas', 'edit']} font_size={12} />
      </div>
    </>
  );
};

CampaignWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const Campaign = withRouter(CampaignWithRouter);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action } from 'components/actions/Action';
import { MegaMenu } from 'components/mega-menu/megaMenu';
import { updateSettings } from 'ducks/actions/settings';
import * as PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';
import { mergeQuery } from 'utils/queries';
import { getSettings, setSettings } from 'utils/storage';

import cn from './Navigation.module.scss';

export const LineItemWithRouter = ({ history, match, location }) => {
  const dispatch = useDispatch();
  const { advertisers, campaigns, flights, line_items, settings } = useSelector(
    (state) => ({
      advertisers: state.advertisers,
      campaigns: state.campaigns,
      flights: state.flights,
      line_items: state.line_items,
      settings: state.settings,
    }),
    shallowEqual,
  );

  const setExpand = () => {
    const current_settings = getSettings();
    const merged_settings = { ...current_settings, expand_line_item: !settings.expand_line_item };
    setSettings(merged_settings);
    dispatch(updateSettings(merged_settings));
  };

  const { search } = location;
  const { advertiser_id, campaign_id, flight_id, line_item_id, page } = match.params;
  const advertiser = advertisers.find((r) => Number(r.advertiser_id) === Number(advertiser_id)) || {};
  const campaign = campaigns.find((r) => Number(r.campaign_id) === Number(campaign_id)) || {};
  const flight = flights.find((r) => Number(r.flight_id) === Number(flight_id)) || {};
  const line_item = line_items.find((r) => Number(r.line_item_id) === Number(line_item_id)) || {};
  const { expand_line_item } = settings;
  const queries = mergeQuery(search);
  const line_item_width = getTextWidth(line_item.line_item_name);
  const advertiser_width = getTextWidth(advertiser.advertiser_name);
  const campaign_width = getTextWidth(campaign.campaign_name);
  const flight_width = getTextWidth(line_item.flight_name);
  return (
    <>
      <div className={cn.items}>
        <Link className={cn.page} to="/advertisers">
          Advertisers
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={advertiser.advertiser_name}
          values={advertisers}
          page="Advertiser"
          width={advertiser_width}
          height={200}
          locations={location}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/overview`)}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={campaign.campaign_name}
          values={campaigns}
          page="Campaign"
          width={campaign_width}
          height={47}
          onKeyDown={() => history.push(`/advertisers/${advertiser_id}/campaigns/${campaign_id}/flights`)}
          color="#1D1D1D"
          iconEnable={false}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={line_item.flight_name}
          values={flights}
          page="Flight"
          width={flight_width}
          onKeyDown={() =>
            history.push(
              `/advertisers/${advertiser_id}/campaigns/${campaign_id}/flights/${flight_id}/line-items?highlights=trues`,
            )
          }
          iconEnable={false}
          color="#1D1D1D"
          height={200}
        />
        <FontAwesomeIcon icon={['fas', 'chevron-right']} className={cn.icon} />
        <MegaMenu
          labels={line_item.line_item_name}
          values={line_items}
          page="LineItem"
          width={line_item_width}
          height={200}
        />
        <div className={cn.flex} />
        <Action
          tooltip={expand_line_item ? 'Compress Line Item' : 'Expand Line Item'}
          onClick={setExpand}
          icon={expand_line_item ? ['fas', 'compress'] : ['fas', 'expand']}
        />
      </div>
    </>
  );
};

LineItemWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const LineItem = withRouter(LineItemWithRouter);

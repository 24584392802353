import * as constants from 'ducks/types';

export const setCampaignMetrics = (payload = {}) => ({
  type: constants.SET_CAMPAIGN_METRICS,
  payload,
});

export const clearCampaignMetrics = () => ({
  type: constants.CLEAR_CAMPAIGN_METRICS,
});

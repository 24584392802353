import * as constants from 'ducks/types';

export const setFlight = (payload = {}) => ({
  type: constants.SET_FLIGHT,
  payload,
});

export const clearFlight = () => ({
  type: constants.CLEAR_FLIGHT,
});

export const setFlights = (payload = {}) => ({
  type: constants.SET_FLIGHTS,
  payload,
});

export const updateFlights = (payload = {}) => ({
  type: constants.UPDATE_FLIGHTS,
  payload,
});

export const clearFlights = () => ({
  type: constants.CLEAR_FLIGHTS,
});

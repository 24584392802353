import * as constants from 'ducks/types';

export const setTagFields = (payload = []) => ({
  type: constants.SET_TAG_FIELDS,
  payload,
});

export const clearTagFields = () => ({
  type: constants.CLEAR_TAG_FIELDS,
});

import React from 'react';
import { CreateCircle } from 'components/icons/CreateCircle';
import { updateModal } from 'ducks/actions/modals';
import { clearVendorFees } from 'ducks/actions/vendor-fees';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import cn from './Navigation.module.scss';

export const VendorFeesWithRouter = () => {
  const dispatch = useDispatch();

  const selectDeals = () => `/vendor-fees`;

  const createVendorFee = () => {
    dispatch(clearVendorFees());
    dispatch(updateModal({ manage_vendor_fee: true }));
  };

  return (
    <>
      <div className={cn.items}>
        <Link className={cn.item} to={selectDeals}>
          Vendor Fees
        </Link>
        <div className={cn.flex} />
      </div>
      <CreateCircle tooltip="Create Vendor Fee" onClick={createVendorFee} />
    </>
  );
};

VendorFeesWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export const VendorFees = withRouter(VendorFeesWithRouter);

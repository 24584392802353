import React from 'react';
import { Button, Modal } from '@cognitiv/cyprus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateModal } from 'ducks/actions/modals';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import cn from './Modal.module.scss';

const SuccessResetWithRouter = ({ history }) => {
  const dispatch = useDispatch();
  const { success_reset } = useSelector((state) => state.modals);

  const closeModal = async () => {
    history.push('/login');
    dispatch(updateModal({ success_reset: false }));
  };

  return (
    <Modal name="success_reset" show={success_reset} width={400} padding={0} onClose={closeModal}>
      <div className={cn.modalMessage}>
        <div className={cn.iconContainer}>
          <FontAwesomeIcon className={cn.icon} icon={['far', 'shield-check']} />
        </div>
        <p className={cn.text} style={{ textAlign: 'center', wordBreak: 'normal', margin: '10px 0px' }}>
          Password has successfully been updated! Please navigate back to the login page and authenticate with your new
          credentials.
        </p>
        <div className={cn.buttonContainer}>
          <Button button_size="small" width="100%" onClick={closeModal}>
            Back to Login
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SuccessResetWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const SuccessReset = withRouter(SuccessResetWithRouter);

import React, { useEffect, useState } from 'react';
import { Button, DropdownIcon, Input, Modal, Table } from '@cognitiv/cyprus-ui';
import { Row } from 'components/row/Row';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { upsertCampaignFrequencyCaps } from 'ducks/operators/frequency-caps';
import { transformFrequencyCaps } from 'ducks/transforms/frequency-caps';
import { useBulkSelect } from 'hooks/useBulkTable';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import {
  FrequencyCapNameCell,
  IdentityTypeCell,
  ImpressionCell,
  IntervalCountCell,
  IntervalTypeCell,
  SelectCell,
} from './ManageCampaignFrequencyCapsCells';

import cn from './Modal.module.scss';

export const headers = [
  {
    name: '',
    width: '35px',
    bulk_select: true,
    resize: false,
    uuid: 1, // required key
  },
  {
    name: 'Frequency Cap Name',
    width: '200px',
    flex_grow: 1,
    uuid: 5, // required key
  },
  {
    name: 'Impressions',
    width: '150px',
    uuid: 2, // required key
  },
  {
    name: 'Interval Type',
    width: '150px',
    uuid: 3, // required key
  },
  {
    name: 'Interval Count',
    width: '150px',
    uuid: 4, // required key
  },
  {
    name: 'Identity Type',
    width: '150px',
    uuid: 6, // required key
  },
];

const bulk_options = [
  {
    name: 'Activate Frequency Cap',
    state_id: 1,
    uuid: 1, // required key
  },
  {
    name: 'Deactivate Frequency Cap',
    state_id: 3,
    uuid: 2, // required key
  },
];

const default_row = {
  duration_formatted: null,
  duration_in_interval: null,
  duration_seconds: null,
  impression_count: null,
  impression_count_formatted: null,
  interval_id: null,
  interval_name: '',
  is_campaign_frequency_cap: true,
  is_line_item_frequency_cap: true,
  is_strategy_frequency_cap: false,
  state_id: 1,
  state_name: 'Active',
  modified: false,
  saved: false,
  identity_type_id: null,
  identity_type_name: '',
};

const FILTER_KEY = 'frequency_cap_name';
export const ManageCampaignFrequencyCaps = ({ location, history, match }) => {
  const dispatch = useDispatch();
  const { campaign, frequency_caps, campaign_frequency_caps, frequency_intervals_list, modals, identity_types_list } =
    useSelector(
      (state) => ({
        frequency_intervals_list: state.frequency_intervals_list,
        campaign: state.campaign,
        frequency_caps: state.frequency_caps,
        campaign_frequency_caps: state.frequency_caps.filter((frequency_cap) => frequency_cap.state_id === 1),
        modals: state.modals,
        identity_types_list: state.identity_types_list,
      }),
      shallowEqual,
    );

  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);
  const { bulkSelect, hydrateOptions } = useBulkSelect({ filter, data, setData, bulk_options, filter_key: FILTER_KEY });

  useEffect(() => {
    setData([...campaign_frequency_caps, { ...default_row, campaign_id: campaign.campaign_id, uuid: uuidv4() }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.campaign_id, frequency_caps]);

  const closeModal = () => {
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_campaign_frequency_caps: false }));
  };

  const selectFrequencyCap = (row) => {
    const new_row = {
      checked: row.checked,
      uuid: row.uuid,
    };
    updateData(new_row);
  };

  const updateData = (row) => {
    setData((prev) => {
      const copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].uuid === row.uuid) {
          copy[i] = { ...copy[i], ...row };
        }
      }
      return copy;
    });
  };

  const onSelect = async ({ state_id: checked_state_id }) => {
    try {
      const { pathname, search } = location;
      const { campaign_id } = match.params;
      const queries = mergeQuery(search, { update: uuidv4() });

      const filtered_frequency_caps = data.filter((d) => d.saved || d.modified);
      const frequency_caps = filtered_frequency_caps.map(
        ({ impression_count, duration_in_interval, identity_type_id, duration_seconds, state_id, checked }) => ({
          impression_count,
          state_id: checked ? checked_state_id : state_id,
          identity_type_id,
          duration_seconds,
          duration_in_interval,
        }),
      );
      const transform_frequency_caps = transformFrequencyCaps(frequency_caps);

      await dispatch(upsertCampaignFrequencyCaps(campaign_id, transform_frequency_caps));

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('frequency caps have been successfully updated'));
      closeModal();
    } catch (err) {
      handleError(err);
    }
  };

  const onSubmit = async () => {
    try {
      const { pathname, search } = location;
      const { campaign_id } = match.params;
      const queries = mergeQuery(search, { update: uuidv4() });
      const filtered_data = data.filter((d) => d.saved || d.modified);
      const frequency_caps = transformFrequencyCaps(filtered_data);

      await dispatch(upsertCampaignFrequencyCaps(campaign_id, frequency_caps));

      history.push(`${pathname}${queries}`);
      dispatch(updateSuccess('frequency caps have been successfully updated'));
      closeModal();
    } catch (err) {
      handleError(err);
    }
  };

  const onUpdate = (row) => {
    setData((prev) => {
      const prev_copy = [...prev];
      const last_index = prev_copy.length - 1;

      for (let i = 0; i < prev_copy.length; i++) {
        if (prev_copy[i].uuid === row.uuid) {
          prev_copy[i] = { ...prev_copy[i], ...row, modified: true };
          break;
        }
      }

      if (prev_copy[last_index].modified) {
        prev_copy.push({ ...default_row, uuid: uuidv4() });
      }

      return prev_copy;
    });
  };

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_campaign_frequency_caps"
      show={modals.manage_campaign_frequency_caps}
      width={900}
      onClose={closeModal}
      standard={false}
    >
      <h3 style={{ paddingBottom: 5 }}>Manage Campaign Frequency Caps</h3>
      <Row>
        <Input left_icon={['fas', 'search']} margin="15px 0px 15px 0px" width="300px" onChange={setFilter} />
        <div className={cn.flex} />

        <DropdownIcon
          options={hydrateOptions}
          icon={['fas', 'ellipsis-v']}
          tooltip="Bulk Menu"
          onSelect={onSelect}
          transparent
          height={30}
          margin="15px 0px 15px 0px"
        />
      </Row>
      <Table
        headers={headers}
        rows={data}
        filter={filter}
        onSelect={(item) => bulkSelect(item, FILTER_KEY)}
        settings={{ filter_key: FILTER_KEY }}
      >
        <SelectCell onClick={selectFrequencyCap} />
        <FrequencyCapNameCell />
        <ImpressionCell onChange={onUpdate} />
        <IntervalTypeCell options={frequency_intervals_list} onChange={onUpdate} />
        <IntervalCountCell onChange={onUpdate} />
        <IdentityTypeCell identity_types_list={identity_types_list} campaign={campaign} onChange={onUpdate} />
      </Table>

      <div className={cn.buttonContainer}>
        <div className={cn.flex} />
        <Button onClick={onSubmit} button_size="small" width="100px">
          Save
        </Button>
      </div>
    </Modal>
  );
};

ManageCampaignFrequencyCaps.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

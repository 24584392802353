import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';

export const normalizeParameters = (parameters = []) =>
  uniqueIdentifiers(parameters).sort((a, b) => checkSort(a, b, 'parameter_group_name', 'asc'));

export const normalizeParameterTypes = (parameter_types = []) =>
  uniqueIdentifiers(parameter_types).sort((a, b) => checkSort(a, b, 'parameter_type_name', 'asc'));

export const normalizeParameterGroups = (parameter_groups = []) =>
  uniqueIdentifiers(parameter_groups).sort((a, b) => checkSort(a, b, 'parameter_group_name', 'asc'));

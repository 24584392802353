import { CLEAR_FILTER_SOURCES_LIST, SET_FILTER_SOURCES_LIST } from '../types';

export const filterSourcesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FILTER_SOURCES_LIST:
      return action.payload;
    case CLEAR_FILTER_SOURCES_LIST:
      return [];
    default:
      return state;
  }
};

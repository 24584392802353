import { CLEAR_SETTINGS, UPDATE_SETTINGS, UPDATE_SUCCESS } from '../types';

const initial_state = {
  loading: false,
  snackbar: false,
  snackbar_auto_hide: 3000,
  snackbar_action: '',
  snackbar_message: '',
  snackbar_label: '',
  sw_registration: { waiting: false },
  tracking_error: true,
  error: null,
  service_worker: false,
};

export const settingsReducer = (state = { ...initial_state }, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return { ...state, ...action.payload };
    case UPDATE_SUCCESS:
      return { ...state, ...action.payload };
    case CLEAR_SETTINGS:
      return { ...initial_state };
    default:
      return state;
  }
};
